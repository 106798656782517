import React, { FocusEvent, FC, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Card from '@empiriecom/module-components/Card';
import TouchDetectingDateInput from '@empiriecom/module-components/FormControls/Input/DateInput';
import Input from '@empiriecom/module-components/FormControls/Input';
import { Cell, Grid } from '@empiriecom/module-components/Grid';
import { Modal } from '@empiriecom/module-components/Modal';
import { Headline } from '@empiriecom/module-components/Headline';
import { useMedia } from 'react-media-match';
import { ReturnDeclarationInlineErrorTrackingData } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/ReturnDeclarationInlineErrorTrackingData';
import getIcon from '@empiriecom/module-components/utils/getIcon';

const StyledCard = styled(Card)<{ isMobile: boolean }>`
  margin-bottom: 1rem;
  width: ${({ isMobile }) => (isMobile ? '21rem' : '24rem')};
`;

const InputWrapper = styled.div`
  padding-top: 1.125rem;
`;

// solve issue of text exploding cells
// see https://css-tricks.com/preventing-a-grid-blowout/
const StyledGrid = styled(Grid)`
  width: 100%;
  align-items: center;

  > * {
    min-width: 0;
  }
`;

const CardContent = styled.div`
  padding: 1rem 1rem 1.5rem;
`;

const IconWrapper = styled.div`
  margin-top: 1.25rem;

  svg {
    fill: ${({ theme }) => theme.global.color.textLight};
  }
`;

const StyledIcon = styled(getIcon('Information'))`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  margin: 0 0.25rem;
  min-width: 1.25rem;
  border-radius: 100%;
  min-height: 1.25rem;
  vertical-align: middle;
  border: 5px solid ${({ theme }) => theme.info.color.normal};
  background-color: ${({ theme }) => theme.info.color.normal};
`;

const StyledHeadline = styled(Headline)`
  line-height: 1.5;
  margin-top: 1rem;
  text-align: center;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalContent = styled.div`
  padding: 0 0.5rem;
  max-width: 30rem;
  text-align: center;
  font-size: 0.813rem;
`;

const GreyBox = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  height: 100%;
  padding: 1rem;
`;

const cardHeadlines = defineMessages({
  returnDeclarationCardFirstReturn: {
    id: 'ReturnDeclarationCard.Return.First',
    defaultMessage: 'Rücksendung',
  },
  returnDeclarationCardSecondReturn: {
    id: 'ReturnDeclarationCard.Return.Second',
    defaultMessage: 'Zweite Rücksendung',
  },
  returnDeclarationCardThirdReturn: {
    id: 'ReturnDeclarationCard.Return.Third',
    defaultMessage: 'Dritte Rücksendung',
  },
  returnDeclarationCardFourthReturn: {
    id: 'ReturnDeclarationCard.Return.Fourth',
    defaultMessage: 'Vierte Rücksendung',
  },
  returnDeclarationCardFifthReturn: {
    id: 'ReturnDeclarationCard.Return.Fifth',
    defaultMessage: 'Fünfte Rücksendung',
  },
  returnDeclarationCardSixthReturn: {
    id: 'ReturnDeclarationCard.Return.Sixth',
    defaultMessage: 'Sechste Rücksendung',
  },
  returnDeclarationCardSeventhReturn: {
    id: 'ReturnDeclarationCard.Return.Seventh',
    defaultMessage: 'Siebte Rücksendung',
  },
  returnDeclarationCardEighthReturn: {
    id: 'ReturnDeclarationCard.Return.Eighth',
    defaultMessage: 'Achte Rücksendung',
  },
  returnDeclarationCardNinthReturn: {
    id: 'ReturnDeclarationCard.Return.Ninth',
    defaultMessage: 'Neunte Rücksendung',
  },
  returnDeclarationCardTenthReturn: {
    id: 'ReturnDeclarationCard.Return.Tenth',
    defaultMessage: 'Zehnte Rücksendung',
  },
});

const messages = defineMessages({
  returnDeclarationCardDateInputLabel: {
    id: 'ReturnDeclarationCard.DateInput.Label',
    defaultMessage: 'Abgabedatum der Rücksendung',
  },
  returnDeclarationCardDateInputError: {
    id: 'ReturnDeclarationCard.DateInput.Error',
    defaultMessage: 'Das Abgabedatum deiner Rücksendung liegt zu weit zurück',
  },
  returnDeclarationCardDateInputFutureError: {
    id: 'ReturnDeclarationCard.DateInput.Future.Error',
    defaultMessage: 'Verwende {date} oder einen früheren Wert',
  },
  returnDeclarationCardDateInputFormatError: {
    id: 'ReturnDeclarationCard.DateInput.Format.Error',
    defaultMessage: 'Bitte korrigiere das Datum (Format: TT.MM.JJJJ)',
  },
  returnDeclarationCardInputLabel: {
    id: 'ReturnDeclarationCard.Input.Label',
    defaultMessage: 'Sendungsnummer',
  },
  returnDeclarationCardInputPlaceholder: {
    id: 'ReturnDeclarationCard.Input.Placeholder',
    defaultMessage: 'z.B. 123456789',
  },
  returnDeclarationCardInfoModalHeadline: {
    id: 'ReturnDeclarationCard.InfoModal.Headline',
    defaultMessage: 'Abgabedatum der Rücksendung',
  },
  returnDeclarationCardMissingInputInlineError: {
    id: 'ReturnDeclarationCard.MissingInput.InlineError',
    defaultMessage: 'Bitte vervollständige deine Eingabe oder lösche diese',
  },
});

export interface ReturnDeclarationData extends ReturnDeclarationInlineErrorTrackingData {
  missingInputError?: boolean;
}

const ReturnDeclarationCard: FC<{
  index?: number;
  onCardDelete?: (index: number) => void;
  trackingData: ReturnDeclarationData;
  handleDataInputCallback?: (data: ReturnDeclarationData, index: number) => void;
}> = ({ index = 0, onCardDelete = () => {}, trackingData, handleDataInputCallback = () => {} }) => {
  const { formatMessage } = useIntl();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [dateError, setDateError] = useState<string>('');
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const [inputFormState, setInputFormState] = useState<ReturnDeclarationData>(trackingData);

  const maxDate = new Date();
  // 12 weeks in the past
  const minDate = new Date(new Date().setDate(maxDate.getDate() - 12 * 7));

  useEffect(() => {
    setInputFormState(trackingData);
  }, [trackingData]);

  useEffect(() => {
    handleDataInputCallback(inputFormState, index);
  }, [inputFormState]);

  const validateInline = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setDateError('');

    if (!value) {
      setDateError(formatMessage(messages.returnDeclarationCardDateInputFormatError));
      return true;
    }

    if (value && value < minDate.toISOString().split('T')[0]) {
      setDateError(formatMessage(messages.returnDeclarationCardDateInputError));
      return true;
    }

    if (value && value > maxDate.toISOString().split('T')[0]) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const today = new Date().toLocaleDateString('de-DE', options);

      setDateError(
        formatMessage(messages.returnDeclarationCardDateInputFutureError, {
          date: today,
        }),
      );
      return true;
    }

    return false;
  };

  const handleDateChange = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const hasError = validateInline(e);

    if (!hasError) {
      setInputFormState({ ...inputFormState, date: value, missingInputError: undefined });
    } else {
      setInputFormState({ ...inputFormState, date: '', missingInputError: undefined });
    }
  };

  const handleInputChange = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setInputFormState({
      ...inputFormState,
      trackingId: value,
      error: undefined,
      missingInputError: undefined,
    });
  };

  const handleDelete = () => {
    onCardDelete(index);
  };

  return (
    <StyledCard
      isMobile={isMobile}
      showHeaderSeparator
      headline={formatMessage(Object.values(cardHeadlines)[index])}
      action={index > 0 ? { type: 'DELETE', onClick: () => handleDelete() } : undefined}
    >
      <CardContent data-testid={`return-declaration-card-${index}`}>
        <StyledGrid fractions="8fr 1fr" colGap="0.75rem">
          <Cell>
            <TouchDetectingDateInput
              maxDate={maxDate}
              minDate={minDate}
              error={dateError}
              onBlur={handleDateChange}
              value={inputFormState.date}
              data-testid={`return-declaration-date-input-${index}`}
              hasErrorStyle={!!dateError || inputFormState.missingInputError}
              label={formatMessage(messages.returnDeclarationCardDateInputLabel)}
            />
          </Cell>
          <Cell>
            <Modal
              open={showInfo}
              testIdClose="return-declaration-modal-close"
              onRequestClose={() => setShowInfo(false)}
              headline={formatMessage(messages.returnDeclarationCardInfoModalHeadline)}
            >
              <GreyBox>
                <ModalContentWrapper>
                  <StyledHeadline level={4}>
                    <FormattedMessage
                      id="ReturnDeclarationCard.InfoModal.Subheadline"
                      defaultMessage="Warum benötigen wir das Abgabedatum?"
                    />
                  </StyledHeadline>
                  <ModalContent>
                    <FormattedMessage
                      id="ReturnDeclarationCard.InfoModal.Content"
                      defaultMessage="Mit dem Datum können wir deine Sendungsdaten eindeutig in unserer Datenbank zuordnen."
                      values={{
                        br: <br />,
                      }}
                    />
                  </ModalContent>
                </ModalContentWrapper>
              </GreyBox>
            </Modal>
            <IconWrapper>
              <StyledIcon
                size="1.25rem"
                data-testid="return-declaration-info"
                onClick={() => setShowInfo(!showInfo)}
              />
            </IconWrapper>
          </Cell>
        </StyledGrid>
        <InputWrapper>
          <Input
            onChange={handleInputChange}
            value={inputFormState.trackingId}
            data-testid={`return-declaration-tracking-input-${index}`}
            hasErrorStyle={!!inputFormState.error || inputFormState.missingInputError}
            label={formatMessage(messages.returnDeclarationCardInputLabel)}
            placeholder={formatMessage(messages.returnDeclarationCardInputPlaceholder)}
            error={
              inputFormState.error ||
              (inputFormState.missingInputError
                ? formatMessage(messages.returnDeclarationCardMissingInputInlineError)
                : undefined)
            }
          />
        </InputWrapper>
      </CardContent>
    </StyledCard>
  );
};

export default ReturnDeclarationCard;
