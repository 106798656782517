import React, { FC, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useMedia } from 'react-media-match';

import { Headline } from '@empiriecom/module-components/Headline';
import Card, { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import { Theme } from '@empiriecom/module-components/ThemeProvider';
import { Button } from '@empiriecom/module-components/Button';
import { Modal } from '@empiriecom/module-components/Modal';
import Notification from '@empiriecom/module-components/Notification';

import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import Loader from '@empiriecom/module-components/Loader';
import {
  CustomerDataWithStatus,
  ExtendedCustomerApiInterface,
  GrantSepaDirectDebitAuthorisationRequest,
  GrantSepaDirectDebitAuthorisationResult,
  SaveSepaBankAccountRequest,
  StoredPaymentDataCreditCardBrandEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import SepaBankAccount from '@empiriecom/mybuy-components/components/SepaBankAccount';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import EventTracking from '../../EventTracking';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { bridge } from '@empiriecom/mybuy-session/Bridge';

const messages = defineMessages({
  deleteButton: {
    id: 'PaymentSettings.deleteButton',
    defaultMessage: 'löschen',
  },
  confirmDeleteButton: {
    id: 'PaymentSettings.confirmDeleteButton',
    defaultMessage: 'löschen bestätigen',
  },
  sepaHeadline: {
    id: 'PaymentSettings.directDebit.new',
    defaultMessage: 'SEPA-Lastschriftmandat',
  },
  confirmDelete: {
    id: 'PaymentSettings.confirmDelete',
    defaultMessage: 'Löschen kann nicht rückgängig gemacht werden',
  },
  successDeleteDirectDebit: {
    id: 'PaymentSettings.successDeleteDirectDebit',
    defaultMessage: 'Bankdaten wurden erfolgreich gelöscht',
  },
  successDeleteCreditCard: {
    id: 'PaymentSettings.successDeleteCreditCard',
    defaultMessage: 'Kreditkartendaten wurden erfolgreich gelöscht',
  },
  sepaDownload: {
    id: 'PaymentSettings.directDebit.download',
    defaultMessage: 'SEPA Mandat herunterladen',
  },
});

const DownloadIcon = getIcon('Download');

const StyledHeadline = styled(Headline)<{ isMobile: boolean }>`
  margin: 0 0 1rem 0;
  ${({ isMobile }) => isMobile && 'text-align: center;'}
  background-color: ${({ theme }) => theme.base.color.normal};
  padding: 0.5rem;
`;

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const New = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border: 1px dashed ${({ theme }: { theme: Theme }) => theme.greyNormal.color.normal};
  padding: 1rem;
  text-align: center;
  margin: 0.5rem 0 1rem 0;
  font-weight: bold;
  cursor: pointer;
`;

const PlusIcon = styled.div`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  flex-grow: 0;
  padding: 1rem 0.969rem 1rem 1.031rem;
  border: solid 1px ${({ theme }: { theme: Theme }) => theme.primary.color.text};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardHead = styled.div`
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.5rem;
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Space = styled.div`
  height: 0.5rem;
`;

const NotificationWrapper = styled.div``;

const PaymentSettings: FC<{ customerMockData?: CustomerDataWithStatus }> = ({
  customerMockData,
}): JSX.Element => {
  const intl = useIntl();
  const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
  const [confirmDeleteDirectDebit, setConfirmDeleteDirectDebit] = useState<boolean>(false);
  const [confirmDeleteCreditCard, setConfirmDeleteCreditCard] = useState<boolean>(false);
  const [successDeleteDirectDebit, setSuccessDeleteDirectDebit] = useState<boolean>(false);
  const [successDeleteCreditCard, setSuccessDeleteCreditCard] = useState<boolean>(false);
  const [downloadingSepa, setDownloadingSepa] = useState<boolean>(false);
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const customerContext = useCustomerContext();
  const customerApi = useCustomerApi();
  const {
    images: { mastercard, amex, diners, discover, visa },
  }: FragmentConfig = useConfig();

  // MockData is only used für Storybook and Testing
  // customer shoud not be null, otherwise PersonalDataContainer don't render this
  const customer = customerMockData || customerContext.customer || ({} as CustomerDataWithStatus);

  const removeDirectDebit = async () => {
    const result = await customerApi!.revokeSepaDirectDebitAuthorisation({
      ecLocale: customerContext.ecLocale,
    });
    if (result.customer) {
      await customerContext.actions.update(result.customer);
      setConfirmDeleteDirectDebit(false);
      setSuccessDeleteDirectDebit(true);
    }
  };

  const downloadSepaData = async () => {
    const cookie = (typeof document !== 'undefined' && document.cookie) || '';
    const isAppView = cookie.split(';').some((item) => item.trim().startsWith('appView='));

    setDownloadingSepa(true);
    try {
      if (isAppView) {
        const urlForDownloadSepaMandate = (
          customerApi as ExtendedCustomerApiInterface
        ).getUrlForDownloadSepaMandateByGET({});

        // istanbul ignore else
        if (bridge && bridge.downloadDocument) {
          bridge.downloadDocument(
            `${window.location.protocol}//${window.location.hostname}${urlForDownloadSepaMandate}`,
          );
        }
      } else {
        const data = await customerApi?.downloadSepaMandate({ ecLocale: customerContext.ecLocale });
        /* istanbul ignore next -> test all that cases if you have a lot of time and are bored */
        if (data) {
          const filename = 'SEPAMandatData.pdf';
          const blob = new Blob([data]);
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            const objectURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectURL;
            // safari doesn't support this yet
            if (typeof link.download === 'undefined') {
              window.location.href = objectURL;
            } else {
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
            }
          }
        }
      }
      setDownloadingSepa(false);
    } catch (e) {
      /* istanbul ignore next */
      setDownloadingSepa(false);
    }
  };

  const removeCreditCard = async () => {
    const result = await customerApi!.deleteCreditCard({ ecLocale: customerContext.ecLocale });
    if (result.customer) {
      await customerContext.actions.update(result.customer);
      setConfirmDeleteCreditCard(false);
      setSuccessDeleteCreditCard(true);
    }
  };

  const saveSepa = async (params: SaveSepaBankAccountRequest) => {
    // istanbul ignore else
    if (customerApi) {
      return customerApi.saveSepaBankAccount(params);
    }
    // istanbul ignore next - implement a test if you are bored
    return Promise.reject(new Error('No customer api'));
  };

  const confirmSepaWrapper = async (
    grantSepaDirectDebitAuthorisationRequest: GrantSepaDirectDebitAuthorisationRequest,
  ): Promise<GrantSepaDirectDebitAuthorisationResult> => {
    const confirmSepaResult = await customerApi!.grantSepaDirectDebitAuthorisation(
      grantSepaDirectDebitAuthorisationRequest,
    );
    // istanbul ignore else
    if (confirmSepaResult.customer) {
      customerContext.actions.update(confirmSepaResult.customer);
    }
    return confirmSepaResult;
  };

  return (
    <>
      <Modal
        open={newModalOpen}
        headline={intl.formatMessage(messages.sepaHeadline)}
        onRequestClose={() => {
          setNewModalOpen(false);
        }}
        testIdClose="paymentsettings-newmodal-close"
      >
        <div data-testid="paymentsettings-newmodal">
          <EventTracking eventCategory="personalData_directDebit" isLayer />
          <SepaBankAccount
            closeModal={setNewModalOpen}
            locale={customerContext.ecLocale}
            saveSepaBankAccount={saveSepa}
            grantSepaDirectDebitAuthorisation={confirmSepaWrapper}
          />
        </div>
      </Modal>
      <StyledHeadline level={2} stylingLevel={4} isMobile={isMobile}>
        <FormattedMessage
          id="PaymentSettings.Headline"
          defaultMessage="Gespeicherte Zahlungsarten"
        />
      </StyledHeadline>
      <GreyBox data-testid="paymentsettings-info">
        {!customer.payment.directDebit?.current && !customer.payment.creditCard ? (
          <>
            <BorderedPanel>
              <strong>
                <FormattedMessage
                  id="PaymentSettings.empty.hint.headline"
                  defaultMessage="Schneller & sicher einkaufen"
                />
              </strong>
              <br />
              <FormattedMessage
                id="PaymentSettings.empty.hint.text"
                defaultMessage="Für den Bankeinzug kannst du jederzeit Daten hinterlegen. Bei Kreditkarte ist das nur im Zuge einer Bestellung möglich. So brauchst du sie beim nächsten Einkauf nur noch kurz auswählen und sparst Zeit."
              />
            </BorderedPanel>
            {customer.payment.directDebit?.allowed && (
              <New
                tabIndex={0}
                onClick={() => {
                  setNewModalOpen(true);
                  setSuccessDeleteDirectDebit(false);
                }}
                onKeyDown={() => {
                  setNewModalOpen(true);
                  setSuccessDeleteDirectDebit(false);
                }}
              >
                <PlusIcon>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 6a1 1 0 0 1 1 1v8h8a1 1 0 1 1 0 2h-8v8a1 1 0 1 1-2 0v-8H7a1 1 0 1 1 0-2h8V7a1 1 0 0 1 1-1z"
                      fill="#262729"
                    />
                  </svg>
                </PlusIcon>
                <span>
                  <FormattedMessage
                    defaultMessage="Bankeinzug anlegen"
                    id="PaymentSettings.empty.addDirectDebit"
                  />
                </span>
              </New>
            )}
          </>
        ) : (
          <>
            {successDeleteDirectDebit && (
              <NotificationWrapper>
                <Notification
                  level="success"
                  isInline
                  content={intl.formatMessage(messages.successDeleteDirectDebit)}
                />
              </NotificationWrapper>
            )}
            {customer.payment.directDebit?.current && (
              <>
                {confirmDeleteDirectDebit && (
                  <NotificationWrapper>
                    <Notification
                      level="warning"
                      isInline
                      content={intl.formatMessage(messages.confirmDelete)}
                    />
                  </NotificationWrapper>
                )}
                <Card>
                  <CardHead>
                    <FormattedMessage
                      defaultMessage="Bankeinzug"
                      id="PaymentSettings.directDebit"
                    />
                  </CardHead>
                  <HR />
                  <CardContent>
                    <LeftRight>
                      <div>
                        <div>IBAN</div>
                        <div data-mf-replace-inner="**REMOVED**">
                          <strong>{customer.payment.directDebit.current.iban}</strong>
                        </div>
                        <div>BIC</div>
                        <div data-mf-replace-inner="**REMOVED**">
                          <strong>{customer.payment.directDebit.current.bic}</strong>
                        </div>
                      </div>
                      {!confirmDeleteDirectDebit && (
                        <Button layout="default" onClick={() => setConfirmDeleteDirectDebit(true)}>
                          {intl.formatMessage(messages.deleteButton)}
                        </Button>
                      )}
                      {confirmDeleteDirectDebit && (
                        <Button layout="error" onClick={() => removeDirectDebit()}>
                          {intl.formatMessage(messages.confirmDeleteButton)}
                        </Button>
                      )}
                    </LeftRight>
                  </CardContent>
                  <CardSeperator />
                  <CardContent>
                    <LeftRight>
                      <CardAction
                        tabIndex={0}
                        onClick={() => downloadSepaData()}
                        icon={
                          downloadingSepa ? (
                            <Loader size="1.5rem" />
                          ) : (
                            <DownloadIcon size="1.5rem" />
                          )
                        }
                        text={intl.formatMessage(messages.sepaDownload)}
                      />
                    </LeftRight>
                  </CardContent>
                </Card>
                <Space />
              </>
            )}
            {successDeleteCreditCard && (
              <NotificationWrapper data-testid="confirm-delete-creditcard">
                <Notification
                  level="success"
                  isInline
                  content={intl.formatMessage(messages.successDeleteCreditCard)}
                />
              </NotificationWrapper>
            )}
            {customer.payment.creditCard && (
              <>
                {confirmDeleteCreditCard && (
                  <NotificationWrapper data-testid="confirm-delete-creditcard">
                    <Notification
                      level="warning"
                      isInline
                      content={intl.formatMessage(messages.confirmDelete)}
                    />
                  </NotificationWrapper>
                )}
                <Card>
                  <CardHead>
                    <FormattedMessage
                      defaultMessage="Kreditkarte"
                      id="PaymentSettings.creditCard"
                    />
                  </CardHead>
                  <HR />
                  <CardContent>
                    <LeftRight>
                      <LeftRight>
                        <div>
                          <div>
                            <FormattedMessage
                              defaultMessage="Kartennummer"
                              id="PaymentSettings.creditCard.number"
                            />
                          </div>
                          <div data-mf-replace-inner="**REMOVED**">
                            <strong>{customer.payment.creditCard.number}</strong>
                          </div>
                          <div>
                            <FormattedMessage
                              defaultMessage="Gültig bis"
                              id="PaymentSettings.creditCard.validThru"
                            />
                          </div>
                          <div data-mf-replace-inner="**REMOVED**">
                            <strong>{customer.payment.creditCard.validThru}</strong>
                          </div>
                        </div>
                        {customer.payment.creditCard.brand ===
                          StoredPaymentDataCreditCardBrandEnum.VISA && (
                          <img src={visa.default} alt="visa" width="50px" />
                        )}
                        {customer.payment.creditCard.brand ===
                          StoredPaymentDataCreditCardBrandEnum.MASTERCARD && (
                          <img src={mastercard.default} alt="mastercard" width="50px" />
                        )}
                        {customer.payment.creditCard.brand ===
                          StoredPaymentDataCreditCardBrandEnum.AMERICANEXPRESS && (
                          <img src={amex.default} alt="amex" width="50px" />
                        )}
                        {customer.payment.creditCard.brand ===
                          StoredPaymentDataCreditCardBrandEnum.DINERS && (
                          <img src={diners.default} alt="diners" width="50px" />
                        )}
                        {customer.payment.creditCard.brand ===
                          StoredPaymentDataCreditCardBrandEnum.DISCOVER && (
                          <img src={discover.default} alt="discover" width="50px" />
                        )}
                      </LeftRight>
                      {!confirmDeleteCreditCard && (
                        <div>
                          <Button layout="default" onClick={() => setConfirmDeleteCreditCard(true)}>
                            {intl.formatMessage(messages.deleteButton)}
                          </Button>
                        </div>
                      )}
                      {confirmDeleteCreditCard && (
                        <Button layout="error" onClick={() => removeCreditCard()}>
                          {intl.formatMessage(messages.confirmDeleteButton)}
                        </Button>
                      )}
                    </LeftRight>
                  </CardContent>
                </Card>
                <Space />
                {!customer.payment.directDebit?.current &&
                  customer.payment.directDebit?.allowed && (
                    <New
                      tabIndex={0}
                      onClick={() => {
                        setNewModalOpen(true);
                        setSuccessDeleteDirectDebit(false);
                      }}
                      onKeyDown={() => {
                        setNewModalOpen(true);
                        setSuccessDeleteDirectDebit(false);
                      }}
                    >
                      <span>
                        <FormattedMessage
                          defaultMessage="Bankeinzug anlegen"
                          id="PaymentSettings.empty.addDirectDebit"
                        />
                      </span>
                    </New>
                  )}
              </>
            )}
            <BorderedPanel>
              <strong>
                <FormattedMessage
                  id="PaymentSettings.hint.headline"
                  defaultMessage="Woher stammen die Daten?"
                />
              </strong>
              <br />
              <FormattedMessage
                id="PaymentSettings.hint.text"
                defaultMessage="Hier findest du die Daten, die du für weitere Einkäufe hinterlegt hast. Per Klick auf „löschen“ kannst du die Daten natürlich endgültig entfernen."
              />
            </BorderedPanel>
          </>
        )}
      </GreyBox>
    </>
  );
};

export default PaymentSettings;
