import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';

import { Button, Modal, Select } from '@empiriecom/module-components';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';

import useBookings, {
  BookingListData,
} from '@/src/components/BookingContainer/BookingList/useBookings';
import { GetBookingsTypeEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import BookingElement from '@/src/components/BookingContainer/BookingList/BookingListElement';
import { usePageContext } from '@/src/components/Pages/PageProvider';
import EInvoice from '@/src/components/EInvoice';
import BookingSkeleton from './skeleton';
import EventTracking from '../../EventTracking';
import Notification from '@empiriecom/module-components/Notification';

const LoadReload = getIcon('LoadReload');

const messages = defineMessages({
  defaultAsyncErrorMessage: {
    id: 'BookingListContainer.BookingList.defaultAsyncErrorMessage',
    defaultMessage:
      'Entschuldige bitte, wir können deine Buchungen zur Zeit leider nicht abholen. <reload>Bitte lade die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuche es dann später noch einmal. Vielen Dank für dein Verständnis.',
  },
  emptyBookingListHeadline: {
    id: 'BookingListContainer.BookingList.emptyBookingListHeadline',
    defaultMessage: 'Keine Buchungen?',
  },
  emptyBookingListText: {
    id: 'BookingListContainer.BookingList.emptyBookingListText',
    defaultMessage:
      'Dann hast du noch nicht bei uns bestellt - stöbere in unserem vielfältigen Sortiment und sichere dir dein neues Lieblingsteil!',
  },
  fullViewSelectionText: {
    id: 'BookingListContainer.BookingList.fullViewSelectionText',
    defaultMessage: 'Alle Buchungen',
  },
  invoiceViewSelectionText: {
    id: 'BookingListContainer.BookingList.invoiceViewSelectionText',
    defaultMessage: 'Rechnungen',
  },
  paymentViewSelectionText: {
    id: 'BookingListContainer.BookingList.paymentViewSelectionText',
    defaultMessage: 'Zahlungen',
  },
  reimbursementViewSelectionText: {
    id: 'BookingListContainer.BookingList.reimbursementViewSelectionText',
    defaultMessage: 'Rücksendungen',
  },
  loadMoreButton: {
    id: 'BookingListContainer.BookingList.loadMorebutton',
    defaultMessage: 'weitere laden',
  },
});

const BookingListWrapper = styled.div`
  display: block;
  padding: 1rem;
  background-color: ${({ theme }) => theme.greyLight.color.normal};

  & > * {
    margin-bottom: 1rem;
  }
`;

const BookingSpacer = styled.div`
  margin-bottom: 1rem;
`;

const BookingSelectWrapper = styled.div`
  display: block;
  width: 75%;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.base.color.normal};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-anchor: none;
`;

const BookingList: FC<{ mockData?: BookingListData }> = ({ mockData }): JSX.Element => {
  const intl = useIntl();
  const [bookingFilter, setBookingFilter] = React.useState<GetBookingsTypeEnum>(
    GetBookingsTypeEnum.All,
  );
  const { isLoading, isError, bookingListData, loadMoreBookings } = useBookings(bookingFilter);
  const realIsLoading = mockData ? mockData.isLoading : isLoading;
  const realIsError = mockData ? mockData.isError : isError;
  const realBookingListData = mockData ? mockData.bookingListData : bookingListData;

  const {
    hashParameters: { invoiceNumber, invoiceDate },
  } = usePageContext();

  const [openEInvoiceId, setOpenEInvoiceId] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    if (invoiceNumber && invoiceDate) {
      setOpenEInvoiceId(`NO:${invoiceNumber}:${invoiceDate}`);
    } else {
      setOpenEInvoiceId(undefined);
    }
  }, [invoiceNumber, invoiceDate]);

  const selectOptions = [
    {
      value: GetBookingsTypeEnum.All,
      displayName: intl.formatMessage(messages.fullViewSelectionText),
    },
    {
      value: GetBookingsTypeEnum.Invoice,
      displayName: intl.formatMessage(messages.invoiceViewSelectionText),
    },
    {
      value: GetBookingsTypeEnum.Payment,
      displayName: intl.formatMessage(messages.paymentViewSelectionText),
    },
    {
      value: GetBookingsTypeEnum.Reimbursement,
      displayName: intl.formatMessage(messages.reimbursementViewSelectionText),
    },
  ];

  return (
    <>
      <BookingListWrapper data-testid="booking-list-wrapper">
        <BookingSelectWrapper>
          <Select
            options={selectOptions}
            value={bookingFilter}
            onChange={(selectedValue) => {
              const selectedElement: GetBookingsTypeEnum =
                selectedValue as any as GetBookingsTypeEnum;
              setBookingFilter(selectedElement);
            }}
          />
        </BookingSelectWrapper>
        {realBookingListData.bookingList && realBookingListData.bookingList.length > 0 ? (
          <div>
            <BookingSpacer>
              {realBookingListData.bookingList.map((bookingEntry) => (
                <BookingSpacer key={bookingEntry.id}>
                  <BookingElement entry={bookingEntry} onEInvoiceOpen={setOpenEInvoiceId} />
                </BookingSpacer>
              ))}
            </BookingSpacer>
            {realBookingListData.nextDataId && (
              <ButtonWrapper>
                <Button
                  layout="default"
                  uppercase={false}
                  size="medium"
                  onClick={() => loadMoreBookings()}
                >
                  {intl.formatMessage(messages.loadMoreButton)}
                  <LoadReload size="22px" />
                </Button>
              </ButtonWrapper>
            )}
          </div>
        ) : (
          <>
            <BorderedPanel headlineText={intl.formatMessage(messages.emptyBookingListHeadline)}>
              <span>{intl.formatMessage(messages.emptyBookingListText)}</span>
            </BorderedPanel>
            {realIsError && (
              <Notification isInline level="warning">
                {intl.formatMessage(messages.defaultAsyncErrorMessage, {
                  reload: (chunks: ReactNode) => (
                    <a href="javascript:window.location.reload()">{chunks}</a>
                  ),
                })}
              </Notification>
            )}
          </>
        )}
      </BookingListWrapper>
      {realIsLoading && <BookingSkeleton />}
      {openEInvoiceId && (
        <Modal
          open
          size="large"
          onRequestClose={() => setOpenEInvoiceId(undefined)}
          testIdClose="booking-modal-close"
        >
          <EventTracking eventCategory="booking_eInvoice" isLayer />
          <EInvoice id={openEInvoiceId} />
        </Modal>
      )}
    </>
  );
};

export default BookingList;
