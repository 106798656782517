import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';
import { browserSupportsGrid, Cell, Grid } from '@empiriecom/module-components/Grid';
import { useMedia } from 'react-media-match';

const HeadlineSkeleton = styled(Skeleton)<{ isMobile: boolean }>`
  margin: 0 0 1rem 0;
  border-radius: 4px;
  width: 50%;
  ${({ isMobile }) =>
    isMobile &&
    `
    margin-left: auto;
    margin-right: auto;
  `}
`;

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const InlineGrid = styled(Grid)<{ gridSupported: boolean }>`
  ${({ gridSupported }) => gridSupported && 'display: inline-grid;'}
`;

const GreyBox = styled(Skeleton)`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const Card = styled.div`
  border-radius: 4px;
  background-color: #fff;
`;

const Space = styled.div`
  height: 0.5rem;
`;

const CardHead = styled.div`
  padding: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Circle = styled(Skeleton)`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 0 1rem 0 0;
`;

const CardContent = styled.div`
  padding: 0.5rem;
`;

const MediumLine = styled(Skeleton)`
  width: 8rem;
  margin: 0.25rem 0 0 0;
`;

const ShortLine = styled(Skeleton)`
  width: 5rem;
  margin: 0.25rem 0 0 0;
`;

const LargeLine = styled(Skeleton)`
  width: 10rem;
  margin: 0.25rem 0 0 0;
`;

const HR = styled(Skeleton)`
  width: 100%;
  margin: 0.5rem 0;
`;

const PersonalDataContainerSkeleton: FunctionComponent = (): JSX.Element => {
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  return (
    <>
      <StyledGrid cellsPerRow={[1, 2, 2]} colGap="1rem">
        <Cell>
          {/* Left side first box */}
          <HeadlineSkeleton height="2rem" isMobile={isMobile} />
          <GreyBox height="auto">
            <Card>
              <CardHead>
                <LeftRight>
                  <Circle height="2rem" />
                  <div>
                    <MediumLine height="1.25rem" />
                    <ShortLine height="1rem" />
                  </div>
                </LeftRight>
              </CardHead>
              <HR height="0" />
              <CardContent>
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
                <InlineGrid
                  cellsPerRow={[2, 2, 2]}
                  colGap="0.25rem"
                  gridSupported={browserSupportsGrid()}
                >
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                  <Cell>
                    <ShortLine height="1rem" />
                  </Cell>
                </InlineGrid>
                <HR height="0" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <ShortLine height="1rem" />
                <ShortLine height="1rem" />
                <ShortLine height="1rem" />
              </CardContent>
            </Card>
          </GreyBox>
          {/* Left side second box */}
          <HeadlineSkeleton height="2rem" isMobile={isMobile} />
          <GreyBox height="auto">
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
            <Space />
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
          </GreyBox>
        </Cell>
        <Cell>
          {/* Right side first box */}
          <HeadlineSkeleton height="2rem" isMobile={isMobile} />
          <GreyBox height="auto">
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
            <Space />
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <MediumLine height="1rem" />
                <ShortLine height="1rem" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
          </GreyBox>
          {/* Right side second box */}
          <HeadlineSkeleton height="2rem" isMobile={isMobile} />
          <GreyBox height="auto">
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
            <Space />
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
          </GreyBox>
        </Cell>
      </StyledGrid>
    </>
  );
};

export default PersonalDataContainerSkeleton;
