import React from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const EInvoiceSkeletonBlock = styled(Skeleton)`
  display: block;
  width: 100%;
  margin: 1rem;
  border-radius: 4px;
`;

const EInvoiceSkeleton: React.FC = () => (
  <div data-testid="einvoice-skeleton">
    <EInvoiceSkeletonBlock height="15rem" />
    <EInvoiceSkeletonBlock height="15rem" />
    <EInvoiceSkeletonBlock height="15rem" />
    <EInvoiceSkeletonBlock height="15rem" />
  </div>
);

export default EInvoiceSkeleton;
