import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';

import { GetShipmentInfosRequest } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { useShipmentsContext } from '@/src/components/AccountProvider/useShipmentsContext';

type UseShipmentInfosParams = GetShipmentInfosRequest;

export function useShipmentInfos(params?: UseShipmentInfosParams) {
  const { type, ecLocale, days } = params || {};

  const { load, cancel, data, loadMore: loadNextPage } = useShipmentsContext();

  const { pushNotification } = useNotificationContext();

  const [isLoading, setLoading] = useState<boolean>(false);

  const displayError = useCallback(
    (content: string | JSX.Element) =>
      pushNotification({
        autoClose: 5000,
        hasClose: true,
        level: 'error',
        content,
      }),
    [],
  );

  useEffect(() => {
    if (load && params && !isLoading) {
      setLoading(true);
      load(params)
        .catch(() =>
          displayError(
            <FormattedMessage
              defaultMessage="Beim Laden der Bestellungen ist ein Fehler aufgetreten."
              id="ShipmentInfos.request.error.message"
            />,
          ),
        )
        .finally(() => setLoading(false));
    }
  }, [load, type, ecLocale, days]);

  const loadMore = loadNextPage
    ? () => {
        setLoading(true);
        loadNextPage()
          .catch(displayError)
          .finally(() => setLoading(false));
      }
    : undefined;

  const cancelOrder = cancel
    ? (id: string, onSuccess?: () => void) => {
        cancel(id, onSuccess).catch(() => {
          displayError(
            <FormattedMessage
              defaultMessage="Beim Stornieren der Bestellung ist ein Fehler aufgetreten."
              id="ShipmentInfos.cancel.error.message"
            />,
          );
        });
      }
    : undefined;

  return {
    isLoading,
    infos: data,
    load,
    loadMore,
    cancelOrder,
  };
}

export default useShipmentInfos;
