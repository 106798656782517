import React, { FC } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const HeadlineSkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
  width: 30%;
`;

const SkeletonBlock = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const ReturnDeclarationSkeleton: FC = () => (
  <div data-testid="return-skeleton">
    <HeadlineSkeleton height="2rem" />
    <SkeletonBlock height="10rem" />
    <SkeletonBlock height="10rem" />
    <SkeletonBlock height="10rem" />
    <SkeletonBlock height="10rem" />
    <SkeletonBlock height="10rem" />
  </div>
);

export default ReturnDeclarationSkeleton;
