import React from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';

import getIcon from '@empiriecom/module-components/utils/getIcon';
import { Button } from '@empiriecom/module-components/Button';
import { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import Modal from '@empiriecom/module-components/Modal';
import {
  ShipmentInfosPackages,
  ShipmentInfosShipmentList,
  ShipmentInfosShipmentListTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import EInvoice from '@/src/components/EInvoice';
import ReturnShipmentInfo from '../ReturnShipmentInfo';
import { downloadReturnShipmentLabel } from '@/src/components/ShipmentInfosContainer/ReturnShipmentInfo';
import { Link } from '@empiriecom/module-components/Link';
import { ShipmentProgress } from '../ShipmentProgress';
import EventTracking from '../../EventTracking';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import { useCustomerContext } from '@empiriecom/mybuy-components';
import {
  PushNotificationProps,
  useNotificationContext,
} from '@empiriecom/module-components/Notification/NotificationProvider';
import Loader from '@empiriecom/module-components/Loader';
import { isAppView } from '@/src/components/PersonalDataContainer/DataExport';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const InvoiceIcon = getIcon('Invoice');
const ReturnslipIcon = getIcon('Returnslip');

const Flex = styled.div`
  display: flex;
`;

const Columns = styled(Flex)`
  flex-direction: column;
`;

const Root = styled.div`
  padding: 1rem;

  .w-full {
    width: 100%;
  }

  .mt-1 {
    margin-top: 1rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mr-025 {
    margin-right: 0.25rem;
  }
`;

const DataContainer = styled(Flex)`
  flex-wrap: wrap;

  > div {
    flex-wrap: wrap;
    align-content: baseline;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
      width: 50%;
    }
  }
  > div > * {
    width: 50%;

    margin: 0 0 0.5rem 0;
  }

  .address {
    @media (max-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
      margin: 1rem 0;
    }
  }
`;

const SmallText = styled.span`
  font-size: 0.813rem;
`;
const BigText = styled.span`
  font-size: 0.938rem;
`;

const SmallTextGrey = styled(SmallText)`
  color: #a0a0a0;
`;

const BoldText = styled(SmallText)`
  font-weight: bold;
`;
const BoldTextUpperCase = styled(BoldText)`
  text-transform: uppercase;
`;

const BoldBigText = styled(BoldText)`
  font-size: 0.938rem;
`;

const StyledShipmentProgress = styled(ShipmentProgress)`
  margin-bottom: 1.375rem;
`;

const PackageActionContainer = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      min-width: 18.188rem;
      margin-right: 1.688rem;
    }
  }
`;
const ReturnButton = styled(Button)`
  margin-bottom: 2rem;
  margin-top: 0.75rem;
`;

const messages = defineMessages({
  orderDate: {
    id: 'ShipmentCardDetails.label.orderDate',
    defaultMessage: 'Bestelldatum',
  },
  paymentType: {
    id: 'ShipmentCardDetails.label.paymentType',
    defaultMessage: 'Zahlungsart',
  },
  deliveryAddress: {
    id: 'ShipmentCardDetails.label.deliveryAddress',
    defaultMessage: 'Lieferanschrift',
  },
  carrier: {
    id: 'ShipmentCardDetails.label.carrier',
    defaultMessage: 'Versandpartner',
  },
  deliveryService: {
    id: 'ShipmentCardDetails.label.deliveryService',
    defaultMessage: 'Lieferservice',
  },
  packageType: {
    id: 'ShipmentCardDetails.label.packageType',
    defaultMessage: 'Verpackungsart',
  },
  numberOfPackages: {
    id: 'ShipmentCardDetails.label.numberOfPackages',
    defaultMessage: 'Paketanzahl',
  },
  invoiceLink: {
    id: 'ShipmentCardDetails.label.invoiceLink',
    defaultMessage: 'Rechnung ansehen',
  },
  trackingId: {
    id: 'ShipmentCardDetails.label.trackingId',
    defaultMessage: 'Sendungs ID',
  },
  noTrackingAvailable: {
    id: 'ShipmentCardDetails.label.noTrackingAvailable',
    defaultMessage: 'Es sind keine Track & Trace-Informationen verfügbar',
  },
  hint: {
    id: 'ShipmentCardDetails.label.hint',
    defaultMessage: 'Hinweis',
  },
  awaitingPaymentHint: {
    id: 'ShipmentCardDetails.hint.downpayment',
    defaultMessage: 'Bitte beachte unsere Anzahlungshinweise an den einzelnen Artikeln',
  },
  desiredDeliveryLink: {
    id: 'ShipmentCardDetails.link.desiredDelivery',
    defaultMessage: 'Wunschzustellung {optPackageNo}',
  },
  trackingLink: {
    id: 'ShipmentCardDetails.link.tracking',
    defaultMessage: 'Sendungsverfolgung {optPackageNo}',
  },
  packageIndicator: {
    id: 'ShipmentCardDetails.package.indicator',
    defaultMessage: '(Paket {current} von {total})',
  },
  deliveredHint: {
    id: 'ShipmentCardDetails.hint.delivered',
    defaultMessage: 'Details zum Standort deines Pakets findest du über die Sendungsverfolgung.',
  },
  returnLabel: {
    id: 'ShipmentInfosShipmentListType.returnLabel',
    defaultMessage: 'Rücksende-Etikett',
  },
  returnLabelCreated: {
    id: 'ShipmentCardDetails.returnLabelCreated',
    defaultMessage: 'Rücksende-Etikett erstellt',
  },
  returnLabelShow: {
    id: 'ShipmentCardDetails.link.returnLabelShow',
    defaultMessage: 'Rücksende-Etikett anzeigen',
  },
  trackingUrlError: {
    id: 'ShipmentCardDetails.trackingUrlError',
    defaultMessage: 'Etwas ist schief gelaufen. Bitte versuche es später erneut.',
  },
});

function Hint({ text }: { text: string }) {
  const intl = useIntl();
  return (
    <>
      <BoldTextUpperCase className="mr-025">{intl.formatMessage(messages.hint)}:</BoldTextUpperCase>

      <SmallText>{text}</SmallText>
      <CardSeperator className="w-full mb-1 mt-1" />
    </>
  );
}

function PackageInfo({
  packageInfo,
  carrier,
  packageNo,
  total,
}: {
  packageInfo: ShipmentInfosPackages;
  carrier: string;
  packageNo: number;
  total: number;
}) {
  const intl = useIntl();
  const addition =
    packageNo === total && total < 2
      ? ''
      : intl.formatMessage(messages.packageIndicator, { current: packageNo, total });
  const accountApi = useAccountApi();
  const { pushNotification } = useNotificationContext();
  const {
    shipmentInfos: { hideTrackingIdWithoutUrl },
  }: FragmentConfig = useConfig();

  const [resolvedTrackingUrl, setResolvedTrackingUrl] = React.useState<string | undefined>(
    undefined,
  );
  const { trackingUrl } = packageInfo;

  const errorNotification: PushNotificationProps = {
    autoClose: 2500,
    hasClose: true,
    level: 'error',
    children: <>{intl.formatMessage(messages.trackingUrlError)}</>,
  };

  React.useEffect(() => {
    if (trackingUrl && trackingUrl.indexOf('track://') === 0) {
      accountApi
        ?.resolveTrackAndTraceUrl({ urlContainer: { url: trackingUrl } })
        .then((urlContainer) => {
          setResolvedTrackingUrl(urlContainer.url);
        })
        .catch(() => {
          pushNotification(errorNotification);
        });
    } else {
      setResolvedTrackingUrl(trackingUrl);
    }
  }, [accountApi, trackingUrl]);

  return (
    <div data-mf-replace-inner="**REMOVED**">
      <Columns>
        <BoldBigText className="mt-1">{`${carrier} ${addition}`}</BoldBigText>
        <SmallText>{packageInfo.forecast}</SmallText>
        <SmallTextGrey className="mt-1">
          {!packageInfo.trackingUrl && hideTrackingIdWithoutUrl
            ? intl.formatMessage(messages.noTrackingAvailable)
            : `${intl.formatMessage(messages.trackingId)}: ${packageInfo.trackingId}`}
        </SmallTextGrey>
        <PackageActionContainer>
          {packageInfo.trackingUrl && resolvedTrackingUrl && (
            <Button className="mt-1">
              <a data-testid="shipment-tracking-url" target="_blank" href={resolvedTrackingUrl}>
                {intl.formatMessage(messages.trackingLink, { optPackageNo: addition })}
              </a>
            </Button>
          )}
          {packageInfo.desiredDeliveryUrl && (
            <Button className="mt-1" as="a">
              <Link target="_blank" to={packageInfo.desiredDeliveryUrl}>
                {intl.formatMessage(messages.desiredDeliveryLink, { optPackageNo: addition })}
              </Link>
            </Button>
          )}
        </PackageActionContainer>
      </Columns>
    </div>
  );
}

export function ShipmentCardDetails({ shipmentList }: { shipmentList: ShipmentInfosShipmentList }) {
  const intl = useIntl();
  const { ecLocale } = useCustomerContext();
  const { pushNotification } = useNotificationContext();
  const accountApi = useAccountApi();
  const [isEInvoiceOpen, setIsEInvoiceOpen] = React.useState<boolean>(false);

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = React.useState<boolean>(false);

  const isNativeApp = isAppView();

  return (
    <div>
      <Root>
        <StyledShipmentProgress shipmentList={shipmentList} />

        {shipmentList.type === ShipmentInfosShipmentListTypeEnum.AwaitingDownpayment && (
          <Hint text={intl.formatMessage(messages.awaitingPaymentHint)} />
        )}
        {shipmentList.type === ShipmentInfosShipmentListTypeEnum.Delivered && (
          <Hint text={intl.formatMessage(messages.deliveredHint)} />
        )}
        {shipmentList.type === ShipmentInfosShipmentListTypeEnum.ReturnlabelCreated && (
          <Flex>
            <Columns className="w-full">
              <BigText>{intl.formatMessage(messages.returnLabelCreated)}</BigText>
              <SmallText>{shipmentList.state}</SmallText>
              {!isDownloading && (
                <ReturnButton
                  type="submit"
                  onClick={() => (
                    downloadReturnShipmentLabel(
                      accountApi,
                      shipmentList.orderDetails?.returnInformation?.returnId!,
                      ecLocale,
                      setIsDownloading,
                      pushNotification,
                      intl,
                      isNativeApp,
                    ),
                    setIsReturnModalOpen(false)
                  )}
                  fullWidth
                >
                  {intl.formatMessage(messages.returnLabelShow)}
                </ReturnButton>
              )}
              {isDownloading && (
                <ReturnButton type="submit" disabled fullWidth>
                  <Loader size="2rem" />
                </ReturnButton>
              )}
              <CardSeperator className="w-full mt-1 mb-1" />
            </Columns>
          </Flex>
        )}
        {shipmentList.packages?.map((s, idx, arr) => (
          <React.Fragment key={idx}>
            <PackageInfo
              packageInfo={s}
              carrier={shipmentList.orderDetails?.carrier || '---'}
              packageNo={idx + 1}
              total={arr.length}
            />
            <CardSeperator className="w-full mb-1 mt-1" />
          </React.Fragment>
        ))}

        <DataContainer>
          <Flex>
            <BoldText>{intl.formatMessage(messages.orderDate)}</BoldText>
            <SmallText data-mf-replace-inner="**REMOVED**">
              {shipmentList.orderDetails?.orderDate}
            </SmallText>
            {shipmentList.type !== ShipmentInfosShipmentListTypeEnum.Returned && (
              <>
                <BoldText className="address">
                  {intl.formatMessage(messages.deliveryAddress)}
                </BoldText>
                <Columns className="address">
                  <SmallText data-mf-replace-inner="**REMOVED**">
                    {`${shipmentList.orderDetails?.deliveryAddress?.firstName} ${shipmentList.orderDetails?.deliveryAddress?.lastName}`}
                  </SmallText>
                  <SmallText data-mf-replace-inner="**REMOVED**">
                    {`${shipmentList.orderDetails?.deliveryAddress?.street} ${shipmentList.orderDetails?.deliveryAddress?.houseNumber}`}
                  </SmallText>
                  {shipmentList.orderDetails?.deliveryAddress?.additional && (
                    <SmallText data-mf-replace-inner="**REMOVED**">
                      {shipmentList.orderDetails?.deliveryAddress?.additional}
                    </SmallText>
                  )}
                  <SmallText data-mf-replace-inner="**REMOVED**">
                    {`${shipmentList.orderDetails?.deliveryAddress?.zipCode} ${shipmentList.orderDetails?.deliveryAddress?.city}`}
                  </SmallText>
                </Columns>
              </>
            )}
          </Flex>

          {shipmentList.type !== ShipmentInfosShipmentListTypeEnum.Returned && (
            <Flex>
              {shipmentList.orderDetails?.carrier && (
                <>
                  <BoldText>{intl.formatMessage(messages.carrier)}</BoldText>
                  <SmallText>{shipmentList.orderDetails?.carrier}</SmallText>
                </>
              )}
              {shipmentList.orderDetails?.deliveryService && (
                <>
                  <BoldText>{intl.formatMessage(messages.deliveryService)}</BoldText>
                  <SmallText>{shipmentList.orderDetails?.deliveryService}</SmallText>
                </>
              )}
              {shipmentList.orderDetails?.packageType && (
                <>
                  <BoldText>{intl.formatMessage(messages.packageType)}</BoldText>
                  <SmallText>{shipmentList.orderDetails?.packageType}</SmallText>
                </>
              )}
              {shipmentList.orderDetails?.numberOfPackages && (
                <>
                  <BoldText>{intl.formatMessage(messages.numberOfPackages)}</BoldText>
                  <SmallText>{shipmentList.orderDetails?.numberOfPackages}</SmallText>
                </>
              )}
            </Flex>
          )}
        </DataContainer>
      </Root>

      {shipmentList.orderDetails?.unifiedInvoiceId && (
        <>
          <CardSeperator className="w-full mt-1 mb-1" />
          <CardAction
            onClick={() => setIsEInvoiceOpen(true)}
            icon={<InvoiceIcon size="1rem" />}
            text={intl.formatMessage(messages.invoiceLink)}
          />
          {shipmentList.type === ShipmentInfosShipmentListTypeEnum.ReturnlabelCreated && (
            <>
              <CardSeperator className="w-full mt-1 mb-1" />
              <CardAction
                onClick={() => setIsReturnModalOpen(true)}
                icon={<ReturnslipIcon size="1rem" />}
                text={intl.formatMessage(messages.returnLabel)}
              />
              {isReturnModalOpen && (
                <ReturnShipmentInfo
                  shipment={shipmentList}
                  modalOverride={true}
                  modalOpen={isReturnModalOpen}
                  setModalOpen={setIsReturnModalOpen}
                />
              )}
            </>
          )}
          <Modal
            open={isEInvoiceOpen}
            size="large"
            testIdClose="shipping-einvoice-close"
            onRequestClose={() => setIsEInvoiceOpen(false)}
          >
            <EventTracking eventCategory="shipmentInfos_eInvoice" isLayer />
            <EInvoice id={shipmentList.orderDetails.unifiedInvoiceId} />
          </Modal>
        </>
      )}
    </div>
  );
}

export default ShipmentCardDetails;
