import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useMedia } from 'react-media-match';

import { Headline } from '@empiriecom/module-components/Headline';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import Button from '@empiriecom/module-components/Button';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import Loader from '@empiriecom/module-components/Loader';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';

const StyledHeadline = styled(Headline)<{ isMobile: boolean }>`
  margin: 0 0 1rem 0;
  ${({ isMobile }) => isMobile && 'text-align: center;'}
  background-color: ${({ theme }) => theme.base.color.normal};
  padding: 0.5rem;
`;

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const Subheadline = styled.div`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin: 1rem 0 0.5rem 0;
`;

const Content = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
`;

const messages = defineMessages({
  downloadError: {
    defaultMessage:
      'Leider ist beim Export der Daten ein Fehler unterlaufen. Versuche es zu einem späteren Zeitpunkt erneut.',
    id: 'DataExport.DownloadError',
  },
  downloadSuccess: {
    defaultMessage: 'Deine Daten wurden heruntergeladen.',
    id: 'DataExport.DownloadSuccess',
  },
});

// https://developer.mozilla.org/en-US/docs/web/api/document/cookie
export const isAppView = () => {
  const cookie = document?.cookie || '';
  return cookie.split(';').some((item) => item.trim().startsWith('appView='));
};

const DataExport: FC = (): JSX.Element => {
  const customerContext = useCustomerContext();
  const accountApi = useAccountApi();
  const [downloading, setDownloading] = useState(false);
  const { pushNotification } = useNotificationContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const showAppView = isAppView();

  const translate = useIntl();

  const downloadData = async () => {
    try {
      const data = await accountApi!.getDataExport({ ecLocale: customerContext.ecLocale });
      setDownloading(true);
      const filename = 'data.json';
      const blob = new Blob([data]);
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
        // istanbul ignore next - implement a test if you are bored
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        // safari doesn't support this yet
        if (typeof link.download === 'undefined') {
          // istanbul ignore next - implement a test if you are bored
          window.location.href = objectURL;
        } else {
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      }
      setDownloading(false);
      pushNotification({
        autoClose: 5000,
        hasClose: true,
        level: 'success',
        content: translate.formatMessage(messages.downloadSuccess),
      });
    } catch (e) {
      pushNotification({
        autoClose: 5000,
        hasClose: true,
        level: 'error',
        content: translate.formatMessage(messages.downloadError),
      });
      setDownloading(false);
    }
  };

  return (
    <>
      <StyledHeadline level={2} stylingLevel={4} isMobile={isMobile}>
        <FormattedMessage id="DataExport.Headline" defaultMessage="Datenexport" />
      </StyledHeadline>
      <GreyBox>
        {showAppView && (
          <BorderedPanel>
            <Content>
              <Subheadline>
                <FormattedMessage
                  id="DataExport.Subheadline"
                  defaultMessage="Deine Daten, deine Sache."
                />
              </Subheadline>
              <FormattedMessage
                id="DataExport.NoDownloadText"
                defaultMessage="Natürlich kannst du deine persönlichen Daten, die du angegeben hast, jederzeit herunterladen – ohne Auswirkungen auf dein Kundenkonto.{break}{break}Diese Funktion ist leider nur im Browser verfügbar. Melde dich einfach mit deinem Handy oder Computer auf deinem Browser an, um die Datei herunterzuladen."
                values={{
                  break: <br />,
                }}
              />
            </Content>
          </BorderedPanel>
        )}
        {!showAppView && (
          <BorderedPanel>
            <Content>
              <Subheadline>
                <FormattedMessage
                  id="DataExport.Subheadline"
                  defaultMessage="Deine Daten, deine Sache."
                />
              </Subheadline>
              <FormattedMessage
                id="DataExport.Text"
                defaultMessage="Natürlich kannst du deine persönlichen Daten, die du angegeben hast, jederzeit herunterladen – ohne Auswirkungen auf dein Kundenkonto.{break}{break}Alles bleibt wie gewohnt für dich nutzbar und bei uns gespeichert. So kannst du die Daten archivieren oder sie für einen anderen Dienst nutzen."
                values={{
                  break: <br />,
                }}
              />
            </Content>
            <ButtonWrapper>
              {!downloading && (
                <Button
                  layout="default"
                  size="small"
                  onClick={() => downloadData()}
                  uppercase={false}
                >
                  <FormattedMessage id="DataExport.Button" defaultMessage="Daten herunterladen" />
                </Button>
              )}
              {downloading && (
                <Button layout="default" disabled size="small" uppercase={false}>
                  <FormattedMessage
                    id="DataExport.DisabledButton"
                    defaultMessage="Exportiere... "
                  />
                  <Loader size="1rem" />
                </Button>
              )}
            </ButtonWrapper>
          </BorderedPanel>
        )}
      </GreyBox>
    </>
  );
};

export default DataExport;
