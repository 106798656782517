import React from 'react';
import { JoeCard } from '@empiriecom/mybuy-components/components/Joe/JoeCard';
import { JoeNotConnectedBox } from '@empiriecom/mybuy-components/components/Joe/JoeNotConnectedBox';
import { defineMessages, useIntl } from 'react-intl';
import useJoe from '../useJoe';

const messages = defineMessages({
  headline: {
    id: 'JoeConnectAccountCard.headline',
    defaultMessage: 'jö Bonus Club',
  },
});

export const JoeConnectAccountCard: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  const { refresh } = useJoe();
  const handleConnect = () => refresh();
  return (
    <JoeCard headline={formatMessage(messages.headline)}>
      <JoeNotConnectedBox
        mode="externallink"
        showRegisterLink
        showLogo
        showConnect
        onConnect={handleConnect}
      />
    </JoeCard>
  );
};

export default JoeConnectAccountCard;
