import React, { FC, useEffect } from 'react';
import { useTracking } from '@empiriecom/module-components/hooks/useTracking';
import { useIntl } from 'react-intl';

import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';

const NativeAppHeader: FC = (): JSX.Element => {
  const api = useCustomerApi();
  const dispatch = useTracking();
  const intl = useIntl();
  const { isLoggedIn } = useCustomerContext();

  useEffect(() => {
    if (api) {
      api
        .getInformation({
          ecLocale: intl.locale,
        })
        .then((customerInformation) => {
          dispatch({
            event: 'dataLayerReady',
            Sprache: intl.locale,
            ...customerInformation,
            visitor: {
              ...customerInformation.visitor,
              login: isLoggedIn,
            },
          });
        });
    }
  }, [api]);

  return <div data-testid="native-app-header"></div>;
};

export default NativeAppHeader;
