import React, { FC } from 'react';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerStatus from '@/src/hooks/useCustomerStatus';
import Notification from '@empiriecom/module-components/Notification';
import { FormattedMessage } from 'react-intl';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import styled from 'styled-components';

const Space = styled.div`
  margin-top: 0.5rem;
`;

const NotificationWrapper = styled.div`
  padding: 0.5rem 1rem 0;
  > div {
    a {
      text-decoration: underline;
    }
  }
`;

const PrepaymentHint: FC<{ className?: string }> = ({ className }): JSX.Element => {
  const { data } = useCustomerStatus();
  const { customer } = useCustomerContext();

  return (
    <>
      {customer && data && data.prepaymentHint && (
        <NotificationWrapper className={className}>
          <div data-mf-replace-inner="**REMOVED**">
            <Notification
              level="warning"
              isInline
              children={
                <FormattedMessage
                  id="PrepaymentHint.text"
                  defaultMessage="Die Bestellung wird erst bearbeitet, wenn eine Anzahlung in Höhe von {amount} bei uns eingegangen ist. Hinweis: Bestellte Artikel sind solange nicht reserviert.
                {space}
                Unsere Bankverbindung:
                {space}
                Quelle GmbH
                {break}
                Commerzbank AG
                {break}
                IBAN: DE58 7804 0081 0788 0990 00
                {break}
                BIC: COBADEFF780
                {space}
                Bitte geben Sie bei <strong>Verwendungszweck</strong> immer
                {break}
                folgendes an: <strong>{bankTransferReference}</strong>
                {space}
                Schnell, sicher, einfach – Jetzt zahlen per <link>EPS Online-Überweisung</link>
                "
                  values={{
                    amount: (
                      <FormattedCurrency
                        currency={data.prepaymentHint.amount.currencyCode}
                        value={data.prepaymentHint.amount.value}
                      />
                    ),
                    strong: (...chunks: string[]) => <strong>{chunks}</strong>,
                    link: (...chunks: string[]) => <a href={data.prepaymentHint?.link}>{chunks}</a>,
                    bankTransferReference: customer.bankTransferReference,
                    break: <br />,
                    space: <Space />,
                  }}
                />
              }
            />
          </div>
        </NotificationWrapper>
      )}
    </>
  );
};

export default PrepaymentHint;
