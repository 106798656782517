import React, { FC } from 'react';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import styled from 'styled-components';
import { Headline } from '@empiriecom/module-components/Headline';
import { FormattedMessage } from 'react-intl';
import { useMedia } from 'react-media-match';
import { Link } from 'react-router-dom';
import Button from '@empiriecom/module-components/Button';

const SuccessWrapper = styled.div`
  display: flex;
  padding: 2rem;
  max-width: 550px;
  text-align: center;
  align-items: center;
  font-size: 0.813rem;
  flex-direction: column;
`;

const StyledHeadline = styled(Headline)<{ isMobile: boolean }>`
  line-height: 1;
  margin-top: 1rem;
  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? '0.938rem' : '1.625rem')};
`;

const ButtonWrapper = styled.div`
  margin: 2rem 0;
  width: 12.5rem;
  max-width: 100%;

  a:link {
    color: ${({ theme }) => theme.primary.color.text};
  }
`;

const CheckmarkIcon = getIcon('Checkmark');
const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  fill: ${({ theme }) => theme.global.color.textLight};
`;

const CheckmarkIconWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: center;
  color: ${({ theme }) => theme.global.color.textLight};
  width: ${({ isMobile }) => (isMobile ? '3rem' : '4rem')};
  height: ${({ isMobile }) => (isMobile ? '3rem' : '4rem')};
  border: 1px solid ${({ theme }) => theme.success.color.normal};
  background-color: ${({ theme }) => theme.success.color.normal};
`;

const ReturnDeclarationSuccess: FC = () => {
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });

  return (
    <SuccessWrapper data-testid="return-declaration-success">
      <CheckmarkIconWrapper isMobile={isMobile}>
        <StyledCheckmarkIcon size={isMobile ? '2.5rem' : '3.5rem'} />
      </CheckmarkIconWrapper>
      <StyledHeadline level={3} isMobile={isMobile}>
        <FormattedMessage
          id="ReturnDeclarationContainer.Success.Headline"
          defaultMessage="Vielen Dank!"
        />
      </StyledHeadline>
      <div>
        <FormattedMessage
          id="ReturnDeclarationContainer.Success.Description"
          defaultMessage="Da wir nun wissen, dass dein Paket auf dem Weg zu uns ist, können wir dies direkt in unseren Systemen vormerken.{br}{br}Wir melden uns bei dir, wenn dein Paket bei uns eingegangen ist!"
          values={{
            br: <br />,
          }}
        />
      </div>
      <ButtonWrapper>
        <Button as={Link} layout="primary" to="/">
          <FormattedMessage
            id="ReturnDeclarationContainer.Success.GoToShop"
            defaultMessage="Zurück zum Shop"
          />
        </Button>
      </ButtonWrapper>
    </SuccessWrapper>
  );
};

export default ReturnDeclarationSuccess;
