import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Notification from '@empiriecom/module-components/Notification';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const messages = defineMessages({
  delayedDeliveryWarning: {
    id: 'MyAccount.Notification.DelayedDeliveryWarning',
    defaultMessage:
      'Aufgrund der aktuellen Hochwasserlage können unsere Dienstleister nicht alle Gebiete hindernisfrei erreichen. Deshalb kann es in betroffenen Gebieten leider zu einer Verzögerung der Auslieferung kommen.{break}Vielen Dank für dein Verständnis.',
  },
});

export const DelayedDeliveryWarning: FC = ({}) => {
  const { formatMessage } = useIntl();
  const { delayedDeliveryWarning }: FragmentConfig = useConfig();

  if (delayedDeliveryWarning) {
    return (
      <Notification
        level="warning"
        children={formatMessage(messages.delayedDeliveryWarning, {
          break: <br />,
        })}
      />
    );
  }

  return null;
};

export default DelayedDeliveryWarning;
