import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from '@empiriecom/module-components/Link';
import internalPages, { Language } from '@/config/pages';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import Notification from '@empiriecom/module-components/Notification';
const NotificationWithTitle = styled(Notification)`
  .title {
    font-weight: bold;
    display: block;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.75rem;
  }
`;

const messages = defineMessages({
  title: {
    id: 'ProductNotFoundHint.title',
    defaultMessage: 'Artikel nicht gefunden?',
  },
  copy: {
    id: 'ProductNotFoundHint.copy',
    defaultMessage:
      'Ältere Bestelldaten können aus technischen Gründen leider nicht angezeigt werden. Versendete Artikel findest du aber unter <a>deinen Buchungen</a>.',
  },
});

export function ProductNotFoundHint() {
  const { formatMessage, locale } = useIntl();
  const dispatch = useTracking();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      dispatch({
        event: 'ga_event',
        eventAction: 'view',
        eventCategory: 'booking_link',
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  return (
    <NotificationWithTitle
      hasClose={false}
      isAnimated={false}
      isInline
      level="warning"
      children={
        <>
          <span className="title">{formatMessage(messages.title)}</span>
          <span>
            {formatMessage(messages.copy, {
              a: (...chunks) => {
                const target = internalPages.bookings.paths[locale.split('-')[0] as Language];
                return (
                  <Link
                    key={target}
                    to={{ pathname: target, state: { showBookings: true } }}
                    onClick={() => {
                      dispatch({
                        event: 'ga_event',
                        eventAction: 'click',
                        eventCategory: 'booking_link',
                      });
                    }}
                  >
                    {chunks}
                  </Link>
                );
              },
            })}
          </span>
        </>
      }
    />
  );
}

export default ProductNotFoundHint;
