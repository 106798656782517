import { DefaultCard, Button, Modal, Theme, Headline } from '@empiriecom/module-components';
import React, { FC, useEffect } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import ShipmentInfosProductView from '../ShipmentInfoCard/ShipmentInfosProductView';
import {
  AccountApiInterface,
  ExtendedAccountApiInterface,
  ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum,
  ShipmentInfosShipmentList,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import InstructionsForReturn, { SemiBold } from './InstructionsForReturn';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import {
  PushNotificationProps,
  useNotificationContext,
} from '@empiriecom/module-components/Notification/NotificationProvider';
import Loader from '@empiriecom/module-components/Loader';
import { useCustomerContext } from '@empiriecom/mybuy-components';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { isAppView } from '@/src/components/PersonalDataContainer/DataExport';
import { bridge } from '@empiriecom/mybuy-session/Bridge';
const StyledHeadline = styled(Headline)`
  text-align: center;
  margin: 0 -2rem 0 -2rem;
  padding: 0.875rem 0;
  background: white;
  font-size: 1.131rem;
  line-height: 1.75;
`;
const Content = styled.div`
  min-height: 100%;
  padding: 0 2rem 0 2rem;
  background-color: ${({ theme }: { theme: Theme }) => theme.greyLight.color.normal};
  display: flex;
  flex-direction: column;
  .card {
    margin: 2rem -0.75rem 2rem -0.75rem;
  }
`;
const SubContent = styled.div`
  background: white;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem 1rem 2.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;
const ButtonContainer = styled.div`
  margin-top: 3rem;
`;
const messages = defineMessages({
  errorToast: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.errorToast',
    defaultMessage:
      'Ups. Etwas ist schief gelaufen. Probiere es später noch einmal oder wende dich unter {phoneNumber} an unseren Kundenservice',
  },
  modalMainHeaderPICKUP: {
    id: 'ReturnShipmentInfo.modalMainHeader.Pickup',
    defaultMessage: 'Speditionsartikel zurückschicken',
  },
  modalMainHeaderBRING: {
    id: 'ReturnShipmentInfo.modalMainHeader.Bring',
    defaultMessage: 'Artikel zurückschicken',
  },
  modalMainHeaderONLINE: {
    id: 'ReturnShipmentInfo.modalMainHeader.Online',
    defaultMessage: 'Rücksende-Etikett erstellen',
  },
  modalMainHeaderBEFORE: {
    id: 'ReturnShipmentInfo.modalMainHeader.Before',
    defaultMessage: 'Rücksende-Etikett erstellen',
  },
  modalSubHeaderONLINE: {
    id: 'ReturnShipmentInfo.modalSubHeader.Online',
    defaultMessage: 'So kannst du deinen Artikel an uns zurückschicken',
  },
  modalSubHeaderBEFORE: {
    id: 'ReturnShipmentInfo.modalSubHeader.Before',
    defaultMessage: 'So kannst du deinen Artikel an uns zurückschicken',
  },
  returnTypePICKUP: {
    id: 'ReturnShipmentInfo.returnType.Pickup',
    defaultMessage: 'Zurück zu meinen Bestellungen',
  },
  returnTypeBRING: {
    id: 'ReturnShipmentInfo.returnType.Bring',
    defaultMessage: 'Zurück zu meinen Bestellungen',
  },
  returnTypeONLINE: {
    id: 'ReturnShipmentInfo.returnType.Online',
    defaultMessage: 'Rücksende-Etikett anzeigen',
  },
  returnTypeBEFORE: {
    id: 'ReturnShipmentInfo.returnType.Before',
    defaultMessage: 'Rücksende-Etikett anzeigen',
  },
  contactPhone: {
    defaultMessage: '09572 - 50 50',
    id: 'ReturnShipmentInfo.phoneNumber',
  },
});
export const BEFORE = ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum.Before;
export const NONE = ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum.None;
export const ONLINE = ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum.Online;
// not in use yet
export const PICKUP = ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum.Pickup;
export const BRING = ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum.Bring;
export type ShipmentTypeWithoutNone = Omit<
  ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum,
  typeof NONE
>;

export const downloadReturnShipmentLabel = async (
  accountApi: AccountApiInterface | null,
  returnId: string,
  ecLocale: string,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  pushNotification: (notification: PushNotificationProps) => string,
  intl: IntlShape,
  isNativeApp: boolean,
) => {
  try {
    setIsDownloading(true);
    if (typeof returnId === 'undefined') {
      throw new Error('returnId is undefined');
    }

    if (isNativeApp) {
      const urlForGetReturnLabelDocument = (
        accountApi as ExtendedAccountApiInterface
      ).getUrlForGetReturnLabelDocumentByGET({ id: returnId });

      // istanbul ignore else
      if (bridge && bridge.downloadDocument) {
        bridge.downloadDocument(
          `${window.location.protocol}//${window.location.hostname}${urlForGetReturnLabelDocument}`,
        );
      }
    } else {
      const responseBlob = await accountApi!.getReturnLabelDocument({
        id: returnId,
        ecLocale,
      });
      if (responseBlob.type !== 'application/octet-stream') {
        throw new Error();
      }
      const fileName = `Ruecksende-Etikett-${returnId}.pdf`;
      /* istanbul ignore next -> test all that cases if you have a lot of time and are bored */
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
        window.navigator.msSaveBlob(responseBlob, fileName);
      } else {
        const objectUrl = window.URL.createObjectURL(responseBlob);
        const link = document.createElement('a');
        link.href = objectUrl;
        // safari doesn't support this yet
        if (typeof link.download === 'undefined') {
          window.location.href = objectUrl;
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      }
    }
  } catch (error) {
    pushNotification({
      level: 'error',
      hasClose: true,
      autoClose: undefined,
      content: intl.formatMessage(messages.errorToast, {
        break: <br />,
        phoneNumber: <SemiBold>{intl.formatMessage(messages.contactPhone)}</SemiBold>,
      }),
    });
  } finally {
    setIsDownloading(false);
  }
};

const ReturnShipmentInfo: FC<{
  shipment: ShipmentInfosShipmentList;
  modalOverride?: boolean;
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ shipment, modalOverride, modalOpen, setModalOpen }) => {
  const dispatch = useTracking();
  const intl = useIntl();
  const accountApi = useAccountApi();
  const { pushNotification } = useNotificationContext();
  const { ecLocale } = useCustomerContext();
  const {
    shipmentInfos: { returnLabelShown },
  } = useConfig<FragmentConfig>();
  const [isReturnModalOpen, setIsReturnModalOpen] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  /* istanbul ignore next - istanbuljs issue #526 or #516 */
  const { returnId, returnType } = shipment.orderDetails?.returnInformation ?? {};
  const [shipmentType, setShipmentType] = React.useState<
    ShipmentInfosOrderDetailsReturnInformationReturnTypeEnum | undefined
  >(returnType);
  useEffect(() => {
    setShipmentType(returnType);
  }, [returnType]);
  const isNativeApp = isAppView();

  const buttonHandler = () => {
    if (shipmentType === ONLINE || shipmentType === BEFORE) {
      downloadReturnShipmentLabel(
        accountApi,
        returnId!,
        ecLocale,
        setIsDownloading,
        pushNotification,
        intl,
        isNativeApp,
      );
    } else {
      setIsReturnModalOpen(false);
    }
  };
  if (shipmentType === NONE || shipmentType === undefined) {
    // currently no implementation for this type
    return null;
  }
  const openModalView = () => {
    setIsReturnModalOpen(true);
    dispatch({ event: 'ga_event', eventAction: 'click', eventCategory: 'return_shipment' });
  };
  return (
    <>
      {!modalOverride && returnLabelShown && (
        <Button data-testid="return-products-btn" onClick={() => openModalView()}>
          {intl.formatMessage(
            messages[`modalMainHeader${shipmentType.toUpperCase()}` as keyof typeof messages],
          )}
        </Button>
      )}
      <Modal
        noContentPadding
        open={!modalOverride ? isReturnModalOpen : modalOpen}
        testId="return-modal"
        testIdClose="return-products-close"
        headline={intl.formatMessage(
          messages[`modalMainHeader${shipmentType.toUpperCase()}` as keyof typeof messages],
        )}
        onRequestClose={() => {
          if (modalOverride && setModalOpen) {
            setModalOpen(false);
          } else {
            setIsReturnModalOpen(false);
          }
        }}
      >
        <Content>
          {(shipmentType === BEFORE || shipmentType === ONLINE) && (
            <StyledHeadline level={3}>
              {intl.formatMessage(
                messages[`modalSubHeader${shipmentType.toUpperCase()}` as keyof typeof messages],
              )}
            </StyledHeadline>
          )}
          <DefaultCard className="card">
            {shipment.product && <ShipmentInfosProductView product={shipment.product} />}
          </DefaultCard>
          <SubContent>
            <InstructionsForReturn shipmentType={shipmentType} intl={intl} />
            {!modalOverride && (
              <ButtonContainer>
                {!isDownloading && (
                  <Button
                    type="submit"
                    fullWidth
                    layout="primary"
                    onClick={() => buttonHandler()}
                    data-testid="submit-return-products-btn"
                  >
                    {intl.formatMessage(
                      messages[`returnType${shipmentType.toUpperCase()}` as keyof typeof messages],
                    )}
                  </Button>
                )}
                {isDownloading && (
                  <Button
                    layout="primary"
                    type="button"
                    fullWidth
                    disabled
                    uppercase={false}
                    data-testid="loading-btn"
                  >
                    <Loader size="2rem" />
                  </Button>
                )}
              </ButtonContainer>
            )}
          </SubContent>
        </Content>
      </Modal>
    </>
  );
};

export default ReturnShipmentInfo;
