import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@empiriecom/module-components';
import { FormattedMessage } from 'react-intl';
import { Headline } from '@empiriecom/module-components/Headline';
import ReturnDeclarationCard, {
  ReturnDeclarationData,
} from '@/src/components/ReturnDeclarationContainer/ReturnDeclarationCard';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { useMedia } from 'react-media-match';
import { ReturnDeclarationRequestPayload } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import ReturnDeclarationSuccess from '@/src/components/ReturnDeclarationContainer/ReturnDeclaration/ReturnDeclarationSuccess';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import { deleteId } from '@/utils/returnDeclarationUtil';

const GreyBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 23rem;
  align-items: center;
  padding: 1.25rem 1rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  font-size: 0.813rem;
  padding: 0 0 1.25rem;
`;

const StyledHeadline = styled(Headline)`
  line-height: 1;
  margin-top: 1rem;
`;

const Disclaimer = styled.div`
  padding-top: 1rem;
  color: ${({ theme }) => theme.greyDark.color.normal};
`;

const CTAContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  height: 10rem;
  margin-top: 1rem;
  padding: 1.25rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: ${({ isMobile }) => (isMobile ? '21rem' : '24rem')};
  border: 1px dashed ${({ theme }) => theme.greyLight.color.text};
  font-size: ${({ isMobile }) => (isMobile ? '0.813rem' : '0.938rem')};
`;

const InfoBoxWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  min-height: 8rem;
  margin: 1.25rem 0;
  border-radius: 3px;
  text-align: center;
  font-size: 0.813rem;
  align-items: center;
  padding: 1.25rem 1rem;
  flex-direction: column;
  justify-content: center;
  width: ${({ isMobile }) => (isMobile ? '21rem' : '24rem')};
  border: 1px solid ${({ theme }) => theme.greyLight.color.text};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InfoBoxHeadline = styled.div`
  font-weight: 600;
  padding-bottom: 0.5rem;
`;

const AddIcon = getIcon('Plus');
const StyledAddIcon = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  border: 1px solid ${({ theme }) => theme.global.color.textLight};
  background-color: ${({ theme }) => theme.global.color.textLight};
`;

const ReturnDeclaration: FC<{ customerNo?: string }> = ({ customerNo }) => {
  const accountApi = useAccountApi();
  const dispatch = useTracking();
  const [success, setSuccess] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const [inputData, setInputData] = useState<ReturnDeclarationData[]>([
    {
      trackingId: '',
      date: '',
    },
  ]);

  useEffect(() => {
    dispatch({
      event: 'ga_event',
      eventAction: 'viewPage',
      eventCategory: 'return_announcement',
    });
  }, []);

  useEffect(() => {
    if (inputData.length > 0) {
      const indexArray = [];
      inputData.forEach((element, index) => {
        const firstElementIsEmpty = index === 0 && (!element.trackingId || !element.date);

        if (element.error || firstElementIsEmpty) {
          indexArray.push(index);
        }
      });

      if (indexArray.length > 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [inputData]);

  const addNewReturn = () => {
    if (inputData.length < 10) {
      const newElement = { trackingId: '', date: '' };
      setInputData([...inputData, newElement]);
    }
  };

  const updateInputData = (data: ReturnDeclarationData, index: number) => {
    const newData = [...inputData];
    newData[index] = data;
    setInputData(newData);
  };

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleCardDelete = (index: number) => {
    const newData = [...inputData];
    newData.splice(index, 1);
    setInputData(newData);
  };

  const handleSubmit = async () => {
    const trackingData = inputData.filter((data) => data.trackingId && data.date);

    const result = await accountApi?.declareReturns({
      returnDeclarationRequestPayload: {
        customerNo,
        trackingData,
      } as ReturnDeclarationRequestPayload,
    });

    if (result?.success) {
      deleteId();
      handleSuccess();
    }

    if (result?.errors) {
      setInputData(result?.errors?.trackingData);
    }
  };

  const checkForIncompleteData = () => {
    if (inputData.length > 1) {
      const incompleteCardIndices = [];
      inputData.forEach((element, index) => {
        const currentElement = inputData[index];

        if (index > 0) {
          if ((element.trackingId && !element.date) || (!element.trackingId && element.date)) {
            currentElement.missingInputError = true;
            updateInputData(currentElement, index);

            incompleteCardIndices.push(index);
          }
        }
      });

      if (incompleteCardIndices.length <= 0) {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  if (success) {
    dispatch({
      event: 'ga_event',
      eventAction: 'viewPage',
      eventCategory: 'return_announcement_success',
    });

    return <ReturnDeclarationSuccess />;
  }

  return (
    <Content>
      <DescriptionWrapper>
        <StyledHeadline level={4}>
          <FormattedMessage
            id="ReturnDeclaration.Headline"
            defaultMessage="Rücksendung aufgegeben?"
          />
        </StyledHeadline>
        <div>
          <FormattedMessage
            id="ReturnDeclaration.Description"
            defaultMessage="Dein Paket ist bereits auf dem Weg zu uns?{br}Dann teile uns einfach deine Tracking- bzw. Sendungsnummer mit."
            values={{
              br: <br />,
            }}
          />
        </div>
        <Disclaimer>
          <FormattedMessage
            id="ReturnDeclaration.Disclaimer"
            defaultMessage="Speditionsartikel sind leider ausgeschlossen."
          />
        </Disclaimer>
      </DescriptionWrapper>
      <GreyBox data-testid="return-declaration">
        {inputData.map((trackingData, index) => (
          <ReturnDeclarationCard
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            index={index}
            onCardDelete={(cardNumber: number) => handleCardDelete(cardNumber)}
            trackingData={trackingData}
            handleDataInputCallback={(data, number) => updateInputData(data, number)}
          />
        ))}
        <Button
          data-testid="return-declaration-submit"
          layout="primary"
          disabled={!isValid}
          onClick={() => checkForIncompleteData()}
        >
          <FormattedMessage
            id="ReturnDeclaration.Submit.Button.Label"
            defaultMessage="Sendungsnummer abschicken"
          />
        </Button>
        {inputData.length < 10 && (
          <CTAContentWrapper isMobile={isMobile}>
            <StyledAddIcon data-testid="return-declaration-add" onClick={() => addNewReturn()}>
              <AddIcon size="2.5rem" />
            </StyledAddIcon>
            <FormattedMessage
              id="ReturnDeclaration.CTA.Button.Label"
              defaultMessage="Weitere Sendungsnummer hinzufügen"
            />
          </CTAContentWrapper>
        )}
      </GreyBox>
      <InfoBoxWrapper isMobile={isMobile}>
        <InfoBoxHeadline>
          <FormattedMessage
            id="ReturnDeclaration.InfoBox.Headline"
            defaultMessage="Wo finde ich meine Sendungsnummer?"
          />
        </InfoBoxHeadline>
        <div>
          <FormattedMessage
            id="ReturnDeclaration.InfoBox.Text"
            defaultMessage="Du findest deine Tracking- bzw. Sendungsnummer auf dem Beleg, den du bei der Abgabe deiner Rücksendung bekommen hast."
          />
        </div>
      </InfoBoxWrapper>
    </Content>
  );
};

export default ReturnDeclaration;
