import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { GetShipmentInfosTypeEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

import { SelectProps } from '@empiriecom/module-components/FormControls/Select/types';
import { ShipmentSelect } from '../ShipmentSelect';

const messages = defineMessages({
  label: {
    id: 'ShipmentInfoTypeSelect.option.label',
    defaultMessage: 'Sendungsstatus:',
  },
  all: {
    id: 'ShipmentInfoTypeSelect.option.all',
    defaultMessage: 'Alle',
  },
  ordered: {
    id: 'ShipmentInfoTypeSelect.option.ordered',
    defaultMessage: 'Bestellt',
  },
  delivered: {
    id: 'ShipmentInfoTypeSelect.option.delivered',
    defaultMessage: 'Zugestellt',
  },
  canceled: {
    id: 'ShipmentInfoTypeSelect.option.canceled',
    defaultMessage: 'Storniert',
  },
  returned: {
    id: 'ShipmentInfoTypeSelect.option.returned',
    defaultMessage: 'Zurückgeschickt',
  },
  sent: {
    id: 'ShipmentInfoTypeSelect.option.sent',
    defaultMessage: 'Unterwegs',
  },
});

function useShipmentTypeOptions() {
  const intl = useIntl();

  const [options] = useState<SelectProps['options']>([
    {
      displayName: intl.formatMessage(messages.all),
      value: GetShipmentInfosTypeEnum.All,
    },
    {
      displayName: intl.formatMessage(messages.ordered),
      value: GetShipmentInfosTypeEnum.Ordered,
    },
    {
      displayName: intl.formatMessage(messages.delivered),
      value: GetShipmentInfosTypeEnum.Delivered,
    },
    {
      displayName: intl.formatMessage(messages.canceled),
      value: GetShipmentInfosTypeEnum.Canceled,
    },
    {
      displayName: intl.formatMessage(messages.returned),
      value: GetShipmentInfosTypeEnum.Returned,
    },
    {
      displayName: intl.formatMessage(messages.sent),
      value: GetShipmentInfosTypeEnum.Sent,
    },
  ]);

  return options;
}

export interface ShipmentInfoTypeSelectProps {
  onSelect?: (value: GetShipmentInfosTypeEnum) => void;
  value?: GetShipmentInfosTypeEnum;
  disabled?: boolean;
}

export const SHIPMENT_INFOTYPE_SELECT_TESTID = 'shipment-infotype-select';

export function ShipmentInfoTypeSelect({ onSelect, value, disabled }: ShipmentInfoTypeSelectProps) {
  const intl = useIntl();
  const options = useShipmentTypeOptions();

  return (
    <div>
      <div>{intl.formatMessage(messages.label)}</div>
      <ShipmentSelect
        disabled={disabled}
        data-testid={SHIPMENT_INFOTYPE_SELECT_TESTID}
        value={value}
        onChange={(val) => onSelect?.(val as GetShipmentInfosTypeEnum)}
        options={options}
      />
    </div>
  );
}
