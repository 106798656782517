import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const CardSkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
`;

// Simple skeleton; could be much more fancy component
const PaymentBoxSkeleton: FunctionComponent = (): JSX.Element => (
  <>
    <CardSkeleton height="20rem" />
  </>
);

export default PaymentBoxSkeleton;
