import { defineMessages } from 'react-intl';

export const reclamationMessages = defineMessages({
  headlineArticle: {
    id: 'ReclamationForm.headlineArticle',
    defaultMessage: 'Artikel und Bestellinformationen',
  },
  headlineCustomer: {
    id: 'ReclamationForm.headlineCustomer',
    defaultMessage: 'Kundeninformation',
  },
  headlineShipping: {
    id: 'ReclamationForm.headlineShipping',
    defaultMessage: 'Lieferadresse',
  },
  labelArticleNumber: {
    id: 'ReclamationForm.labelArticleNumber',
    defaultMessage: 'Artikelnummer:',
  },
  labelArticleDescription: {
    id: 'ReclamationForm.labelArticleDescription',
    defaultMessage: 'Artikelbeschreibung:',
  },
  labelCustomerNo: {
    id: 'ReclamationForm.labelCustomerNo',
    defaultMessage: 'Kundennummer:',
  },
  labelFirstName: {
    id: 'ReclamationForm.labelFirstName',
    defaultMessage: 'Vorname:',
  },
  labelLastName: {
    id: 'ReclamationForm.labelLastName',
    defaultMessage: 'Nachname:',
  },
  labelStreet: {
    id: 'ReclamationForm.labelStreet',
    defaultMessage: 'Straße:',
  },
  labelHouseNumber: {
    id: 'ReclamationForm.labelHouseNumber',
    defaultMessage: 'Hausnummer:',
  },
  labelZipCode: {
    id: 'ReclamationForm.labelZipCode',
    defaultMessage: 'PLZ:',
  },
  labelCity: {
    id: 'ReclamationForm.labelCity',
    defaultMessage: 'Ort:',
  },
  labelEmailAddress: {
    id: 'ReclamationForm.emailAddress',
    defaultMessage: 'E-Mail:',
  },
  labelPrimaryPhoneNumber: {
    id: 'ReclamationForm.primaryPhoneNumber',
    defaultMessage: 'Telefon:',
  },
  labelReclamationReason: {
    id: 'ReclamationForm.labelReclamationReason',
    defaultMessage: 'Reklamationsgrund:',
  },
  reclamationReasonPlaceholder: {
    id: 'ReclamationForm.reclamationReasonPlaceholder',
    defaultMessage: 'Bitte auswählen',
  },
  reclamationReasonIncomplete: {
    id: 'ReclamationForm.reclamationReasonIncomplete',
    defaultMessage: 'Artikel unvollständig',
  },
  reclamationReasonDamaged: {
    id: 'ReclamationForm.reclamationReasonDamaged',
    defaultMessage: 'Artikel defekt',
  },
  reclamationReasonWrongProduct: {
    id: 'ReclamationForm.reclamationReasonWrongProduct',
    defaultMessage: 'Falscher Artikel geliefert',
  },
  reclamationReasonWrongPrice: {
    id: 'ReclamationForm.reclamationReasonWrongPrice',
    defaultMessage: 'Falscher Preis',
  },
  reclamationReasonOther: {
    id: 'ReclamationForm.reclamationReasonOther',
    defaultMessage: 'Sonstiges',
  },
  labelBestReachedPhoneNumber: {
    id: 'ReclamationForm.labelBestReachedPhoneNumber',
    defaultMessage: 'am besten erreichbar:',
  },
  labelReclamationDescription: {
    id: 'ReclamationForm.labelReclamationDescription',
    defaultMessage: 'Mängelbeschreibung:*',
  },
  labelReplacementNumber: {
    id: 'ReclamationForm.labelReplacementNumber',
    defaultMessage: 'Ersatzteilnummer:',
  },
  labelDataTypeShield: {
    id: 'ReclamationForm.labelDataTypeShield',
    defaultMessage: 'Daten-Typenschild:',
  },
});

export default reclamationMessages;
