import React from 'react';
import styled, { css } from 'styled-components';

import getIcon from '@empiriecom/module-components/utils/getIcon';
import {
  ShipmentInfosShipmentList,
  ShipmentInfosShipmentListTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

interface DashProps {
  active?: boolean;
  muted?: boolean;
}

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Dash = styled.span.attrs<DashProps>((_) => _)<DashProps>`
  border: 1px solid
    ${({ active, muted, theme }) => {
      if (muted) {
        return theme.greyDark.color.normal;
      }

      if (active) {
        return theme.primary.color.normal;
      }
      return theme.greyLight.color.normal;
    }};
  flex: 1;
  height: 1px;
`;

interface CircleProps {
  active?: boolean;
  filled?: boolean;
  muted?: boolean;
}

/**

 */

const Circle = styled.div.attrs<CircleProps, {}>((props) => props)<CircleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 2.25rem;
  height: 2.25rem;
  padding: 3px;
  border-radius: 100%;

  ${({ active, muted, filled, theme }) => {
    const mutedColor = '#d5d3d1';
    let textColor = 'inherit';
    let backgroundColor = theme.greyLight.color.normal;
    let borderColor = theme.greyLight.color.normal;

    if (active && !filled && !muted) {
      backgroundColor = theme.global.color.textLight;
      borderColor = theme.primary.color.normal;
    } else if (active && filled && !muted) {
      textColor = theme.global.color.textLight;
      backgroundColor = theme.primary.color.normal;
      borderColor = theme.primary.color.normal;
    } else if (active && filled && muted) {
      backgroundColor = mutedColor;
      borderColor = mutedColor;
    } else if (active && !filled && muted) {
      backgroundColor = theme.global.color.textLight;
      borderColor = theme.greyDark.color.normal;
    }

    return css`
      border: 1px solid ${borderColor};
      background-color: ${backgroundColor};
      > * {
        fill: ${textColor};
      }
    `;
  }}

  margin: 0 2px;
  > * {
    width: 1.75rem;
    height: 1.75rem;
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    width: 3.063rem;
    height: 3.063rem;

    &:not(:first-of-type) {
      margin-left: 0.813rem;
    }

    &:not(:last-of-type) {
      margin-right: 0.813rem;
    }
  }
`;

const OrderedIcon = getIcon('CheckOrder');
const ShipmentPreparedIcon = getIcon('Tracking');
const SentIcon = getIcon('Truck');
const DeliveryIcon = getIcon('InDelivery');
const ReturnedIcon = getIcon('Return');
const DeliveredIcon = getIcon('CatHome');
const ReturnslipIcon = getIcon('Returnslip');

export function ShipmentProgress({
  shipmentList,
  className,
}: {
  shipmentList: ShipmentInfosShipmentList;
  className?: string;
}) {
  const steps = [
    ShipmentInfosShipmentListTypeEnum.AwaitingDownpayment,
    ShipmentInfosShipmentListTypeEnum.Ordered,
    ShipmentInfosShipmentListTypeEnum.ShipmentPrepared,
    ShipmentInfosShipmentListTypeEnum.Sent,
    ShipmentInfosShipmentListTypeEnum.InDelivery,
    ShipmentInfosShipmentListTypeEnum.Delivered,
    ShipmentInfosShipmentListTypeEnum.Returned,
    ShipmentInfosShipmentListTypeEnum.ReturnlabelCreated,
    ShipmentInfosShipmentListTypeEnum.ReturnShipment,
  ];

  const progressIndex = steps.indexOf(shipmentList.type);
  const isReturned = shipmentList.type === ShipmentInfosShipmentListTypeEnum.Returned;
  const isReturnLabelCreated =
    shipmentList.type === ShipmentInfosShipmentListTypeEnum.ReturnlabelCreated ||
    shipmentList.type === ShipmentInfosShipmentListTypeEnum.ReturnShipment;
  const finalCircleIcon = () => {
    if (isReturned) {
      return <ReturnedIcon />;
    }
    if (isReturnLabelCreated) {
      return <ReturnslipIcon />;
    }
    return <DeliveredIcon />;
  };

  return (
    <Root className={className}>
      <Circle
        muted={isReturned}
        active={progressIndex > -1}
        filled={!isReturned && progressIndex < 2}
      >
        <OrderedIcon />
      </Circle>
      <Dash active={!isReturned && progressIndex > 1} />
      <Circle muted={isReturned} active={progressIndex > 1} filled={progressIndex === 2}>
        <ShipmentPreparedIcon />
      </Circle>
      <Dash active={!isReturned && progressIndex > 2} />
      <Circle muted={isReturned} active={progressIndex > 2} filled={progressIndex === 3}>
        <SentIcon />
      </Circle>
      <Dash active={!isReturned && progressIndex > 3} />
      <Circle muted={isReturned} active={progressIndex > 3} filled={progressIndex === 4}>
        <DeliveryIcon />
      </Circle>
      <Dash active={!isReturned && progressIndex > 4} />
      <Circle
        muted={false}
        active={progressIndex > 4}
        filled={
          progressIndex === 5 || progressIndex === 6 || progressIndex === 7 || progressIndex === 8
        }
      >
        {finalCircleIcon()}
      </Circle>
    </Root>
  );
}

export default ShipmentProgress;
