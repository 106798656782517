import React, { FC } from 'react';
import { useMedia } from 'react-media-match';
import styled from 'styled-components';
import { SkeletonProvider, withSkeleton } from '@empiriecom/module-components/Skeleton';
import { Grid } from '@empiriecom/module-components/Grid';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import PersonalDataContainerSkeleton from '@/src/components/PersonalDataContainer/skeleton';
import PersonalData from '@/src/components/PersonalDataContainer/PersonalData';
import AccountSettings from '@/src/components/PersonalDataContainer/AccountSettings';
import DataExport from '@/src/components/PersonalDataContainer/DataExport';
import PaymentSettings from '@/src/components/PersonalDataContainer/PaymentSettings';
import { defineMessages, useIntl } from 'react-intl';
import { Kim } from '@empiriecom/mybuy-components/components/Kim';
import EventTracking from '../EventTracking';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';

// solve issue of text (like the users email address) exploding cells
// see https://css-tricks.com/preventing-a-grid-blowout/
const StyledGrid = styled(Grid)`
  width: 100%;
  > * {
    min-width: 0;
  }
`;

const StyledCell = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
`;

const MarginTop = styled.div`
  margin-top: 1rem;
`;

const KimWrapper = styled.div`
  padding-top: 2rem;
`;

const messages = defineMessages({
  kimTitle: {
    id: 'PersonalDataContainer.Kim.title',
    defaultMessage: 'Wie gefällt Ihnen die Persönliche Daten Seite?',
  },
});

const PersonalDataContainerContent: FC = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const customer = useCustomerContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const { kim, personalData }: FragmentConfig = useConfig();

  const kimId = customer.customer?.extra?.kid ? parseInt(customer.customer?.extra?.kid, 10) : 0;

  if (isMobile) {
    return (
      <div data-testid="personal-data-mobile">
        <StyledGrid cellsPerRow={[1, 2, 2]}>
          <StyledCell>
            {/* for app tests - they check for this testid */}
            <div data-testid="personal-data-card-wrapper">
              <PersonalData />
            </div>
          </StyledCell>
          {!personalData.isNL && (
            <StyledCell>
              <MarginTop>
                <PaymentSettings />
              </MarginTop>
            </StyledCell>
          )}
          <StyledCell>
            <MarginTop>
              <AccountSettings />
            </MarginTop>
          </StyledCell>
          <StyledCell>
            <MarginTop>
              <DataExport />
            </MarginTop>
          </StyledCell>
        </StyledGrid>
        {kim?.enabled && (
          <KimWrapper>
            <Kim
              i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
              headline={formatMessage(messages.kimTitle)}
              x03={20}
              x05="PERSD"
              x06={kimId}
            />
          </KimWrapper>
        )}
      </div>
    );
  }

  return (
    <div data-testid="personal-data-desktop">
      <StyledGrid cellsPerRow={[1, 2, 2]} colGap="1rem">
        <StyledCell>
          <div data-testid="personal-data-card-wrapper">
            <PersonalData />
          </div>
          <div data-testid="settings-card-wrapper">
            <MarginTop>
              <AccountSettings />
            </MarginTop>
          </div>
        </StyledCell>
        <StyledCell>
          {!personalData.isNL && (
            <div data-testid="payment-card-wrapper">
              <PaymentSettings />
            </div>
          )}
          <div data-testid="dataexport-card-wrapper">
            {!personalData.isNL && (
              <MarginTop>
                <DataExport />
              </MarginTop>
            )}
            {personalData.isNL && <DataExport />}
          </div>
        </StyledCell>
      </StyledGrid>
      {kim?.enabled && (
        <KimWrapper>
          <Kim
            i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
            headline={formatMessage(messages.kimTitle)}
            x03={20}
            x05="PERSD"
            x06={kimId}
          />
        </KimWrapper>
      )}
    </div>
  );
};

const PersonalDataContainerWithSkeleton = withSkeleton(
  PersonalDataContainerContent,
  PersonalDataContainerSkeleton,
);

const PersonalDataContainer: FC = (): JSX.Element => {
  const { isLoading } = useCustomerContext();
  return (
    <>
      <SkeletonProvider isLoading={isLoading}>
        <EventTracking eventCategory="personalData" />
        <PersonalDataContainerWithSkeleton />
      </SkeletonProvider>
    </>
  );
};

export default PersonalDataContainer;
