import React from 'react';
import styled from 'styled-components';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { JoeLogo } from '@empiriecom/mybuy-components/components/Joe/icons/JoeLogo';
import Loader from '@empiriecom/module-components/Loader';
import useJoe from './useJoe';

const StyledLogo = styled(JoeLogo)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.688rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.875rem;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Trigger = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const messages = defineMessages({
  success: {
    id: 'Joe.disconnect.success',
    defaultMessage: 'Ihr Konto wurde erfolgreich getrennt.',
  },
});

export const DisconnectJoe: React.FC<{}> = () => {
  const { disconnect, loading, isConnected, pending } = useJoe();
  const { pushNotification } = useNotificationContext();
  const { formatMessage } = useIntl();

  const handleTriggerClick = () => {
    disconnect().then(() =>
      pushNotification({
        content: formatMessage(messages.success),
        level: 'success',
        autoClose: 3000,
      }),
    );
  };
  return (
    <Row>
      <StyledLogo />
      <Column>
        {!loading && !pending && !isConnected && (
          <FormattedMessage
            tagName="span"
            id="Joe.disconnect.not-connected.text"
            defaultMessage="Ihr Konto ist nicht mit Ihrem jö Konto verknüpft."
          />
        )}
        {loading && <Loader />}
        {!loading && isConnected && (
          <>
            <FormattedMessage
              tagName="span"
              id="Joe.disconnect.text"
              defaultMessage="Ihr Universal-Konto ist mit Ihrem jö Konto verknüpft. Sie sammeln bei jedem Einkauf Ös."
            />
            <Trigger onClick={handleTriggerClick}>
              <FormattedMessage
                id="Joe.disconnect.label"
                defaultMessage="Kontoverknüpfung trennen"
              />
            </Trigger>
          </>
        )}
      </Column>
    </Row>
  );
};

export default DisconnectJoe;
