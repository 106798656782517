import React, { FC } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const ForgotPasswordSkeleton = styled(Skeleton)`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const ForgotPasswordContainerSkeleton: FC = () => (
  <div data-testid="forgot-password-skeleton">
    <ForgotPasswordSkeleton height="10rem" />
  </div>
);

export default ForgotPasswordContainerSkeleton;
