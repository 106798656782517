import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { DataWithLoadingInfo } from '@empiriecom/mybuy-components/provider/CustomerProvider/interfaces';
import { CustomerStatus } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

export type CustomerStatusData = DataWithLoadingInfo<CustomerStatus>;

/**
 * get maturities data from customer context; load them once only, and store them with the customer
 */

function useCustomerStatus(): CustomerStatusData {
  const { additionalData } = useCustomerContext();
  const { customerStatus } = additionalData;

  return customerStatus || { loading: true };
}

export default useCustomerStatus;
