import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Gender } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

interface GenderProps {
  gender?: Gender;
}

const GenderComponent: React.FC<GenderProps> = ({ gender }) => {
  switch (gender) {
    case Gender.M:
      return <FormattedMessage id="Gender.male" defaultMessage="Herr" />;
    case Gender.F:
      return <FormattedMessage id="Gender.female" defaultMessage="Frau" />;
    default:
      return null;
  }
};

export default GenderComponent;
