import 'unfetch/polyfill';

import React from 'react';
import { render } from 'react-dom';
import BrowserRouter from '@empiriecom/module-components/BrowserRouter';
import App from '@/src/components/App';

const namespace = process.env.FRAGMENT_NAMESPACE || 'og-emma';

declare global {
  interface Window {
    ecAggFeature: string | undefined;
    isNativeApp: boolean | undefined;
    bindOnlineInvoiceEventHandlers: boolean | undefined;
  }
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

window.bindOnlineInvoiceEventHandlers = true;

render(
  <BrowserRouter>
    <App appMode={window.isNativeApp} />
  </BrowserRouter>,
  document.getElementById(namespace),
);

if (module.hot) {
  module.hot.accept();
}
