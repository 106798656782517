import React, { createContext, useContext, useEffect, FC, useRef, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-media-match';
import { NavigationEntry as ApiNavigationEntry } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/NavigationEntry';
import useGlobalApi from '@empiriecom/mybuy-components/api/useGlobalApi';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';

export type NavigationEntry = {
  path: string;
  children: NavigationEntry[] | null;
  name: MessageDescriptor;
};

export type Navigation = readonly NavigationEntry[];

export const NavigationContext = createContext<Navigation>([]);
export const useNavigationContext = (): Navigation => useContext(NavigationContext);

/**
 * maps an api provided navigationentry to a format that the fragment can handle better.
 * It will also set the `internal` flag if the path vom the `entry` is included in `internalPagePaths`.
 * The `entry` might have sub elements, those will be handled recursively
 * @param entry
 */
export const apiNavEntryToFragmentNavEntry = (entry: ApiNavigationEntry): NavigationEntry => {
  return {
    path: entry.url || '',
    children: entry.subEntries?.map((subEntry) => apiNavEntryToFragmentNavEntry(subEntry)) || null,
    name: {
      id: entry.url || 'Navigation.unknown',
      defaultMessage: entry.displayName,
    },
  };
};

export type NavigationProviderProps = {
  staticNavigation?: Required<Navigation>;
};

export const NavigationProvider: FC<NavigationProviderProps> = ({ staticNavigation, children }) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const globalApi = isClientSide() && useGlobalApi();
  const [navigation, setNavigation] = useState<Navigation>([]);

  // using a ref here will not cause a rerender when the location changes like a state would
  // We already rerender because of the location change, no need to do that twice
  // The first location will be missed until we push it here manually
  const pathHistory = useRef<string[]>([location.pathname]);

  const { locale } = useIntl();

  /*
    This effect is needed because we need to clean up the listener after the component is unmounted.
    Otherwise we will simply pile up listeners and push paths multiple times
  */
  useEffect(() => {
    const unregister = history.listen(({ pathname }) => pathHistory.current.push(pathname));

    return () => unregister();
  }, []);

  useEffect(() => {
    // isMounted tracks if the component actually still exists when the api call succeeds - we can't mutate the state of a non-existing component
    let isMounted = true;
    if (staticNavigation) {
      setNavigation(staticNavigation);
    }
    if (globalApi) {
      (async () => {
        const readNavigation = await globalApi.getNavigation({
          ecMobile: isMobile,
          ecLocale: locale,
        });
        if (isMounted) {
          setNavigation(
            readNavigation.entries!.map((entry: ApiNavigationEntry) =>
              apiNavEntryToFragmentNavEntry(entry),
            ),
          );
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [globalApi]);

  return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>;
};
