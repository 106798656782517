const storage2 = require('storage2');

const returnDeclarationStorage = storage2.sessionStorage;

export const STORAGE_KEY = 'returnDeclaration';

export const setId = (id: string) => {
  returnDeclarationStorage.setItem(STORAGE_KEY, id);
};

export const getId = (): string | undefined => returnDeclarationStorage.getItem(STORAGE_KEY);

export const deleteId = () => {
  returnDeclarationStorage.removeItem(STORAGE_KEY);
};
