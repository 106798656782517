import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-media-match';

import NotificationProvider from '@empiriecom/module-components/Notification/NotificationProvider';
import LoginContainer from '@empiriecom/mybuy-components/components/LoginContainer';
import Notification from '@empiriecom/module-components/Notification';

import Navigation from '@/src/components/ExternalNavigation';
import { usePageContext } from '@/src/components/Pages/PageProvider';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { LoginStatus } from '@/src/typings/Navigation';
import ServiceContactTile from '@/src/components/ServiceContactTile';
import { FormattedMessage } from 'react-intl';
import { useSessionContext } from '@empiriecom/mybuy-session/SessionProvider';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import EventTracking from '../../EventTracking';
import { useLocation } from 'react-router';
import ActivateEmailLoginModal from '@empiriecom/mybuy-components/components/LoginContainer/ActivateEmailLogin/ActivateEmailLoginModal';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

export interface MyAccountContainerProps {
  appMode?: boolean;
}

const MyAccountWrapper = styled.div<{ isMobile: boolean }>`
  margin: ${({ isMobile }) => (isMobile ? '' : '0 auto')};
  max-width: 1140px;
  padding-top: 1rem;
`;

const ContentNavigationWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'wrap' : 'nowrap')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row-reverse')};

  @media print {
    /* prevent firefox cutting the content */
    display: block;
  }

  /* overwrite tailwind basics coming from turborepo (ORDER-3000) */
  /* https://tailwindcss.com/docs/preflight#images-are-block-level */
  /* https://tailwindcss.com/docs/preflight#images-are-constrained */
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display: inline;
    max-width: none;
  }
`;

const NavigationWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '20%')};

  @media print {
    /* not needed in print view */
    display: none;
  }
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '80%')};
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
`;

const MyAccountContainerContent: FunctionComponent<MyAccountContainerProps> = ({
  appMode,
}): JSX.Element => {
  const { entry } = usePageContext();
  const { token } = useSessionContext();
  const { search } = useLocation();
  const {
    show: { changeToEmailLogin },
  }: FragmentConfig = useConfig();
  const { container: Container, requiredLoginStatus } = entry || {};
  const [isSoftLogin, setIsSoftLogin] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(search.indexOf('toMigrate') >= 0);
  const { isLoggedIn, isMyAccountAccessDenied, isLoading } = useCustomerContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });

  const decodedSearchParams = decodeURI(search);
  const searchParams = new URLSearchParams(decodedSearchParams);
  const redirectUrl = searchParams.get('redirectUrl') || undefined;

  useEffect(() => {
    if (!token || isLoading) {
      return;
    }

    const tokenContents = apiKeyManager.getTokenContents(token);

    if (tokenContents && tokenContents.type === 1) {
      setIsSoftLogin(true);
    } else {
      setIsSoftLogin(false);
    }
  }, [isLoading, token]);

  let pageContent: React.ReactNode;

  const anonymousPageContent = (
    <>
      <EventTracking eventCategory="login" />
      <LoginContainer isSoftLogin={isSoftLogin} hideChangeToEmailLogin={!changeToEmailLogin} />
      {modalOpen && <ActivateEmailLoginModal onClose={() => setModalOpen(false)} />}
    </>
  );

  if (!isLoading) {
    const isAllowedToView = requiredLoginStatus === LoginStatus.NotLoggedIn || isLoggedIn;
    const hasAccessDenied = isAllowedToView && isMyAccountAccessDenied;
    if (hasAccessDenied) {
      pageContent = (
        <Notification
          level="error"
          isInline
          content={
            <FormattedMessage
              id="MyAccountContainer.accessDenied.error"
              defaultMessage="Als EU Kunde kann Ihnen leider kein Zugriff auf Mein Konto ermöglicht werden."
            />
          }
        />
      );
    } else if (isAllowedToView) {
      pageContent = (
        <>
          <Container />
        </>
      );
    } else {
      pageContent = anonymousPageContent;
    }
  }

  // If the user changes the billing or email address during a login check, customerContext is updated and triggers a
  // rerender before the token has been set in the browser and causes a lot of chaos.
  // So we need to check for the existence of a valid token.
  // See module-io-components/packages/components/LoginContainer/index.tsx for further confusing information.
  if (
    (!token || apiKeyManager.getTokenContents(token)?.type === 0) &&
    requiredLoginStatus === LoginStatus.LoggedIn
  ) {
    pageContent = anonymousPageContent;
  }

  if (appMode) {
    return (
      <NotificationProvider>
        <MyAccountWrapper data-testid="myaccount-container-wrapper-app" isMobile={isMobile}>
          <ContentNavigationWrapper data-testid="content-navigation-wrapper" isMobile={isMobile}>
            <ContentWrapper isMobile={isMobile}>{pageContent}</ContentWrapper>
          </ContentNavigationWrapper>
        </MyAccountWrapper>
      </NotificationProvider>
    );
  }

  return (
    <NotificationProvider>
      <MyAccountWrapper data-testid="myaccount-container-wrapper" isMobile={isMobile}>
        <ContentNavigationWrapper data-testid="content-navigation-wrapper" isMobile={isMobile}>
          {/* add an additional login container independent of login state to handle incoming users with redirect urls */}
          {redirectUrl && (
            <LoginContainer
              redirectUrl={redirectUrl}
              onSuccess={() => {
                if (redirectUrl) {
                  window.location.href = redirectUrl;
                }
              }}
            />
          )}
          <ContentWrapper isMobile={isMobile}>{pageContent}</ContentWrapper>
          <NavigationWrapper isMobile={isMobile}>
            <Navigation />
            <ServiceContactTile />
          </NavigationWrapper>
        </ContentNavigationWrapper>
      </MyAccountWrapper>
    </NotificationProvider>
  );
};

const MyAccountContainer: FunctionComponent<MyAccountContainerProps> = ({
  appMode,
}): JSX.Element => (
  <>
    <MyAccountContainerContent appMode={appMode} />
  </>
);

export default MyAccountContainer;
