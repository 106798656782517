import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedCurrency } from '@empiriecom/module-components/FormattedCurrency';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useInvoice from '@/src/components/AccountProvider/useInvoice';
import AccountInformation from '@/src/components/PaymentBox/AccountInformation';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import Image from '@empiriecom/module-components/Image';
import { NoArticleFallbackImage } from '@/src/components/icons/NoArticleFallback';
import { Modal } from '@empiriecom/module-components/Modal';
import EventTracking from '@/src/components/EventTracking';
import { EventCategory } from '@/src/hooks/useEventTracking';
// eslint-disable-next-line import/no-cycle
import PaymentPlan from '@/src/components/PaymentPlan';
import ReclamationForm, { ReclamationFormData } from '@/src/components/EInvoice/ReclamationForm';
import getLocalizedPath from '@/utils/getLocalizedPath';
import { DownloadEInvoice } from './DownloadEInvoice';
import EInvoiceSkeleton from './skeleton';
import Gender from '@/src/components/Gender';
import Card from '@empiriecom/module-components/Card';

const GeneralSection = styled.section`
  display: block;
  margin-bottom: 1rem;
`;

const HeaderSection = styled(GeneralSection)`
  border-bottom: 2px solid ${({ theme }) => theme.greyNormal.color.normal};
  padding-bottom: 1rem;
`;

const InvoicePricingSection = styled(GeneralSection)`
  border-top: 2px solid ${({ theme }) => theme.greyNormal.color.normal};
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  text-align: right;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const FooterSection = styled(GeneralSection)`
  font-size: 80%;
  color: ${({ theme }) => theme.base.color.textTransparent};

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const HeaderRow = styled.div<{ largeSpacing?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ largeSpacing }) => (largeSpacing ? '1.5rem' : '0.25rem')};
  margin-bottom: ${({ largeSpacing }) => (largeSpacing ? '1.5rem' : '0.25rem')};

  & > * {
    flex: 0 1 50%;
  }
`;

const TabledSection = styled.div<{ hasMoreMobileSpace?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    margin: 1px;
    padding: 0.25rem 0.5rem;
    overflow: hidden;
  }

  & > *:first-child {
    flex: 0 1 60%;
    padding-right: ${({ hasMoreMobileSpace }) => (hasMoreMobileSpace ? '4rem' : '0.5rem')};
  }

  & > *:last-child {
    flex: 0 1 40%;
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    flex-direction: row;

    & > *:first-child {
      flex: 0 1 60%;
      padding-right: 0.5rem;
    }
  }
`;

const ProductSectionHead = styled(TabledSection)`
  @media (max-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    display: none;
  }

  & > div {
    background-color: ${({ theme }) => theme.greyDark.color.normal};
    color: ${({ theme }) => theme.greyDark.color.text};
    font-weight: bold;
  }
`;

const ProductSectionBody = styled(TabledSection)<{ hideBackground?: boolean }>`
  background-color: ${({ theme, hideBackground }) =>
    hideBackground ? theme.base.color.normal : theme.greyLight.color.normal};
  color: ${({ theme, hideBackground }) =>
    hideBackground ? theme.base.color.text : theme.greyLight.color.text};

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    background-color: ${({ theme }) => theme.base.color.normal};
    color: ${({ theme }) => theme.base.color.text};

    & > * {
      background-color: ${({ theme, hideBackground }) =>
        hideBackground ? theme.base.color.normal : theme.greyLight.color.normal};
      color: ${({ theme, hideBackground }) =>
        hideBackground ? theme.base.color.text : theme.greyLight.color.text};
    }
  }
`;

const ProductInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProductImageWrapper = styled.div`
  flex: 0 0 4rem;
  max-height: 5rem;
  padding: 0 0.5rem 0.5rem 0;

  & img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    font-size: 50%;
    text-overflow: ellipsis;
  }
`;

const ProductData = styled.div`
  flex: 1 1 auto;
`;

const PriceRow = styled.div<{ isBold?: boolean; hasSpace?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  margin: ${({ hasSpace }) => (hasSpace ? '1rem auto' : 'auto')};

  & > *:first-child {
    flex: 10 1 5rem;
  }

  & > *:last-child {
    padding-left: 0.25rem;
    flex: 1 1 5rem;
  }
`;

const EffectiveInstallmentsHint = styled.div`
  margin: 0.5rem 0;
`;

const PaymentPlanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const APlusWrapper = styled.div`
  margin-top: 1rem;
`;

const SubHeadline = styled.div`
  font-weight: bold;
`;

const StyledTextLink = styled.div`
  font-size: 0.75rem;
  margin: 0.5rem auto;
  line-height: 150%;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const CardWithPadding = styled(Card)`
  padding: 0.75rem;
`;

const EInvoice: React.FC<{ id: string; data?: ReturnType<typeof useInvoice> }> = ({
  id,
  data: propData,
}) => {
  const { customer, ecLocale } = useCustomerContext();
  const config = useConfig<FragmentConfig>();
  const intl = useIntl();
  const [paymentPlanModalOpen, setPaymentPlanModalOpen] = useState<boolean>(false);
  const {
    isLoading: asyncIsLoading,
    hasErrors: asyncHasErrors,
    data: asyncData,
  } = useInvoice({
    id,
    ecLocale,
  });
  const [currentlyOpenReclamationData, setCurrentlyOpenReclamationData] = useState<
    ReclamationFormData | undefined
  >(undefined);

  const isLoading = propData ? propData.isLoading : asyncIsLoading;
  const hasErrors = propData ? propData.hasErrors : asyncHasErrors;
  const data = propData ? propData.data : asyncData;

  const paymentHint = (hint: string) => {
    return (
      <>
        {intl.formatMessage(
          { id: 'EInvoice.backend.paymentHint', defaultMessage: hint },
          {
            break: <br />,
            strong: (...chunks: string[]) => <strong>{chunks}</strong>,
            u: (...chunks: string[]) => <u>{chunks}</u>,
          },
        )}
      </>
    );
  };

  if (isLoading) {
    return <EInvoiceSkeleton />;
  }

  if (hasErrors || !data) {
    return (
      <div>
        <FormattedMessage
          id="EInvoice.error.description"
          defaultMessage="In unserem System ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
        />
      </div>
    );
  }

  return (
    <div>
      <HeaderSection>
        <HeaderRow>
          <SubHeadline>
            <FormattedMessage
              id="EInvoice.header.invoiceDate.headline"
              defaultMessage="Rechnungsdatum:"
            />
          </SubHeadline>
          <div data-mf-replace-inner="**REMOVED**">{data.invoiceDate}</div>
        </HeaderRow>
        <HeaderRow>
          <SubHeadline>
            <FormattedMessage
              id="EInvoice.header.invoiceNumber.headline"
              defaultMessage="Rechnungsnummer:"
            />
          </SubHeadline>
          <div data-mf-replace-inner="**REMOVED**">{data.invoiceNumber}</div>
        </HeaderRow>
        <HeaderRow>
          <SubHeadline>
            <FormattedMessage
              id="EInvoice.header.customerNumber.headline"
              defaultMessage="Kundennummer:"
            />
          </SubHeadline>
          <div data-mf-replace-inner="**REMOVED**">{data.customerNumber}</div>
        </HeaderRow>
        <HeaderRow>
          <SubHeadline>
            <FormattedMessage
              id="EInvoice.header.paymentType.headline"
              defaultMessage="Zahlungsart:"
            />
          </SubHeadline>
          <div data-mf-replace-inner="**REMOVED**">{data.paymentMethod}</div>
        </HeaderRow>
        <HeaderRow largeSpacing>
          <div>
            <SubHeadline>
              <FormattedMessage
                id="EInvoice.header.deliveryAddress.headline"
                defaultMessage="Lieferadresse:"
              />
            </SubHeadline>
            <div data-mf-replace-inner="**REMOVED**">
              <Gender gender={data.deliveryAddress.gender} /> {data.deliveryAddress.honorific}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.deliveryAddress.firstName} {data.deliveryAddress.lastName}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.deliveryAddress.street} {data.deliveryAddress.houseNumber}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.deliveryAddress.zipCode} {data.deliveryAddress.city}
            </div>
          </div>
          <div>
            <SubHeadline>
              <FormattedMessage
                id="EInvoice.header.invoiceAddress.headline"
                defaultMessage="Rechnungsadresse:"
              />
            </SubHeadline>
            <div data-mf-replace-inner="**REMOVED**">
              <Gender gender={data.invoiceAddress.gender} /> {data.invoiceAddress.honorific}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.invoiceAddress.firstName} {data.invoiceAddress.lastName}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.invoiceAddress.street} {data.invoiceAddress.houseNumber}
            </div>
            <div data-mf-replace-inner="**REMOVED**">
              {data.invoiceAddress.zipCode} {data.invoiceAddress.city}
            </div>
          </div>
        </HeaderRow>
      </HeaderSection>
      {data.products && data.products.length > 0 && (
        <GeneralSection>
          <ProductSectionHead>
            <div>
              <FormattedMessage id="EInvoice.products.productHeadline" defaultMessage="Artikel" />
            </div>
            <div>
              <FormattedMessage id="EInvoice.products.pricesHeadline" defaultMessage="Rechnung" />
            </div>
          </ProductSectionHead>
          {data.products.map((singleProduct, index) => (
            // together with order number it is unique
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${singleProduct.orderNumber}${index}`}>
              <ProductSectionBody hideBackground={index % 2 === 1} hasMoreMobileSpace>
                <ProductInformation>
                  <ProductImageWrapper>
                    {singleProduct.imageUrl ? (
                      <Image
                        src={singleProduct.imageUrl}
                        alt={singleProduct.name}
                        fallback={<NoArticleFallbackImage />}
                      />
                    ) : (
                      <NoArticleFallbackImage />
                    )}
                  </ProductImageWrapper>
                  <ProductData>
                    <SubHeadline>{singleProduct.name}</SubHeadline>
                    <div>
                      <FormattedMessage
                        id="EInvoice.products.articleNumber"
                        defaultMessage="Art.Nr.: {articlenumber}"
                        values={{ articlenumber: singleProduct.orderNumber }}
                      />
                    </div>
                    {singleProduct.size && (
                      <div>
                        <FormattedMessage
                          id="EInvoice.products.size"
                          defaultMessage="Größe: {size}"
                          values={{ size: singleProduct.size }}
                        />
                      </div>
                    )}
                    <div>
                      <FormattedCurrency
                        value={singleProduct.price.value}
                        currency={singleProduct.price.currencyCode}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="EInvoice.products.quantity"
                        defaultMessage="Menge: {quantity}"
                        values={{ quantity: singleProduct.quantity }}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="EInvoice.products.paymentMethod"
                        defaultMessage="Zahlungsart: {paymentMethod}"
                        values={{ paymentMethod: singleProduct.paymentMethod }}
                      />
                    </div>
                    {singleProduct.installmentCount && (
                      <div>
                        <FormattedMessage
                          id="EInvoice.products.installments"
                          defaultMessage="in {installmentCount} Monatsraten"
                          values={{ installmentCount: singleProduct.installmentCount }}
                        />
                      </div>
                    )}
                    {singleProduct.isReturned && (
                      <div>
                        <FormattedMessage
                          id="EInvoice.products.productReturned"
                          defaultMessage="Produkt wurde zurückgeschickt"
                        />
                      </div>
                    )}
                    {!singleProduct.isReturned && config.einvoice.showReclaimLink && (
                      <StyledTextLink
                        onClick={() =>
                          setCurrentlyOpenReclamationData({
                            customer: customer || undefined,
                            invoiceNumber: data.invoiceNumber,
                            invoiceDate: data.invoiceDate,
                            articleNumber: singleProduct.orderNumber,
                            articleDescription: singleProduct.name,
                            deliveryAddress: data.deliveryAddress,
                          })
                        }
                      >
                        <FormattedMessage
                          id="EInvoice.products.reclaim.link"
                          defaultMessage="Ein Problem mit diesem Artikel melden"
                        />
                      </StyledTextLink>
                    )}
                    {currentlyOpenReclamationData && (
                      <Modal
                        open
                        onRequestClose={() => setCurrentlyOpenReclamationData(undefined)}
                        noContentPadding
                        testIdClose="reclamation-close"
                      >
                        <EventTracking eventCategory="booking_eInvoice_complain" isLayer />
                        <ReclamationForm
                          data={currentlyOpenReclamationData}
                          onSuccess={() => setCurrentlyOpenReclamationData(undefined)}
                        />
                      </Modal>
                    )}
                  </ProductData>
                </ProductInformation>
                <div>
                  <PriceRow key="product-price">
                    <div>
                      <FormattedMessage
                        id="EInvoice.products.productPrice"
                        defaultMessage="Warenwert:"
                      />
                    </div>
                    <div>
                      <FormattedCurrency
                        value={singleProduct.price.value}
                        currency={singleProduct.price.currencyCode}
                      />
                    </div>
                  </PriceRow>
                  {singleProduct.priceModifications &&
                    singleProduct.priceModifications.map((priceModification) => (
                      <PriceRow key={priceModification.name}>
                        <div>{priceModification.name}</div>
                        <div>
                          {priceModification.value.value >= 0 && <span>+</span>}
                          <FormattedCurrency
                            value={priceModification.value.value}
                            currency={priceModification.value.currencyCode}
                          />
                        </div>
                      </PriceRow>
                    ))}
                  {singleProduct.aplus && (
                    <APlusWrapper>
                      <SubHeadline>
                        <FormattedMessage
                          id="EInvoice.aplus.headline"
                          defaultMessage="Gebuchte Services"
                        />
                      </SubHeadline>
                      {singleProduct.aplus.map((singleService) => (
                        <div key={`${singleProduct.orderNumber}${singleService.name}`}>
                          <PriceRow>
                            <div>{singleService.name}</div>
                            <div>
                              <FormattedCurrency
                                value={singleService.price.value}
                                currency={singleService.price.currencyCode}
                              />
                            </div>
                          </PriceRow>
                          {singleService.priceModifications &&
                            singleService.priceModifications.map((priceMod) => (
                              <PriceRow key={priceMod.name}>
                                <div>{priceMod.name}</div>
                                <div>
                                  {priceMod.value.value >= 0 && <span>+</span>}
                                  <FormattedCurrency
                                    value={priceMod.value.value}
                                    currency={priceMod.value.currencyCode}
                                  />
                                </div>
                              </PriceRow>
                            ))}
                        </div>
                      ))}
                    </APlusWrapper>
                  )}
                  <PriceRow key="final-price" isBold hasSpace>
                    <div>
                      <FormattedMessage
                        id="EInvoice.products.finalPrice"
                        defaultMessage="Endpreis:"
                      />
                    </div>
                    <div>
                      <FormattedCurrency
                        value={singleProduct.finalPrice.value}
                        currency={singleProduct.finalPrice.currencyCode}
                      />
                    </div>
                  </PriceRow>
                </div>
              </ProductSectionBody>
            </div>
          ))}
        </GeneralSection>
      )}
      <InvoicePricingSection>
        <div>
          <SubHeadline>
            <FormattedMessage id="EInvoice.invoiceTotals.headline" defaultMessage="Rechnung" />
          </SubHeadline>
          <PriceRow key="sub-totals">
            <SubHeadline>
              <FormattedMessage
                id="EInvoice.invoiceTotals.subTotals"
                defaultMessage="Zwischensumme:"
              />
            </SubHeadline>
            <div>
              <FormattedCurrency
                value={data.subTotal.value}
                currency={data.subTotal.currencyCode}
              />
            </div>
          </PriceRow>
          {data.priceModifications && data.priceModifications.length > 0 && (
            <div>
              {data.priceModifications.map((singleModification) => (
                <PriceRow key={singleModification.name}>
                  <div>{singleModification.name}</div>
                  <div>
                    {singleModification.value.value >= 0 && <span>+</span>}
                    <FormattedCurrency
                      value={singleModification.value.value}
                      currency={singleModification.value.currencyCode}
                    />
                  </div>
                </PriceRow>
              ))}
            </div>
          )}
          <PriceRow key="grand-totals">
            <SubHeadline>
              <FormattedMessage
                id="EInvoice.invoicetotals.grandTotals"
                defaultMessage="Gesamtsumme:"
              />
            </SubHeadline>
            <div>
              <FormattedCurrency
                value={data.grandTotal.value}
                currency={data.grandTotal.currencyCode}
              />
            </div>
          </PriceRow>
        </div>
        {data.paymentPlan && (
          <div>
            <SubHeadline>
              <FormattedMessage id="EInvoice.paymentplan.headline" defaultMessage="Zahlungsplan" />
            </SubHeadline>
            {data.paymentPlan.map((entry) => (
              <PriceRow key={entry.text}>
                <div>{entry.text}</div>
                {entry.value && (
                  <div>
                    <FormattedCurrency
                      value={entry.value.value}
                      currency={entry.value.currencyCode}
                    />
                  </div>
                )}
              </PriceRow>
            ))}
            {data.showEffectiveInstallmentsHint && (
              <EffectiveInstallmentsHint>
                <FormattedMessage
                  id="EInvoice.paymentplan.showEffectiveInstallmentsHint"
                  defaultMessage="Dies ist deine ursprüngliche Rate.{break}Die aktuellen Raten inkl. der Zusammensetzung findest du <link>hier</link>."
                  values={{
                    break: <br />,
                    link: (...chunks: string[]) => (
                      <PaymentPlanLink
                        data-testid="payment-plan-link"
                        onClick={() => setPaymentPlanModalOpen(true)}
                      >
                        {chunks}
                      </PaymentPlanLink>
                    ),
                  }}
                />
              </EffectiveInstallmentsHint>
            )}
            <Modal
              open={paymentPlanModalOpen}
              headline={intl.formatMessage({
                id: 'EInvoice.paymentPlanModalHeadline',
                defaultMessage: 'Übersicht nächste Zahltermine',
              })}
              onRequestClose={() => {
                setPaymentPlanModalOpen(false);
              }}
              testId="paymentplan-modal"
              testIdClose="paymentplan-modal-close"
              noContentPadding
            >
              <EventTracking eventCategory={'bookings_paymentPlan' as EventCategory} isLayer />
              <PaymentPlan />
            </Modal>
          </div>
        )}
      </InvoicePricingSection>
      {config.einvoice.isFixedFooter ? (
        <FooterSection>
          <div>
            <FormattedMessage
              id="EInvoice.footer.fixed.info.first"
              defaultMessage="Zahlbar bei Rechnungskauf innerhalb von 14 Tagen, bei Ratenkauf innerhalb von 30 Tagen und bei Zahlpause innerhalb von 120 Tagen nach Erhalt der Lieferung. Bitte beachte, dass du spätestens 30 Tage nach Zugang dieser Rechnung und Fälligkeit der Forderung in Verzug gerätst, ohne dass es einer weiteren Mahnung bedarf."
            />
          </div>
          <div>
            <FormattedMessage
              id="EInvoice.footer.fixed.info.second"
              defaultMessage="Bei Vorliegen eines gültigen Lastschriftmandates werden wir die ausgewiesenen fälligen Beträge einziehen. Retouren vermindern den Einzugsbetrag."
            />
          </div>
          <CardWithPadding>
            <AccountInformation showQRPayment />
          </CardWithPadding>
          <div>
            <FormattedMessage
              id="EInvoice.footer.fixed.info.third"
              defaultMessage="Bitte gib unbedingt in deiner Überweisung Kundennummer und Rechnungsnummer im Verwendungszweck an. Du beschleunigst damit die Gutschrift auf deinem Kundenkonto."
            />
          </div>
          <DownloadEInvoice id={id} />
        </FooterSection>
      ) : (
        <>
          {data.paymentHint && (
            <GeneralSection>
              <div>{paymentHint(data.paymentHint)}</div>
              <CardWithPadding>
                <AccountInformation showQRPayment />
              </CardWithPadding>
            </GeneralSection>
          )}
          <FooterSection>
            <div>
              <FormattedMessage
                id="EInvoice.footer.businessaddress"
                defaultMessage="Hier die Unternehmensadresse einfügen."
              />
            </div>
            <div>
              <FormattedMessage
                id="EInvoice.footer.businessterms"
                defaultMessage="Bitte beachten Sie unsere Geschäftsbedingungen <link>hier</link>"
                values={{
                  link: (...chunks: string[]) => (
                    <a href={getLocalizedPath(config.businessTerms, intl.locale)}>{chunks}</a>
                  ),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="EInvoice.footer.ownership"
                defaultMessage="Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum."
              />
            </div>
            <DownloadEInvoice id={id} />
          </FooterSection>
        </>
      )}
    </div>
  );
};

export default EInvoice;
