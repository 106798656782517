import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { Cell, Grid, Headline } from '@empiriecom/module-components';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import PartialPaymentCard from './PartialPaymentCard';
import PaymentPauseCard from './PaymentPauseCard';
import { PaymentProtectionCard } from '@/src/components/FlexikontoContainer/PaymentProtectionCard';
import { FaqCard } from '@/src/components/FlexikontoContainer/FaqCard';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import { CustomerCreditData } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import EventTracking from '@/src/components/EventTracking';

const messages = defineMessages({
  myFlexAccount: {
    id: 'FlexikontoContainer.myFlexAccount',
    defaultMessage: 'Mein Flexikonto',
  },
});

const PageHeadline = styled(Headline)`
  margin-bottom: 0.5rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    text-align: left;
  }
`;

const StyledGrid = styled(Grid)`
  width: 100%;
  > * {
    min-width: 0;
  }
  grid-template-columns: repeat(${(props) => props.cellsPerRow?.[1]}, 1fr);
  grid-gap: ${(props) => props.rowGap};

  @media (max-width: 768px) {
    grid-template-columns: repeat(${(props) => props.cellsPerRow?.[0]}, 1fr);
  }
`;
const GreyBox = styled.div`
  padding: 1rem 0.5rem;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  font-size: 0.75rem;
`;

const FlexikontoContainer: FC = () => {
  const intl = useIntl();
  const [customerCreditData, setCustomerCreditData] = useState<CustomerCreditData>();

  const {
    flexikonto: { enabled },
  } = useConfig<FragmentConfig>();

  // moved to here from child components -> now only one request is made
  const customerApi = useCustomerApi();
  useEffect(() => {
    const getDelayedPayment = async () => {
      const result =
        (await customerApi?.getCustomerCreditData({ ecLocale: intl.locale })) ??
        ({} as CustomerCreditData);
      setCustomerCreditData(result);
    };
    getDelayedPayment();
  }, [customerApi]);

  if (enabled) {
    return (
      <div>
        <EventTracking eventCategory="flexikonto" />
        <PageHeadline level={3}>{intl.formatMessage(messages.myFlexAccount)}</PageHeadline>
        <GreyBox>
          <StyledGrid cellsPerRow={[1, 2]} rowGap="1rem">
            <Cell data-testid="partial-payment-wrapper">
              <PartialPaymentCard paymentData={customerCreditData?.paymentData} />
            </Cell>
            <Cell data-testid="payment-pause-wrapper">
              <PaymentPauseCard delayedPayment={customerCreditData?.delayedPayment} />
              <PaymentProtectionCard paymentInsurance={customerCreditData?.paymentInsurance} />
            </Cell>
          </StyledGrid>
          <FaqCard />
        </GreyBox>
      </div>
    );
  }

  return null;
};

export default FlexikontoContainer;
