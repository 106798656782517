import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import useJoeApi from '@empiriecom/mybuy-components/api/useJoeApi';
import { JoeConnectionStatusResponseStatusEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { useCustomerContext } from '@empiriecom/mybuy-components';

type JoeProviderProps = {};

export type JoeContextInterface = {
  disconnect: () => Promise<void>;
  refresh: () => void;
  isConnected: boolean;
  pending: boolean;
  loading: boolean;
  enabled: boolean;
};

// istanbul ignore next - irrelavant default value is immediately overriden
export const JoeContext = createContext<JoeContextInterface>({
  disconnect: () => Promise.reject(new Error('default context')),
  refresh: () => {},
  isConnected: false,
  loading: false,
  pending: true,
  enabled: false,
});

export const JoeProvider: FC<JoeProviderProps> = ({ children }) => {
  const config = useConfig<FragmentConfig>();
  const api = useJoeApi();
  const [isConnected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const [enabled] = useState(!!config?.joe?.enabled);
  const { ecLocale } = useCustomerContext();

  const refresh = useCallback(() => {
    if (enabled && api) {
      setLoading(true);
      api
        .getJoeConnectionStatus({
          ecLocale: ecLocale,
        })
        .then((r) => {
          setConnected(r.status === JoeConnectionStatusResponseStatusEnum.ConnectionEstablished);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          setPending(false);
          setLoading(false);
        });
    }
  }, [api, enabled]);

  useEffect(() => {
    refresh();
  }, [enabled, api]);

  const disconnect = useCallback(() => {
    if (api) {
      setLoading(true);
      return api
        .deleteJoeConnection({
          ecLocale,
        })
        .then(() => setConnected(false))
        .finally(() => setLoading(false));
    }

    return Promise.reject(new Error('api not available'));
  }, [api]);

  const value: JoeContextInterface = {
    disconnect,
    isConnected,
    loading,
    pending,
    refresh,
    enabled,
  };

  return <JoeContext.Provider value={value}>{children}</JoeContext.Provider>;
};
