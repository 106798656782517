import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Button from '@empiriecom/module-components/Button';
import Loader from '@empiriecom/module-components/Loader';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import getIcon from '@empiriecom/module-components/utils/getIcon';

import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import { isAppView } from '@/src/components/PersonalDataContainer/DataExport';
import { ExtendedAccountApiInterface } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { bridge } from '@empiriecom/mybuy-session/Bridge';

const DownloadIcon = getIcon('Download');

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const DownloadEInvoice: React.FC<{ id: string }> = ({ id }) => {
  const accountApi = useAccountApi();
  const { ecLocale } = useCustomerContext();
  const [downloading, setDownloading] = useState<boolean>(false);
  const { pushNotification } = useNotificationContext();
  const dispatch = useTracking();
  const isNativeApp = isAppView();

  const downloadData = async () => {
    if (accountApi) {
      try {
        setDownloading(true);
        const fileName = `e-rechnung-${id}.pdf`;

        if (isNativeApp) {
          const urlForGetInvoiceDocument = (
            accountApi as ExtendedAccountApiInterface
          ).getUrlForGetInvoiceDocumentByGET({ id });

          // istanbul ignore else
          if (bridge && bridge.downloadDocument) {
            bridge.downloadDocument(
              `${window.location.protocol}//${window.location.hostname}${urlForGetInvoiceDocument}`,
            );
          }
        } else {
          const responseBlob = await accountApi!.getInvoiceDocument({ id, ecLocale });
          /* istanbul ignore next -> test all that cases if you have a lot of time and are bored */
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(responseBlob, fileName);
          } else {
            const objectUrl = window.URL.createObjectURL(responseBlob);
            const link = document.createElement('a');
            link.href = objectUrl;
            // safari doesn't support this yet
            if (typeof link.download === 'undefined') {
              window.location.href = objectUrl;
            } else {
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
          }
        }
      } catch {
        pushNotification({
          autoClose: 5000,
          hasClose: true,
          level: 'error',
          content: (
            <FormattedMessage
              id="EInvoice.Download.Error"
              defaultMessage="Leider ist beim Herunterladen ein Fehler aufgetreten. Versuche es zu einem späteren Zeitpunkt erneut."
            />
          ),
        });
      } finally {
        setDownloading(false);
        dispatch({
          event: 'ga_event',
          eventAction: 'click',
          eventCategory: 'eInvoice_download',
        });
      }
    }
  };

  return (
    <DownloadWrapper>
      {!downloading && (
        <Button layout="default" size="small" onClick={downloadData} uppercase={false}>
          <FormattedMessage
            id="EInvoice.Download.Button"
            defaultMessage="Herunterladen und Drucken"
          />
          <DownloadIcon size="1rem" />
        </Button>
      )}
      {downloading && (
        <Button layout="default" disabled size="small" uppercase={false}>
          <FormattedMessage
            id="EInvoice.Download.DisabledButton"
            defaultMessage="Bitte warten ..."
          />
          <Loader size="1rem" />
        </Button>
      )}
    </DownloadWrapper>
  );
};

export default DownloadEInvoice;
