import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const HeadlineSkeleton = styled(Skeleton)`
  width: 75%;
  margin: 1rem auto 1rem auto;
`;

const CollapseSkeleton = styled(Skeleton)`
  margin-bottom: 0.25rem;
`;

const PaymentPlanSkeleton: FunctionComponent = (): JSX.Element => (
  <>
    <HeadlineSkeleton height="2rem" />
    <CollapseSkeleton height="4rem" />
    <CollapseSkeleton height="4rem" />
    <CollapseSkeleton height="4rem" />
  </>
);

export default PaymentPlanSkeleton;
