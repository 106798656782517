import React, { FC, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useMedia } from 'react-media-match';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { useSessionContext } from '@empiriecom/mybuy-session/SessionProvider';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const messages = defineMessages({
  questions: {
    id: 'ServiceContactTile.questions',
    defaultMessage: 'Hast du Fragen?',
  },
  write: {
    id: 'ServiceContactTile.write',
    defaultMessage: 'Schreib uns',
  },
  emailAddress: {
    id: 'ServiceContactTile.emailAddress',
    defaultMessage: 'service@<wbr />imwalking.de',
  },
  emailAddressEmployee: {
    id: 'ServiceContactTile.emailAddressEmployee',
    defaultMessage: 'personalkauf@<wbr />imwalking.de',
  },
  callUs: {
    id: 'ServiceContactTile.callUs',
    defaultMessage: 'Ruf uns an',
  },
  phoneNumber: {
    id: 'ServiceContactTile.phoneNumber',
    defaultMessage: '09572 - 50 70',
  },
  phoneNumberEmployee: {
    id: 'ServiceContactTile.phoneNumberEmployee',
    defaultMessage: '09572 - 91 61 31',
  },
  phoneTimes: {
    id: 'ServiceContactTile.phoneTimes',
    defaultMessage: 'täglich von 6 bis 23 Uhr',
  },
  phoneTimesEmployee: {
    id: 'ServiceContactTile.phoneTimesEmployee',
    defaultMessage: 'täglich von 8 bis 17 Uhr',
  },
  yourCustomerNumber: {
    id: 'ServiceContactTile.yourCustomerNumber',
    defaultMessage: 'Deine Kundennummer',
  },
});

const Border = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
  border-radius: 3px;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const ServiceContactTile: FC = (): JSX.Element => {
  const intl = useIntl();
  const { isLoggedIn, customer } = useCustomerContext();
  const isMobile = useMedia({ mobile: 'true', tablet: 'false', desktop: 'false' });
  const { token } = useSessionContext();
  const {
    show: { isServiceContactTileEnabled },
    customerTypes: { employees },
  }: FragmentConfig = useConfig();
  const [employee, setEmployee] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      return;
    }

    const customerType = apiKeyManager.getTokenContents(token)?.ct;

    if (customerType && employees && employees.includes(customerType)) {
      setEmployee(true);
    }
  }, [token]);

  if (!isLoggedIn || isMobile === 'true' || !isServiceContactTileEnabled) {
    return <></>;
  }

  return (
    <Border data-testid="service-contact-tile">
      <p>
        <strong>{intl.formatMessage(messages.questions)}</strong>
      </p>
      <p>
        {intl.formatMessage(messages.write)}
        <br />
        <strong
          // a wbr tag is contained here, to wrap the email address e. g. in tablet portrait mode
          // move this into a separate component if needed more times
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              employee ? messages.emailAddressEmployee : messages.emailAddress,
            ),
          }}
        />
      </p>
      <p>
        {intl.formatMessage(messages.callUs)}
        <br />
        <strong>
          {intl.formatMessage(employee ? messages.phoneNumberEmployee : messages.phoneNumber)}
        </strong>
        <br />
        {intl.formatMessage(employee ? messages.phoneTimesEmployee : messages.phoneTimes, {
          break: <br />,
        })}
      </p>
      {customer?.businessPartnerNo && (
        <p>
          {intl.formatMessage(messages.yourCustomerNumber)}
          <br />
          <strong data-mf-replace-inner="**REMOVED**">{customer?.businessPartnerNo}</strong>
        </p>
      )}
    </Border>
  );
};
export default ServiceContactTile;
