import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Card, { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import Skeleton from '@empiriecom/module-components/Skeleton';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import useCustomerReturns from '@/src/hooks/useCustomerReturns';
import { Bookings } from '@/config/pages';
import { FragmentConfig } from '@/config/types';
import { CustomerStatusReturns } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { WithBadge } from '@/src/components/NewsBadge';

interface IReturnsInformation {
  /**
   * Toggles the component between loading and data display state
   */
  mockedLoading?: boolean;

  /**
   * Shipment data, look at CustomerStatusReturns
   */
  mockedData?: CustomerStatusReturns;

  /**
   * Number to show in the badge, no value equals no badge
   */
  mockedNews?: number;
}

const ReturnsSkeleton = styled(Skeleton)`
  border-radius: 5px;
`;

const ReturnsCardContentWrapper = styled.div`
  display: block;
  padding: 0.75rem 1rem;
`;

const ItemWrapper = styled.div`
  display: block;
  margin: 0.5rem auto;
`;

const ItemSplit = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardSeperatorWithSpace = styled(CardSeperator)`
  margin: 0.75rem 0;
`;

const RetourIcon = getIcon('Return');

const messages = defineMessages({
  ReturnsCardHeadline: {
    id: 'OverviewContainer.ReturnsInformation.card.headline',
    defaultMessage: 'Rücksendungen',
  },
  ReturnsCardGoToAll: {
    id: 'OverviewContainer.ReturnsInformation.goToAllReturns.text',
    defaultMessage: 'Alle Rücksendungen',
  },
});

const ReturnsDisplayWrapper: React.FC = ({ children }) => {
  const { formatMessage, locale } = useIntl();
  const history = useHistory();

  const navigateToAllReturns = () => {
    const goToAllReturnsLink =
      locale.split('-')[0] === 'fr' ? Bookings.Reimbursement.frUrl : Bookings.Reimbursement.deUrl;
    history.push(goToAllReturnsLink);
  };

  return (
    <Card headline={formatMessage(messages.ReturnsCardHeadline)} showHeaderSeparator>
      <ReturnsCardContentWrapper>{children}</ReturnsCardContentWrapper>
      <CardSeperator />
      <CardAction
        icon={<RetourIcon size="1.5rem" />}
        text={formatMessage(messages.ReturnsCardGoToAll)}
        onClick={navigateToAllReturns}
      />
    </Card>
  );
};

const ReturnsInformation = ({
  mockedLoading,
  mockedData,
  mockedNews,
}: IReturnsInformation): JSX.Element => {
  const { loading, news, data } = useCustomerReturns();
  const {
    overview: { newsBadge },
  } = useConfig<FragmentConfig>();
  const renderedLoading: boolean = mockedLoading !== undefined ? mockedLoading : loading;
  const renderedData = mockedData || data || undefined;
  const renderedNews = mockedNews || news || undefined;
  const renderedAmount = renderedData?.items.length || 0;

  if (renderedLoading) {
    return <ReturnsSkeleton height="10rem" />;
  }

  if (!renderedData || renderedAmount <= 0) {
    return (
      <ReturnsDisplayWrapper>
        <FormattedMessage
          defaultMessage="Bei uns ist keine Rücksendung eingegangen."
          id="OverviewContainer.ReturnsInformation.empty.message"
        />
      </ReturnsDisplayWrapper>
    );
  }

  return (
    <WithBadge value={renderedNews} which={newsBadge.color}>
      <ReturnsDisplayWrapper>
        <div data-mf-replace-inner="**REMOVED**">
          {renderedData.items &&
            renderedData.items.map((item, index) => (
              <div key={item.name}>
                <ItemWrapper>
                  <strong>{item.name}</strong>
                  <div>
                    <FormattedMessage
                      defaultMessage="Größe:"
                      id="OverviewContainer.ReturnsInformation.item.size.label"
                    />{' '}
                    {item.size}
                  </div>
                  <div>
                    <FormattedMessage
                      defaultMessage="Art.-Nr.:"
                      id="OverviewContainer.ReturnsInformation.item.number.label"
                    />{' '}
                    {item.number}
                  </div>
                  <div>
                    <FormattedMessage
                      defaultMessage="Menge:"
                      id="OverviewContainer.ReturnsInformation.item.quantity.label"
                    />{' '}
                    {item.quantity}
                  </div>
                </ItemWrapper>
                {renderedData.items.length > index + 1 && <CardSeperatorWithSpace />}
              </div>
            ))}
          <CardSeperatorWithSpace />
          {renderedData.date && (
            <div>
              <ItemSplit>
                <div>
                  <FormattedMessage
                    defaultMessage="Gutschrift angewiesen am:"
                    id="OverviewContainer.ReturnsInformation.date"
                  />{' '}
                  {renderedData.date}
                </div>
                {renderedData.grandTotal && (
                  <strong>
                    <FormattedCurrency
                      value={renderedData.grandTotal.value}
                      currency={renderedData.grandTotal.currencyCode}
                    />
                  </strong>
                )}
              </ItemSplit>
            </div>
          )}
        </div>
      </ReturnsDisplayWrapper>
    </WithBadge>
  );
};

export default ReturnsInformation;
