import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ShipmentProductAllOfAplus } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/ShipmentProductAllOfAplus';
import { Price } from '@empiriecom/module-components/Price';
import styled from 'styled-components';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { Headline } from '@empiriecom/module-components/Headline';

const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;

  align-items: center;

  padding: 1.063rem 0;

  > * {
    font-size: 0.813rem;
  }

  > *:nth-child(1) {
    margin-right: 1.25rem;
  }

  > *:nth-child(2) {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
  }

  > *:nth-child(3) {
    flex: 1;
  }

  > *:nth-child(4) {
    color: ${({ theme }) => theme.success.color.normal};

    > * {
      font-size: inherit;
    }
  }

  max-width: 31rem;
`;

const Stack = styled.div`
  display: flex;
  flex-flow: column;

  > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const Checkmark = styled(getIcon('Checkmark'))`
  fill: ${({ theme }) => theme.success.color.normal};
`;

const aplusMessages = defineMessages({
  included: {
    id: 'Aplus.included',
    defaultMessage: 'inklusive',
  },
});

interface APlusProps {
  item: ShipmentProductAllOfAplus;
  className?: string;
}

export function APlusItem({ item, className }: APlusProps) {
  const { formatMessage } = useIntl();
  return (
    <Flex className={className}>
      <Checkmark />
      <img src={item?.iconUrl} alt="" />
      <span>{item.name}</span>
      {item.isIncluded ? (
        <BoldText>{formatMessage(aplusMessages.included)}</BoldText>
      ) : (
        <Price price={item.price.value} currency={item.price.currencyCode} />
      )}
    </Flex>
  );
}

interface APLusListProps {
  aplus?: ShipmentProductAllOfAplus[];
  className?: string;
}

export function APlusList({ aplus, className }: APLusListProps) {
  return (
    <>
      {aplus && (
        <div className={className}>
          <Headline element="div" level={6}>
            <FormattedMessage
              id="APlusList.headline"
              defaultMessage="Ihre bereits gebuchten Services"
            />
          </Headline>
          <Stack>
            {aplus.map((item) => (
              <APlusItem key={item.name} item={item} />
            ))}
          </Stack>
        </div>
      )}
    </>
  );
}

export default APlusList;
