import React, { FC, useState } from 'react';
import { Headline } from '@empiriecom/module-components/Headline';
import Card from '@empiriecom/module-components/Card';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@empiriecom/module-components/Button';
import { Cell, Grid } from '@empiriecom/module-components';
import { browserSupportsGrid } from '@empiriecom/module-components/Grid';
import { Modal } from '@empiriecom/module-components/Modal';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { PhoneNumberWithTypeTypeEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/PhoneNumberWithType';
import ChangeEmail from '@empiriecom/mybuy-components/components/ChangeEmail';
import ChangeAddress from '@empiriecom/mybuy-components/components/ChangeAddress';
import ChangePassword from '@/src/components/ChangePassword';
import { useMedia } from 'react-media-match';
import PhoneBook from '@empiriecom/mybuy-components/components/PhoneBook';
import EventTracking from '../../EventTracking';
import VerificationNotification from '../../VerificationNotification';
import { VerificationStatus } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import Chip from '@empiriecom/mybuy-components/components/Chip';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import { ChangeIbanForm } from '../../Forms/ChangeIbanForm';

const messages = defineMessages({
  change: {
    id: 'PersonalData.change',
    defaultMessage: 'ändern',
  },
  MOBILE: {
    id: 'PersonalData.phone.mobile',
    defaultMessage: 'Handy',
  },
  FIXED: {
    id: 'PersonalData.phone.fixed',
    defaultMessage: 'Festnetz',
  },
  emailModalHeadline: {
    id: 'PersonalData.emailmodal.headline',
    defaultMessage: 'Neue E-Mail-Adresse?',
  },
  passwordModalHeadline: {
    id: 'PersonalData.passwordmodal.headline',
    defaultMessage: 'Neues Passwort?',
  },
  phoneModalHeadline: {
    id: 'PersonalData.phonemodal.headline',
    defaultMessage: 'Neue Telefonnummer?',
  },
  addressModalHeadline: {
    id: 'PersonalData.addressmodal.headline',
    defaultMessage: 'Neue Adresse?',
  },
  ibanModalHeadline: {
    id: 'PersonalData.ibanmodal.headline',
    defaultMessage: 'IBAN ändern',
  },
});

const StyledHeadline = styled(Headline)<{ isMobile: boolean }>`
  margin: 0 0 1rem 0;
  ${({ isMobile }) => isMobile && 'text-align: center;'}
  background-color: ${({ theme }) => theme.base.color.normal};
  padding: 0.5rem;
`;

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .left {
    width: 80%;
  }
`;

const Ellipsed = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.5rem;
`;

const PasswordHint = styled.div`
  color: ${({ theme }) => theme.base.color.textTransparent};
  font-size: 0.75rem;
`;

const Label = styled.div`
  font-weight: bold;

  &.marginTop {
    margin-top: 0.25rem;
  }
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.5rem 0;

  &.noMargin {
    margin: 0;
  }
`;

const ChipWithMargin = styled(Chip)`
  margin: 0.5rem 0;
  padding-left: 0.25rem;
  padding-right: 0;
`;

const InlineGrid = styled(Grid)<{ gridSupported: boolean }>`
  ${({ gridSupported }) => gridSupported && 'display: inline-grid;'}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const User = getIcon('User');

const PersonalData: FC = (): JSX.Element => {
  const intl = useIntl();
  const { customer } = useCustomerContext();
  const { pushNotification } = useNotificationContext();
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [phoneModalOpen, setPhoneModalOpen] = useState<boolean>(false);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [ibanModalOpen, setIbanModalOpen] = useState<boolean>(false);
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const {
    personalData: { supressEmailChange, isIbanEditable },
    showHonorificForAddress,
  }: FragmentConfig = useConfig();
  const {
    firstName,
    lastName,
    email,
    businessPartnerNo,
    payment,
    birthDay,
    phoneNumbers,
    billingAddress: address,
  } = customer || {};

  const closeEmailModal = () => setEmailModalOpen(false);
  const closeAddressModal = () => setAddressModalOpen(false);

  return (
    <>
      <StyledHeadline level={2} stylingLevel={4} isMobile={isMobile}>
        <FormattedMessage id="PersonalData.Headline" defaultMessage="Persönliche Daten" />
      </StyledHeadline>
      <GreyBox>
        <VerificationNotification />
        <Card
          showHeaderSeparator={false}
          headerIcon={<User />}
          headerVariant="dark"
          headline={`${firstName} ${lastName} `}
          subHeadline={email}
          shouldHideHeaderDataForMouseflow
        >
          <HR className="noMargin" />
          <CardContent>
            <Label>
              <FormattedMessage id="PersonalData.BusinessPartnerNo" defaultMessage="Kundennummer" />
            </Label>
            <div data-mf-replace-inner="**REMOVED**">{businessPartnerNo}</div>
            <Label className="marginTop">
              <FormattedMessage id="PersonalData.Birthdate" defaultMessage="Geburtsdatum" />
            </Label>
            <div data-mf-replace-inner="**REMOVED**">{birthDay}</div>
            <HR />

            <LeftRight>
              <div className="left">
                <Label>
                  <FormattedMessage
                    id="PersonalData.EmailAddress"
                    defaultMessage="E-Mail-Adresse"
                  />
                </Label>
                <Ellipsed data-mf-replace-inner="**REMOVED**">{email}</Ellipsed>
              </div>
              {!supressEmailChange && (
                <StyledButton
                  layout="default"
                  data-testid="personaldata-emailmodal-button"
                  onClick={() => {
                    setEmailModalOpen(true);
                  }}
                  uppercase={false}
                >
                  {intl.formatMessage(messages.change)}
                </StyledButton>
              )}
              <Modal
                open={emailModalOpen}
                headline={intl.formatMessage(messages.emailModalHeadline)}
                noContentPadding
                onRequestClose={closeEmailModal}
                testId="personaldata-emailmodal"
                testIdClose="personaldata-emailmodal-close"
              >
                <EventTracking eventCategory="personalData_changeEmail" isLayer />
                <ChangeEmail onSuccess={closeEmailModal} />
              </Modal>
            </LeftRight>
            {customer?.verificationStatus === VerificationStatus.Verified && (
              <ChipWithMargin
                hasCheckmark
                colorScheme="success"
                variant="outlined-transparent"
                size="small"
              >
                <FormattedMessage id="PersonalData.Verified.Text" defaultMessage="Bestätigt" />
              </ChipWithMargin>
            )}
            <HR />
            <LeftRight>
              <div className="left">
                <Label>
                  <FormattedMessage id="PersonalData.Password" defaultMessage="Password" />
                </Label>
                <div>*************</div>
                <PasswordHint>
                  <FormattedMessage
                    id="PersonalData.PasswordHint"
                    defaultMessage="Das Passwort wird aus Sicherheitsgründen nicht angezeigt."
                  />
                </PasswordHint>
              </div>
              <StyledButton
                layout="default"
                data-testid="personaldata-passwordmodal-button"
                onClick={() => {
                  setPasswordModalOpen(true);
                }}
                uppercase={false}
              >
                {intl.formatMessage(messages.change)}
              </StyledButton>
              <Modal
                open={passwordModalOpen}
                headline={intl.formatMessage(messages.passwordModalHeadline)}
                onRequestClose={() => {
                  setPasswordModalOpen(false);
                }}
                testId="personaldata-passwordmodal"
                testIdClose="personaldata-passwordmodal-close"
              >
                <div>
                  <EventTracking eventCategory="personalData_changePassword" isLayer />
                  <ChangePassword
                    onSuccess={() => {
                      setPasswordModalOpen(false);
                      pushNotification({
                        level: 'success',
                        hasClose: true,
                        autoClose: 3000,
                        content: (
                          <FormattedMessage
                            id="PersonalData.changepassword.success"
                            defaultMessage="Passwort erfolgreich geändert."
                          />
                        ),
                      });
                    }}
                  />
                </div>
              </Modal>
            </LeftRight>
            <HR />
            <LeftRight>
              <div className="left">
                <Label>
                  <FormattedMessage id="PersonalData.Phone" defaultMessage="Telefon" />
                </Label>
                <div data-mf-replace-inner="**REMOVED**">
                  {phoneNumbers && (
                    <InlineGrid
                      cellsPerRow={[2, 2, 2]}
                      colGap="0.25rem"
                      gridSupported={browserSupportsGrid()}
                    >
                      {phoneNumbers?.map((numberWithType) => (
                        <React.Fragment key={numberWithType.number}>
                          <Cell>
                            {intl.formatMessage(
                              messages[numberWithType.type || PhoneNumberWithTypeTypeEnum.FIXED],
                            )}
                          </Cell>
                          <Cell>{numberWithType.number}</Cell>
                        </React.Fragment>
                      ))}
                    </InlineGrid>
                  )}
                </div>
              </div>
              <StyledButton
                layout="default"
                data-testid="personaldata-phonemodal-button"
                onClick={() => {
                  setPhoneModalOpen(true);
                }}
                uppercase={false}
              >
                {intl.formatMessage(messages.change)}
              </StyledButton>
              <Modal
                open={phoneModalOpen}
                headline={intl.formatMessage(messages.phoneModalHeadline)}
                onRequestClose={() => {
                  setPhoneModalOpen(false);
                }}
                testId="personaldata-phonemodal"
                testIdClose="personaldata-phonemodal-close"
                noContentPadding
              >
                <div>
                  <EventTracking eventCategory="personalData_changePhoneNumber" isLayer />
                  <PhoneBook />
                </div>
              </Modal>
            </LeftRight>
            <HR />
            <LeftRight>
              <div className="left">
                <Label>
                  <FormattedMessage
                    id="PersonalData.InvoiceAddress"
                    defaultMessage="Rechnungsanschrift"
                  />
                </Label>
                <div data-mf-replace-inner="**REMOVED**">
                  <div>{address?.honorific || ''}</div>
                  <div>{`${address?.firstName} ${address?.lastName} `}</div>
                  <div>{`${address?.street} ${address?.houseNumber} `}</div>
                  {address?.additional && <div>{`${address?.additional} `}</div>}
                  <div>{`${address?.zipCode} ${address?.city} `}</div>
                </div>
              </div>
              <StyledButton
                layout="default"
                data-testid="personaldata-addressmodal-button"
                onClick={() => {
                  setAddressModalOpen(true);
                }}
                uppercase={false}
              >
                {intl.formatMessage(messages.change)}
              </StyledButton>
              <Modal
                className="addressform-modal"
                open={addressModalOpen}
                headline={intl.formatMessage(messages.addressModalHeadline)}
                onRequestClose={closeAddressModal}
                testId="personaldata-addressmodal"
                testIdClose="personaldata-addressmodal-close"
              >
                <EventTracking eventCategory="personalData_changeBillingAddress" isLayer />
                <ChangeAddress
                  address={address}
                  showHonorific={showHonorificForAddress}
                  onSuccess={closeAddressModal}
                  useChangeAddressWording
                />
              </Modal>
            </LeftRight>
            {isIbanEditable && (
              <>
                <HR />
                <LeftRight>
                  <div className="left">
                    <Label>
                      <FormattedMessage id="PersonalData.Iban" defaultMessage="IBAN" />
                    </Label>
                    <div data-mf-replace-inner="**REMOVED**">
                      <div>{payment?.bankAccount?.iban}</div>
                    </div>
                  </div>
                  <StyledButton
                    layout="default"
                    data-testid="personaldata-iban-button"
                    onClick={() => {
                      setIbanModalOpen(true);
                    }}
                    uppercase={false}
                  >
                    {intl.formatMessage(messages.change)}
                  </StyledButton>
                  <Modal
                    className="iban-modal"
                    open={ibanModalOpen}
                    headline={intl.formatMessage(messages.ibanModalHeadline)}
                    onRequestClose={() => setIbanModalOpen(false)}
                    testId="iban-change-modal"
                    testIdClose="iban-change-modal-close"
                  >
                    <ChangeIbanForm
                      iban={payment?.bankAccount?.iban}
                      onSuccess={() => setIbanModalOpen(false)}
                      onCancel={() => setIbanModalOpen(false)}
                    />
                  </Modal>
                </LeftRight>
              </>
            )}
          </CardContent>
        </Card>
      </GreyBox>
    </>
  );
};

export default PersonalData;
