import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';
import { Cell, Grid } from '@empiriecom/module-components/Grid';

const HeadlineSkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
  width: 30%;
`;

const CardSkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const StyledGrid = styled(Grid)`
  width: 100%;
`;

// Simple skeleton; could be much more fancy component
const AddressbookContainerSkeleton: FunctionComponent = (): JSX.Element => (
  <>
    <HeadlineSkeleton height="2rem" />
    <StyledGrid cellsPerRow={[1, 2, 2]} colGap="1rem">
      <Cell>
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
      </Cell>
      <Cell>
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
        <CardSkeleton height="10rem" />
      </Cell>
    </StyledGrid>
  </>
);

export default AddressbookContainerSkeleton;
