import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';
import { Cell, Grid } from '@empiriecom/module-components/Grid';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';

const GreyBox = styled(Skeleton)`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const Card = styled.div`
  border-radius: 4px;
  background-color: #fff;
`;

const CardHead = styled.div`
  padding: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: justify;
`;

const Square = styled(Skeleton)`
  width: 3rem;
  height: 3rem;
  margin: 0 1rem 0 0;
`;

const CardContent = styled.div`
  padding: 0.5rem;
`;

const MediumLine = styled(Skeleton)`
  width: 8rem;
  margin: 0.25rem 0 0 0;
  align-self: center;
`;

const ShortLine = styled(Skeleton)`
  width: 5rem;
  margin: 0.25rem 0 0 0;
`;

const LargeLine = styled(Skeleton)`
  width: 10rem;
  margin: 0.25rem 0 0 0;
`;

const HR = styled(Skeleton)`
  width: 100%;
  margin: 0.5rem 0;
`;

const GridLeftRight = styled.div`
  font-size: 0.813rem;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Creates 2 columns */
  justify-items: flex-start;
`;

const StyledBorderedPanel = styled(BorderedPanel)`
  margin: 0.75rem 0;
`;

const StyledPauseGrid = styled(Grid)`
  grid-template-columns: 2fr 1fr 1fr;
`;

export const PartialPaymentCardSkeleton: FunctionComponent = (): React.ReactElement => (
  <Cell>
    {/* Left side first box */}
    <GreyBox height="auto">
      <Card>
        <CardHead>
          <LeftRight>
            <Square height="2rem" />
            <div>
              <MediumLine height="1.25rem" />
            </div>
          </LeftRight>
        </CardHead>
        <HR height="0" />
        <CardContent>
          <LeftRight style={{ justifyContent: 'space-between' }}>
            <MediumLine height="1rem" />
            <MediumLine height="1rem" />
          </LeftRight>

          <HR height="0" />
          <StyledBorderedPanel>
            <div>
              <LeftRight style={{ justifyContent: 'space-between' }}>
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
              </LeftRight>
              <HR height="0" />
              <GridLeftRight>
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
              </GridLeftRight>
              <MediumLine height="1rem" />
            </div>
          </StyledBorderedPanel>
          <Skeleton height="1rem" />
          <ShortLine height="1rem" />
          <StyledBorderedPanel>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MediumLine height="1.5rem" />
              <GridLeftRight>
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
                <MediumLine height="1rem" />
              </GridLeftRight>
            </div>
          </StyledBorderedPanel>

          <HR height="0" />
          <MediumLine height="1.5rem" />
        </CardContent>
      </Card>
    </GreyBox>
  </Cell>
);

export const PaymentPauseCardSkeletion: FunctionComponent = (): React.ReactElement => (
  <Cell>
    {/* Right side first box */}
    <GreyBox height="auto">
      <Card>
        <CardHead>
          <LeftRight>
            <Square height="2rem" />
            <div>
              <MediumLine height="1.25rem" />
            </div>
          </LeftRight>
        </CardHead>
        <HR height="0" />
        <CardContent>
          <Skeleton height="1rem" />
          <ShortLine height="1rem" />

          <HR height="0" />
          <StyledPauseGrid>
            <MediumLine height="1rem" />
            <ShortLine height="1rem" />
            <ShortLine height="1rem" />
            <MediumLine height="1rem" />
            <ShortLine height="1rem" />
            <ShortLine height="1rem" />
            <MediumLine height="1rem" />
            <ShortLine height="1rem" />
            <ShortLine height="1rem" />
          </StyledPauseGrid>
          <HR height="0" />

          <Skeleton height="1rem" />
          <LargeLine height="1rem" />
        </CardContent>
      </Card>
    </GreyBox>
  </Cell>
);

export const PaymentProtectionCardSkeletion: FunctionComponent = (): React.ReactElement => (
  <Cell>
    {/* Right side first box */}
    <GreyBox height="auto">
      <Card>
        <CardHead>
          <LeftRight>
            <Square height="2rem" />
            <div>
              <MediumLine height="1.25rem" />
            </div>
          </LeftRight>
        </CardHead>
        <HR height="0" />
        <CardContent>
          <Skeleton height="1rem" />
          <ShortLine height="1rem" />
          <LargeLine height="1rem" />
          <LargeLine height="1rem" />
          <LargeLine height="1rem" />

          <HR height="0" />
          <Skeleton height="1rem" />
          <HR height="0" />

          <Skeleton height="1rem" />
          <LargeLine height="1rem" />
        </CardContent>
      </Card>
    </GreyBox>
  </Cell>
);
