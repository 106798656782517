import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import {
  BookingList,
  BookingsResponse,
  GetBookingsRequest,
  GetBookingsTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-media-match';

export type BookingListData = {
  isLoading: boolean;
  isError: boolean;
  bookingListData: BookingList;
  loadMoreBookings: () => Promise<void>;
};

const useBookings = (
  bookingFilter: GetBookingsTypeEnum = GetBookingsTypeEnum.All,
): BookingListData => {
  const accountApi = useAccountApi();
  const { ecLocale } = useCustomerContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentBookingListData, setCurrentBookingListData] = useState<BookingList>({});
  const [isError, setIsError] = useState<boolean>(false);
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });

  let isSubscribed: boolean = true;

  const fetchBookingData = async ({ id, days, type }: GetBookingsRequest): Promise<BookingList> => {
    let bookingResult: BookingsResponse | undefined;
    if (accountApi) {
      bookingResult = await accountApi.getBookings({
        id,
        days,
        type,
        ecMobile: isMobile,
        ecLocale,
      });
    }
    if (!bookingResult || !bookingResult.data) {
      throw new Error('No valid data was returned');
    }
    return bookingResult.data;
  };

  const reloadBookingDataForFilter = async (): Promise<void> => {
    try {
      /* istanbul ignore else */
      if (isSubscribed) {
        setIsError(false);
        setIsLoading(true);
      }
      const bookingData = await fetchBookingData({
        type: bookingFilter,
        ecLocale,
      });
      /* istanbul ignore else */
      if (isSubscribed) {
        setCurrentBookingListData(bookingData);
      }
    } catch {
      /* istanbul ignore else */
      if (isSubscribed) {
        setIsError(true);
      }
    } finally {
      /* istanbul ignore else */
      if (isSubscribed) {
        setIsLoading(false);
      }
    }
  };

  const loadMoreBookings = async (): Promise<void> => {
    const { nextDataId } = currentBookingListData;
    if (nextDataId) {
      try {
        /* istanbul ignore else */
        if (isSubscribed) {
          setIsError(false);
          setIsLoading(true);
        }
        const bookingData = await fetchBookingData({
          id: nextDataId,
          type: bookingFilter,
          ecLocale,
        });
        /* istanbul ignore else */
        if (isSubscribed) {
          setCurrentBookingListData({
            bookingList: [
              ...(currentBookingListData.bookingList || []),
              ...(bookingData.bookingList || []),
            ],
            nextDataId: bookingData.nextDataId,
          });
        }
      } catch {
        /* istanbul ignore else */
        if (isSubscribed) {
          setIsError(true);
        }
      } finally {
        /* istanbul ignore else */
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    reloadBookingDataForFilter();
  }, [accountApi, bookingFilter]);

  useEffect(() => {
    return () => {
      isSubscribed = false;
    };
  }, []);

  return { isLoading, isError, bookingListData: currentBookingListData, loadMoreBookings };
};

export default useBookings;
