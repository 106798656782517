import React, { FC } from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-media-match';
import { Headline, Modal } from '@empiriecom/module-components';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import getIcon from '@empiriecom/module-components/utils/getIcon';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerStatus from '@/src/hooks/useCustomerStatus';
import Button from '@empiriecom/module-components/Button';
import Card, { CardSeperator } from '@empiriecom/module-components/Card';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import { Esi } from '@empiriecom/module-components/Esi';

import LoungeContainerSkeleton from '@/src/components/LoungeContainer/skeleton';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import { CustomerStatusPremiumStatusKindEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { Redirect } from 'react-router';
import EventTracking from '../EventTracking';
import VerificationNotification from '../VerificationNotification';

const Checkmark = getIcon('Checkmark');

const messages = defineMessages({
  pageHeadline: {
    id: 'LoungeContainer.pageHeadline',
    defaultMessage: '{firstName} Premiumkunden-Seite',
  },
  introtext: {
    id: 'LoungeContainer.introtext',
    defaultMessage: 'Ihr exklusiver Premiumkunden-Vorteil garantiert Ihnen sorgenfreies Einkaufen!',
  },
  advantagesHeadline: {
    id: 'LoungeContainer.advantagesHeadline',
    defaultMessage: 'Als Premiumkunde genießen Sie exklusive Vorteile:',
  },
  advantage0: {
    id: 'LoungeContainer.advantage0',
    defaultMessage: 'Gratis Versand bei jeder Bestellung, ohne Mindestbestellwert',
  },
  advantage1: {
    id: 'LoungeContainer.advantage1',
    defaultMessage: 'Keine Speditionskosten (im Wert von 34,99 €)',
  },
  advantage2: {
    id: 'LoungeContainer.advantage2',
    defaultMessage: 'Exklusive Gutschein- und Rabattaktionen',
  },
  advantage3: {
    id: 'LoungeContainer.advantage3',
    defaultMessage:
      'Exklusiver und bevorzugter Kundenservice per Mail <maillink>premiumkunde@universal.at</maillink> oder Telefon <b>0662/4485-2500</b>',
  },
  advantage4: {
    id: 'LoungeContainer.advantage4',
    defaultMessage: '-',
  },
  advantage5: {
    id: 'LoungeContainer.advantage5',
    defaultMessage: '-',
  },
  advantage6: {
    id: 'LoungeContainer.advantage6',
    defaultMessage: '-',
  },
  advantage7: {
    id: 'LoungeContainer.advantage7',
    defaultMessage: '-',
  },
  advantage8: {
    id: 'LoungeContainer.advantage8',
    defaultMessage: '-',
  },
  advantage9: {
    id: 'LoungeContainer.advantage9',
    defaultMessage: '-',
  },
  mystatus: {
    id: 'LoungeContainer.mystatus',
    defaultMessage: 'Mein Status',
  },
  importantOverview: {
    id: 'LoungeContainer.importantOverview',
    defaultMessage: 'Das Wichtigste im Überblick',
  },
});

const PageHeadline = styled(Headline)`
  margin-bottom: 0.5rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    text-align: left;
  }
`;

const Introtext = styled.div`
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    text-align: left;
  }
`;

const LeftRight = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  margin-bottom: 1rem;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    flex-direction: row;
  }
`;

const Box = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    width: 50%;
  }
`;

const Advantages = styled.div`
  padding: 2rem;
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.mobile}) {
    text-align: left;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
`;

const Li = styled.li`
  font-size: 0.75rem;
  letter-spacing: 0.18px;
  line-height: 1.5;
  display: flex;
  padding: 0.25rem 0;
`;

const ListText = styled.div`
  padding-top: 0.2rem;
`;
const HintDisclaimer = styled.div`
  margin: 1rem 1rem 1rem 2rem;
`;
const StyledCheckmark = styled(Checkmark)<{ colorVariant: 'primary' | 'secondary' | 'success' }>`
  margin-right: 0.5rem;
  color: ${({ theme, colorVariant }) => theme[colorVariant].color.normal};
  fill: ${({ theme, colorVariant }) => theme[colorVariant].color.normal};
`;

const StyledCard = styled(Card)`
  margin: 1rem;
`;

const CardContent = styled.div`
  > * + * {
    border-top: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
  }
`;

const ReturnsHint = styled.div`
  margin: 0 1rem 1rem 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1.5rem;

  & > * {
    margin-bottom: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;

    .submit-btn {
      margin-left: auto;
    }
  }
`;

const StatusData = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin: 0 1rem;
`;

const ModalHeadline = styled(Headline)`
  margin-bottom: 1rem;
`;

const LoungeContainer: FC = (): JSX.Element => {
  const intl = useIntl();
  const { pushNotification } = useNotificationContext();

  // @TODO check if the expected behaviour of the customer context.isLoading props is still correct

  const { lounge, companyId } = useConfig<FragmentConfig>();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const { locale } = useIntl();
  const customerContext = useCustomerContext();
  const customerApi = useCustomerApi();
  const {
    customer,
    actions: { setAdditionalData },
  } = customerContext || {};
  const { loading, data } = useCustomerStatus();
  const { businessPartnerNo, firstName } = customer || {};
  const { premiumStatus } = data || {};
  const {
    kind,
    startDate,
    endDate,
    renewalDate,
    lastUpdate,
    cancellable,
    orderCount,
    orders,
    savings,
    salesTarget,
  } = premiumStatus || {};

  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState<boolean>(false);

  const lang = locale.split('-')[0];

  let premiumContent: string | undefined;
  if (
    kind === CustomerStatusPremiumStatusKindEnum.Subscription &&
    lounge.premiumContentSubscription
  ) {
    premiumContent = lounge.premiumContentSubscription(lang);
  } else if (
    kind === CustomerStatusPremiumStatusKindEnum.Salestarget &&
    lounge.premiumContentSalesTarget
  ) {
    premiumContent = lounge.premiumContentSalesTarget(lang);
  } else if (
    kind === CustomerStatusPremiumStatusKindEnum.Fixedterm &&
    lounge.premiumContentFixedTerm
  ) {
    premiumContent = lounge.premiumContentFixedTerm(lang);
  } else if (kind === CustomerStatusPremiumStatusKindEnum.Dummy && lounge.premiumContentDummy) {
    premiumContent = lounge.premiumContentDummy(lang);
  } else {
    premiumContent = undefined;
  }

  const closeModalbox = () => setIsCancelModalOpen(false);

  const cancelPremium = async () => {
    try {
      const result = await customerApi!.cancelPremium({
        ecLocale: locale,
      });
      if (result.success) {
        setAdditionalData('customerStatus', {
          loading: false,
          data: result.customerStatus,
        });
        setIsCancelModalOpen(false);
        pushNotification({
          autoClose: 2500,
          hasClose: true,
          level: 'success',
          content: (
            <>
              <FormattedMessage
                defaultMessage="Ihre Mitgliedschaft wurde beendet"
                id="LoungeContainer.cancelPremium.success"
              />
            </>
          ),
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      pushNotification({
        autoClose: 2500,
        hasClose: true,
        level: 'error',
        content: (
          <>
            <FormattedMessage
              defaultMessage="Das hat leider nicht geklappt. Bitte versuche es nochmal."
              id="LoungeContainer.cancelPremium.error"
            />
          </>
        ),
      });
    }
  };

  if (customerContext.isLoading || loading) {
    return <LoungeContainerSkeleton />;
  }

  const pageHeadlineName = firstName && firstName.endsWith('s') ? firstName : `${firstName}´s`;
  const pageHeadline = intl.formatMessage(messages.pageHeadline, {
    firstName: <span data-mf-replace-inner="**REMOVED**">{pageHeadlineName}</span>,
  });

  if (!premiumStatus) {
    return <Redirect to="/mein-konto/uebersicht" />;
  }

  return (
    <div>
      <EventTracking eventCategory="premiumLounge" />
      <VerificationNotification />
      <PageHeadline level={3}>{pageHeadline}</PageHeadline>
      <Introtext>{intl.formatMessage(messages.introtext)}</Introtext>
      {/* Image & Advantages */}
      <div>
        <LeftRight>
          <Box>
            <img src={lounge.banner(lang, isMobile ? 'mobile' : 'desktop')} alt="" />
          </Box>
          <Box>
            <Advantages>
              <StyledHeadline level={5}>
                {intl.formatMessage(messages.advantagesHeadline)}
              </StyledHeadline>
              <Ul>
                {[...Array(10)].map((_v, i) => {
                  /* Not the best solution but maybe the only way to access messages dynamically */
                  const text = intl.formatMessage(
                    (messages as { [key: string]: MessageDescriptor })[`advantage${i}`],
                    {
                      maillink: (...chunks: string[]) => (
                        <a href={`mailto:${chunks.join('')}`}>{chunks}</a>
                      ),
                      b: (...chunks: string[]) => <b>{chunks}</b>,
                      break: <br />,
                    },
                  );
                  if (text !== '-') {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Li key={i}>
                        <div>
                          <StyledCheckmark
                            size="1.5rem"
                            colorVariant={
                              companyId === 'universalat'
                                ? 'secondary'
                                : companyId === 'quellede'
                                ? 'primary'
                                : 'success'
                            }
                          />
                        </div>
                        <ListText>{text}</ListText>
                      </Li>
                    );
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  return <div key={i} />;
                })}
              </Ul>
            </Advantages>
          </Box>
        </LeftRight>
      </div>
      {/* Status & Data */}
      {lounge.showStatusAndData && (
        <div>
          <LeftRight>
            <Box>
              <StyledCard headline={intl.formatMessage(messages.mystatus)}>
                <CardContent>
                  {startDate && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.since"
                          defaultMessage="Premiumkunde seit"
                        />
                      </div>
                      <div>{startDate}</div>
                    </StatusData>
                  )}
                  {endDate && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.until"
                          defaultMessage="Premiumkunden-Vorteil läuft bis"
                        />
                      </div>
                      <div>{endDate}</div>
                    </StatusData>
                  )}
                  {renewalDate && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.renewal"
                          defaultMessage="Mitgliedschafts-Verlängerung am"
                        />
                      </div>
                      <div>{renewalDate}</div>
                    </StatusData>
                  )}
                  {salesTarget && (
                    <StatusData>
                      <div>
                        <FormattedMessage id="LoungeContainer.goal" defaultMessage="Umsatzziel" />
                      </div>
                      <div>
                        <FormattedCurrency
                          value={salesTarget.value}
                          currency={salesTarget.currencyCode}
                        />
                      </div>
                    </StatusData>
                  )}
                  {!!orderCount && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.orderCount"
                          defaultMessage="Ihre versandkostenfreien Bestellungen"
                        />
                      </div>
                      <div>{orderCount}</div>
                    </StatusData>
                  )}
                </CardContent>
                <CardSeperator />
                {savings && (
                  <StatusData>
                    <div>
                      <strong>
                        <FormattedMessage
                          id="LoungeContainer.savings"
                          defaultMessage="Aktuelle Ersparnisse"
                        />
                      </strong>
                    </div>
                    <div>
                      <strong>
                        <FormattedCurrency
                          value={savings?.value || 0}
                          currency={savings?.currencyCode || 'EUR'}
                        />
                      </strong>
                    </div>
                  </StatusData>
                )}
              </StyledCard>
              <HintDisclaimer>
                {renewalDate && (
                  <FormattedMessage id="LoungeContainer.extensionHint" defaultMessage=" " />
                )}
                {startDate && !renewalDate && (
                  <FormattedMessage id="LoungeContainer.freeMembershipHint" defaultMessage=" " />
                )}
              </HintDisclaimer>
            </Box>
            <Box>
              <StyledCard headline={intl.formatMessage(messages.importantOverview)}>
                <CardContent>
                  {businessPartnerNo && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.businessPartnerNo"
                          defaultMessage="Meine Kundennummer"
                        />
                      </div>
                      <div data-mf-replace-inner="**REMOVED**">{businessPartnerNo}</div>
                    </StatusData>
                  )}
                  {orders && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.orders"
                          defaultMessage="Mein aktueller Jahresumsatz"
                        />
                      </div>
                      <div>
                        <FormattedCurrency
                          value={orders?.value || 0}
                          currency={orders?.currencyCode || 'EUR'}
                        />
                        *
                      </div>
                    </StatusData>
                  )}
                  {lastUpdate && (
                    <StatusData>
                      <div>
                        <FormattedMessage
                          id="LoungeContainer.datastatus"
                          defaultMessage="Datenstand"
                        />
                      </div>
                      <div>{lastUpdate}</div>
                    </StatusData>
                  )}
                </CardContent>
              </StyledCard>
              {lounge.showHint && (
                <ReturnsHint>
                  <FormattedMessage
                    id="LoungeContainer.retursHint"
                    defaultMessage="* Retouren werden beim Umsatz nicht berücksichtigt."
                  />
                </ReturnsHint>
              )}
            </Box>
          </LeftRight>
        </div>
      )}
      {cancellable && (
        <>
          <ButtonWrapper>
            <Button
              layout="primary"
              size="medium"
              type="submit"
              className="submit-btn"
              uppercase={false}
              onClick={() => setIsCancelModalOpen(true)}
              data-testid="submit-button"
            >
              <FormattedMessage
                id="LoungeContainer.cancel"
                defaultMessage="Premiumkunden-Abo kündigen"
              />
            </Button>
          </ButtonWrapper>
          <Modal open={isCancelModalOpen} onRequestClose={closeModalbox}>
            <EventTracking eventCategory="premiumLounge_cancelSubscription" isLayer />
            <ModalHeadline element="div" level={2}>
              <FormattedMessage
                id="LoungeContainer.cancel.modal.headline"
                defaultMessage="Schade, dass Sie sich abmelden wollen!"
              />
            </ModalHeadline>
            <div>
              <FormattedMessage
                id="LoungeContainer.cancel.info"
                defaultMessage="Ihre Vorteile sind Ihnen bis Ablauf eines Jahres nach Anmeldung sicher.{break}Wir sind stehts bemüht, die Vorteile so exklusiv wie möglich auszuwählen und Ihr Vorteilspaket stetig zu erweitern. Geben Sie uns weiterhin die Chance, Sie zu überzeugen.{break}Wenn Sie nicht mehr die Vorteile genießen möchten, klicken Sie bitte verbindlich auf nachfolgenden Button."
                values={{
                  break: (
                    <>
                      <br />
                      <br />
                    </>
                  ),
                }}
              />
            </div>
            <ButtonWrapper>
              <Button
                layout={lounge.switchModalButtons ? 'primary' : 'default'}
                size="medium"
                onClick={closeModalbox}
              >
                <FormattedMessage
                  id="LoungeContainer.cancel.stay"
                  defaultMessage="Ich möchte Premiumkunde bleiben"
                />
              </Button>
              <Button
                layout={lounge.switchModalButtons ? 'default' : 'primary'}
                size="medium"
                onClick={cancelPremium}
                className="submit-btn"
                data-testid="real-submit-button"
              >
                <FormattedMessage
                  id="LoungeContainer.cancel"
                  defaultMessage="Premiumkunden-Abo kündigen"
                />
              </Button>
            </ButtonWrapper>
          </Modal>
        </>
      )}
      {lounge.basicExternalLoungeContent && (
        <div>
          <Esi src={lounge.basicExternalLoungeContent} />
        </div>
      )}
      {premiumContent && (
        <div>
          <Esi src={premiumContent} />
        </div>
      )}
    </div>
  );
};

export default LoungeContainer;
