import React, { FC } from 'react';
import { InternalPageContainer } from '@/src/typings/Navigation';
import AddressbookContainer from '@/src/components/AddressbookContainer';
import BookingContainer from '@/src/components/BookingContainer';
import PersonalDataContainer from '@/src/components/PersonalDataContainer';
import OverviewContainer from '@/src/components/OverviewContainer';
import ShipmentInfosContainer from '@/src/components/ShipmentInfosContainer';
import LoungeContainer from '@/src/components/LoungeContainer';
import FlexikontoContainer from '@/src/components/FlexikontoContainer';
import BankstatementsContainer from '@/src/components/BankstatementsContainer';
import CallcenterLoginContainer from '@/src/components/CallcenterLoginContainer';
import ForgotPasswordContainer from '@/src/components/ForgotPasswordContainer';
import CallCenterADFSLoginResponseContainer from '@/src/components/CallcenterLoginContainer/CCADFSLoginResponse';

type NeverCalled = (_: never) => FC;
const neverCalled: NeverCalled = () => () => <></>;

/**
Maps InternalPageContainer to the actual FC behind it.
This is required to prevent a dependency cycle where the config depends on React components
but our component tree depends on the config
*/
const mapInternalPageToFC = (container: InternalPageContainer): FC => {
  switch (container) {
    case InternalPageContainer.Addressbook:
      return AddressbookContainer;
    case InternalPageContainer.Overview:
      return OverviewContainer;
    case InternalPageContainer.PersonalData:
      return PersonalDataContainer;
    case InternalPageContainer.Bookings:
      return BookingContainer;
    case InternalPageContainer.ShipmentInfos:
      return ShipmentInfosContainer;
    case InternalPageContainer.Lounge:
      return LoungeContainer;
    case InternalPageContainer.Flexikonto:
      return FlexikontoContainer;
    case InternalPageContainer.Bankstatements:
      return BankstatementsContainer;
    case InternalPageContainer.CallcenterLogin:
      return CallcenterLoginContainer;
    case InternalPageContainer.CallcenterADFSLoginResponse:
      return CallCenterADFSLoginResponseContainer;
    case InternalPageContainer.PasswordForget:
      return ForgotPasswordContainer;
    default:
      /*
         behold the magic!
         This branch will only be called when our switch doesn't have a case for all options,
         which will cause a build failure as neverCalled should never get a parameter (which is indicated by the never type above)
       */
      return neverCalled(container);
  }
};

export default mapInternalPageToFC;
