import React, { SetStateAction, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Headline } from '@empiriecom/module-components/Headline';
import { Modal } from '@empiriecom/module-components/Modal';
import { Input, Select, Textarea } from '@empiriecom/module-components/FormControls';
import { DefaultCard as Card } from '@empiriecom/module-components/Card';
import { Button } from '@empiriecom/module-components/Button';
import { Notification } from '@empiriecom/module-components/Notification';

import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';

import { ReclaimProductRequestPayload } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { IReclamationForm } from './types';
import { reclamationMessages } from './messages';
import { getFieldErrors, reclamationFormReducer } from './reducer';
import { setValue, confirmInputvalue, setErrors } from './actions';

const SpacedCard = styled(Card)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

const ResponsiveColumnLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    flex-direction: row;

    & > * {
      flex: 1 1 50%;
    }

    & > *:last-child {
      padding-left: 0.75rem;
    }
  }
`;

const ContentWrapper = styled.div`
  display: block;
  padding: 0.5rem;
`;

const FormWrapper = styled.div`
  display: block;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.5rem;

  & > * {
    flex: 0 1 40%;
  }
`;

const InformationBlock = styled.div`
  display: block;

  & > *:first-child {
    display: block;
  }

  & > *:last-child {
    display: block;
    font-weight: bold;
  }
`;

const InputColumnWrapper = styled.div<{ extendSide?: 'left' | 'right' }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;

  ${({ extendSide }) =>
    !extendSide &&
    `
    & > * {
    flex: 0 0 48%;
  }
  `}

  ${({ extendSide }) =>
    extendSide === 'left' &&
    `
    & > *:first-child {
      flex: 0 1 75%;
    }

    & > *:last-child {
      padding-left: 1rem;
      flex: 1 0 25%;
    }`}

  ${({ extendSide }) =>
    extendSide === 'right' &&
    `
    & > *:first-child {
      flex: 1 0 25%;
    }

    & > *:last-child {
      padding-left: 1rem;
      flex: 0 1 75%;
    }`}
`;

export const ReclamationForm: React.FC<IReclamationForm> = ({
  data: {
    customer,
    invoiceNumber,
    invoiceDate,
    articleNumber,
    articleDescription,
    deliveryAddress,
  },
  onCancel,
  onSuccess,
}) => {
  if (!customer) {
    return (
      <div data-testid="no-customer-content">
        <FormattedMessage
          id="ReclamationForm.error"
          defaultMessage="Du bist aktuell nicht angemeldet! Melde dich doch bitte an!"
        />
      </div>
    );
  }

  const {
    businessPartnerNo: customerNo,
    firstName,
    lastName,
    billingAddress: address,
    email: emailAddress,
  } = customer;

  if (!invoiceNumber || !invoiceDate || !customerNo) {
    return (
      <div data-testid="invalid-data-content">
        <FormattedMessage
          id="ReclamationForm.error.invalidData"
          defaultMessage="Wir konnten deine Daten aktuell nicht finden. Versuche es doch später erneut!"
        />
      </div>
    );
  }

  const intl = useIntl();
  const [openModalId, setOpenModalId] = useState<'article' | 'shipping' | undefined>(undefined);
  const [state, dispatch] = useReducer(reclamationFormReducer, {
    articleNumber: {
      value: articleNumber,
      inputValue: articleNumber,
    },
    articleDescription: {
      value: articleDescription,
      inputValue: articleDescription,
    },
    shippingFirstName: {
      value: deliveryAddress?.firstName || address?.firstName,
      inputValue: deliveryAddress?.firstName || address?.firstName,
    },
    shippingLastName: {
      value: deliveryAddress?.lastName || address?.lastName,
      inputValue: deliveryAddress?.lastName || address?.lastName,
    },
    shippingStreet: {
      value: deliveryAddress?.street || address?.street,
      inputValue: deliveryAddress?.street || address?.street,
    },
    shippingHouseNumber: {
      value: deliveryAddress?.houseNumber || address?.houseNumber,
      inputValue: deliveryAddress?.houseNumber || address?.houseNumber,
    },
    shippingZipCode: {
      value: deliveryAddress?.zipCode || address?.zipCode,
      inputValue: deliveryAddress?.zipCode || address?.zipCode,
    },
    shippingCity: {
      value: deliveryAddress?.city || address?.city,
      inputValue: deliveryAddress?.city || address?.city,
    },
    reclamationReason: {},
    reclamationDescription: {},
    replacementNumber: {},
    dataTypeShield: {},
    contactBy: {},
  });

  const customerApi = useCustomerApi();
  const { ecLocale } = useCustomerContext();
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);

  const handleOnClick = (modalId: SetStateAction<'article' | 'shipping' | undefined>) => {
    setOpenModalId(modalId);
  };

  const submitReclamation = async () => {
    setShowSubmitError(false);
    const allFieldErrors = getFieldErrors(state);
    if (!customerApi || allFieldErrors.hasErrors) {
      dispatch(setErrors(allFieldErrors));
      return;
    }
    try {
      await customerApi.reclaimProduct({
        ecLocale,
        reclaimProductRequestPayload: {
          invoiceNumber: invoiceNumber.toString(),
          invoiceDate,
          articleNumber: state.articleNumber.value || '',
          articleDescription: state.articleDescription.value || '',
          customerNo,
          emailAddress,
          billingAddress: {
            firstName: address?.firstName,
            lastName: address?.lastName,
            street: address?.street,
            houseNumber: address?.houseNumber,
            zipCode: address?.zipCode,
            city: address?.city,
            countryCode: address?.countryCode || 'DE',
          },
          shippingAddress: {
            firstName: state.shippingFirstName.value,
            lastName: state.shippingLastName.value,
            street: state.shippingStreet.value,
            houseNumber: state.shippingHouseNumber.value,
            zipCode: state.shippingZipCode.value,
            city: state.shippingCity.value,
            countryCode: deliveryAddress?.countryCode || address?.countryCode || 'DE',
          },
          reclamationReason: state.reclamationReason.value,
          reclamationDescription: state.reclamationDescription.value,
          replacementNumber: state.replacementNumber.value,
          dataTypeShield: state.dataTypeShield.value,
          contactBy: state.contactBy.value,
        } as ReclaimProductRequestPayload,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch {
      setShowSubmitError(true);
    }
  };

  const ArticleOrderDetailsModal: React.ReactNode = (
    <Modal
      noContentPadding
      testIdClose="article-modal-close"
      open={openModalId === 'article'}
      onRequestClose={() => setOpenModalId(undefined)}
    >
      <div>
        <FormWrapper>
          <div>
            <Input
              id="articleNumber"
              name="articleNumber"
              label={intl.formatMessage(reclamationMessages.labelArticleNumber)}
              value={state.articleNumber.inputValue}
              error={
                state.articleNumber.errorMessage &&
                intl.formatMessage(state.articleNumber.errorMessage)
              }
              onChange={(e) => dispatch(setValue('articleNumber', e.target.value))}
            />
          </div>
          <div>
            <Input
              id="articleDescription"
              name="articleDescription"
              label={intl.formatMessage(reclamationMessages.labelArticleDescription)}
              value={state.articleDescription.inputValue}
              error={
                state.articleDescription.errorMessage &&
                intl.formatMessage(state.articleDescription.errorMessage)
              }
              onChange={(e) => dispatch(setValue('articleDescription', e.target.value))}
            />
          </div>
        </FormWrapper>
      </div>
      <ButtonWrapper>
        <Button
          layout="primary"
          onClick={() => {
            const fieldErrors = getFieldErrors(state, ['articleNumber', 'articleDescription']);
            if (fieldErrors.hasErrors) {
              dispatch(setErrors(fieldErrors));
            } else {
              dispatch(confirmInputvalue(['articleNumber', 'articleDescription']));
            }
            setOpenModalId(undefined);
          }}
        >
          <FormattedMessage id="ReclamationForm.Article.Submit" defaultMessage="Übernehmen" />
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  const ShippingDetailsModal: React.ReactNode = (
    <Modal
      noContentPadding
      testIdClose="shipping-modal-close"
      open={openModalId === 'shipping'}
      onRequestClose={() => setOpenModalId(undefined)}
    >
      <div>
        <FormWrapper>
          <div>
            <Input
              id="shippingFirstName"
              name="shippingFirstName"
              label={intl.formatMessage(reclamationMessages.labelFirstName)}
              value={state.shippingFirstName.inputValue}
              error={
                state.shippingFirstName.errorMessage &&
                intl.formatMessage(state.shippingFirstName.errorMessage)
              }
              onChange={(e) => dispatch(setValue('shippingFirstName', e.target.value))}
            />
          </div>
          <div>
            <Input
              id="shippingLastName"
              name="shippingLastName"
              label={intl.formatMessage(reclamationMessages.labelLastName)}
              value={state.shippingLastName.inputValue}
              error={
                state.shippingLastName.errorMessage &&
                intl.formatMessage(state.shippingLastName.errorMessage)
              }
              onChange={(e) => dispatch(setValue('shippingLastName', e.target.value))}
            />
          </div>
          <InputColumnWrapper extendSide="left">
            <div>
              <Input
                id="shippingStreet"
                name="shippingStreet"
                label={intl.formatMessage(reclamationMessages.labelStreet)}
                value={state.shippingStreet.inputValue}
                error={
                  state.shippingStreet.errorMessage &&
                  intl.formatMessage(state.shippingStreet.errorMessage)
                }
                onChange={(e) => dispatch(setValue('shippingStreet', e.target.value))}
              />
            </div>
            <div>
              <Input
                id="shippingHouseNumber"
                name="shippingHouseNumber"
                label={intl.formatMessage(reclamationMessages.labelHouseNumber)}
                value={state.shippingHouseNumber.inputValue}
                error={
                  state.shippingHouseNumber.errorMessage &&
                  intl.formatMessage(state.shippingHouseNumber.errorMessage)
                }
                onChange={(e) => dispatch(setValue('shippingHouseNumber', e.target.value))}
              />
            </div>
          </InputColumnWrapper>
          <InputColumnWrapper extendSide="right">
            <div>
              <Input
                id="shippingZipCode"
                name="shippingZipCode"
                label={intl.formatMessage(reclamationMessages.labelZipCode)}
                value={state.shippingZipCode.inputValue}
                error={
                  state.shippingZipCode.errorMessage &&
                  intl.formatMessage(state.shippingZipCode.errorMessage)
                }
                onChange={(e) => dispatch(setValue('shippingZipCode', e.target.value))}
              />
            </div>
            <div>
              <Input
                id="shippingCity"
                name="shippingCity"
                label={intl.formatMessage(reclamationMessages.labelCity)}
                value={state.shippingCity.inputValue}
                error={
                  state.shippingCity.errorMessage &&
                  intl.formatMessage(state.shippingCity.errorMessage)
                }
                onChange={(e) => dispatch(setValue('shippingCity', e.target.value))}
              />
            </div>
          </InputColumnWrapper>
        </FormWrapper>
      </div>
      <ButtonWrapper>
        <Button
          layout="primary"
          onClick={() => {
            const fieldErrors = getFieldErrors(state, [
              'shippingFirstName',
              'shippingLastName',
              'shippingStreet',
              'shippingHouseNumber',
              'shippingZipCode',
              'shippingCity',
            ]);
            if (fieldErrors.hasErrors) {
              dispatch(setErrors(fieldErrors));
            } else {
              dispatch(
                confirmInputvalue([
                  'shippingFirstName',
                  'shippingLastName',
                  'shippingStreet',
                  'shippingHouseNumber',
                  'shippingZipCode',
                  'shippingCity',
                ]),
              );
            }
            setOpenModalId(undefined);
          }}
        >
          <FormattedMessage id="ReclamationForm.Shipping.Submit" defaultMessage="Übernehmen" />
        </Button>
      </ButtonWrapper>
    </Modal>
  );

  return (
    <div>
      <ContentWrapper>
        <Headline element="div" level={3}>
          <div data-mf-replace-inner="**REMOVED**">
            <FormattedMessage
              id="ReclamationForm.InvoiceInfo"
              defaultMessage="Ihre Reklamation zur Rechnung {invoiceNumber} vom {invoiceDate}"
              values={{ invoiceNumber, invoiceDate }}
            />
          </div>
        </Headline>
        <div>
          <FormattedMessage
            id="ReclamationForm.Information"
            defaultMessage="Bitte nutze dieses Formular nur für die Meldung von defekten oder beschädigten Artikeln, die repariert oder begutachtet werden sollen. Für alles andere nutze bitte unser Kontaktformular unter "
          />
          <FormattedMessage
            id="ContactFormLink"
            defaultMessage="<link>https://www.baur.de/service-hilfe/kontakt</link>"
            values={{
              link: (...chunks: string[]) => <a href={`${chunks.join('')}`}>{chunks}</a>,
            }}
          />
        </div>
      </ContentWrapper>
      {showSubmitError && (
        <Notification
          level="error"
          content={
            <FormattedMessage
              id="ReclamationForm.Submit.Error"
              defaultMessage="Ihre Reklamation konnte leider gerade nicht durchgeführt werden. Versuchen Sie es doch später erneut!"
            />
          }
        />
      )}
      <FormWrapper>
        <ResponsiveColumnLayoutWrapper>
          <div>
            <SpacedCard
              headline={intl.formatMessage(reclamationMessages.headlineArticle)}
              action={{ type: 'EDIT', onClick: () => handleOnClick('article') }}
            >
              {ArticleOrderDetailsModal}
              <ContentWrapper>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelArticleNumber)}</div>
                  <div>{state.articleNumber.value}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelArticleDescription)}</div>
                  <div>{state.articleDescription.value}</div>
                </InformationBlock>
              </ContentWrapper>
            </SpacedCard>
            <SpacedCard headline={intl.formatMessage(reclamationMessages.headlineCustomer)}>
              <ContentWrapper>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelCustomerNo)}</div>
                  <div data-mf-replace-inner="**REMOVED**">{customerNo}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelFirstName)}</div>
                  <div data-mf-replace-inner="**REMOVED**">{firstName}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelLastName)}</div>
                  <div data-mf-replace-inner="**REMOVED**">{lastName}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>
                    {`${intl.formatMessage(reclamationMessages.labelStreet)}/${intl.formatMessage(
                      reclamationMessages.labelHouseNumber,
                    )}`}
                  </div>
                  <div data-mf-replace-inner="**REMOVED**">{`${address?.street} ${address?.houseNumber}`}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>
                    {`${intl.formatMessage(reclamationMessages.labelZipCode)}/${intl.formatMessage(
                      reclamationMessages.labelCity,
                    )}`}
                  </div>
                  <div data-mf-replace-inner="**REMOVED**">{`${address?.zipCode} ${address?.city}`}</div>
                </InformationBlock>
                <InformationBlock>
                  <div>{intl.formatMessage(reclamationMessages.labelEmailAddress)}</div>
                  <div data-mf-replace-inner="**REMOVED**">{emailAddress}</div>
                </InformationBlock>
              </ContentWrapper>
            </SpacedCard>
          </div>
          <div>
            <Select
              label={intl.formatMessage(reclamationMessages.labelReclamationReason)}
              placeholder={intl.formatMessage(reclamationMessages.reclamationReasonPlaceholder)}
              value={state.reclamationReason.inputValue}
              error={
                state.reclamationReason.errorMessage &&
                intl.formatMessage(state.reclamationReason.errorMessage)
              }
              options={[
                {
                  value: 'incomplete',
                  displayName: intl.formatMessage(reclamationMessages.reclamationReasonIncomplete),
                },
                {
                  value: 'damaged',
                  displayName: intl.formatMessage(reclamationMessages.reclamationReasonDamaged),
                },
                {
                  value: 'wrongProduct',
                  displayName: intl.formatMessage(
                    reclamationMessages.reclamationReasonWrongProduct,
                  ),
                },
                {
                  value: 'wrongPrice',
                  displayName: intl.formatMessage(reclamationMessages.reclamationReasonWrongPrice),
                },
                {
                  value: 'other',
                  displayName: intl.formatMessage(reclamationMessages.reclamationReasonOther),
                },
              ]}
              onChange={(value) => {
                dispatch(setValue('reclamationReason', value, true));
              }}
            />
            <div>
              <Textarea
                label={intl.formatMessage(reclamationMessages.labelReclamationDescription)}
                value={state.reclamationDescription.inputValue}
                error={
                  state.reclamationDescription.errorMessage &&
                  intl.formatMessage(state.reclamationDescription.errorMessage)
                }
                onChange={(e) => dispatch(setValue('reclamationDescription', e.target.value, true))}
              />
            </div>
          </div>
        </ResponsiveColumnLayoutWrapper>
      </FormWrapper>
      <ContentWrapper>
        <FormattedMessage
          id="ReclamationForm.Replacement.Headline"
          defaultMessage="Benötigen Sie ein Ersatzteil? Ergänzen Sie bitte folgende Angaben"
        />
      </ContentWrapper>
      <FormWrapper>
        <ResponsiveColumnLayoutWrapper>
          <div>
            <Input
              type="text"
              label={intl.formatMessage(reclamationMessages.labelReplacementNumber)}
              value={state.replacementNumber.inputValue}
              error={
                state.replacementNumber.errorMessage &&
                intl.formatMessage(state.replacementNumber.errorMessage)
              }
              onChange={(e) => dispatch(setValue('replacementNumber', e.target.value, true))}
            />
            <div>
              <Input
                type="text"
                label={intl.formatMessage(reclamationMessages.labelBestReachedPhoneNumber)}
                value={state.contactBy.inputValue}
                error={
                  state.contactBy.errorMessage && intl.formatMessage(state.contactBy.errorMessage)
                }
                onChange={(e) => dispatch(setValue('contactBy', e.target.value, true))}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              label={intl.formatMessage(reclamationMessages.labelDataTypeShield)}
              value={state.dataTypeShield.inputValue}
              error={
                state.dataTypeShield.errorMessage &&
                intl.formatMessage(state.dataTypeShield.errorMessage)
              }
              onChange={(e) => dispatch(setValue('dataTypeShield', e.target.value, true))}
            />
          </div>
        </ResponsiveColumnLayoutWrapper>
      </FormWrapper>
      <ContentWrapper>
        <FormattedMessage
          id="ReclamationForm.Shipping.Headline"
          defaultMessage="Ist deine Lieferadresse abweichend von deiner Kontoanschrift?"
        />
      </ContentWrapper>
      <FormWrapper>
        <SpacedCard
          headline={intl.formatMessage(reclamationMessages.headlineShipping)}
          action={{ type: 'EDIT', onClick: () => handleOnClick('shipping') }}
        >
          {ShippingDetailsModal}
          <ContentWrapper>
            <InformationBlock>
              <div>{intl.formatMessage(reclamationMessages.labelFirstName)}</div>
              <div data-mf-replace-inner="**REMOVED**">{state.shippingFirstName.value}</div>
            </InformationBlock>
            <InformationBlock>
              <div>{intl.formatMessage(reclamationMessages.labelLastName)}</div>
              <div data-mf-replace-inner="**REMOVED**">{state.shippingLastName.value}</div>
            </InformationBlock>
            <InformationBlock>
              <div>
                {`${intl.formatMessage(reclamationMessages.labelStreet)}/${intl.formatMessage(
                  reclamationMessages.labelHouseNumber,
                )}`}
              </div>
              <div data-mf-replace-inner="**REMOVED**">{`${state.shippingStreet.value} ${state.shippingHouseNumber.value}`}</div>
            </InformationBlock>
            <InformationBlock>
              <div>
                {`${intl.formatMessage(reclamationMessages.labelZipCode)}/${intl.formatMessage(
                  reclamationMessages.labelCity,
                )}`}
              </div>
              <div data-mf-replace-inner="**REMOVED**">{`${state.shippingZipCode.value} ${state.shippingCity.value}`}</div>
            </InformationBlock>
          </ContentWrapper>
        </SpacedCard>
      </FormWrapper>
      <ButtonWrapper>
        {onCancel && (
          <Button layout="default" onClick={onCancel}>
            <FormattedMessage id="ReclamationForm.FormCancel" defaultMessage="Abbrechen" />
          </Button>
        )}
        <Button
          layout="primary"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            submitReclamation();
          }}
        >
          <FormattedMessage id="ReclamationForm.FormSubmit" defaultMessage="Abschicken" />
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default ReclamationForm;
export * from './types';
