import React, { FC } from 'react';
import { SkeletonProvider, withSkeleton } from '@empiriecom/module-components/Skeleton';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import Maturities from '@/src/components/Maturities';
import OverviewContainerSkeleton from '@/src/components/OverviewContainer/skeleton';
import { useMedia } from 'react-media-match';
import styled from 'styled-components';
import { Cell, Grid } from '@empiriecom/module-components/Grid';
import CustomerInformation from '@/src/components/OverviewContainer/CustomerInformation';
import { Kim } from '@empiriecom/mybuy-components/components/Kim';
import { defineMessages, useIntl } from 'react-intl';
import ShipmentInformation from './ShipmentInformation';
import ReturnsInformation from './ReturnsInformation';
import EventTracking from '../EventTracking';
import JoeConnectAccountCard from './Joe/JoeConnectAccountCard';
import useJoe from './Joe/useJoe';
import { JoeProvider } from './Joe/JoeProvider';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import Notification from '@empiriecom/module-components/Notification';
import PersonalCoupon from '../PersonalCoupon';
import VerificationNotification from '../VerificationNotification';
import DelayedDeliveryWarning from '@/src/components/DelayedDeliveryNotification';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';

const GreyBox = styled.div`
  padding: 1rem 0.5rem;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  font-size: 0.75rem;
`;

// solve issue of text (like the users email address) exploding cells
// see https://css-tricks.com/preventing-a-grid-blowout/
const StyledGrid = styled(Grid)`
  width: 100%;
  > * {
    min-width: 0;
  }
`;

const MarginTop = styled.div`
  margin-top: 1rem;
`;

const KimWrapper = styled.div`
  padding-top: 2rem;
`;

const messages = defineMessages({
  kimTitle: {
    id: 'OverviewContainer.Kim.title',
    defaultMessage: 'Wie gefällt Ihnen die Übersichtsseite?',
  },
});

type OverviewContainerProps = {
  showKim?: boolean;
  isLblEsi?: boolean;
};

export const OverviewContainerContent: FC<OverviewContainerProps> = ({
  isLblEsi = false,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const customerContext = useCustomerContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const { isConnected, pending, enabled } = useJoe();
  const { kim }: FragmentConfig = useConfig();

  let isEmployee: boolean = false;
  if (customerContext.token) {
    const contents = apiKeyManager.getTokenContents(customerContext.token);
    isEmployee = contents?.ct === 93 || contents?.ct === 94;
  }

  const showJoeConnect = enabled && !isConnected && !pending && !isEmployee;
  const showJoeDisconnect = !pending && isConnected && !isEmployee;

  const kimId = customerContext.customer?.extra?.kid
    ? parseInt(customerContext.customer?.extra?.kid, 10)
    : 0;

  const shouldDisplayVerificationNotification = !customerContext.customer?.personalCoupon;

  if (isMobile) {
    // Mobile we need a different component order than desktop
    return (
      <>
        {customerContext.customer?.personalCoupon && (
          <PersonalCoupon
            code={customerContext.customer.personalCoupon.code}
            text={customerContext.customer.personalCoupon.text}
            conditions={customerContext.customer.personalCoupon.conditionsText}
          />
        )}
        {customerContext.customer?.customerSpecificHint && (
          <Notification level="warning" isInline>
            {customerContext.customer?.customerSpecificHint}
          </Notification>
        )}
        <GreyBox>
          <DelayedDeliveryWarning />
          {shouldDisplayVerificationNotification && <VerificationNotification />}
          <StyledGrid cellsPerRow={[1, 2, 2]} rowGap="1rem">
            <Cell data-testid="customer-card-wrapper">
              <CustomerInformation isLblEsi={isLblEsi} enableJoeDisconnect={showJoeDisconnect} />
            </Cell>
            <Cell data-testid="orders-shipments-card-wrapper">
              <MarginTop>
                <ShipmentInformation />
              </MarginTop>
            </Cell>
            <Cell data-testid="maturities-card-wrapper">
              <MarginTop>
                <Maturities scope="overview" />
              </MarginTop>
            </Cell>
            <Cell data-testid="returns-card-wrapper">
              <MarginTop>
                <ReturnsInformation />
              </MarginTop>
            </Cell>

            {showJoeConnect && (
              <Cell data-testid="connect-joe-card-wrapper">
                <MarginTop>
                  <JoeConnectAccountCard />
                </MarginTop>
              </Cell>
            )}
          </StyledGrid>
        </GreyBox>
        {!isLblEsi && kim?.enabled && (
          <KimWrapper>
            <Kim
              i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
              headline={formatMessage(messages.kimTitle)}
              x03={20}
              x05="UEBER"
              x06={kimId}
            />
          </KimWrapper>
        )}
      </>
    );
  }
  return (
    <>
      {customerContext.customer?.personalCoupon && (
        <PersonalCoupon
          code={customerContext.customer.personalCoupon.code}
          text={customerContext.customer.personalCoupon.text}
          conditions={customerContext.customer.personalCoupon.conditionsText}
        />
      )}
      {customerContext.customer?.customerSpecificHint && (
        <Notification level="warning" isInline>
          {customerContext.customer?.customerSpecificHint}
        </Notification>
      )}
      <GreyBox>
        <DelayedDeliveryWarning />
        {shouldDisplayVerificationNotification && <VerificationNotification />}
        <StyledGrid cellsPerRow={[1, 2, 2]} colGap="1rem">
          <Cell>
            <div data-testid="customer-card-wrapper">
              <CustomerInformation
                isLblEsi={isLblEsi}
                enableJoeDisconnect={!pending && isConnected}
              />
            </div>
            {!showJoeConnect && (
              <MarginTop>
                <div data-testid="maturities-card-wrapper">
                  <Maturities scope="overview" />
                </div>
              </MarginTop>
            )}
            {showJoeConnect && (
              <MarginTop>
                <div data-testid="connect-joe-card-wrapper">
                  <JoeConnectAccountCard />
                </div>
              </MarginTop>
            )}
          </Cell>

          <Cell>
            <div data-testid="orders-shipments-card-wrapper">
              <ShipmentInformation />
            </div>
            <MarginTop>
              <div data-testid="returns-card-wrapper">
                <ReturnsInformation />
              </div>
            </MarginTop>
            {showJoeConnect && (
              <MarginTop>
                <div data-testid="maturities-card-wrapper">
                  <Maturities scope="overview" />
                </div>
              </MarginTop>
            )}
          </Cell>
        </StyledGrid>
      </GreyBox>
      {!isLblEsi && kim?.enabled && (
        <KimWrapper>
          <Kim
            i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
            headline={formatMessage(messages.kimTitle)}
            x03={20}
            x05="UEBER"
            x06={kimId}
          />
        </KimWrapper>
      )}
    </>
  );
};

const OverviewContainerWithSkeleton = withSkeleton(
  OverviewContainerContent,
  OverviewContainerSkeleton,
);

const OverviewContainer: FC<OverviewContainerProps> = ({
  showKim = true,
  isLblEsi = false,
}): JSX.Element => {
  const { isLoading } = useCustomerContext();
  return (
    <JoeProvider>
      <SkeletonProvider isLoading={isLoading}>
        <EventTracking eventCategory="overview" />
        <OverviewContainerWithSkeleton showKim={showKim} isLblEsi={isLblEsi} />
      </SkeletonProvider>
    </JoeProvider>
  );
};

export default OverviewContainer;
