import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Paylink from '@empiriecom/mybuy-components/components/Paylink';
import trackingAdapter from '@/utils/trackingAdapter';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import Card from '@empiriecom/module-components/Card';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import { useMedia } from 'react-media-match';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import {
  BankstatementsCircleData,
  BankstatementsTable,
  BankstatementsTimeslots,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models';
import { Select } from '@empiriecom/module-components/FormControls';
import { Button } from '@empiriecom/module-components/Button';
import { Modal } from '@empiriecom/module-components/Modal';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import CircleStats from '@/src/components/CircleStats';
import Notification from '@empiriecom/module-components/Notification';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { Permissions } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/Permissions';
import { Checkbox } from '@empiriecom/module-components';
import internalPages, { Language } from '@/config/pages';

const Description = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const GreyBox = styled.div`
  padding: 1rem 0.5rem;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  font-size: 0.75rem;
`;

const OverviewContainer = styled(GreyBox)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const OverviewPeriodInputContainer = styled.div``;

const Spacer = styled.div`
  margin-bottom: 1rem;
`;

const OverviewCard = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: 0.75rem;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 1.25rem;
  align-items: flex-start;
`;

const OverviewCardList = styled(BorderedPanel)`
  flex: 1;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const StatementListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StatementListElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatementListElementLabel = styled.div<{ isLabelBold?: boolean }>`
  font-size: 0.875rem;
  font-weight: ${({ isLabelBold }) => (isLabelBold ? 'bold' : 'normal')};
`;

const StatementListElementValue = styled.div<{ isValueBold?: boolean }>`
  font-size: 0.875rem;
  font-weight: ${({ isValueBold }) => (isValueBold ? 'bold' : 'normal')};
`;

const Separator = styled.div`
  width: 100%;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.greyNormal.color.normal};
`;

const DownloadContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 1rem;
`;

const DownloadCard = styled(Card)`
  padding: 1rem;
  flex: 1;
`;

const DownloadCardContainer = styled.div`
  display: flex;
`;

const DownloadCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
`;

const DownloadCardToggle = styled.div``;

const DownloadCardTitle = styled.h1`
  font-size: 1rem;
  color: ${({ theme }) => theme.greyNormal.color.text};
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const DownloadCardDescription = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.greyNormal.color.text};
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

const DownloadCardButton = styled(Button)`
  border-radius: 50px;
  display: inline;
`;

const DownloadCardLearnMore = styled.div`
  font-size: 0.688rem;
  text-decoration: underline;
  cursor: pointer;
`;

const ModalContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  height: 100%;
  padding: 15px;
`;

const messages = defineMessages({
  overviewPeriodLabel: {
    id: 'BankstatementsContainer.Overview.overviewPeriodLabel',
    defaultMessage: 'Zeitraum auswählen',
  },
  overviewPeriodBallanceStart: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallanceStart',
    defaultMessage: 'Beginn Saldo',
  },
  overviewPeriodBallanceEnd: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallanceEnd',
    defaultMessage: 'Ende Saldo',
  },
  overviewPeriodBallancePreviousPeriod: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallancePreviousPeriod',
    defaultMessage: 'Saldo der vorherigen Abrechnung',
  },
  overviewPeriodBallanceEndOfPeriod: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallanceEndOfPeriod',
    defaultMessage: 'Saldo (Ende des Monats)',
  },
  overviewPeriodBallanceStartOfPeriod: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallanceStartOfPeriod',
    defaultMessage: 'Saldo (Beginn des Monats)',
  },
  overviewPeriodBallanceNew: {
    id: 'BankstatementsContainer.Overview.overviewPeriodBallanceNew',
    defaultMessage: 'Neuer Saldo',
  },
  overviewPeriodEmpty: {
    id: 'BankstatementsContainer.Overview.overviewPeriodEmpty',
    defaultMessage:
      'Sie haben keinen aktuellen Kontoauszug. Bei älteren Kontoauszügen wählen Sie im Dropdown den gewünschten Zeitraum aus.',
  },
  downloadCardTitle: {
    id: 'BankstatementsContainer.Overview.downloadCardTitle',
    defaultMessage: 'Diesen Kontoauszug herunterladen',
  },
  downloadCardDescription: {
    id: 'BankstatementsContainer.Overview.downloadCardDescription',
    defaultMessage:
      'Um die vollständige Aufschlüsselung zu sehen, laden Sie diesen Kontoauszug als PDF-Datei herunter (kostenlos)',
  },
  downloadCardButton: {
    id: 'BankstatementsContainer.Overview.downloadCardButton',
    defaultMessage: 'Herunterladen',
  },
  downloadCardWarning: {
    id: 'BankstatementsContainer.Overview.downloadCardWarning',
    defaultMessage:
      'Leider ist beim Herunterladen ein Fehler aufgetreten. Versuche es zu einem späteren Zeitpunkt erneut.',
  },
  perEmailReceiveCardTitle: {
    id: 'BankstatementsContainer.Overview.perEmailReceiveCardTitle',
    defaultMessage: 'Kontoauszug per Post auf Papier erhalten',
  },
  perEmailReceiveCardDescriptionSecondary: {
    id: 'BankstatementsContainer.Overview.perEmailReceiveCardDescriptionSecondary',
    defaultMessage: 'Lasse dir Kontoauszüge per Post zuschicken (zzg. 1,50 €)',
  },
  perEmailReceiveCardLearnMore: {
    id: 'BankstatementsContainer.Overview.perEmailReceiveCardLearnMore',
    defaultMessage: 'Weitere Informationen',
  },
  perEmailReceiveCardLearnMoreHeadline: {
    id: 'BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreHeadline',
    defaultMessage: 'Postalische Kontoauszüge:',
  },
  perEmailReceiveCardLearnMoreContent: {
    id: 'BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreContent',
    defaultMessage: 'TODO',
  },
});

interface StatementListProps {
  elements: {
    label: string;
    value?: number;
    currencyCode?: string;
    isLabelBold?: boolean;
    isValueBold?: boolean;
  }[];
  hasSeparator: boolean;
}

const StatementList = (props: StatementListProps) => {
  return (
    <StatementListContainer>
      {props.elements.map((element, index) => (
        <>
          <StatementListElement key={index}>
            <StatementListElementLabel isLabelBold={element.isLabelBold}>
              {element.label}
            </StatementListElementLabel>
            <StatementListElementValue isValueBold={element.isValueBold}>
              {element.value && element.currencyCode && (
                <FormattedCurrency value={element.value} currency={element.currencyCode} />
              )}
            </StatementListElementValue>
          </StatementListElement>
          {props.hasSeparator && index < props.elements.length - 1 && <Separator />}
        </>
      ))}
    </StatementListContainer>
  );
};

const BankstatementsContainer = () => {
  const { locale } = useIntl();
  const customerApi = useCustomerApi();
  const [selectedTimeslotId, setSelectedTimeslotId] = React.useState<string | undefined>(undefined);
  const [selectedTimeslot, setSelectedTimeslot] = React.useState<
    BankstatementsTimeslots | undefined
  >(undefined);
  const [timeslots, setTimeslots] = React.useState<BankstatementsTimeslots[]>([]);
  const [circle, setCircle] = React.useState<BankstatementsCircleData | undefined>(undefined);
  const [table, setTable] = React.useState<BankstatementsTable | undefined>(undefined);
  const [allowPaylink, setAllowPaylink] = React.useState<boolean | undefined>(undefined);
  const [isLearnMoreModalOpened, setIsLearnMoreModalOpened] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const intl = useIntl();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const accountApi = useAccountApi();
  const customerContext = useCustomerContext();
  const { customer } = customerContext || {};
  const { permissions } = customer || {};
  const { postalBankStatement } = permissions || {};
  const { pushNotification } = useNotificationContext();
  const bookingsLink = internalPages.bookings.paths[intl.locale.split('-')[0] as Language];

  React.useEffect(() => {
    if (accountApi) {
      accountApi
        .getBankStatements({
          ecLocale: intl.locale,
        })
        .then((fetchedBankStatements) => {
          setTimeslots(fetchedBankStatements.timeslots ? fetchedBankStatements.timeslots : []);
          setCircle(fetchedBankStatements.circle);
          setTable(fetchedBankStatements.table);
          setAllowPaylink(fetchedBankStatements.allowPaylink);
        });
    }
  }, [accountApi, intl]);

  React.useEffect(() => {
    if (timeslots.length > 0) {
      const firstTimeslotId = timeslots[0].id;
      if (firstTimeslotId) {
        setSelectedTimeslotId(firstTimeslotId);
      } else {
        setSelectedTimeslotId(undefined);
      }
    } else {
      setSelectedTimeslotId(undefined);
    }
  }, [timeslots]);

  React.useEffect(() => {
    if (selectedTimeslotId) {
      const newSelectedTimeslot = timeslots.find((timeslot) => timeslot.id === selectedTimeslotId);
      /* istanbul ignore else  */
      if (newSelectedTimeslot) {
        setSelectedTimeslot(timeslots.find((timeslot) => timeslot.id === selectedTimeslotId));
      } else {
        setSelectedTimeslot(undefined);
      }
    } else {
      setSelectedTimeslot(undefined);
    }
  }, [selectedTimeslotId, timeslots]);

  const handlePayClick = async () => {
    trackingAdapter.sendLayerLandmark('PayBoxLayer');
    return customerApi!.getCustomerPaylink({ ecLocale: locale });
  };

  const downloadData = async () => {
    if (accountApi && selectedTimeslotId) {
      try {
        setIsDownloading(true);
        const fileName = `financial-overview-${selectedTimeslotId}.pdf`;

        /* istanbul ignore next -> test all that cases if you have a lot of time and are bored */
        const responseBlob = await accountApi.getBankStatementDocument({
          id: selectedTimeslotId,
          ecLocale: intl.locale,
        });

        /* istanbul ignore next -> test all that cases if you have a lot of time and are bored */
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(responseBlob, fileName);
        } else {
          const objectUrl = window.URL.createObjectURL(responseBlob);
          const link = document.createElement('a');
          link.href = objectUrl;
          // safari doesn't support this yet
          if (typeof link.download === 'undefined') {
            window.location.href = objectUrl;
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        }
      } catch {
        pushNotification({
          autoClose: 5000,
          hasClose: true,
          level: 'error',
          children: <span>{intl.formatMessage(messages.downloadCardWarning)}</span>,
        });
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const saveSetting = async ({
    which,
    selected,
  }: {
    which: keyof Omit<Permissions, 'parcelShopSMS' | 'parcelShopSMSIndex'>;
    selected: boolean;
  }) => {
    const newPermissions: Permissions = {};
    newPermissions[which] = selected;
    const result = await customerApi!.updatePermissions({
      permissions: newPermissions,
      ecLocale: customerContext.ecLocale,
    });
    if (result.customer) {
      await customerContext.actions.update(result.customer);
    }
  };

  return (
    <>
      <h1>
        <FormattedMessage id="BankstatementsContainer.title" defaultMessage="Meine Kontoauszüge" />
      </h1>
      <Description>
        <FormattedMessage
          id="BankstatementsContainer.introtext"
          defaultMessage="Hier finden Sie Ihre Kontoauszüge.{break}Alle Buchungen und Ihren aktuellen Kontostand finden Sie in Ihrem <link>Kundenkonto</link>."
          values={{
            break: <br />,
            link: (chunks) => <a href={bookingsLink}>{chunks}</a>,
          }}
        />
      </Description>
      <GreyBox>
        {circle && table && (
          <>
            <CircleStats circle={circle} table={table} />
            <Spacer />
            {allowPaylink && <Paylink price={circle.value} onPayClick={handlePayClick} />}
          </>
        )}
      </GreyBox>
      <OverviewContainer>
        <OverviewPeriodInputContainer>
          <Select
            label={intl.formatMessage(messages.overviewPeriodLabel)}
            value={selectedTimeslotId ? selectedTimeslotId : ''}
            options={
              timeslots.length > 0
                ? timeslots.map((timeslot) => ({
                    value: timeslot.id ? timeslot.id : '',
                    displayName: timeslot.value ? timeslot.value : '',
                  }))
                : [
                    {
                      value: '',
                      displayName: '---',
                    },
                  ]
            }
            onChange={(value) => {
              setSelectedTimeslotId(value);
            }}
            disabled={timeslots.length === 0}
          />
        </OverviewPeriodInputContainer>
        {timeslots.length > 0 ? (
          <Card>
            <OverviewCard isMobile={isMobile}>
              <OverviewCardList>
                <StatementList
                  elements={[
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallanceStart),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.startBalance?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.startBalance?.currencyCode
                        : undefined,
                      isValueBold: true,
                    },
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallanceEnd),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.endBalance?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.endBalance?.currencyCode
                        : undefined,
                      isValueBold: true,
                    },
                  ]}
                  hasSeparator={false}
                />
              </OverviewCardList>
              <OverviewCardList>
                <StatementList
                  elements={[
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallancePreviousPeriod),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.startBalance?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.startBalance?.currencyCode
                        : undefined,
                      isLabelBold: true,
                      isValueBold: true,
                    },
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallanceEndOfPeriod),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.reductionAmount?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.reductionAmount?.currencyCode
                        : undefined,
                    },
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallanceStartOfPeriod),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.increasingAmount?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.increasingAmount?.currencyCode
                        : undefined,
                    },
                    {
                      label: intl.formatMessage(messages.overviewPeriodBallanceNew),
                      value: selectedTimeslot
                        ? selectedTimeslot.balances?.endBalance?.value
                        : undefined,
                      currencyCode: selectedTimeslot
                        ? selectedTimeslot.balances?.endBalance?.currencyCode
                        : undefined,
                    },
                  ]}
                  hasSeparator={true}
                />
              </OverviewCardList>
            </OverviewCard>
          </Card>
        ) : (
          <Notification level="info" isInline>
            <FormattedMessage {...messages.overviewPeriodEmpty} />
          </Notification>
        )}
      </OverviewContainer>
      <DownloadContainer>
        <DownloadCard>
          <DownloadCardContainer>
            <DownloadCardContent>
              <DownloadCardTitle>
                {intl.formatMessage(messages.downloadCardTitle)}
              </DownloadCardTitle>
              <DownloadCardDescription>
                {intl.formatMessage(messages.downloadCardDescription)}
              </DownloadCardDescription>
              <DownloadCardButton
                layout="primary"
                size="large"
                onClick={downloadData}
                disabled={isDownloading || !selectedTimeslotId}
              >
                {intl.formatMessage(messages.downloadCardButton)}
              </DownloadCardButton>
            </DownloadCardContent>
          </DownloadCardContainer>
        </DownloadCard>
        <DownloadCard>
          <DownloadCardContainer>
            <DownloadCardContent>
              <DownloadCardTitle>
                {intl.formatMessage(messages.perEmailReceiveCardTitle)}
              </DownloadCardTitle>
              <DownloadCardDescription>
                {intl.formatMessage(messages.perEmailReceiveCardDescriptionSecondary)}
              </DownloadCardDescription>
              <DownloadCardLearnMore onClick={() => setIsLearnMoreModalOpened(true)}>
                {intl.formatMessage(messages.perEmailReceiveCardLearnMore)}
              </DownloadCardLearnMore>
            </DownloadCardContent>
            <DownloadCardToggle>
              <Checkbox
                label=""
                checked={postalBankStatement}
                onChange={async () => {
                  await saveSetting({
                    which: 'postalBankStatement',
                    selected: !postalBankStatement,
                  });
                }}
              />
            </DownloadCardToggle>
          </DownloadCardContainer>
        </DownloadCard>
      </DownloadContainer>
      <Modal
        open={isLearnMoreModalOpened}
        noContentPadding
        headline={intl.formatMessage(messages.perEmailReceiveCardLearnMoreHeadline)}
        onRequestClose={() => setIsLearnMoreModalOpened(false)}
        testId="service-modal"
        testIdClose="service-modal-close"
      >
        <ModalContentWrapper>
          <strong>{intl.formatMessage(messages.perEmailReceiveCardLearnMoreContent)}</strong>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default BankstatementsContainer;
