import React, {
  FunctionComponent,
  useReducer,
  Dispatch,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import Input from '@empiriecom/module-components/FormControls/Input/PasswordInput';
import { FormattedMessage, defineMessages, useIntl, IntlShape } from 'react-intl';
import { ComponentThemeProvider } from '@empiriecom/module-components/ThemeProvider';
import {
  getEvaluationLevelFromPassword,
  EvaluationLevel,
} from '@empiriecom/module-components/FormControls/Input/PasswordInput/validatePassword';
import { Button } from '@empiriecom/module-components/Button';
import Headline from '@empiriecom/module-components/Headline';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import componentTheme from '@/src/components/ChangePassword/theme';
import { FragmentConfig } from '@/config/types';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import {
  ChangeCustomerResult,
  ChangePasswordRequest,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { useProfiling } from '@empiriecom/mybuy-components/modules/tmx';

const PasswordFormWrapper = styled.form`
  background-color: ${({ theme }) => theme.component.changePassword.formBackground};
  padding: 1rem;
`;

const Block = styled.div`
  margin-bottom: 1rem;
`;

const SecurityInfo = styled.div`
  border: ${({ theme }) => theme.component.changePassword.securityHintBorder};
  margin: 1rem;
  padding: 1rem;
  text-align: center;
`;

const SecurityHeadline = styled(Headline)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const HintHeadline = styled(Headline)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const HintList = styled.ul`
  padding-left: 1.5rem;
`;

export const messages = defineMessages({
  newLabel: {
    id: 'ChangePassword.new.label',
    defaultMessage: 'Neues Passwort *',
  },
  repeatLabel: {
    id: 'ChangePassword.repeat.label',
    defaultMessage: 'Neues Passwort wiederholen *',
  },
  placeholder: {
    id: 'ChangePassword.placeholder',
    defaultMessage: '{minLength}-{maxLength} Zeichen',
  },
  errorDontMatch: {
    id: 'ChangePassword.passwordsDontMatch',
    defaultMessage: 'Die beiden Passwörter stimmen nicht überein.',
  },
  errorTooLong: {
    id: 'ChangePassword.passwordstooLong',
    defaultMessage: 'Das angegebene Kennwort ist zu lang (max. {maxLength} Zeichen)',
  },
  errorTooShort: {
    id: 'ChangePassword.passwordstooShort',
    defaultMessage: 'Das Passwort muss mindestens {minLength} Zeichen beinhalten.',
  },
  errorFromBackend: {
    id: 'ChangePassword.errorFromBackend',
    defaultMessage:
      'Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.',
  },
});

export const enum Errors {
  DONT_MATCH,
  TOO_SHORT,
  TOO_LONG,
}

export type ChangePasswordState = {
  newPassword: string;
  repeatPassword: string;
  error?: Errors;
  minLength?: number;
  maxLength?: number;
};

export const defaultInitialState: ChangePasswordState = {
  newPassword: '',
  repeatPassword: '',
};

export const enum ActionTypes {
  UPDATE_NEW,
  UPDATE_REPEAT,
  SUBMIT,
  DISPATCH_ERROR,
}

export type SetNewPasswordAction = {
  type: ActionTypes.UPDATE_NEW;
  payload: string;
};

export type SetRepeatPasswordAction = {
  type: ActionTypes.UPDATE_REPEAT;
  payload: string;
};

export type SubmitAction = {
  type: ActionTypes.SUBMIT;
};

export type DispatchErrorAction = {
  type: ActionTypes.DISPATCH_ERROR;
  payload: Errors;
};

export type ValidActions =
  | SetNewPasswordAction
  | SetRepeatPasswordAction
  | SubmitAction
  | DispatchErrorAction;

const validateEvaluationLevel = (state: ChangePasswordState): ChangePasswordState => {
  const level = getEvaluationLevelFromPassword(state.newPassword, state.minLength, state.maxLength);

  if (level === EvaluationLevel.TOO_SHORT) {
    return {
      ...state,
      error: Errors.TOO_SHORT,
    };
  }
  if (level === EvaluationLevel.TOO_LONG) {
    return {
      ...state,
      error: Errors.TOO_LONG,
    };
  }
  return state;
};

const validateEquality = (state: ChangePasswordState): ChangePasswordState => {
  const { newPassword, repeatPassword } = state;

  if (newPassword !== repeatPassword) {
    return {
      ...state,
      error: Errors.DONT_MATCH,
    };
  }
  return state;
};

export const validate = (state: ChangePasswordState): ChangePasswordState => {
  const validations = [validateEvaluationLevel, validateEquality];
  const noError = { ...state };
  if (typeof noError.error !== 'undefined') {
    delete noError.error;
  }
  return validations.reduce((acc, validation) => {
    if (typeof acc.error === 'undefined') {
      return validation(acc);
    }
    return acc;
  }, noError);
};

export const reducer = (state: ChangePasswordState, action: ValidActions): ChangePasswordState => {
  switch (action.type) {
    case ActionTypes.UPDATE_NEW: {
      const password = action.payload;
      return validate({
        ...state,
        newPassword: password,
      });
    }
    case ActionTypes.UPDATE_REPEAT: {
      const password = action.payload;
      return validate({
        ...state,
        repeatPassword: password,
      });
    }
    case ActionTypes.DISPATCH_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export const dispatchMiddleware =
  (
    state: ChangePasswordState,
    dispatch: Dispatch<ValidActions>,
    executeRequest: (args: ChangePasswordRequest) => Promise<Promise<ChangeCustomerResult>>,
    ecLocale: string,
    onSuccess = () => {},
    customSubmit?: (password: string) => Promise<void>,
    setErrorMessage?: React.Dispatch<React.SetStateAction<string | undefined>>,
  ): ((action: ValidActions) => void) =>
  async (action: ValidActions) => {
    switch (action.type) {
      case ActionTypes.SUBMIT: {
        const { error } = validate(state);
        // need to be explicit with the undefined here
        // state.error might be 0
        if (typeof error !== 'undefined') {
          const newAction: DispatchErrorAction = {
            type: ActionTypes.DISPATCH_ERROR,
            payload: error,
          };
          return dispatch(newAction);
        }
        if (customSubmit) {
          await customSubmit(state.newPassword);
        } else {
          const result = await executeRequest({
            changePasswordRequestPayload: {
              newPassword: state.newPassword,
            },
            ecLocale,
          });
          if (result.errors?.elements) {
            // set error to backend error
            setErrorMessage?.(result.errors.elements[0].description);
            return Promise.resolve(null);
          }
        }
        onSuccess();
        return Promise.resolve(null);
      }
      default:
        return dispatch(action);
    }
  };

const getNewErrorMessage = (
  intl: IntlShape,
  minLength: number,
  maxLength: number,
  error?: Errors,
) => {
  switch (error) {
    case Errors.TOO_LONG:
      return intl.formatMessage(messages.errorTooLong, { maxLength });
    case Errors.TOO_SHORT:
      return intl.formatMessage(messages.errorTooShort, { minLength });
    default:
      return undefined;
  }
};

export type ChangePasswordProps = {
  initialState?: ChangePasswordState;
  customSubmit?: (password: string) => Promise<void>;
  onSuccess?: () => void;
};

export const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  initialState = defaultInitialState,
  customSubmit,
  onSuccess = () => {},
}) => {
  const intl = useIntl();
  const {
    password: { minLength, maxLength },
  } = useConfig<FragmentConfig>();
  const customerContext = useCustomerContext();
  const customerApi = useCustomerApi();
  const [state, basicDispatch] = useReducer(reducer, {
    ...initialState,
    minLength,
    maxLength,
  });
  const profile = useProfiling();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { newPassword, repeatPassword, error } = state;
  const requestGenerator = (args: ChangePasswordRequest) =>
    profile(customerApi!, 'changePassword', true, args);
  const dispatch = dispatchMiddleware(
    state,
    basicDispatch,
    requestGenerator,
    customerContext.ecLocale,
    onSuccess,
    customSubmit,
    setErrorMessage,
  );
  useEffect(() => {
    setErrorMessage(getNewErrorMessage(intl, minLength, maxLength, error));
  }, [error]);
  return (
    <ComponentThemeProvider theme={componentTheme}>
      <PasswordFormWrapper
        data-testid="changepassword-form"
        onSubmit={(event) => {
          dispatch({
            type: ActionTypes.SUBMIT,
          });
          event.preventDefault();
        }}
      >
        <Block>
          <div>
            <Input
              type="password"
              id="changepassword-new"
              data-testid="changepassword-new"
              label={intl.formatMessage(messages.newLabel)}
              displayLength
              onChange={(event) =>
                dispatch({
                  type: ActionTypes.UPDATE_NEW,
                  payload: event.target.value,
                })
              }
              lengthMinMax={[minLength, maxLength]}
              error={
                errorMessage &&
                intl.formatMessage(
                  { id: 'backend-error', defaultMessage: `${errorMessage}` },
                  {
                    migrate: (parts: ReactNode) => (
                      <a href="/klantenservice/privacy/veilig-shoppen">{parts}</a>
                    ),
                  },
                )
              }
              value={newPassword}
              placeholder={intl.formatMessage(messages.placeholder, { minLength, maxLength })}
              checkPasswordStrength
              validate={false}
            />
          </div>
        </Block>
        <Block>
          <div>
            <Input
              data-testid="changepassword-repeat"
              id="changepassword-repeat"
              type="password"
              onChange={(event) =>
                dispatch({
                  type: ActionTypes.UPDATE_REPEAT,
                  payload: event.target.value,
                })
              }
              lengthMinMax={[minLength, maxLength]}
              value={repeatPassword}
              error={
                error === Errors.DONT_MATCH
                  ? intl.formatMessage(messages.errorDontMatch)
                  : undefined
              }
              label={intl.formatMessage(messages.repeatLabel)}
              displayLength
              placeholder={intl.formatMessage(messages.placeholder, { minLength, maxLength })}
              validate={false}
            />
          </div>
        </Block>
        <Button type="submit" fullWidth data-testid="changepassword-submit" layout="primary">
          <FormattedMessage id="ChangePassword.submit" defaultMessage="Neues Passwort übernehmen" />
        </Button>
        <SecurityInfo data-testid="changepassword-securityinfo">
          <SecurityHeadline level={4}>
            <FormattedMessage
              id="ChangePassword.SecurityHint.headline"
              defaultMessage="Sicherheit ist uns wichtig"
            />
          </SecurityHeadline>
          <FormattedMessage
            id="ChangePassword.SecurityHint.text"
            defaultMessage="Dein Passwort muss aus {minLength}-{maxLength} Zeichen bestehen, Achte auch auf Groß- und Kleinschreibung"
            values={{ minLength, maxLength }}
          />
        </SecurityInfo>
      </PasswordFormWrapper>
      <div>
        <HintHeadline level={5}>
          <FormattedMessage
            id="ChangePassword.Howto.headline"
            defaultMessage="Wie sieht ein sicheres Passwort aus?"
          />
        </HintHeadline>
        <FormattedMessage
          id="ChangePassword.Howto.text"
          defaultMessage='Kennwörter wie "123456", "hallo" und "passwort" stehen ganz oben auf der Beliebtheitsskala, sind aber ziemlich unsicher. Mit diesen fünf Tipps erstellen Sie ein gutes, sicheres Passwort:'
          tagName="p"
        />
        <HintList>
          <FormattedMessage
            id="ChangePassword.Howto.list.1"
            defaultMessage="Kombinieren Sie kleine und große Buchstaben, Zahlen und Sonderzeichen"
            tagName="li"
          />
          <FormattedMessage
            id="ChangePassword.Howto.list.2"
            defaultMessage="Wählen Sie mindestens acht bis zehn Zeichen"
            tagName="li"
          />
          <FormattedMessage
            id="ChangePassword.Howto.list.3"
            defaultMessage="Verwenden Sie keine Namen oder Geburtsdaten"
            tagName="li"
          />
          <FormattedMessage
            id="ChangePassword.Howto.list.4"
            defaultMessage='Zeichenfolgen wie "abc", "quertz" oder "123" sollten vermieden werden'
            tagName="li"
          />
          <FormattedMessage
            id="ChangePassword.Howto.list.5"
            defaultMessage="Verwenden Sie keine Passwörter mehrfach"
            tagName="li"
          />
        </HintList>
      </div>
    </ComponentThemeProvider>
  );
};

export default ChangePassword;
