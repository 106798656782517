import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headline: {
    id: 'AccountInformation.headline',
    defaultMessage: 'Unsere Bankverbindung',
  },
  receiverTitle: {
    id: 'AccountInformation.receiverTitle',
    defaultMessage: 'Empfänger:',
  },
  receiverName: {
    id: 'AccountInformation.receiverName',
    defaultMessage: "I'm walking",
  },
  receiverNameWithBank: {
    id: 'AccountInformation.receiverNameWithBank',
    defaultMessage: 'Bankname',
  },
  recieverAdditionals: {
    defaultMessage: 'Bankinformation',
    id: 'AccountInformation.receiverAdditionals',
  },
  bic: {
    id: 'AccountInformation.bic',
    defaultMessage: 'BIC: <split>DRESDEFF760</split>',
  },
  subject: {
    id: 'AccountInformation.subject',
    defaultMessage: 'Verwendungszweck: {bankTransferReference}',
  },
  iban: {
    id: 'AccountInformation.iban',
    defaultMessage: 'IBAN: <split>DE05 7608 0040 0623 0490 03</split>',
  },
});
