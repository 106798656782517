import React, { FC } from 'react';
import styled from 'styled-components';
import { SkeletonProvider, withSkeleton } from '@empiriecom/module-components/Skeleton';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import useMaturities from '@/src/hooks/useMaturities';
import AccountInformation from '@/src/components/PaymentBox/AccountInformation';
import PaymentBoxSkeleton from '@/src/components/PaymentBox/skeleton';
import { FragmentConfig } from '@/config/types';
import Paylink from '@empiriecom/mybuy-components/components/Paylink';
import EventTracking from '../EventTracking';
import trackingAdapter from '@/src/utils/trackingAdapter';
import { useCustomerApi, useCustomerContext } from '@empiriecom/mybuy-components';
import Card from '@empiriecom/module-components/Card';

const CardWithPadding = styled(Card)`
  padding: 0.75rem;
`;

const PaymentBoxContent = () => {
  const maturitiesData = useMaturities();
  const config = useConfig<FragmentConfig>();
  const customerContext = useCustomerContext();
  const customerApi = useCustomerApi();

  const handlePayClick = async () => {
    trackingAdapter.sendLayerLandmark('PayBoxLayer');
    return customerApi!.getCustomerPaylink({ ecLocale: customerContext.ecLocale });
  };

  if (
    config.paylink.enforced ||
    (config.paylink.enabled &&
      maturitiesData.data &&
      maturitiesData.data.grandTotal &&
      (maturitiesData.data.grandTotal?.value || 0) > 0)
  ) {
    return (
      <Paylink
        onPayClick={handlePayClick}
        price={
          config.paylink.enforced
            ? { value: 100, currencyCode: 'EUR' }
            : maturitiesData.data!.grandTotal!
        }
        buttonStyle="primary"
      >
        {config.paylink.bankTransferEnabled && <AccountInformation contentOnly />}
        <EventTracking eventCategory="paylink" />
      </Paylink>
    );
  }
  // Account information
  return (
    <>
      {config.paylink.bankTransferEnabled && (
        <CardWithPadding>
          <AccountInformation showQRPayment />
        </CardWithPadding>
      )}
    </>
  );
};

const PaymentBoxWithSkeleton = withSkeleton(PaymentBoxContent, PaymentBoxSkeleton);

const PaymentBox: FC = (): JSX.Element => {
  const { loading } = useMaturities();
  return (
    <>
      <SkeletonProvider isLoading={loading}>
        <PaymentBoxWithSkeleton />
      </SkeletonProvider>
    </>
  );
};

export default PaymentBox;
