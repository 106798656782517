import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';
import { Cell, Grid } from '@empiriecom/module-components/Grid';

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const GreyBox = styled(Skeleton)`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const Card = styled.div`
  border-radius: 4px;
  background-color: #fff;
`;

const Space = styled.div`
  height: 0.5rem;
`;

const CardHead = styled.div`
  padding: 0.5rem;
`;

const LeftLeft = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Circle = styled(Skeleton)`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 0 1rem 0 0;
`;

const CardContent = styled.div`
  padding: 0.5rem;
`;

const MediumLine = styled(Skeleton)`
  width: 8rem;
  margin: 0.25rem 0 0 0;
`;

const ShortLine = styled(Skeleton)`
  width: 5rem;
  margin: 0.25rem 0 0 0;
`;

const SmallLine = styled(Skeleton)`
  width: 2rem;
  margin: 0.25rem 0 0 0;
`;

const LargeLine = styled(Skeleton)`
  width: 10rem;
  margin: 0.25rem 0 0 0;
`;

const HR = styled(Skeleton)`
  width: 100%;
  margin: 0.5rem 0;
`;

const LoungeContainerSkeleton: FunctionComponent = (): JSX.Element => {
  return (
    <>
      <GreyBox height="auto">
        <StyledGrid cellsPerRow={[1, 2, 2]} colGap="1rem">
          <Cell>
            {/* Left side first box */}
            <Card>
              <CardHead>
                <LeftLeft>
                  <Circle height="2rem" />
                  <div>
                    <MediumLine height="1.25rem" />
                    <ShortLine height="1rem" />
                    <LargeLine height="1rem" />
                  </div>
                </LeftLeft>
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <MediumLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
            {/* Left side second box */}
            <Space />
            <Card>
              <CardHead>
                <MediumLine height="1.5rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <LeftRight>
                  <Cell>
                    <MediumLine height="1rem" />
                  </Cell>
                  <Cell>
                    <SmallLine height="1rem" />
                  </Cell>
                </LeftRight>
                <LeftRight>
                  <Cell>
                    <MediumLine height="1rem" />
                  </Cell>
                  <Cell>
                    <SmallLine height="1rem" />
                  </Cell>
                </LeftRight>
                <LeftRight>
                  <Cell>
                    <MediumLine height="1rem" />
                  </Cell>
                  <Cell>
                    <SmallLine height="1rem" />
                  </Cell>
                </LeftRight>
                <HR height="0" />
                <LargeLine height="1.5rem" />
                <Space />
                <MediumLine height="1.5rem" />
                <Space />
                <MediumLine height="1.5rem" />
                <Space />
                <MediumLine height="1.5rem" />
              </CardContent>
            </Card>
          </Cell>
          <Cell>
            {/* Right side first box */}
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
            <Space />
            {/* Right side second box */}
            <Card>
              <CardHead>
                <MediumLine height="1.25rem" />
              </CardHead>
              <HR height="0" />
              <CardContent>
                <LargeLine height="1rem" />
                <HR height="0" />
                <MediumLine height="1rem" />
              </CardContent>
            </Card>
          </Cell>
        </StyledGrid>
      </GreyBox>
    </>
  );
};

export default LoungeContainerSkeleton;
