import React, { FC, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Card, { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import getIcon from '@empiriecom/module-components/utils/getIcon';

import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerAdvantages from '@/src/hooks/useCustomerAdvantages';

import internalPages, { Language } from '@/config/pages';
import { useLoginApi } from '@empiriecom/mybuy-components/api/useLoginApi';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import DisconnectJoe from '../Joe/DisconnectJoe';
import useCustomerStatus from '@/src/hooks/useCustomerStatus';

type CustomerInformationProps = {
  isLblEsi?: boolean;
  isSoftLogin?: boolean;
  enableJoeDisconnect?: boolean;
};

// card contains user-generated content (like name and email) which can be very long
const StyledCard = styled(Card)`
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// doesn't work on CardAction directly, so apply style via another wrapper
const CardActionWrapper = styled.div<{ insertRenewalDate?: string }>`
  > div > div:first-child {
    flex: 0 0 1.5rem;
  }
  /* I dont want to change card action only for this edge case. So I added this little text per css. */
  > div > div:last-child::after {
    content: ${({ insertRenewalDate }) => insertRenewalDate && `'${insertRenewalDate}'`};
    display: block;
    color: ${({ theme }) => theme.base.color.textTransparent};
    font-size: 0.688rem;
  }
`;

const Logout = styled.div<CustomerInformationProps>`
  ${({ isLblEsi }) => (isLblEsi ? '' : 'margin-left: 4.25rem;')}

  cursor: pointer;
  text-decoration: underline;
  font-size: 0.688rem;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.5rem 1rem;
`;

const User = getIcon('User');
const OriginalCheckmark = getIcon('Checkmark');
const Checkmark = styled(OriginalCheckmark)<{ colorVariant: 'primary' | 'secondary' | 'success' }>`
  ${({ theme, colorVariant }) => `
    color: ${theme[colorVariant].color.normal};
    fill: ${theme[colorVariant].color.normal};
    stroke: ${theme[colorVariant].color.normal};
  `}
`;
const Premiumlounge = getIcon('Premiumlounge');
const FlexipayLogo = getIcon('FlexipayLogo');

const messages = defineMessages({
  customerInformationPersonalDataLink: {
    id: 'CustomerInformation.PersonalDataLink',
    defaultMessage: 'Meine persönlichen Daten',
  },
  premiumRenewalDate: {
    id: 'CustomerInformation.PremiumRenewalDate',
    defaultMessage: '(verlängert sich am {placeholder})',
  },
});

const CustomerInformation: FC<CustomerInformationProps> = ({
  isLblEsi = false,
  isSoftLogin = false,
  enableJoeDisconnect = false,
}): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const loginApi = useLoginApi();
  const customerApi = useCustomerApi();
  const { customer, ecLocale } = useCustomerContext();
  const { data } = useCustomerAdvantages();
  const { data: status } = useCustomerStatus();
  const { premiumStatus } = status || {};

  const {
    show,
    checkMarkColor,
    lounge: { icon: loungeIconPath, premiumOnTop },
  }: FragmentConfig = useConfig();
  const [customerData, setCustomerData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  }>();
  const lang = intl.locale.split('-')[0];

  const { businessPartnerNo } = customer || {};

  useEffect(() => {
    let isSubscribed = true;

    if (customer) {
      // istanbul ignore else
      if (isSubscribed) {
        setCustomerData({
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
        });
      }
    }

    const getAbstractCustomer = async () => {
      return customerApi?.getCustomerAbstract({ ecLocale });
    };

    if (isSoftLogin) {
      getAbstractCustomer().then((abstractCustomer) => {
        // istanbul ignore else
        if (abstractCustomer) {
          // istanbul ignore else
          if (isSubscribed) {
            setCustomerData({
              firstName: abstractCustomer.firstName,
              lastName: abstractCustomer.lastName,
              email: abstractCustomer.email,
            });
          }
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [customerApi, customer]);

  const navigateToUrl = (url: string) => {
    history.push(url);
  };

  const goToPersonalData = () => {
    const personalDataUrl = internalPages.personaldata.paths[intl.locale.split('-')[0] as Language];
    navigateToUrl(personalDataUrl);
  };

  const handleLogout = async () => {
    if (loginApi) {
      try {
        const response = await loginApi.logout({ ecLocale });
        await apiKeyManager.logout(response);
        const tokenContents = apiKeyManager.getTokenContents(response.sessionToken);
        const isCallcenterUser = tokenContents?.role === 1;
        if (isCallcenterUser) {
          navigateToUrl('/mein-konto/callcenter');
        } else {
          navigateToUrl('/');
        }
      } catch (e) {
        await apiKeyManager.logout();
        navigateToUrl('/');
      }
    }
  };
  const additionalContent: React.ReactNode = isSoftLogin ? undefined : (
    <>
      <CardSeperator />
      <CardContent>
        <div>
          <strong>
            <FormattedMessage
              id="CustomerInformation.BusinessPartnerNo"
              defaultMessage="Kundennummer: {businessPartnerNo}"
              values={{
                businessPartnerNo: (
                  <span data-mf-replace-inner="**REMOVED**">{businessPartnerNo}</span>
                ),
              }}
            />
          </strong>
        </div>
      </CardContent>
      {premiumOnTop && data && data.links && (
        <>
          <CardSeperator />
          {data.links
            .filter((link) => link.iconType === 'premium')
            .map((link) => {
              const loungeIcon = loungeIconPath ? (
                <img src={loungeIconPath(lang)} width="1.5rem" height="1.5rem" />
              ) : (
                <Premiumlounge size="1.5rem" />
              );
              return (
                <>
                  <CardActionWrapper
                    insertRenewalDate={
                      premiumStatus?.renewalDate
                        ? intl.formatMessage(messages.premiumRenewalDate, {
                            placeholder: premiumStatus.renewalDate,
                          })
                        : undefined
                    }
                  >
                    <CardAction
                      key={link.description}
                      icon={loungeIcon}
                      text={link.description}
                      onClick={() => navigateToUrl(link.href)}
                    />
                  </CardActionWrapper>
                </>
              );
            })}
        </>
      )}
      {data && data.list && (
        <div>
          {data.list.map((advantage) => (
            <CardActionWrapper>
              <CardAction
                key={advantage}
                icon={<Checkmark size="1.5rem" colorVariant={checkMarkColor} />}
                text={advantage}
              />
            </CardActionWrapper>
          ))}
        </div>
      )}
      {data && data.links && (
        <>
          <CardSeperator />
          {data.links.map((link) => {
            let linkIcon = null;
            const loungeIcon = loungeIconPath ? (
              <img src={loungeIconPath(lang)} width="1.5rem" height="1.5rem" alt="premium icon" />
            ) : (
              <Premiumlounge size="1.5rem" />
            );
            if (link.iconType === 'credit') {
              linkIcon = <FlexipayLogo size="1.5rem" />;
            } else if (link.iconType === 'premium') {
              linkIcon = loungeIcon;
            }
            return (
              <>
                <CardActionWrapper
                  insertRenewalDate={
                    link.iconType === 'premium' && premiumStatus?.renewalDate
                      ? intl.formatMessage(messages.premiumRenewalDate, {
                          placeholder: premiumStatus.renewalDate,
                        })
                      : undefined
                  }
                >
                  {!premiumOnTop && link.iconType === 'premium' && (
                    <CardAction
                      key={link.description}
                      icon={loungeIcon}
                      text={link.description}
                      onClick={() => navigateToUrl(link.href)}
                    />
                  )}
                  {link.iconType !== 'premium' && (
                    <CardAction
                      key={link.description}
                      icon={linkIcon}
                      text={link.description}
                      onClick={() => navigateToUrl(link.href)}
                    />
                  )}
                </CardActionWrapper>
              </>
            );
          })}
        </>
      )}
      {enableJoeDisconnect && (
        <>
          <CardSeperator />
          <DisconnectJoe />
        </>
      )}

      <CardSeperator />
      <CardActionWrapper>
        <CardAction
          icon={<User size="1.5rem" />}
          text={intl.formatMessage(messages.customerInformationPersonalDataLink)}
          onClick={goToPersonalData}
        />
      </CardActionWrapper>
    </>
  );

  return (
    <StyledCard
      showHeaderSeparator={false}
      headerIcon={<User />}
      headerVariant="dark"
      headline={`${customerData?.firstName} ${customerData?.lastName}`}
      subHeadline={customerData?.email}
      shouldHideHeaderDataForMouseflow
    >
      <CardContent>
        <Logout tabIndex={0} isLblEsi={isLblEsi} onClick={handleLogout}>
          <FormattedMessage
            id="CustomerInformation.LogoutText"
            defaultMessage="Sie sind nicht {name}? Jetzt abmelden"
            values={{
              name: (
                <span data-mf-replace-inner="**REMOVED**">
                  {customerData?.firstName}
                  {show.lastName && customerData?.lastName && ` ${customerData.lastName}`}
                </span>
              ),
            }}
          />
        </Logout>
        {additionalContent}
      </CardContent>
    </StyledCard>
  );
};

export default CustomerInformation;
