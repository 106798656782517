import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SelectProps } from '@empiriecom/module-components/FormControls/Select/types';
import { ShipmentSelect } from '../ShipmentSelect';

const messages = defineMessages({
  label: {
    id: 'ShipmentTimeRangeSelect.option.label',
    defaultMessage: 'Zeitraum:',
  },
  all: {
    id: 'ShipmentTimeRangeSelect.option.all',
    defaultMessage: 'Alle',
  },
  days: {
    id: 'ShipmentTimeRangeSelect.option.days',
    defaultMessage: '{amount} Tage',
  },
  weeks: {
    id: 'ShipmentTimeRangeSelect.option.weeks',
    defaultMessage: '{amount} Wochen',
  },
});

function useShipmentTimeRangeOptions() {
  const intl = useIntl();
  const [options] = useState<SelectProps['options']>([
    {
      displayName: intl.formatMessage(messages.all),
      value: '',
    },
    {
      displayName: intl.formatMessage(messages.days, {
        amount: 7,
      }),
      value: '7',
    },
    {
      displayName: intl.formatMessage(messages.days, {
        amount: 14,
      }),
      value: '14',
    },
    {
      displayName: intl.formatMessage(messages.weeks, {
        amount: 4,
      }),
      value: '28',
    },
  ]);

  return options;
}

export interface ShipmentTimeRangeSelectProps {
  value?: number;
  disabled?: boolean;
  onSelect?: (value?: number) => void;
}

export const SHIPMENT_TIMERANGE_SELECT_TESTID = 'shipment-timerange-select';

export function ShipmentTimeRangeSelect({
  onSelect,
  value,
  disabled,
}: ShipmentTimeRangeSelectProps) {
  const intl = useIntl();
  const options = useShipmentTimeRangeOptions();

  const handleChange = useCallback(
    (change: string) => {
      const val = change ? parseInt(change, 10) : undefined;
      onSelect?.(val);
    },
    [onSelect],
  );

  return (
    <div>
      <div>{intl.formatMessage(messages.label)}</div>
      <ShipmentSelect
        disabled={disabled}
        data-testid={SHIPMENT_TIMERANGE_SELECT_TESTID}
        value={`${value || ''}`}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
}
