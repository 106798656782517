import Headline from '@empiriecom/module-components/Headline';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import PrepaymentHint from '@/src/components/PrepaymentHint';

const Container = styled.div`
  background-color: white;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const StyledText = styled.p`
  display: block;
  ${({ theme }) => {
    const {
      global: { spacer },
    } = theme;
    return css`
      margin-top: ${spacer.medium};
      margin-bottom: ${spacer.medium};
      font-size: 0.813rem;
      line-height: 1.125rem;
    `;
  }}
`;

const StyledPrepaymentHint = styled(PrepaymentHint)`
  margin-bottom: 1.813rem;
`;

const messages = defineMessages({
  headline: {
    id: 'ShipmentInfoHeader.headline',
    defaultMessage: 'Meine Bestellungen und Lieferungen',
  },
  text: {
    id: 'ShipmentInfoHeader.text',
    defaultMessage:
      'Verschaffen Sie sich einen Überblick, welchen Status ihre Bestellungen und Lieferungen haben.',
  },
});

export function ShipmentInfoHeader() {
  const intl = useIntl();

  return (
    <Container>
      <Headline level={3}>{intl.formatMessage(messages.headline)}</Headline>
      <StyledText>{intl.formatMessage(messages.text)}</StyledText>
      <StyledPrepaymentHint />
    </Container>
  );
}

export default ShipmentInfoHeader;
