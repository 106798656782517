/* eslint-disable */
  import { LocaleWrapper } from '@empiriecom/module-components/IntlProvider';
  import deepmerge from 'deepmerge';
  import ioLocale from '@empiriecom/mybuy-components/locales/ackermannch';
  
  const messages: LocaleWrapper = {
  "de-CH": {
    "APlusList.headline": "Ihre bereits gebuchten Services",
    "AccountInformation.headline": "Unsere Bankverbindung",
    "AccountInformation.qrPayment.Or": "ODER",
    "AccountInformation.qrPayment.infoText": "Nutzen Sie den QR-Zahlschein über den unten stehenden Link:",
    "AccountInformation.receiverNameWithBank": "Bankname",
    "AccountInformation.receiverTitle": " ",
    "AccountInformation.subject": "Bitte tragen Sie bei einer E-Banking Überweisung im Feld Referenz Ihre Referenznummer <strong>{bankTransferReference}</strong> ein.",
    "AccountSettings.Autologin": "automatische Anmeldung",
    "AccountSettings.Autologin.Headline": "Aktivieren Sie die automatische Anmeldung.",
    "AccountSettings.Autologin.Text": "Beim nächsten Besuch erkennen wir Sie wieder und Sie müssen sich nicht jedes Mal neu anmelden.",
    "AccountSettings.Delivery": "Zustellung",
    "AccountSettings.Headline": "Einstellungen",
    "AccountSettings.MoreInformation": "weitere Informationen",
    "AccountSettings.NeighboursDelivery.Headline": "An Nachbar zustellen:",
    "AccountSettings.NeighboursDelivery.Infotext": "Der Zusteller kann mein Paket beim Nachbarn abgeben, wenn ich nicht zu Hause bin. Eine Änderung dieser Lieferpräferenz ist nur für Artikel möglich, die noch nicht in Rechnung gestellt wurden.",
    "AccountSettings.NeighboursDelivery.Text": "In deiner Abwesenheit kannst du Pakete auch an Nachbarn zustellen lassen",
    "AccountSettings.Newsletter.Headline": "Newsletter",
    "AccountSettings.Newsletter.HeadlineModal": "Newsletter",
    "AccountSettings.Newsletter.Infotext": "Kostenlose News zu Sparvorteilen, Trends rund um Mode, Schuhe und Wohnen, Sale und Aktionen – so verpassen Sie garantiert nichts mehr.",
    "AccountSettings.Newsletter.Text": "Sichern Sie sich News zu Trends, Aktionen und inspirierende Ideen.",
    "AccountSettings.Notifications": "Benachrichtigungen",
    "AccountSettings.PostalBankStatement.Headline": "Postalische Kontoauszüge:",
    "AccountSettings.PostalBankStatement.Infotext": "TODO",
    "AccountSettings.PostalBankStatement.Text": "Lasse dir Kontoauszüge per Post zuschicken (zzg. 1,50 €)",
    "AccountSettings.ServiceMail.Headline": "Service-Infos",
    "AccountSettings.ServiceMail.Text": "So entgehen Ihnen keine Informationen über Ihre Bestellungen.",
    "AccountSettings.ServiceMail.entry0": "Versandbestätigungen",
    "AccountSettings.ServiceMail.entry1": "Retoureneingangsbestätigungen",
    "AccountSettings.ServiceMail.entry2": "Zahlungseingangsbestätigungen",
    "AccountSettings.ServiceMail.entry3": "Bewertungsmails",
    "AccountSettings.ServiceMail.entry4": "Anzahlungs-Aufforderungen",
    "AccountSettings.ServiceMail.entry5": "-",
    "AccountSettings.ServiceMail.entry6": "-",
    "AccountSettings.ServiceMail.entry7": "-",
    "AccountSettings.ServiceMail.entry8": "-",
    "AccountSettings.ServiceMail.entry9": "-",
    "AccountSettings.ServiceMailInfo.Headline": "Wir halten Sie per Mail über alle Themen rund um Ihre Bestellung auf dem Laufenden. So entgeht Ihnen nichts. Sie erhalten z.B.",
    "AccountSettings.SmsNotification.AddMobileNumber": "Handynummer hinzufügen",
    "AccountSettings.SmsNotification.ChangeMobileNumber": "Handynummer ändern",
    "AccountSettings.SmsNotification.Headline": "SMS Genehmigung:",
    "AccountSettings.SmsNotification.Infotext": "Wenn ich bei der Bestellung die Lieferung an einen PostNL-Punkt wähle oder einen grossen Artikel von Dynalogic zu mir nach Hause liefern lasse, möchte ich bei Lieferung meiner Bestellung eine kostenlose SMS erhalten.",
    "AccountSettings.SmsNotification.MobilePhoneNumber": "Gewählte Handynummer:{break}{phoneNumber}",
    "AccountSettings.SmsNotification.Text": "Erhalte Benachrichtigungen per SMS",
    "AccountSettings.eInvoice.Headline": "Online-Rechnung",
    "AccountSettings.eInvoice.InfoBenefits": "<ul><li><strong>Immer aktuell & übersichtlich:</strong> Ihre Online-Rechnung aktualisiert sich automatisch online, sodass Sie immer eine aktuelle und übersichtliche Rechnung vorliegen haben.</li><li><strong>Sicher archiviert:</strong> In Ihrem Kundenkonto finden Sie eine Übersicht Ihrer Rechnungen über 10 Jahre.</li><li><strong>Flexibel & praktisch:</strong> Sie haben immer und überall Zugriff auf Ihre Online-Rechnung. Sie können Ihre Online-Rechnung einfach ausdrucken und downloaden.</li></ul>",
    "AccountSettings.eInvoice.Infotext": "Ihre Vorteile mit der Online-Rechnung",
    "AccountSettings.eInvoice.Text": "Entscheiden Sie sich für die Umwelt und gegen Papierrechnungen.",
    "Aplus.included": "inklusive",
    "BankstatementsContainer.Overview.downloadCardButton": "Herunterladen",
    "BankstatementsContainer.Overview.downloadCardDescription": "Um die vollständige Aufschlüsselung zu sehen, laden Sie diesen Kontoauszug als PDF-Datei herunter (kostenlos)",
    "BankstatementsContainer.Overview.downloadCardTitle": "Diesen Kontoauszug herunterladen",
    "BankstatementsContainer.Overview.downloadCardWarning": "Leider ist beim Herunterladen ein Fehler aufgetreten. Versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEnd": "Ende Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEndOfPeriod": "Saldo (Ende des Monats)",
    "BankstatementsContainer.Overview.overviewPeriodBallanceNew": "Neuer Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallancePreviousPeriod": "Saldo der vorherigen Abrechnung",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStart": "Beginn Saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStartOfPeriod": "Saldo (Beginn des Monats)",
    "BankstatementsContainer.Overview.overviewPeriodEmpty": "Sie haben keinen aktuellen Kontoauszug. Bei älteren Kontoauszügen wählen Sie im Dropdown den gewünschten Zeitraum aus.",
    "BankstatementsContainer.Overview.overviewPeriodLabel": "Zeitraum auswählen",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionMain": "Postalische Kontoauszüge",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionSecondary": "Lassen Sie sich Kontoauszüge per Post zuschicken (zzg. 1,50 €)",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMore": "Weitere Informationen",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreContent": "TODO",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreHeadline": "Postalische Kontoauszüge:",
    "BankstatementsContainer.Overview.perEmailReceiveCardTitle": "Kontoauszug per Post auf Papier erhalten",
    "BankstatementsContainer.Overview.title": "Buchungsübersicht",
    "BankstatementsContainer.introtext": "Hier finden Sie Ihre Kontoauszüge.{break}Alle Buchungen und Ihren aktuellen Kontostand finden Sie in Ihrem Kundenkonto.",
    "BankstatementsContainer.title": "Meine Kontoauszüge",
    "BookingContainer.Kim.title": "Wie gefällt Ihnen die Buchungsseite?",
    "BookingContainer.bookingOverview.headline": "Buchungsübersicht",
    "BookingContainer.bookings.headline": "Meine Buchungen",
    "BookingListContainer.BookingList.defaultAsyncErrorMessage": "Entschuldigen Sie bitte, wir können Ihre Buchungen zur Zeit leider nicht abholen. <reload>Bitte laden Sie die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuchen Sie es dann später noch einmal. Vielen Dank für Ihr Verständnis.",
    "BookingListContainer.BookingList.emptyBookingListHeadline": "Keine Buchungen?",
    "BookingListContainer.BookingList.emptyBookingListText": "Dann haben Sie noch nicht bei uns bestellt - stöbern Sie in unserem vielfältigen Sortiment und sichern Sie sich Ihr neues Lieblingsteil",
    "BookingListContainer.BookingList.fullViewSelectionText": "Alle Buchungen",
    "BookingListContainer.BookingList.invoiceViewSelectionText": "Rechnungen",
    "BookingListContainer.BookingList.loadMorebutton": "weitere laden",
    "BookingListContainer.BookingList.paymentViewSelectionText": "Zahlungen",
    "BookingListContainer.BookingList.reimbursementViewSelectionText": "Rücksendungen",
    "BookingListElement.from": "vom ",
    "CCLogin.email.label": "E-Mail-Adresse",
    "CCLogin.email.placeholder": "name@provider.ch",
    "CCLogin.label.login": "Anmelden",
    "CCLogin.password.placeholder": "****",
    "CCLogin.title": "Callcenter Anmeldung",
    "CCLoginAsCustomer.customerNoLabel": "Kundennummer",
    "CCLoginAsCustomer.dobLabel": "Geburtsdatum",
    "CCLoginAsCustomer.submitLabel": "Als Kunde anmelden",
    "CCLoginAsCustomer.title": "Anmeldung als Kunde",
    "CallcenterLoginContainer.CCADFSLoginResponse.errorMessage": "Deine Anfrage konnte nicht verarbeitet werden. Versuche es bitte erneut!",
    "CancelShipmentAction.label.cancel": "Abbrechen",
    "CancelShipmentAction.label.cancelOrder": "Artikel stornieren",
    "CancelShipmentAction.label.cancelOrder.confirm": "Stornieren",
    "CancelShipmentAction.label.close": "Schliessen",
    "CancelShipmentAction.modal.title.confirm": "Stornierung bestätigen",
    "ChangeIban.cancel": "abbrechen",
    "ChangeIban.defaultError": "Es ist ein technischer Fehler aufgetreten",
    "ChangeIban.headline": "IBAN ändern",
    "ChangeIban.inputLabel": "Neue IBAN",
    "ChangeIban.inputPlaceholder": "DE00000000000000000000",
    "ChangeIban.noDataError": "Die Daten wurden gespeichert, können aber nicht angezeigt werden",
    "ChangeIban.save": "Änderungen speichern",
    "ChangePassword.Howto.headline": "Wie sieht ein sicheres Passwort aus?",
    "ChangePassword.Howto.list.1": "Kombinieren Sie kleine und grosse Buchstaben, Zahlen und Sonderzeichen",
    "ChangePassword.Howto.list.2": "Wählen Sie mindestens acht bis zehn Zeichen",
    "ChangePassword.Howto.list.3": "Verwenden Sie keine Namen oder Geburtsdaten",
    "ChangePassword.Howto.list.4": "Zeichenfolgen wie \"abc\", \"quertz\" oder \"123\" sollten vermieden werden",
    "ChangePassword.Howto.list.5": "Verwenden Sie keine Passwörter mehrfach",
    "ChangePassword.Howto.text": "Kennwörter wie \"123456\", \"hallo\" und \"passwort\" stehen ganz oben auf der Beliebtheitsskala, sind aber ziemlich unsicher. Mit diesen fünf Tipps erstellen Sie ein gutes, sicheres Passwort:",
    "ChangePassword.SecurityHint.headline": "Sicherheit ist uns wichtig",
    "ChangePassword.SecurityHint.text": "Ihr Passwort muss aus {minLength}-{maxLength} Zeichen bestehen, achten Sie auch auf Gross- und Kleinschreibung",
    "ChangePassword.errorFromBackend": "Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.",
    "ChangePassword.new.label": "Neues Passwort *",
    "ChangePassword.passwordsDontMatch": "Die beiden Passwörter stimmen nicht überein.",
    "ChangePassword.passwordstooLong": "Das angegebene Kennwort ist zu lang (max. {maxLength} Zeichen)",
    "ChangePassword.passwordstooShort": "Das Passwort muss mindestens {minLength} Zeichen beinhalten.",
    "ChangePassword.placeholder": "{minLength}-{maxLength} Zeichen",
    "ChangePassword.repeat.label": "Neues Passwort wiederholen *",
    "ChangePassword.submit": "Neues Passwort übernehmen",
    "CircleStats.innerTextType.toPay": "Zahlbar vor {Datum}:",
    "CircleStats.innerTextType.withdraw": "Am {Datum}, {Bruch} wird dieser Betrag{Bruch} eingezogen:",
    "CircleStats.sublineTextType.increasedDueToCustomersWish": "Auf Ihren eigenen Wunsch hin wurde der Pausenbetrag angepasst.",
    "CircleStats.sublineTextType.increasingPossible": "Sie können jederzeit ohne zusätzliche Kosten mehr bezahlen.",
    "ContactFormLink": "<link>https://www.ackermann.ch/service-hilfe/ueber-uns/kontakt</link>",
    "CustomerInformation.BusinessPartnerNo": "Kundennummer: {businessPartnerNo}",
    "CustomerInformation.LogoutText": "Sie sind nicht {name}? Jetzt abmelden",
    "CustomerInformation.PersonalDataLink": "Meine persönlichen Daten",
    "CustomerInformation.PremiumRenewalDate": "(verlängert sich am {placeholder})",
    "DataExport.Button": "Daten herunterladen",
    "DataExport.DisabledButton": "Exportiere... ",
    "DataExport.DownloadError": "Leider ist beim Export der Daten ein Fehler unterlaufen. Versuchen Sie zu einem späteren Zeitpunkt erneut.",
    "DataExport.DownloadSuccess": "Ihre Daten wurden heruntergeladen.",
    "DataExport.Headline": "Datenexport",
    "DataExport.NoDownloadText": "Natürlich können Sie Ihre persönlichen Daten jederzeit herunterladen – ohne Auswirkungen auf Ihr Kundenkonto.{break}{break}Diese Funktion ist leider nur im Browser verfügbar. Melden Sie sich einfach mit Ihrem Handy oder Computer auf Ihrem Browser an, um die Datei herunterzuladen.",
    "DataExport.Subheadline": "Ihre Daten, Ihre Sache.",
    "DataExport.Text": "Natürlich können Sie Ihre persönlichen Daten jederzeit herunterladen – ohne Auswirkungen auf Ihr Kundenkonto.{break}{break}Alles bleibt wie gewohnt für Sie nutzbar und bei uns gespeichert. So können Sie die Daten archivieren oder sie für einen anderen Dienst nutzen.",
    "EInvoice.Download.Button": "Herunterladen und Drucken",
    "EInvoice.Download.DisabledButton": "Bitte warten ...",
    "EInvoice.Download.Error": "Leider ist beim Herunterladen ein Fehler aufgetreten. Versuche es zu einem späteren Zeitpunkt erneut.",
    "EInvoice.aplus.headline": "Gebuchte Services",
    "EInvoice.error.description": "In unserem System ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "EInvoice.footer.businessaddress": "Ackermann Vertriebs AG, Industriestrasse 19, 8112 Otelfingen, CHE-115.455.663 HR/MWST",
    "EInvoice.footer.businessterms": "Bitte beachten Sie unsere beiliegenden Geschäftsbedingungen unter <link>ackermann.ch/agb</link>.",
    "EInvoice.footer.fixed.info.first": "Zahlbar bei Rechnungskauf innerhalb von 14 Tagen, bei Ratenkauf innerhalb von 30 Tagen und bei Zahlpause innerhalb von 120 Tagen nach Erhalt der Lieferung. Bitte beachte, dass du spätestens 30 Tage nach Zugang dieser Rechnung und Fälligkeit der Forderung in Verzug gerätst, ohne dass es einer weiteren Mahnung bedarf.",
    "EInvoice.footer.fixed.info.second": "Bei Vorliegen eines gültigen Lastschriftmandates werden wir die ausgewiesenen fälligen Beträge einziehen. Retouren vermindern den Einzugsbetrag.",
    "EInvoice.footer.fixed.info.third": "Bitte geben Sie unbedingt in der Überweisung Kundennummer und Rechnungsnummer im Verwendungszweck an. Sie beschleunigen damit die Gutschrift auf Ihrem Kundenkonto.",
    "EInvoice.footer.ownership": "Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.",
    "EInvoice.header.customerNumber.headline": "Kundennummer:",
    "EInvoice.header.deliveryAddress.headline": "Lieferanschrift:",
    "EInvoice.header.invoiceAddress.headline": "Rechnungsanschrift:",
    "EInvoice.header.invoiceDate.headline": "Rechnungsdatum:",
    "EInvoice.header.invoiceNumber.headline": "Rechnungsnummer:",
    "EInvoice.header.paymentType.headline": "Zahlungsart:",
    "EInvoice.invoiceTotals.headline": "Rechnung",
    "EInvoice.invoiceTotals.subTotals": "Zwischensumme:",
    "EInvoice.invoicetotals.grandTotals": "Gesamtsumme:",
    "EInvoice.paymentplan.headline": "Zahlungsplan",
    "EInvoice.paymentplan.showEffectiveInstallmentsHint": "Dies ist deine ursprüngliche Rate.{break}Die aktuellen Raten inkl. der Zusammensetzung findest du <link>hier</link>.",
    "EInvoice.products.articleNumber": "Art.Nr.: {articlenumber}",
    "EInvoice.products.finalPrice": "Endpreis:",
    "EInvoice.products.installments": "in {installmentCount} Monatsraten",
    "EInvoice.products.paymentMethod": "Zahlungsart: {paymentMethod}",
    "EInvoice.products.pricesHeadline": "Rechnung",
    "EInvoice.products.productHeadline": "Artikel",
    "EInvoice.products.productPrice": "Warenwert:",
    "EInvoice.products.productReturned": "Produkt wurde zurückgeschickt",
    "EInvoice.products.quantity": "Menge: {quantity}",
    "EInvoice.products.reclaim.link": "Ein Problem mit diesem Artikel melden",
    "EInvoice.products.size": "Grösse: {size}",
    "FaqCard.Headline": "Fragen?{br}Wir sind für Sie da",
    "FlexikontoContainer.myFlexAccount": "Mein Flexikonto",
    "Gender.female": "Frau",
    "Gender.male": "Herr",
    "Joe.disconnect.label": "Kontoverknüpfung trennen",
    "Joe.disconnect.not-connected.text": "Ihr Konto ist nicht mit Ihrem jö Konto verknüpft.",
    "Joe.disconnect.success": "Ihr Konto wurde erfolgreich getrennt.",
    "Joe.disconnect.text": "Ihr Universal-Konto ist mit Ihrem jö Konto verknüpft. Sie sammeln bei jedem Einkauf Ös.",
    "JoeConnectAccountCard.headline": "jö Bonus Club",
    "LoginDecider.accessDenied.error": "Als EU Kunde kann Ihnen leider kein Zugriff auf Mein Konto ermöglicht werden.",
    "LoungeContainer.advantage0": "<b>Gratis Speditionslieferung</b>{break}Sie sparen bei jeder Lieferung per Spedition die Versandgebühren für grosse und schwere Artikel im Wert von 34,99€.",
    "LoungeContainer.advantage1": "<b>Gratisversand bei jeder Bestellung ohne Mindestbestellwert</b>{break}Für Sie entfällt der Mindestbestellwert von 75€. Sie sparen bei jeder Bestellung 5,95€.",
    "LoungeContainer.advantage2": "<b>Bevorzugter Kundenservice</b>{break}Sie werden priorisiert von unserem Kundenservice bedient.",
    "LoungeContainer.advantage3": "<b>Spezielle Gutschein- & Rabattaktionen</b>{break}Sie profitieren von exklusiven Angeboten und Vergünstigungen.",
    "LoungeContainer.advantage4": "-",
    "LoungeContainer.advantage5": "-",
    "LoungeContainer.advantage6": "-",
    "LoungeContainer.advantage7": "-",
    "LoungeContainer.advantage8": "-",
    "LoungeContainer.advantage9": "-",
    "LoungeContainer.advantagesHeadline": "Als Flexikonto Vorteilsclub-Mitglied geniessen Sie exklusive Angebote:",
    "LoungeContainer.businessPartnerNo": "Meine Kundennummer",
    "LoungeContainer.cancel": "Premiumkunden-Abo kündigen",
    "LoungeContainer.cancel.info": "Ihre Vorteile sind Ihnen bis Ablauf eines Jahres nach Anmeldung sicher.{break}Wir sind stehts bemüht, die Vorteile so exklusiv wie möglich auszuwählen und Ihr Vorteilspaket stetig zu erweitern. Geben Sie uns weiterhin die Chance, Sie zu überzeugen.{break}Wenn Sie nicht mehr die Vorteile geniessen möchten, klicken Sie bitte verbindlich auf nachfolgenden Button.",
    "LoungeContainer.cancel.modal.headline": "Schade, dass Sie sich abmelden wollen!",
    "LoungeContainer.cancel.stay": "Ich möchte Premiumkunde bleiben",
    "LoungeContainer.cancelPremium.error": "Das hat leider nicht geklappt. Bitte versuchen Sie es nochmal.",
    "LoungeContainer.cancelPremium.success": "Ihre Mitgliedschaft wurde beendet",
    "LoungeContainer.datastatus": "Datenstand",
    "LoungeContainer.extensionHint": " ",
    "LoungeContainer.freeMembershipHint": " ",
    "LoungeContainer.goal": "Mein Umsatzziel",
    "LoungeContainer.importantOverview": "Das Wichtigste im Überblick",
    "LoungeContainer.introtext": "Ihre exklusiven Club-Vorteile garantieren Ihnen sorgenfreies Einkaufen!",
    "LoungeContainer.mystatus": "Mein Status",
    "LoungeContainer.orderCount": "Versandkostenfreie Bestellungen",
    "LoungeContainer.orders": "Mein aktueller Jahresumsatz",
    "LoungeContainer.pageHeadline": "{firstName} Flexikonto Vorteilsclub-Seite",
    "LoungeContainer.renewal": "Mitgliedschafts-Verlängerung am",
    "LoungeContainer.retursHint": "* Retouren werden beim Umsatz nicht berücksichtigt.",
    "LoungeContainer.savings": "Meine Ersparnis",
    "LoungeContainer.since": "Flexikonto Club-Vorteile gültig ab",
    "LoungeContainer.until": "Flexikonto Club-Vorteile enden am",
    "Maturities.RVC.DE.minimalPayment": "Gesamt zu zahlen",
    "Maturities.RVC.DE.openPayments": "davon noch offen",
    "Maturities.RVC.DeductedPromotion": "davon Prämie Restschutz",
    "Maturities.RVC.minimalPayment": "aktuelle Mindestrate",
    "Maturities.RVC.openPayments": "Ihre offenen Zahlungen",
    "Maturities.RVC.promotion": "aktuelle Prämie Restschutz",
    "Maturities.RVC.sum": "Ihr fälliger Betrag",
    "Maturities.RVC.sum.Subheadline": "Ihr fälliger Betrag <italic>(inkl. Mindestrate):</italic>",
    "Maturities.accountBalanced": "Ihr Konto ist ausgeglichen",
    "Maturities.bookingsLink": "Alle Buchungen",
    "Maturities.currentBalance": "Kontostand",
    "Maturities.currentMaturities": "Aktuell fällige Zahlungen",
    "Maturities.error": "Entschuldigen Sie bitte, wir können Ihren Kontostand zur Zeit leider nicht anzeigen. <reload>Bitte laden Sie die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuchen Sie es dann später noch einmal. Vielen Dank für Ihr Verständnis.",
    "Maturities.grandTotal": "Gesamt zu zahlen",
    "Maturities.headline.bookings": "Das Wichtigste in Kürze",
    "Maturities.headline.overview": "Rechnungen / Zahlungen",
    "Maturities.hideDetails": "Details verbergen",
    "Maturities.nextMaturities.headline": "Kommende fällige Zahlungen",
    "Maturities.nextMaturity": "fällig am ",
    "Maturities.openBalance": "OFFENER SALDO",
    "Maturities.overdueMaturities": "Überfällige Zahlungen",
    "Maturities.paymentPlanOverview": "Übersicht nächste Zahltermine",
    "Maturities.positiveBalance": "Guthaben",
    "Maturities.showDetails": "Details anzeigen",
    "MinimalMaturities.customAmount": "Auf Ihren Antrag hin wurde der Betrag angepasst",
    "MinimalMaturities.grandTotal": "Minimal zahlbar",
    "MinimalMaturities.maturityDate": "Am {date}{break}wird dieser Betrag{break}eingezogen",
    "MinimalMaturities.monthlyRate": "Monatlicher Betrag",
    "MinimalMaturities.overdue": "Rückstand",
    "MyAccount.Notification.DelayedDeliveryWarning": "Aufgrund der aktuellen Hochwasserlage können unsere Dienstleister nicht alle Gebiete hindernisfrei erreichen. Deshalb kann es in betroffenen Gebieten leider zu einer Verzögerung der Auslieferung kommen.{break}Vielen Dank für dein Verständnis.",
    "MyAccountContainer.accessDenied.error": "Als EU Kunde kann Ihnen leider kein Zugriff auf Mein Konto ermöglicht werden.",
    "Navigation.MyAccount.Bypass.Headline": "Mein Konto Navigation überspringen",
    "Navigation.headline": "Mein Konto",
    "OverviewContainer.Kim.title": "Wie gefällt Ihnen die Übersichtsseite?",
    "OverviewContainer.ReturnsInformation.card.headline": "Rücksendungen",
    "OverviewContainer.ReturnsInformation.date": "Gutschrift angewiesen am:",
    "OverviewContainer.ReturnsInformation.empty.message": "Bei uns ist keine Rücksendung eingegangen.",
    "OverviewContainer.ReturnsInformation.goToAllReturns.text": "Alle Rücksendungen",
    "OverviewContainer.ReturnsInformation.item.number.label": "Art.-Nr.:",
    "OverviewContainer.ReturnsInformation.item.quantity.label": "Menge:",
    "OverviewContainer.ReturnsInformation.item.size.label": "Grösse:",
    "OverviewContainer.ShipmentInformation.card.headline": "Bestellungen auf dem Weg",
    "OverviewContainer.ShipmentInformation.fallback.text": "Leider ist keine Sendung zu Ihnen unterwegs.",
    "OverviewContainer.ShipmentInformation.goToAll": "Alle Bestellungen & Lieferungen",
    "PartialPayment.AccountBalanceHeadline": "Kontostand",
    "PartialPayment.AccountInformationReceiver": "Empfänger",
    "PartialPayment.AllBookings": "Alle Buchungen",
    "PartialPayment.Birthdate": "Bitte überweisen Sie <strong>{currentAmountDue}</strong> bis zum <strong>{currentAmountDueDate}</strong> auf folgendes Konto:",
    "PartialPayment.CardHeadline": "Flexikonto Teilzahlung",
    "PartialPayment.CurrentAmountDue": "Ihr fälliger Betrag <italic>(inkl. Mindestrate):</italic>",
    "PartialPayment.InlineNotification": "Unser Treuebonus für Sie: {break}Sie können noch für bis zu {amountUntilChange} bei Ackermann bestellen – Ihre aktuelle monatliche Mindestrate bleibt gleich!",
    "PartialPayment.InstructionsQr": "Bezahlen Sie bequem über den unten stehenden QR-Code in der App Ihrer Bank:",
    "PartialPayment.MinimumRate": "Aktuelle Mindestrate",
    "PartialPayment.Or": "ODER",
    "PartialPayment.Purpose": "Referenz:",
    "PartialPayment.RsvFee": "davon Prämie Restschutz",
    "PartialPayment.RsvFeeIncluded": "inkl. Prämie Zahlschutz",
    "PartialPayment.ToMyAccountStatements": "Zu meinen Kontoauszügen",
    "PartialPayment.openBalance": "OFFENER SALDO",
    "PasswordForget.error.general": "Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.",
    "PasswordForget.password.change.success": "Passwort erfolgreich geändert.",
    "PaymentPause.CardHeadline": "Flexikonto Zahlpause",
    "PaymentPause.FurtherInstructions": "Alle Informationen zur Flexikonto Zahlpause",
    "PaymentPause.InactivelInstructions": "Wählen Sie die Flexikonto Zahlpause bei Ihrem nächsten Einkauf im Bestellschritt Zahlung und bezahlen Sie erst nach 3 Monaten.*{break}* Aufpreis 4,99% vom Bestellwert. Bonität vorausgesetzt.",
    "PaymentPause.InactivelSubheadline": "Sie haben aktuell keine aktive Zahlpause.",
    "PaymentPause.InitialPurchaseDate": "Einkaufsdatum",
    "PaymentPause.PurchaseAmount": "Betrag",
    "PaymentPause.PurchaseDueDate": "ist fällig am",
    "PaymentPause.PurchaseFrom": "Einkauf vom ",
    "PaymentPause.activeInstructions": "Hier finden Sie eine Übersicht über Ihre aktiven Zahlpausen und dem Fälligkeitsdatum.",
    "PaymentPlan.due": "Fällig zum ",
    "PaymentPlan.empty": "Zum {date} sind keine Zahltermine vorhanden!",
    "PaymentPlan.error": "Ihre Zahlungen können gerade nicht angezeigt werden. Bitte versuchen Sie es in Kürze erneut.",
    "PaymentPlan.headline": "Ihre nächsten Zahlungen",
    "PaymentPlan.hintHeadline": "Bitte beachten Sie:",
    "PaymentPlan.hintText": "Buchungen aus der Gesamtübersicht werden hier auf verschiedene Fälligkeitstermine aufgeteilt. Die einzelnen Rechnungen finden Sie eventuell unter derselben Belegnummer bei unterschiedlichen Fälligkeitsterminen wieder.",
    "PaymentPlan.showInvoice": "Rechnung Nr. {document} ansehen",
    "PaymentProtectionCard.BulletPointHead": "Der Flexikonto Restschutz bietet Ihnen und Ihrem im gemeinsamen Haushalt lebenden Partner in vielen unvorhergesehenen Situationen optimale Sicherheit:",
    "PaymentProtectionCard.CompletePaymentProtection": "Jetzt den Flexikonto Restschutz abschliessen",
    "PaymentProtectionCard.Enabled": "Der Flexikonto Restschutz ist aktiv",
    "PaymentProtectionCard.GoodToKnow": "Gut zu wissen",
    "PaymentProtectionCard.GoodToKnowText": "Der Versicherungsbeitrag beträgt 1,3% des offenen Saldos Ihres Kundenkontos. Besteht kein offener Saldo, muss natürlich nichts bezahlt werden.",
    "PaymentProtectionCard.Headline": "Flexikonto Restschutz",
    "PaymentProtectionCard.OldInsurance0": "Schwere Erkrankungen{break}<small>Schutz bei Krebs, Herzinfarkt, Schlaganfall, Erblindung, Gehörverlust</small>",
    "PaymentProtectionCard.OldInsurance1": "Unfallbedingte Dauerinvalidität{break}<small>Invalidität (ab 50%)</small>",
    "PaymentProtectionCard.OldInsurance2": "Ableben",
    "PaymentProtectionCard.Service": "{phoneIcon} Im Schadensfall sind wir für Sie da: <b><phonelink>0848 85 85 11 </phonelink></b>",
    "PaymentProtectionCard.YoungInsurance0": "Arbeitslosigkeit{break}<small>Schutz bei unverschuldetem Arbeitsplatzverlust</small>",
    "PaymentProtectionCard.YoungInsurance1": "Erwerbsunfähigkeit{break}<small>Schutz bei Krankheit, Berufsunfähigkeit, Invalidität</small>",
    "PaymentProtectionCard.YoungInsurance2": "Pflegebedürftigkeit{break}<small>Pflegegeld Bezug ab der BESA-Stufe 10 oder darüber</small>",
    "PaymentProtectionCard.YoungInsurance3": "Schwere Erkrankungen{break}<small>Schutz bei Krebs, Herzinfarkt, Schlaganfall, Erblindung, Gehörverlust</small>",
    "PaymentProtectionCard.YoungInsurance4": "Ableben",
    "PaymentSettings.Headline": "Gespeicherte Zahlungsdaten",
    "PaymentSettings.confirmDelete": "Löschen kann nicht rückgängig gemacht werden",
    "PaymentSettings.confirmDeleteButton": "löschen bestätigen",
    "PaymentSettings.creditCard": "Kreditkarte",
    "PaymentSettings.creditCard.number": "Kartennummer",
    "PaymentSettings.creditCard.validThru": "Gültig bis",
    "PaymentSettings.deleteButton": "löschen",
    "PaymentSettings.directDebit": "Bankeinzug",
    "PaymentSettings.directDebit.download": "SEPA Mandat herunterladen",
    "PaymentSettings.directDebit.new": "SEPA-Lastschriftmandat",
    "PaymentSettings.empty.addDirectDebit": "Bankeinzug anlegen",
    "PaymentSettings.empty.hint.headline": "Schneller & sicher einkaufen",
    "PaymentSettings.empty.hint.text": "Sie können im Zuge einer Bestellung Ihre Kreditkartendaten hinterlegen, sodass sie hier gespeichert werden. So ist es beim nächsten Einkauf nicht mehr notwendig, alle Daten erneut einzugeben und Sie sparen Zeit.",
    "PaymentSettings.hint.headline": "Woher stammen die Daten?",
    "PaymentSettings.hint.text": "Hier finden Sie die Daten, die Sie für weitere Einkäufe hinterlegt haben. Per Klick auf „löschen“ können Sie die Daten natürlich endgültig entfernen.",
    "PaymentSettings.successDeleteCreditCard": "Kreditkartendaten wurden erfolgreich gelöscht",
    "PaymentSettings.successDeleteDirectDebit": "Bankdaten wurden erfolgreich gelöscht",
    "PersonalCoupon.ActiveChip": "Gutschein angewendet",
    "PersonalCoupon.ActiveHint": "Der Gutschein wird in Schritt 5 „Prüfen und Bestellen“ angewendet.",
    "PersonalCoupon.Button": "Gutschein anwenden",
    "PersonalCoupon.ConditionsHeadline": "*Gutscheinbedingungen",
    "PersonalCoupon.Header": "Dein Dankeschön:",
    "PersonalData.Birthdate": "Geburtsdatum",
    "PersonalData.BusinessPartnerNo": "Kundennummer",
    "PersonalData.EmailAddress": "E-Mail-Adresse",
    "PersonalData.Headline": "Persönliche Daten",
    "PersonalData.Iban": "IBAN",
    "PersonalData.InvoiceAddress": "Rechnungsanschrift",
    "PersonalData.Password": "Passwort",
    "PersonalData.PasswordHint": "Das Passwort wird aus Sicherheitsgründen nicht angezeigt.",
    "PersonalData.Phone": "Telefon",
    "PersonalData.Verified.Text": "Bestätigt",
    "PersonalData.addressmodal.headline": "Neue Adresse?",
    "PersonalData.change": "ändern",
    "PersonalData.changepassword.success": "Passwort erfolgreich geändert.",
    "PersonalData.emailmodal.headline": "Neue E-Mail-Adresse?",
    "PersonalData.ibanmodal.headline": "IBAN ändern",
    "PersonalData.passwordmodal.headline": "Neues Passwort?",
    "PersonalData.phone.fixed": "Festnetz",
    "PersonalData.phone.mobile": "Handy",
    "PersonalData.phonemodal.headline": "Neue Telefonnummer?",
    "PersonalDataContainer.Kim.title": "Wie gefällt Ihnen die Persönliche Daten Seite?",
    "PrepaymentHint.text": "Die Bestellung wird erst bearbeitet, wenn eine Anzahlung in Höhe von {amount} bei uns eingegangen ist. Hinweis: Bestellte Artikel sind solange nicht reserviert. {space} Unsere Bankverbindung: {space} Ackermann Vertriebs AG {break} Industriestrasse 19 {break} 8112 Otelfingen {break} QR IBAN: CH30 3000 0001 6059 5151 6 {break} Bitte tragen Sie bei einer E-Banking Überweisung im Feld Referenz Ihre Referenznummer <strong>{bankTransferReference}</strong> ein.",
    "ProductNotFoundHint.copy": "Ältere Bestelldaten können aus technischen Gründen leider nicht angezeigt werden. Versendete Artikel finden Sie aber unter <a>Buchungen</a>.",
    "ProductNotFoundHint.title": "Artikel nicht gefunden?",
    "ReclamationForm.Article.Submit": "Übernehmen",
    "ReclamationForm.FormCancel": "Abbrechen",
    "ReclamationForm.FormSubmit": "Abschicken",
    "ReclamationForm.Information": "Bitte nutzen Sie dieses Formular nur für die Meldung von defekten oder beschädigten Artikeln, die repariert oder begutachtet werden sollen. Für alles andere nutzen Sie bitte unser Kontaktformular unter ",
    "ReclamationForm.InvoiceInfo": "Ihre Reklamation zur Rechnung {invoiceNumber} vom {invoiceDate}",
    "ReclamationForm.Replacement.Headline": "Benötigen Sie ein Ersatzteil? Ergänzen Sie bitte folgende Angaben",
    "ReclamationForm.Shipping.Headline": "Ist Ihre Lieferadresse abweichend von Ihrer Kontoanschrift?",
    "ReclamationForm.Shipping.Submit": "Übernehmen",
    "ReclamationForm.Submit.Error": "Ihre Reklamation konnte leider gerade nicht durchgeführt werden. Versuchen Sie es doch später erneut!",
    "ReclamationForm.articleDescription.required": "Die Artikelbeschreibung wird benötigt!",
    "ReclamationForm.articleNumber.required": "Die Artikelnummer wird benötigt!",
    "ReclamationForm.city.required": "Der Ort wird benötigt!",
    "ReclamationForm.email.required": "Die E-Mail-Adresse wird benötigt!",
    "ReclamationForm.emailAddress": "E-Mail:",
    "ReclamationForm.error": "Sie sind aktuell nicht angemeldet! Melden Sie sich doch bitte an!",
    "ReclamationForm.error.invalidData": "Wir konnten Ihre Daten aktuell nicht finden. Versuchen Sie es doch später erneut!",
    "ReclamationForm.firstName.required": "Der Vorname wird benötigt!",
    "ReclamationForm.headlineArticle": "Artikel und Bestellinformationen",
    "ReclamationForm.headlineCustomer": "Kundeninformation",
    "ReclamationForm.headlineShipping": "Lieferadresse (falls abweichend von Kontoanschrift)",
    "ReclamationForm.houseNumber.required": "Die Hausnummer wird benötigt!",
    "ReclamationForm.labelArticleDescription": "Artikelbeschreibung:",
    "ReclamationForm.labelArticleNumber": "Artikelnummer:",
    "ReclamationForm.labelBestReachedPhoneNumber": "am besten erreichbar:",
    "ReclamationForm.labelCity": "Ort:",
    "ReclamationForm.labelCustomerNo": "Kundennummer:",
    "ReclamationForm.labelDataTypeShield": "Daten-Typenschild:",
    "ReclamationForm.labelFirstName": "Vorname:",
    "ReclamationForm.labelHouseNumber": "Hausnummer:",
    "ReclamationForm.labelLastName": "Nachname:",
    "ReclamationForm.labelReclamationDescription": "Mängelbeschreibung:*",
    "ReclamationForm.labelReclamationReason": "Reklamationsgrund:",
    "ReclamationForm.labelReplacementNumber": "Ersatzteilnummer:",
    "ReclamationForm.labelStreet": "Strasse:",
    "ReclamationForm.labelZipCode": "PLZ:",
    "ReclamationForm.lastName.required": "Der Nachname wird benötigt!",
    "ReclamationForm.primaryPhoneNumber": "Telefon:",
    "ReclamationForm.reclamationDescription.required": "Die Mängelbeschreibung wird benötigt!",
    "ReclamationForm.reclamationReasonDamaged": "Artikel defekt",
    "ReclamationForm.reclamationReasonIncomplete": "Artikel unvollständig",
    "ReclamationForm.reclamationReasonOther": "Sonstiges",
    "ReclamationForm.reclamationReasonPlaceholder": "Bitte auswählen",
    "ReclamationForm.reclamationReasonWrongPrice": "Falscher Preis",
    "ReclamationForm.reclamationReasonWrongProduct": "Falscher Artikel geliefert",
    "ReclamationForm.street.required": "Die Strasse wird benötigt!",
    "ReclamationForm.zipCode.required": "Die PLZ wird benötigt!",
    "ReturnDeclaration.CTA.Button.Label": "Weitere Sendungsnummer hinzufügen",
    "ReturnDeclaration.Description": "Dein Paket ist bereits auf dem Weg zu uns?{br}Dann teile uns einfach deine Tracking- bzw. Sendungsnummer mit.",
    "ReturnDeclaration.Disclaimer": "Speditionsartikel sind leider ausgeschlossen.",
    "ReturnDeclaration.Headline": "Rücksendung aufgegeben?",
    "ReturnDeclaration.InfoBox.Headline": "Wo finde ich meine Sendungsnummer?",
    "ReturnDeclaration.InfoBox.Text": "Du findest deine Tracking- bzw. Sendungsnummer auf dem Beleg, den du bei der Abgabe deiner Rücksendung bekommen hast.",
    "ReturnDeclaration.Submit.Button.Label": "Sendungsnummer abschicken",
    "ReturnDeclarationCard.DateInput.Error": "Das Abgabedatum deiner Rücksendung liegt zu weit zurück",
    "ReturnDeclarationCard.DateInput.Format.Error": "Bitte korrigiere das Datum (Format: TT.MM.JJJJ)",
    "ReturnDeclarationCard.DateInput.Future.Error": "Verwende {date} oder einen früheren Wert",
    "ReturnDeclarationCard.DateInput.Label": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Content": "Mit dem Datum können wir deine Sendungsdaten eindeutig in unserer Datenbank zuordnen.",
    "ReturnDeclarationCard.InfoModal.Headline": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Subheadline": "Warum benötigen wir das Abgabedatum?",
    "ReturnDeclarationCard.Input.Label": "Sendungsnummer",
    "ReturnDeclarationCard.Input.Placeholder": "z.B. 123456789",
    "ReturnDeclarationCard.MissingInput.InlineError": "Bitte vervollständige deine Eingabe oder lösche diese",
    "ReturnDeclarationCard.Return.Eighth": "Achte Rücksendung",
    "ReturnDeclarationCard.Return.Fifth": "Fünfte Rücksendung",
    "ReturnDeclarationCard.Return.First": "Rücksendung",
    "ReturnDeclarationCard.Return.Fourth": "Vierte Rücksendung",
    "ReturnDeclarationCard.Return.Ninth": "Neunte Rücksendung",
    "ReturnDeclarationCard.Return.Second": "Zweite Rücksendung",
    "ReturnDeclarationCard.Return.Seventh": "Siebte Rücksendung",
    "ReturnDeclarationCard.Return.Sixth": "Sechste Rücksendung",
    "ReturnDeclarationCard.Return.Tenth": "Zehnte Rücksendung",
    "ReturnDeclarationCard.Return.Third": "Dritte Rücksendung",
    "ReturnDeclarationContainer.Success.Description": "Da wir nun wissen, dass dein Paket auf dem Weg zu uns ist, können wir dies direkt in unseren Systemen vormerken.{br}{br}Wir melden uns bei dir, wenn dein Paket bei uns eingegangen ist!",
    "ReturnDeclarationContainer.Success.GoToShop": "Zurück zum Shop",
    "ReturnDeclarationContainer.Success.Headline": "Vielen Dank!",
    "ReturnDeclarationContainer.accessDenied.error": "Dieser Link ist leider nicht gültig.",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Bring": "So können Sie Ihren Artikel an uns zurückschicken",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Pickup": "So können Sie Ihren Speditionsartikel an uns zurückschicken",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Bring": "Benutzen Sie ganz einfach das Rücksende-Etikett, welches Ihrer Sendung beiliegt. Ansonsten kontaktieren Sie bitte unseren Kundenservice unter <link>{emailAddress}</link> oder unter {phoneNumber}.",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Pickup": "Kontaktieren Sie bitte unseren Kundenservice unter {phoneNumber} zur Abholung Ihrer Rücksendung.",
    "ReturnShipmentInfo.InstructionsForReturn.emailAddress": "service@ackermann.ch",
    "ReturnShipmentInfo.InstructionsForReturn.errorToast": "Ups. Etwas ist schief gelaufen. {break} Probieren Sie später noch einmal oder wenden Sie sich unter {phoneNumber} an unseren Kundenservice.",
    "ReturnShipmentInfo.InstructionsForReturn.href": "/service-hilfe/ueber-uns/kontakt",
    "ReturnShipmentInfo.InstructionsForReturn.noteHeading": "Bitte beachten:",
    "ReturnShipmentInfo.InstructionsForReturn.noteMessage": "Um Ihre Rücksendung so rasch wie möglich abzuwickeln senden Sie bitte nur Artikel aus derselben Lieferung in einem Päckli an uns zurück. Damit können wir Ihre Lieferung eindeutig zuordnen. {break} Sie möchten verschiedene Lieferungen zurücksenden?  Bitte verwenden Sie Ihr jeweils erhaltenes Päckli dafür.",
    "ReturnShipmentInfo.InstructionsForReturn.packingHeading": "1. Verpacken",
    "ReturnShipmentInfo.InstructionsForReturn.packingMessage": "Verpacken Sie bitte Ihren Artikel in das Paket - Idealerweise in das bereits von uns verwendete. Vergessen Sie auch nicht, den Lieferschein mit den Retourengründen beizulegen.",
    "ReturnShipmentInfo.InstructionsForReturn.parcelHeading": "3. PaketShop",
    "ReturnShipmentInfo.InstructionsForReturn.parcelMessage": "Geben Sie Ihre Rücksendung in einer Post- oder einer Päckli Punkt-Filiale ab.",
    "ReturnShipmentInfo.InstructionsForReturn.phoneNumber": "0848 85 85 11",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelHeading": "2. Rücksende-Etikett",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage": "Sie können hier ein neues Rücksende-Etikett erstellen lassen, das Sie ausdrucken und auf das Paket kleben können. Den Rest erledigt eine Filiale der Post oder Päckli Punkt für Sie.",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage2": "<semiBold>Bitte beachten: </semiBold> Wenn Sie das dem Paket beigelegte Rücksende-Etikett schon genutzt haben, wenden Sie sich bitte an unseren Kundenservice unter {phoneNumber} oder {emailAddress} für ein neues Etikett. Nur so kommt Ihre Rücksendung auch wirklich bei uns an.",
    "ReturnShipmentInfo.InstructionsForReturn.relaxHeading": "4. Entspannen",
    "ReturnShipmentInfo.InstructionsForReturn.relaxMessage": "Danach können Sie sich entspannt zurücklehnen. Sobald Ihre Rücksendung von uns bearbeitet wurde, informieren wir Sie per E-Mail und erstatten Ihnen bereits gezahlte Beträge.",
    "ReturnShipmentInfo.modalMainHeader.Before": "Rücksende-Etikett erstellen",
    "ReturnShipmentInfo.modalMainHeader.Bring": "Artikel zurückschicken",
    "ReturnShipmentInfo.modalMainHeader.Online": "Rücksende-Etikett erstellen",
    "ReturnShipmentInfo.modalMainHeader.Pickup": "Speditionsartikel zurückschicken",
    "ReturnShipmentInfo.modalSubHeader.Before": "So können Sie Ihren Artikel an uns zurückschicken",
    "ReturnShipmentInfo.modalSubHeader.Online": "So können Sie Ihren Artikel an uns zurückschicken",
    "ReturnShipmentInfo.phoneNumber": "0848 85 85 11",
    "ReturnShipmentInfo.returnType.Before": "Rücksende-Etikett anzeigen",
    "ReturnShipmentInfo.returnType.Bring": "Zurück zu meinen Bestellungen",
    "ReturnShipmentInfo.returnType.Online": "Rücksende-Etikett anzeigen",
    "ReturnShipmentInfo.returnType.Pickup": "Zurück zu meinen Bestellungen",
    "ServiceContactTile.callUs": "Rufen Sie uns an",
    "ServiceContactTile.questions": "Haben Sie Fragen?",
    "ServiceContactTile.write": "Schreiben Sie uns",
    "ServiceContactTile.yourCustomerNumber": "Ihre Kundennummer",
    "ShipmentCardAction.RecoSlider.headline": "Empfehlungen für Sie",
    "ShipmentCardAction.similarproducts.link": "Ähnliche Artikel",
    "ShipmentCardDetails.hint.delivered": "Details zum Standort Ihres Pakets finden Sie über die Sendungsverfolgung.",
    "ShipmentCardDetails.hint.downpayment": "Bitte beachten Sie unsere Anzahlungshinweise an den einzelnen Artikeln",
    "ShipmentCardDetails.label.carrier": "Versandpartner",
    "ShipmentCardDetails.label.deliveryAddress": "Lieferanschrift",
    "ShipmentCardDetails.label.deliveryService": "Lieferservice",
    "ShipmentCardDetails.label.hint": "Hinweis",
    "ShipmentCardDetails.label.invoiceLink": "Rechnung ansehen",
    "ShipmentCardDetails.label.noTrackingAvailable": "Es sind keine Track & Trace-Informationen verfügbar",
    "ShipmentCardDetails.label.numberOfPackages": "Paketanzahl",
    "ShipmentCardDetails.label.orderDate": "Bestelldatum",
    "ShipmentCardDetails.label.packageType": "Verpackungsart",
    "ShipmentCardDetails.label.paymentType": "Zahlungsart",
    "ShipmentCardDetails.label.trackingId": "Sendungs ID",
    "ShipmentCardDetails.link.desiredDelivery": "Wunschzustellung {optPackageNo}",
    "ShipmentCardDetails.link.returnLabelShow": "Rücksende-Etikett anzeigen",
    "ShipmentCardDetails.link.tracking": "Sendungsverfolgung {optPackageNo}",
    "ShipmentCardDetails.package.indicator": "(Paket {current} von {total})",
    "ShipmentCardDetails.returnLabelCreated": "Rücksende-Etikett erstellt",
    "ShipmentCardDetails.trackingUrlError": "Etwas ist schief gelaufen. Bitte versuche es später erneut.",
    "ShipmentInfoCard.details.headline": "Bestelldetails & Sendungsverfolgung",
    "ShipmentInfoHeader.headline": "Meine Bestellungen und Lieferungen",
    "ShipmentInfoHeader.text": "Verschaffen Sie sich einen Überblick, welchen Status Ihre Bestellungen und Lieferungen haben.",
    "ShipmentInfoTypeSelect.option.all": "Alle",
    "ShipmentInfoTypeSelect.option.canceled": "Storniert",
    "ShipmentInfoTypeSelect.option.delivered": "Zugestellt",
    "ShipmentInfoTypeSelect.option.label": "Sendungsstatus:",
    "ShipmentInfoTypeSelect.option.ordered": "Bestellt",
    "ShipmentInfoTypeSelect.option.returned": "Zurückgeschickt",
    "ShipmentInfoTypeSelect.option.sent": "Unterwegs",
    "ShipmentInfos.cancel.error.message": "Beim Stornieren der Bestellung ist ein Fehler aufgetreten.",
    "ShipmentInfos.headline": "Bestellungen schneller finden:",
    "ShipmentInfos.loadMore": "weitere laden",
    "ShipmentInfos.nodata-hint": "Bitte wählen Sie den gewünschten Status oder Zeitraum.",
    "ShipmentInfos.request.error.message": "Beim Laden der Bestellungen ist ein Fehler aufgetreten.",
    "ShipmentInfosContainer.Kim.title": "Wie gefällt Ihnen die Bestellungen und Lieferungen Seite?",
    "ShipmentInfosProductView.label.amount": "Anzahl",
    "ShipmentInfosProductView.modal.message.canceled.main": "Ihr Artikel wurde storniert.",
    "ShipmentInfosProductView.modal.message.canceled.sub": "Beim nächsten Mal finden Sie bestimmt etwas Passendes für Sie.",
    "ShipmentInfosProductView.modal.title": "Möchten Sie wirklich stornieren?",
    "ShipmentInfosProductView.modal.title.success": "Stornierung erfolgreich",
    "ShipmentInfosProductView.productLink": "zum Artikel",
    "ShipmentInfosShipmentListType.all": "Alle",
    "ShipmentInfosShipmentListType.awaiting_downpayment": "Warten auf Anzahlung",
    "ShipmentInfosShipmentListType.canceled": "Storniert",
    "ShipmentInfosShipmentListType.delivered": "Zugestellt",
    "ShipmentInfosShipmentListType.in_delivery": "In Lieferung",
    "ShipmentInfosShipmentListType.ordered": "Bestellt",
    "ShipmentInfosShipmentListType.returnLabel": "Rücksende-Etikett",
    "ShipmentInfosShipmentListType.return_shipment": "Rücksende-Etikett",
    "ShipmentInfosShipmentListType.returned": "Zurückgeschickt",
    "ShipmentInfosShipmentListType.returnlabel_created": "Rücksende-Etikett erstellt",
    "ShipmentInfosShipmentListType.sent": "Unterwegs",
    "ShipmentInfosShipmentListType.shipment_prepared": "Versand vorbereitet",
    "ShipmentTimeRangeSelect.option.all": "Alle",
    "ShipmentTimeRangeSelect.option.days": "{amount} Tage",
    "ShipmentTimeRangeSelect.option.label": "Zeitraum:",
    "ShipmentTimeRangeSelect.option.weeks": "{amount} Wochen",
    "VerificationNotification.Text": "Bestätigen Sie Ihre E-Mail-Adresse, um die <strong>Sicherheit Ihres Kontos zu erhöhen</strong>.",
    "VerificationNotification.VerifyAgainCTA": "Mail erneut anfordern",
    "VerificationNotification.VerifyAgainText": "Wir haben Ihnen einen <strong>Bestätigungslink</strong> an <strong>{email}</strong> geschickt. Sie haben keine Mail bekommen? Sehen Sie ggf. noch mal im Spamordner nach. Oder Sie können ",
    "VerificationNotification.VerifyCTA": "E-Mail Adresse bestätigen",
    "VerificationNotification.error": "Das hat leider nicht funktioniert. Versuchen Sie es bitte noch einmal.",
    "blackFridayNotificationText": "Aufgrund des sehr hohen Bestelleingangs bitten wir um Verständnis, dass die Zustellung momentan 2-3 Tage länger dauern kann!",
    "meta.title.addressbook": "Mein Adressbuch | Mein Konto",
    "meta.title.bankstatements": "Meine Kontoauszüge | Mein Konto",
    "meta.title.bookings": "Meine Buchungen | Mein Konto",
    "meta.title.callcenterLogin": "Callcenter Login | Mein Konto",
    "meta.title.default": "Mein Konto",
    "meta.title.flexikonto": "Mein Flexikonto | Mein Konto",
    "meta.title.overview": "Übersicht | Mein Konto",
    "meta.title.passwordForget": "Passwort vergessen",
    "meta.title.personaldata": "Meine persönlichen Daten | Mein Konto",
    "meta.title.premiumlounge": "Meine Lounge | Mein Konto",
    "meta.title.shipmentinfos": "Meine Bestellungen & Lieferungen | Mein Konto",
    "AccountInformation.bic": "BIC: <split>DRESDEFF760</split>",
    "AccountInformation.iban": "QR IBAN: <split>CH30 3000 0001 6059 5151 6</split>",
    "AccountInformation.receiverAdditionals": "Industriestrasse 19 {break} 8112 Otelfingen",
    "AccountInformation.receiverName": "Ackermann Vertriebs AG",
    "ServiceContactTile.emailAddress": "service@<wbr />ackermann.ch",
    "ServiceContactTile.emailAddressEmployee": "service@<wbr />ackermann.ch",
    "ServiceContactTile.phoneNumber": "0848 85 85 11",
    "ServiceContactTile.phoneNumberEmployee": "0848 85 85 11",
    "ServiceContactTile.phoneTimes": "täglich von 7 bis 22 Uhr",
    "ServiceContactTile.phoneTimesEmployee": "täglich von 7 bis 22 Uhr"
  },
  "fr-CH": {
    "APlusList.headline": "Vos services déjà réservés",
    "AccountInformation.headline": "Nos coordonnées bancaires",
    "AccountInformation.qrPayment.Or": "OU",
    "AccountInformation.qrPayment.infoText": "Utilisez le bulletin de versement QR via le lien ci-dessous:",
    "AccountInformation.receiverNameWithBank": "Bankname",
    "AccountInformation.receiverTitle": " ",
    "AccountInformation.subject": "Veuillez saisir votre numéro de référence <strong>{bankTransferReference}</strong> dans le champ Référence lors d'un virement e-banking.",
    "AccountSettings.Autologin": "Connexion automatique",
    "AccountSettings.Autologin.Headline": "Activez la connexion automatique.",
    "AccountSettings.Autologin.Text": "connexion automatique lors de votre prochaine visite",
    "AccountSettings.Delivery": "Zustellung",
    "AccountSettings.Headline": "Paramètres",
    "AccountSettings.MoreInformation": "plus d'informations",
    "AccountSettings.NeighboursDelivery.Headline": "An Nachbar zustellen:",
    "AccountSettings.NeighboursDelivery.Infotext": "Der Zusteller kann mein Paket beim Nachbarn abgeben, wenn ich nicht zu Hause bin. Eine Änderung dieser Lieferpräferenz ist nur für Artikel möglich, die noch nicht in Rechnung gestellt wurden.",
    "AccountSettings.NeighboursDelivery.Text": "In deiner Abwesenheit kannst du Pakete auch an Nachbarn zustellen lassen",
    "AccountSettings.Newsletter.Headline": "Newsletter",
    "AccountSettings.Newsletter.HeadlineModal": "Newsletter",
    "AccountSettings.Newsletter.Infotext": "Des nouvelles gratuites sur les économies, les tendances de la mode, des chaussures et de la maison, les soldes et les promotions, pour être sûr de ne rien manquer.",
    "AccountSettings.Newsletter.Text": "Recevez des informations sur les tendances, les promotions et les idées inspirantes.",
    "AccountSettings.Notifications": "Notifications",
    "AccountSettings.PostalBankStatement.Headline": "Postalische Kontoauszüge:",
    "AccountSettings.PostalBankStatement.Infotext": "TODO",
    "AccountSettings.PostalBankStatement.Text": "Lasse dir Kontoauszüge per Post zuschicken (zzg. 1,50 €)",
    "AccountSettings.ServiceMail.Headline": "Informations sur les services",
    "AccountSettings.ServiceMail.Text": "Ainsi, vous ne manquerez aucune information sur vos commandes.",
    "AccountSettings.ServiceMail.entry0": "Confirmation de l'envoi",
    "AccountSettings.ServiceMail.entry1": "Confirmation des retours",
    "AccountSettings.ServiceMail.entry2": "Confirmation de la réception du paiement",
    "AccountSettings.ServiceMail.entry3": "E-mails d'évaluation",
    "AccountSettings.ServiceMail.entry4": "Demandes d'avances",
    "AccountSettings.ServiceMail.entry5": "-",
    "AccountSettings.ServiceMail.entry6": "-",
    "AccountSettings.ServiceMail.entry7": "-",
    "AccountSettings.ServiceMail.entry8": "-",
    "AccountSettings.ServiceMail.entry9": "-",
    "AccountSettings.ServiceMailInfo.Headline": "Nous vous tiendrons au courant de tous les sujets concernant votre commande par e-mail. Ainsi, vous ne manquerez rien. Vous recevrez par exemple",
    "AccountSettings.SmsNotification.AddMobileNumber": "Handynummer hinzufügen",
    "AccountSettings.SmsNotification.ChangeMobileNumber": "Handynummer ändern",
    "AccountSettings.SmsNotification.Headline": "SMS Genehmigung:",
    "AccountSettings.SmsNotification.Infotext": "Wenn ich bei der Bestellung die Lieferung an einen PostNL-Punkt wähle oder einen großen Artikel von Dynalogic zu mir nach Hause liefern lasse, möchte ich bei Lieferung meiner Bestellung eine kostenlose SMS erhalten.",
    "AccountSettings.SmsNotification.MobilePhoneNumber": "Gewählte Handynummer:{break}{phoneNumber}",
    "AccountSettings.SmsNotification.Text": "Erhalte Benachrichtigungen per SMS",
    "AccountSettings.eInvoice.Headline": "Facture en ligne",
    "AccountSettings.eInvoice.InfoBenefits": "<ul><li><strong>Toujours à jour & clair:</strong> Votre facture en ligne se met automatiquement à jour en ligne, de sorte que vous disposez toujours d'une facture actuelle et claire.</li><li><strong>Archivé en toute sécurité:</strong> Dans votre compte client, vous trouverez un aperçu de vos factures sur 10 ans.</li><li><strong>Flexible & pratique:</strong> vous avez toujours accès à votre facture en ligne, où que vous soyez. Vous pouvez facilement imprimer et télécharger votre facture en ligne.</li></ul>",
    "AccountSettings.eInvoice.Infotext": "Vos avantages avec la facture en ligne",
    "AccountSettings.eInvoice.Text": "Optez pour l'environnement et contre les factures papier.",
    "Aplus.included": "y compris",
    "BankstatementsContainer.Overview.downloadCardButton": "Télécharger",
    "BankstatementsContainer.Overview.downloadCardDescription": "Pour voir la répartition complète, téléchargez ce relevé bancaire au format PDF (gratuit)",
    "BankstatementsContainer.Overview.downloadCardTitle": "Télécharger ce relevé bancaire",
    "BankstatementsContainer.Overview.downloadCardWarning": "Malheureusement, une erreur s'est produite lors du téléchargement. Veuillez réessayer plus tard.",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEnd": "Solde final",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEndOfPeriod": "Solde (fin du mois)",
    "BankstatementsContainer.Overview.overviewPeriodBallanceNew": "Nouveau solde",
    "BankstatementsContainer.Overview.overviewPeriodBallancePreviousPeriod": "Solde de la période précédente",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStart": "Solde initial",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStartOfPeriod": "Solde (début du mois)",
    "BankstatementsContainer.Overview.overviewPeriodEmpty": "Vous ne disposez pas d'un relevé de compte récent. Pour les anciens relevés de compte, sélectionnez la période souhaitée dans la liste déroulante.",
    "BankstatementsContainer.Overview.overviewPeriodLabel": "Sélectionner la période",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionMain": "Relevés bancaires postaux",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionSecondary": "Recevez vos relevés bancaires par courrier (1,50 € en supplément)",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMore": "En savoir plus",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreContent": "TODO",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreHeadline": "Postalische Kontoauszüge:",
    "BankstatementsContainer.Overview.perEmailReceiveCardTitle": "Recevoir un relevé bancaire par courrier papier",
    "BankstatementsContainer.Overview.title": "Aperçu des réservations",
    "BankstatementsContainer.introtext": "Vous trouverez ici vos relevés bancaires.{break}Toutes les réservations et le solde de votre compte courant se trouvent dans votre compte client.",
    "BankstatementsContainer.title": "Mes relevés bancaires",
    "BookingContainer.Kim.title": "Comment trouvez-vous l'espace \"Mon compte\"?",
    "BookingContainer.bookingOverview.headline": "Aperçu des réservations",
    "BookingContainer.bookings.headline": "Mes réservations",
    "BookingListContainer.BookingList.defaultAsyncErrorMessage": "Veuillez nous excuser, nous ne pouvons pas récupérer vos réservations pour le moment. <reload>Veuillez recharger la page!</reload> Si l'erreur persiste, nous avons un problème technique interne et nous sommes déjà en train de le résoudre. Veuillez réessayer plus tard. Nous vous remercions de votre compréhension.",
    "BookingListContainer.BookingList.emptyBookingListHeadline": "Pas de réservations?",
    "BookingListContainer.BookingList.emptyBookingListText": "Alors vous n'avez pas encore commandé chez nous - parcourez notre large gamme de produits et sécurisez votre nouvel article préféré",
    "BookingListContainer.BookingList.fullViewSelectionText": "Toutes les réservations",
    "BookingListContainer.BookingList.invoiceViewSelectionText": "Factures",
    "BookingListContainer.BookingList.loadMorebutton": "charger plus",
    "BookingListContainer.BookingList.paymentViewSelectionText": "Paiements",
    "BookingListContainer.BookingList.reimbursementViewSelectionText": "Retours",
    "BookingListElement.from": "du",
    "CCLogin.email.label": "Adresse e-mail",
    "CCLogin.email.placeholder": "nom@fournisseur.ch",
    "CCLogin.label.login": "s'inscrire",
    "CCLogin.password.placeholder": "****",
    "CCLogin.title": "inscription au centre d'appels",
    "CCLoginAsCustomer.customerNoLabel": "Numéro de client",
    "CCLoginAsCustomer.dobLabel": "Date de naissance",
    "CCLoginAsCustomer.submitLabel": "s'inscrire comme client",
    "CCLoginAsCustomer.title": "inscription comme client",
    "CallcenterLoginContainer.CCADFSLoginResponse.errorMessage": "Deine Anfrage konnte nicht verarbeitet werden. Versuche es bitte erneut!",
    "CancelShipmentAction.label.cancel": "avorter",
    "CancelShipmentAction.label.cancelOrder": "annuler l'article",
    "CancelShipmentAction.label.cancelOrder.confirm": "annuler",
    "CancelShipmentAction.label.close": "fermer",
    "CancelShipmentAction.modal.title.confirm": "confirmer l'annulation",
    "ChangeIban.cancel": "annuler",
    "ChangeIban.defaultError": "Une erreur technique s'est produite",
    "ChangeIban.headline": "Modifier l'IBAN",
    "ChangeIban.inputLabel": "Nouvel IBAN",
    "ChangeIban.inputPlaceholder": "FR000000000000000000000000",
    "ChangeIban.noDataError": "Les données ont été enregistrées, mais ne peuvent pas être affichées",
    "ChangeIban.save": "Enregistrer les modifications",
    "ChangePassword.Howto.headline": "Un mot de passe sûr, c'est quoi?",
    "ChangePassword.Howto.list.1": "Combinez les minuscules, majuscules, chiffres et caractères spéciaux",
    "ChangePassword.Howto.list.2": "Choisissez au moins huit à dix caractères",
    "ChangePassword.Howto.list.3": "N'utilisez pas de noms, ni des dates de naissance",
    "ChangePassword.Howto.list.4": "Il vaut mieux éviter les suites telles que \"abc\", \"azerty\" ou \"123\"",
    "ChangePassword.Howto.list.5": "N'utilisez pas plusieurs fois le même mot de passe",
    "ChangePassword.Howto.text": "Les mots de passe tels que \"123456\", \"salut\" ou \"mot de passe\" sont très populaires, mais sont très peu sécurisés. En suivant ces cinq conseils, vous pourrez créer un bon mot de passe:",
    "ChangePassword.SecurityHint.headline": "La sécurité est importante pour nous",
    "ChangePassword.SecurityHint.text": "Veuillez entrer votre nouveau mot de passe: (Entre {minLength} et {maxLength} caractères! Le mot de passe doit se composer de lettres, chiffres et caractères spéciaux.)",
    "ChangePassword.errorFromBackend": "Votre demande ne peut être traitée pour le moment. Veuillez réessayer plus tard.",
    "ChangePassword.new.label": "Votre nouveau mot de passe *",
    "ChangePassword.passwordsDontMatch": "Les mots de passe ne correspondent pas.",
    "ChangePassword.passwordstooLong": "Le mot de passe spécifié est trop long ({maxLength} caractères maximum)",
    "ChangePassword.passwordstooShort": "Le mot de passe doit contenir au moins {minLength} caractères.",
    "ChangePassword.placeholder": "{minLength}-{maxLength} caractères",
    "ChangePassword.repeat.label": "Confirmation mot de passe *",
    "ChangePassword.submit": "Accepter un nouveau mot de passe",
    "CircleStats.innerTextType.toPay": "Payable avant le {date}:",
    "CircleStats.innerTextType.withdraw": "Le {date}, {pause}, ce montant {pause} sera collecté :",
    "CircleStats.sublineTextType.increasedDueToCustomersWish": "À votre demande, le montant de la {pause} a été ajusté.",
    "CircleStats.sublineTextType.increasingPossible": "Vous pouvez toujours{break} payer plus sans{break} frais supplémentaires.",
    "ContactFormLink": "<link>https://www.ackermann.ch/fr/aide-service/qui-sommes-nous/contactez-nous</link>",
    "CustomerInformation.BusinessPartnerNo": "Numéro de client: {businessPartnerNo}",
    "CustomerInformation.LogoutText": "Vous n'êtes pas {name}? Se déconnecter ici",
    "CustomerInformation.PersonalDataLink": "Mes données personnelles",
    "CustomerInformation.PremiumRenewalDate": "(verlängert sich am {placeholder})",
    "DataExport.Button": "Télécharger les données",
    "DataExport.DisabledButton": "Exportation... ",
    "DataExport.DownloadError": "Une erreur s'est malheureusement produite pendant l'exportation. Réessayez ultérieurement.",
    "DataExport.DownloadSuccess": "Vos données sont en cours de téléchargement.",
    "DataExport.Headline": "Exportation de données",
    "DataExport.NoDownloadText": "Vous pouvez bien sûr télécharger vos données personnelles à tous moments – sans répercussions sur votre compte client.{break}{break}Cette fonction n'est disponible que dans le navigateur. Il suffit de se connecter avec son téléphone portable ou son ordinateur avec le navigateur pour télécharger le fichier.",
    "DataExport.Subheadline": "Vos données, votre affaire.",
    "DataExport.Text": "Vous pouvez bien sûr télécharger vos données personnelles à tous moments – sans répercussions sur votre compte client.{break}{break}Tout reste utilisable pour vous comme d'habitude et enregistré chez nous. Vous pouvez ainsi archiver les données ou les utiliser pour un autre service.",
    "EInvoice.Download.Button": "Télécharger et imprimer",
    "EInvoice.Download.DisabledButton": "Veuillez patienter...",
    "EInvoice.Download.Error": "Malheureusement, une erreur s'est produite pendant le téléchargement. Veuillez réessayer plus tard.",
    "EInvoice.aplus.headline": "Services déjà réservés",
    "EInvoice.error.description": "Une erreur s'est produite dans notre système. Veuillez réessayer plus tard.",
    "EInvoice.footer.businessaddress": "Ackermann Vertriebs AG, Industriestrasse 19, 8112 Otelfingen, CHE-115.455.663 HR/MWST",
    "EInvoice.footer.businessterms": "Veuillez consulter nos conditions générales de vente ci-jointes sur <link>ackermann.ch/cgv</link>.",
    "EInvoice.footer.fixed.info.first": "Payable par achat sur facture dans les 14 jours, par paiement partiel dans les 30 jours et par pause de paiement dans les 120 jours après réception de la livraison. Veuillez noter que vous êtes en défaut au plus tard 30 jours après la réception de cette facture et de l'échéance de la créance, sans qu'un nouveau rappel soit nécessaire.",
    "EInvoice.footer.fixed.info.second": "Bei Vorliegen eines gültigen Lastschriftmandates werden wir die ausgewiesenen fälligen Beträge einziehen. Retouren vermindern den Einzugsbetrag.",
    "EInvoice.footer.fixed.info.third": "Veuillez vous assurer d'inclure votre numéro de client et votre numéro de facture dans la raison du transfert. Cela permettra d'accélérer le remboursement de votre compte client.",
    "EInvoice.footer.ownership": "Les marchandises restent notre propriété jusqu'au paiement complet.",
    "EInvoice.header.customerNumber.headline": "Numéro de client",
    "EInvoice.header.deliveryAddress.headline": "Adresse de livraison",
    "EInvoice.header.invoiceAddress.headline": "Adresse de facturation",
    "EInvoice.header.invoiceDate.headline": "Date de facturation",
    "EInvoice.header.invoiceNumber.headline": "Numéro de facture",
    "EInvoice.header.paymentType.headline": "Mode de paiement",
    "EInvoice.invoiceTotals.headline": "Facture",
    "EInvoice.invoiceTotals.subTotals": "Total partiel",
    "EInvoice.invoicetotals.grandTotals": "Montant total",
    "EInvoice.paymentplan.headline": "Plan de paiement pour vos mensualités.",
    "EInvoice.paymentplan.showEffectiveInstallmentsHint": "Dies ist deine ursprüngliche Rate.{break}Die aktuellen Raten inkl. der Zusammensetzung findest du <link>hier</link>.",
    "EInvoice.products.articleNumber": "Ref. art.: {articlenumber}",
    "EInvoice.products.finalPrice": "Prix final",
    "EInvoice.products.installments": "en {installmentCount} mensualités",
    "EInvoice.products.paymentMethod": "Mode de paiement: {paymentMethod}",
    "EInvoice.products.pricesHeadline": "Facture",
    "EInvoice.products.productHeadline": "Article",
    "EInvoice.products.productPrice": "Valeur des marchandises",
    "EInvoice.products.productReturned": "Le produit a été retourné",
    "EInvoice.products.quantity": "Quantité: {quantity}",
    "EInvoice.products.reclaim.link": "Signaler un problème avec cet article",
    "EInvoice.products.size": "Taille: {size}",
    "FaqCard.Headline": "Des questions ?{br}Nous sommes à votre disposition",
    "FlexikontoContainer.myFlexAccount": "Mon Flexikonto",
    "Gender.female": "Madame",
    "Gender.male": "Monsieur",
    "Joe.disconnect.label": "Kontoverknüpfung trennen",
    "Joe.disconnect.not-connected.text": "Ihr Konto ist nicht mit Ihrem jö Konto verknüpft.",
    "Joe.disconnect.success": "Ihr Konto wurde erfolgreich getrennt.",
    "Joe.disconnect.text": "Ihr Universal-Konto ist mit Ihrem jö Konto verknüpft. Sie sammeln bei jedem Einkauf Ös.",
    "JoeConnectAccountCard.headline": "jö Bonus Club",
    "LoginDecider.accessDenied.error": "En tant que client de l'UE, vous ne pouvez malheureusement pas accéder à Mon compte.",
    "LoungeContainer.advantage0": "Livraison gratuite pour toute commande, sans valeur minimale de commande",
    "LoungeContainer.advantage1": "Pas de frais d'expédition (d'une valeur de 39.95 CHF)",
    "LoungeContainer.advantage2": "Des promotions exclusives de bons d'achat et de réductions",
    "LoungeContainer.advantage3": "Service clientèle exclusif et privilégié par mail premiumkunde@universal.at ou par téléphone 0662/4485-2500",
    "LoungeContainer.advantage4": "-",
    "LoungeContainer.advantage5": "-",
    "LoungeContainer.advantage6": "-",
    "LoungeContainer.advantage7": "-",
    "LoungeContainer.advantage8": "-",
    "LoungeContainer.advantage9": "-",
    "LoungeContainer.advantagesHeadline": "En tant que client premium, vous bénéficiez d'avantages exclusifs:",
    "LoungeContainer.businessPartnerNo": "Mon numéro de client",
    "LoungeContainer.cancel": "Annuler l'abonnement des clients premium",
    "LoungeContainer.cancel.info": "Vos avantages sont garantis jusqu'à un an après votre inscription.{break} Nous nous efforçons constamment de sélectionner les avantages les plus exclusifs possibles et d'élargir constamment votre paquet d'avantages. Continuez à nous donner la chance de vous convaincre.{break}Si vous ne souhaitez plus bénéficier des avantages, veuillez cliquer sur le bouton suivant.",
    "LoungeContainer.cancel.modal.headline": "Dommage que vous vouliez vous désabonner!",
    "LoungeContainer.cancel.stay": "Je veux rester un client du Paquet de Fidélité",
    "LoungeContainer.cancelPremium.error": "Désolé, cela n'a pas marché. Veuillez réessayer.",
    "LoungeContainer.cancelPremium.success": "Votre abonnement a été annulé",
    "LoungeContainer.datastatus": "État des données",
    "LoungeContainer.extensionHint": " ",
    "LoungeContainer.freeMembershipHint": " ",
    "LoungeContainer.goal": "Objectif de vente",
    "LoungeContainer.importantOverview": "Le plus important en un coup d'œil",
    "LoungeContainer.introtext": "Votre avantage exclusif de client premium vous garantit des achats sans souci!",
    "LoungeContainer.mystatus": "Mon statut",
    "LoungeContainer.orderCount": "Ihre versandkostenfreien Bestellungen",
    "LoungeContainer.orders": "Mon chiffre d'affaires annuel actuel",
    "LoungeContainer.pageHeadline": "{firstName} Page client Premium",
    "LoungeContainer.renewal": "Mitgliedschafts-Verlängerung am",
    "LoungeContainer.retursHint": "* Les retours ne sont pas pris en compte dans le chiffre d'affaires.",
    "LoungeContainer.savings": "Épargne actuelle",
    "LoungeContainer.since": "Client premium depuis",
    "LoungeContainer.until": "Les avantages pour les clients Premium sont valables jusqu'au",
    "Maturities.RVC.DE.minimalPayment": "montant à payer",
    "Maturities.RVC.DE.openPayments": "dont encore ouvert",
    "Maturities.RVC.DeductedPromotion": "dont prime protection de paiement",
    "Maturities.RVC.minimalPayment": "Taux minimal actuel",
    "Maturities.RVC.openPayments": "Vos paiements ouverts",
    "Maturities.RVC.promotion": "Prime actuelle protection de paiement",
    "Maturities.RVC.sum": "Votre montant dû ",
    "Maturities.RVC.sum.Subheadline": "Votre montant dû <italic>(taux minimum inclus):</italic>",
    "Maturities.accountBalanced": "Votre compte est équilibré",
    "Maturities.bookingsLink": "Toutes les réservations",
    "Maturities.currentBalance": "Solde de compte",
    "Maturities.currentMaturities": "Paiements actuellement dûs",
    "Maturities.error": "Veuillez nous excuser, nous ne pouvons pas afficher le solde de votre compte pour le moment. <reload>Veuillez recharger la page!</reload> Si l'erreur persiste, nous avons un problème technique interne et nous sommes déjà en train de le résoudre. Veuillez réessayer plus tard. Nous vous remercions de votre compréhension.",
    "Maturities.grandTotal": "Montant à payer",
    "Maturities.headline.bookings": "L'essentiel en bref",
    "Maturities.headline.overview": "Paiements / Factures",
    "Maturities.hideDetails": "cacher les détails",
    "Maturities.nextMaturities.headline": "Paiements dus prochainement",
    "Maturities.nextMaturity": "dû le ",
    "Maturities.openBalance": "SOLDE DÛ",
    "Maturities.overdueMaturities": "Retards de paiement",
    "Maturities.paymentPlanOverview": "Aperçu des prochaines dates de paiement",
    "Maturities.positiveBalance": "Solde actuel",
    "Maturities.showDetails": "Afficher les détails",
    "MinimalMaturities.customAmount": "Le montant a été ajusté à votre demande",
    "MinimalMaturities.grandTotal": "Minimal à payer",
    "MinimalMaturities.maturityDate": "Le {date}{break}ce montant{break}sera collecté",
    "MinimalMaturities.monthlyRate": "Montant mensuel",
    "MinimalMaturities.overdue": "arriéré",
    "MyAccount.Notification.DelayedDeliveryWarning": "Aufgrund der aktuellen Hochwasserlage können unsere Dienstleister nicht alle Gebiete hindernisfrei erreichen. Deshalb kann es in betroffenen Gebieten leider zu einer Verzögerung der Auslieferung kommen.{break}Vielen Dank für dein Verständnis.",
    "MyAccountContainer.accessDenied.error": "En tant que client de l'UE, vous ne pouvez malheureusement pas accéder à Mon compte.",
    "Navigation.MyAccount.Bypass.Headline": "Ignorer la navigation sur mon compte",
    "Navigation.headline": "Mon compte",
    "OverviewContainer.Kim.title": "Comment trouvez-vous l'espace \"Mon compte\"?",
    "OverviewContainer.ReturnsInformation.card.headline": "Retours",
    "OverviewContainer.ReturnsInformation.date": "Crédit instruit le:",
    "OverviewContainer.ReturnsInformation.empty.message": "Nous n'avons reçu aucun retour.",
    "OverviewContainer.ReturnsInformation.goToAllReturns.text": "Tous les retours",
    "OverviewContainer.ReturnsInformation.item.number.label": "Numéro d'article:",
    "OverviewContainer.ReturnsInformation.item.quantity.label": "Montant:",
    "OverviewContainer.ReturnsInformation.item.size.label": "Taille:",
    "OverviewContainer.ShipmentInformation.card.headline": "Commandes en cours",
    "OverviewContainer.ShipmentInformation.fallback.text": "Malheureusement, aucun envoi n'est en route vers vous.",
    "OverviewContainer.ShipmentInformation.goToAll": "Toutes les commandes et livraisons",
    "PartialPayment.AccountBalanceHeadline": "Solde de compte",
    "PartialPayment.AccountInformationReceiver": "Bénéficiaire",
    "PartialPayment.AllBookings": "Toutes les réservations",
    "PartialPayment.Birthdate": "Veuillez virer <strong>{currentAmountDue}</strong> avant le <strong>{currentAmountDueDate}</strong> sur le compte suivant:",
    "PartialPayment.CardHeadline": "Flexikonto paiement partiel",
    "PartialPayment.CurrentAmountDue": "Votre montant dû <italic>(taux minimum inclus):</italic>",
    "PartialPayment.InlineNotification": "Notre bonus fidélité spécialement pour vous: {break}vous pouvez encore commander chez Ackermann pour un montant maximal de {amountUntilChange} – votre mensualité minimale actuelle restera la même!",
    "PartialPayment.InstructionsQr": "Payez facilement à l‘aide du QR code ci-dessous dans l'application de votre banque:",
    "PartialPayment.MinimumRate": "Taux minimal actuel",
    "PartialPayment.Or": "ou",
    "PartialPayment.Purpose": "Référence:",
    "PartialPayment.RsvFee": "dont prime protection de paiement",
    "PartialPayment.RsvFeeIncluded": "inkl. Prämie Zahlschutz",
    "PartialPayment.ToMyAccountStatements": "Accéder à mes extraits de compte",
    "PartialPayment.openBalance": "SOLDE DÛ",
    "PasswordForget.error.general": "Votre demande ne peut être traitée pour le moment. Veuillez réessayer plus tard.",
    "PasswordForget.password.change.success": "Le mot de passe a été changé avec succès.",
    "PaymentPause.CardHeadline": "Flexikonto pause de paiement",
    "PaymentPause.FurtherInstructions": "Toutes les informations à ce sujet Flexikonto pause de paiement",
    "PaymentPause.InactivelInstructions": "Choisissez la Flexikonto pause de paiement lors de votre prochaine commande au cours de l'étape de paiement et ne payez qu'au bout de 3 mois.*{break}*Supplément représentant 4,99% de la valeur de la commande. Sous réserve de solvabilité.",
    "PaymentPause.InactivelSubheadline": "Vous n'avez actuellement pas de pause de paiement active. ",
    "PaymentPause.InitialPurchaseDate": "Date d'achat",
    "PaymentPause.PurchaseAmount": "Montant",
    "PaymentPause.PurchaseDueDate": "dû le",
    "PaymentPause.PurchaseFrom": "Achat du ",
    "PaymentPause.activeInstructions": "Vous trouverez ici un aperçu de vos pauses de paiement actives et de la date d'échéance.",
    "PaymentPlan.due": "Due le ",
    "PaymentPlan.empty": "Il n'y a pas de date de paiement pour le {date}!",
    "PaymentPlan.error": "Vos paiements ne peuvent pas être affichés pour le moment. Veuillez réessayer dans quelques instants.",
    "PaymentPlan.headline": "Vos prochains paiements",
    "PaymentPlan.hintHeadline": "Veuillez noter:",
    "PaymentPlan.hintText": "Les réservations de l'aperçu général sont divisées ici en différentes dates d'échéance. Vous pouvez trouver les factures individuelles sous le même numéro de document à des dates d'échéance différentes.",
    "PaymentPlan.showInvoice": "voir facture no. {documentNo}",
    "PaymentProtectionCard.BulletPointHead": "La Flexikonto protection de paiement vous offre une sécurité optimale, à vous et à la personne qui partage votre vie, dans de nombreuses situations imprévues:",
    "PaymentProtectionCard.CompletePaymentProtection": "Souscrire maintenant à la Flexikonto protection de paiement",
    "PaymentProtectionCard.Enabled": "La Flexikonto protection de paiement est active",
    "PaymentProtectionCard.GoodToKnow": "Bon à savoir",
    "PaymentProtectionCard.GoodToKnowText": "la cotisation d'assurance s'élève à 1,3 % du solde en cours de votre compte client. Si vous n’avez pas de solde en cours, vous n‘avez bien entendu rien à payer.",
    "PaymentProtectionCard.Headline": "Flexikonto protection de paiement",
    "PaymentProtectionCard.OldInsurance0": "Maladies graves{break}<small>crise cardiaque, accident vasculaire cérébral, Protection en cas de cancer, cécité, surdité</small>",
    "PaymentProtectionCard.OldInsurance1": "Invalidité permanente des suites d'un accident{break}<small>Invalidité á partir de 50%</small>",
    "PaymentProtectionCard.OldInsurance2": "Décés",
    "PaymentProtectionCard.Service": "En cas de dommage, nous sommes à votre disposition: <b><phonelink>0848 85 85 21</phonelink></b>",
    "PaymentProtectionCard.YoungInsurance0": "Chómage{break}<small>Protection en cas de chómage involontaire</small>",
    "PaymentProtectionCard.YoungInsurance1": "Incapacité de gain{break}<small>Protection en cas de maladie, incapacité de travail, invalidité</small>",
    "PaymentProtectionCard.YoungInsurance2": "Maladies graves{break}<small>crise cardiaque, accident vasculaire cérébral, Protection en cas de cancer, cécité, surdité</small>",
    "PaymentProtectionCard.YoungInsurance3": "Besoin de soins{break}<small>allocation d'impotence BESA de degré 10 ou supérieur</small>",
    "PaymentProtectionCard.YoungInsurance4": "Décés",
    "PaymentSettings.Headline": "Informations de paiement enregistrées",
    "PaymentSettings.confirmDelete": "Supprimer ne peut pas être annulé",
    "PaymentSettings.confirmDeleteButton": "confirmer la suppression",
    "PaymentSettings.creditCard": "Carte de crédit",
    "PaymentSettings.creditCard.number": "Numéro de carte",
    "PaymentSettings.creditCard.validThru": "valable jusqu'au",
    "PaymentSettings.deleteButton": "supprimer",
    "PaymentSettings.directDebit": "Prélèvement automatique",
    "PaymentSettings.directDebit.download": "Télécharger le mandat SEPA",
    "PaymentSettings.directDebit.new": "Mandat de prélèvement SEPA",
    "PaymentSettings.empty.addDirectDebit": "Créer un prélèvement automatique",
    "PaymentSettings.empty.hint.headline": "Des achats plus rapides & plus sûrs",
    "PaymentSettings.empty.hint.text": "Suite à une commande, vous avez la possibilité de sauvegarder vos informations de carte bancaire, afin qu'elles soient enregistrées sur cet écran. Ainsi, lors de votre achat suivant, il n'est plus nécessaire de les saisir à nouveau, ce qui vous fait gagner du temps.",
    "PaymentSettings.hint.headline": "D'où les données proviennent-elles?",
    "PaymentSettings.hint.text": "Vous trouverez ici les données sauvegardées en vue de vos prochains achats. En cliquant sur « supprimer », vous pouvez bien entendu les effacer définitivement.",
    "PaymentSettings.successDeleteCreditCard": "Les données de la carte de crédit ont été supprimées avec succès",
    "PaymentSettings.successDeleteDirectDebit": "Les données bancaires ont été supprimées avec succès",
    "PersonalCoupon.ActiveChip": "Gutschein angewendet",
    "PersonalCoupon.ActiveHint": "Der Gutschein wird in Schritt 5 „Prüfen und Bestellen“ angewendet.",
    "PersonalCoupon.Button": "Gutschein anwenden",
    "PersonalCoupon.ConditionsHeadline": "*Gutscheinbedingungen",
    "PersonalCoupon.Header": "Dein Dankeschön:",
    "PersonalData.Birthdate": "Date de naissance",
    "PersonalData.BusinessPartnerNo": "Numéro de client",
    "PersonalData.EmailAddress": "Adresse e-mail",
    "PersonalData.Headline": "Données personnelles",
    "PersonalData.Iban": "IBAN",
    "PersonalData.InvoiceAddress": "Adresse de facturation",
    "PersonalData.Password": "Mot de passe",
    "PersonalData.PasswordHint": "Le mot de passe n'est pas affiché pour des raisons de sécurité.",
    "PersonalData.Phone": "Téléphone",
    "PersonalData.Verified.Text": "Confirmé",
    "PersonalData.addressmodal.headline": "Nouvelle adresse?",
    "PersonalData.change": "changer",
    "PersonalData.changepassword.success": "Le mot de passe a été modifié avec succès.",
    "PersonalData.emailmodal.headline": "Nouvelle adresse e-mail?",
    "PersonalData.ibanmodal.headline": "IBAN ändern",
    "PersonalData.passwordmodal.headline": "Nouveau mot de passe?",
    "PersonalData.phone.fixed": "Réseau fixe",
    "PersonalData.phone.mobile": "Téléphone portable",
    "PersonalData.phonemodal.headline": "Nouveau numéro de téléphone?",
    "PersonalDataContainer.Kim.title": "Comment trouvez-vous l'espace \"Mon compte\"?",
    "PrepaymentHint.text": "La commande ne sera traitée qu'après réception d'un acompte de {amount}. Note: Les articles commandés ne sont pas réservés jusque-là. {break} Nos coordonnées bancaires: {space} Ackermann Vertriebs AG {break} Industriestrasse 19 {break} 8112 Otelfingen {break} QR IBAN: CH30 3000 0001 6059 5151 6 {break} Veuillez saisir votre numéro de référence <strong>{bankTransferReference}</strong> dans le champ Référence lors d'un virement e-banking.",
    "ProductNotFoundHint.copy": "Les données de commandes plus anciennes ne peuvent malheureusement pas être affichées pour des raisons techniques. Mais vous pouvez trouver ces articles sous vos <a>réservations</a>.",
    "ProductNotFoundHint.title": "Article pas trouvé?",
    "ReclamationForm.Article.Submit": "Appliquer",
    "ReclamationForm.FormCancel": "Annuler",
    "ReclamationForm.FormSubmit": "Soumettre",
    "ReclamationForm.Information": "Veuillez utiliser ce formulaire uniquement pour signaler les articles défectueux ou endommagés qui doivent être réparés ou examinés. Pour tout autre sujet, veuillez utiliser notre formulaire de contact sur ",
    "ReclamationForm.InvoiceInfo": "Votre réclamation concernant la facture {invoiceNumber} du {invoiceDate}",
    "ReclamationForm.Replacement.Headline": "Vous avez besoin d'une pièce de rechange ? Veuillez compléter les informations suivantes",
    "ReclamationForm.Shipping.Headline": "Votre adresse de livraison est-elle différente de l'adresse de votre compte?",
    "ReclamationForm.Shipping.Submit": "Appliquer",
    "ReclamationForm.Submit.Error": "Malheureusement, votre plainte n'a pas pu être traitée pour le moment. Veuillez réessayer plus tard!",
    "ReclamationForm.articleDescription.required": "La description de l'article est nécessaire!",
    "ReclamationForm.articleNumber.required": "Le numéro d'article est nécessaire!",
    "ReclamationForm.city.required": "La ville est nécessaire!",
    "ReclamationForm.email.required": "L'adresse e-mail est nécessaire!",
    "ReclamationForm.emailAddress": "Adresse e-mail:",
    "ReclamationForm.error": "Vous n'êtes actuellement pas connecté! Veuillez vous connecter!",
    "ReclamationForm.error.invalidData": "Nous n'avons pas pu trouver vos données pour le moment. Veuillez réessayer plus tard!",
    "ReclamationForm.firstName.required": "Le prénom est nécessaire!",
    "ReclamationForm.headlineArticle": "Informations sur les articles et les commandes",
    "ReclamationForm.headlineCustomer": "Information client",
    "ReclamationForm.headlineShipping": "Adresse de livraison (si différente de l'adresse du compte)",
    "ReclamationForm.houseNumber.required": "Le numéro de la maison est nécessaire!",
    "ReclamationForm.labelArticleDescription": "Description de l'article:",
    "ReclamationForm.labelArticleNumber": "Ref. art.:",
    "ReclamationForm.labelBestReachedPhoneNumber": "Meilleur numéro pour me joindre:",
    "ReclamationForm.labelCity": "Ville:",
    "ReclamationForm.labelCustomerNo": "Numéro de client:",
    "ReclamationForm.labelDataTypeShield": "Étiquette de type de données:",
    "ReclamationForm.labelFirstName": "Prénom:",
    "ReclamationForm.labelHouseNumber": "Numéro de maison:",
    "ReclamationForm.labelLastName": "Nom:",
    "ReclamationForm.labelReclamationDescription": "Description des défauts:*",
    "ReclamationForm.labelReclamationReason": "Raison de la plainte:",
    "ReclamationForm.labelReplacementNumber": "Numéro de la pièce de rechange:",
    "ReclamationForm.labelStreet": "Rue:",
    "ReclamationForm.labelZipCode": "Code postal:",
    "ReclamationForm.lastName.required": "Le nom est nécessaire!",
    "ReclamationForm.primaryPhoneNumber": "Téléphone:",
    "ReclamationForm.reclamationDescription.required": "La description des défauts est nécessaire!",
    "ReclamationForm.reclamationReasonDamaged": "Article défectueux",
    "ReclamationForm.reclamationReasonIncomplete": "Article incomplet",
    "ReclamationForm.reclamationReasonOther": "Autre",
    "ReclamationForm.reclamationReasonPlaceholder": "Veuillez choisir",
    "ReclamationForm.reclamationReasonWrongPrice": "Prix incorrect",
    "ReclamationForm.reclamationReasonWrongProduct": "Un article erroné a été livré",
    "ReclamationForm.street.required": "La rue est nécessaire!",
    "ReclamationForm.zipCode.required": "Le code postal est nécessaire!",
    "ReturnDeclaration.CTA.Button.Label": "Weitere Sendungsnummer hinzufügen",
    "ReturnDeclaration.Description": "Dein Paket ist bereits auf dem Weg zu uns?{br}Dann teile uns einfach deine Tracking- bzw. Sendungsnummer mit.",
    "ReturnDeclaration.Disclaimer": "Speditionsartikel sind leider ausgeschlossen.",
    "ReturnDeclaration.Headline": "Rücksendung aufgegeben?",
    "ReturnDeclaration.InfoBox.Headline": "Wo finde ich meine Sendungsnummer?",
    "ReturnDeclaration.InfoBox.Text": "Du findest deine Tracking- bzw. Sendungsnummer auf dem Beleg, den du bei der Abgabe deiner Rücksendung bekommen hast.",
    "ReturnDeclaration.Submit.Button.Label": "Sendungsnummer abschicken",
    "ReturnDeclarationCard.DateInput.Error": "Das Abgabedatum deiner Rücksendung liegt zu weit zurück",
    "ReturnDeclarationCard.DateInput.Format.Error": "Bitte korrigiere das Datum (Format: TT.MM.JJJJ)",
    "ReturnDeclarationCard.DateInput.Future.Error": "Verwende {date} oder einen früheren Wert",
    "ReturnDeclarationCard.DateInput.Label": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Content": "Mit dem Datum können wir deine Sendungsdaten eindeutig in unserer Datenbank zuordnen.",
    "ReturnDeclarationCard.InfoModal.Headline": "Abgabedatum der Rücksendung",
    "ReturnDeclarationCard.InfoModal.Subheadline": "Warum benötigen wir das Abgabedatum?",
    "ReturnDeclarationCard.Input.Label": "Sendungsnummer",
    "ReturnDeclarationCard.Input.Placeholder": "z.B. 123456789",
    "ReturnDeclarationCard.MissingInput.InlineError": "Bitte vervollständige deine Eingabe oder lösche diese",
    "ReturnDeclarationCard.Return.Eighth": "Achte Rücksendung",
    "ReturnDeclarationCard.Return.Fifth": "Fünfte Rücksendung",
    "ReturnDeclarationCard.Return.First": "Rücksendung",
    "ReturnDeclarationCard.Return.Fourth": "Vierte Rücksendung",
    "ReturnDeclarationCard.Return.Ninth": "Neunte Rücksendung",
    "ReturnDeclarationCard.Return.Second": "Zweite Rücksendung",
    "ReturnDeclarationCard.Return.Seventh": "Siebte Rücksendung",
    "ReturnDeclarationCard.Return.Sixth": "Sechste Rücksendung",
    "ReturnDeclarationCard.Return.Tenth": "Zehnte Rücksendung",
    "ReturnDeclarationCard.Return.Third": "Dritte Rücksendung",
    "ReturnDeclarationContainer.Success.Description": "Da wir nun wissen, dass dein Paket auf dem Weg zu uns ist, können wir dies direkt in unseren Systemen vormerken.{br}{br}Wir melden uns bei dir, wenn dein Paket bei uns eingegangen ist!",
    "ReturnDeclarationContainer.Success.GoToShop": "Zurück zum Shop",
    "ReturnDeclarationContainer.Success.Headline": "Vielen Dank!",
    "ReturnDeclarationContainer.accessDenied.error": "Dieser Link ist leider nicht gültig.",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Bring": "Voici comment nous renvoyer votre article",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Pickup": "Voici comment nous renvoyer votre article de l'expédition",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Bring": "Utilisez tout simplement l'étiquette de retour jointe à votre envoi. Sinon, veuillez contacter notre service client à <link>{emailAddress}</link> (lien vers le formulaire de contact) ou au {phoneNumber}.",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Pickup": "Veuillez contacter notre service client au {phoneNumber} pour récupérer votre retour.",
    "ReturnShipmentInfo.InstructionsForReturn.emailAddress": "service@ackermann.ch",
    "ReturnShipmentInfo.InstructionsForReturn.errorToast": "Oups ! Quelque chose s'est mal passé.{break} Réessayez plus tard ou contactez notre service clientèle au <semiBold>{phoneNumber}<semiBold>.",
    "ReturnShipmentInfo.InstructionsForReturn.href": "/fr/aide-service/qui-sommes-nous/contactez-nous",
    "ReturnShipmentInfo.InstructionsForReturn.noteHeading": "Attention:",
    "ReturnShipmentInfo.InstructionsForReturn.noteMessage": "Pour que votre retour soit traité le plus rapidement possible, veuillez nous renvoyer uniquement les articles de la même livraison dans un seul colis. Cela nous permet d'attribuer clairement votre livraison. {break}Vous souhaitez renvoyer différentes livraisons ?  Veuillez utiliser à cet effet le paquet que vous avez reçu.",
    "ReturnShipmentInfo.InstructionsForReturn.packingHeading": "1. Emballage",
    "ReturnShipmentInfo.InstructionsForReturn.packingMessage": "Il vous suffit de remettre vos articles dans le paquet que vous avez reçu. N'oubliez pas de joindre le bon de livraison avec les raisons du retour.",
    "ReturnShipmentInfo.InstructionsForReturn.parcelHeading": "3. Magasin de colis",
    "ReturnShipmentInfo.InstructionsForReturn.parcelMessage": "Déposez votre retour dans un bureau de poste ou une filiale Päckli Punkt.",
    "ReturnShipmentInfo.InstructionsForReturn.phoneNumber": "0848 85 85 21",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelHeading": "2. Étiquette de retour",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage": "Vous pouvez faire établir ici une nouvelle étiquette de retour que vous pouvez imprimer et coller sur le colis. Une filiale de la Poste ou Päckli Punkt se chargera du reste.",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage2": "<semiBold>Veuillez noter</semiBold> que si vous avez déjà utilisé l'étiquette de retour jointe au colis, veuillez contacter notre service clientèle au <semiBold>{phoneNumber}</semiBold> ou {emailAddress} pour obtenir une nouvelle étiquette. Ce n'est qu'ainsi que nous recevrons réellement votre retour.",
    "ReturnShipmentInfo.InstructionsForReturn.relaxHeading": "4. Se détendre",
    "ReturnShipmentInfo.InstructionsForReturn.relaxMessage": "Vous pouvez ensute vous détendre. Dès que votre retour aura été traité par nos soins, nous vous en informerons par e-mail et vous rembourserons les sommes déjà versées.",
    "ReturnShipmentInfo.modalMainHeader.Before": "Créer l'étiquette de retour",
    "ReturnShipmentInfo.modalMainHeader.Bring": "Retourner un article",
    "ReturnShipmentInfo.modalMainHeader.Online": "Créer l'étiquette de retour",
    "ReturnShipmentInfo.modalMainHeader.Pickup": "Retourner un article de l'expédition",
    "ReturnShipmentInfo.modalSubHeader.Before": "Voici comment nous renvoyer votre article",
    "ReturnShipmentInfo.modalSubHeader.Online": "Voici comment nous renvoyer votre article",
    "ReturnShipmentInfo.phoneNumber": "0848 85 85 21",
    "ReturnShipmentInfo.returnType.Before": "Afficher l'étiquette de retour",
    "ReturnShipmentInfo.returnType.Bring": "Retourner à mes commandes",
    "ReturnShipmentInfo.returnType.Online": "Afficher l'étiquette de retour",
    "ReturnShipmentInfo.returnType.Pickup": "Retourner à mes commandes",
    "ServiceContactTile.callUs": "Appelez-nous",
    "ServiceContactTile.questions": "Vous avez des questions?",
    "ServiceContactTile.write": "Ecrivez-nous",
    "ServiceContactTile.yourCustomerNumber": "Votre numéro de client",
    "ShipmentCardAction.RecoSlider.headline": "Recommandations pour vous",
    "ShipmentCardAction.similarproducts.link": "Articles similaires",
    "ShipmentCardDetails.hint.delivered": "Vous pouvez trouver des détails sur la localisation de votre paquet via le suivi d'expédition.",
    "ShipmentCardDetails.hint.downpayment": "Veuillez noter nos informations sur les acomptes pour les articles individuels",
    "ShipmentCardDetails.label.carrier": "Partenaire de transport",
    "ShipmentCardDetails.label.deliveryAddress": "Adresse de livraison",
    "ShipmentCardDetails.label.deliveryService": "Service de livraison",
    "ShipmentCardDetails.label.hint": "Remarque",
    "ShipmentCardDetails.label.invoiceLink": "voir la facture",
    "ShipmentCardDetails.label.noTrackingAvailable": "Es sind keine Track & Trace-Informationen verfügbar",
    "ShipmentCardDetails.label.numberOfPackages": "Nombre de colis",
    "ShipmentCardDetails.label.orderDate": "Date de commande",
    "ShipmentCardDetails.label.packageType": "Type d'emballage",
    "ShipmentCardDetails.label.paymentType": "Mode de paiement",
    "ShipmentCardDetails.label.trackingId": "ID de l'envoi",
    "ShipmentCardDetails.link.desiredDelivery": "Livraison à une date précise {optPackageNo}",
    "ShipmentCardDetails.link.returnLabelShow": "Afficher l'étiquette de retour",
    "ShipmentCardDetails.link.tracking": "Suivi d'expédition {optPackageNo}",
    "ShipmentCardDetails.package.indicator": "(Paquet {current} de {total})",
    "ShipmentCardDetails.returnLabelCreated": "Étiquette de retour créée",
    "ShipmentCardDetails.trackingUrlError": "Quelque chose s'est mal passé. Veuillez réessayer plus tard.",
    "ShipmentInfoCard.details.headline": "Détails de commande & suivi d'expédition",
    "ShipmentInfoHeader.headline": "Mes commandes & livraisons",
    "ShipmentInfoHeader.text": "Ici, vous trouverez un aperçu de toutes les commandes et pourrez voir leur statut de livraison actuel.",
    "ShipmentInfoTypeSelect.option.all": "tous",
    "ShipmentInfoTypeSelect.option.canceled": "annulé",
    "ShipmentInfoTypeSelect.option.delivered": "livré",
    "ShipmentInfoTypeSelect.option.label": "statut:",
    "ShipmentInfoTypeSelect.option.ordered": "commandé",
    "ShipmentInfoTypeSelect.option.returned": "retourné",
    "ShipmentInfoTypeSelect.option.sent": "en route",
    "ShipmentInfos.cancel.error.message": "Une erreur s'est produite lors de l'annulation de la commande.",
    "ShipmentInfos.headline": "Trouvez vos commandes plus rapidement :",
    "ShipmentInfos.loadMore": "charger plus",
    "ShipmentInfos.nodata-hint": "Veuillez sélectionner le statut ou la période souhaitée.",
    "ShipmentInfos.request.error.message": "Une erreur s'est produite lors du chargement des commandes.",
    "ShipmentInfosContainer.Kim.title": "Comment trouvez-vous l'espace \"Mon compte\"?",
    "ShipmentInfosProductView.label.amount": "Nombre",
    "ShipmentInfosProductView.modal.message.canceled.main": "Votre article a été annulé.",
    "ShipmentInfosProductView.modal.message.canceled.sub": "La prochaine fois, vous trouverez certainement quelque chose qui vous conviendra.",
    "ShipmentInfosProductView.modal.title": "Voulez-vous vraiment annuler?",
    "ShipmentInfosProductView.modal.title.success": "annulation réussie",
    "ShipmentInfosProductView.productLink": "vers l'article",
    "ShipmentInfosShipmentListType.all": "tous",
    "ShipmentInfosShipmentListType.awaiting_downpayment": "en attente d'un acompte",
    "ShipmentInfosShipmentListType.canceled": "annulé",
    "ShipmentInfosShipmentListType.delivered": "livré",
    "ShipmentInfosShipmentListType.in_delivery": "en livraison",
    "ShipmentInfosShipmentListType.ordered": "commandé",
    "ShipmentInfosShipmentListType.returnLabel": "Étiquette de retour",
    "ShipmentInfosShipmentListType.return_shipment": "Étiquette de retour",
    "ShipmentInfosShipmentListType.returned": "retourné",
    "ShipmentInfosShipmentListType.returnlabel_created": "Étiquette de retour créée",
    "ShipmentInfosShipmentListType.sent": "en route",
    "ShipmentInfosShipmentListType.shipment_prepared": "envoi préparé",
    "ShipmentTimeRangeSelect.option.all": "tous",
    "ShipmentTimeRangeSelect.option.days": "{amount} jours",
    "ShipmentTimeRangeSelect.option.label": "période:",
    "ShipmentTimeRangeSelect.option.weeks": "{amount} semaines",
    "VerificationNotification.Text": "Confirmez votre adresse e-mail afin d'augmenter la <strong>sécurité de votre compte</strong>.",
    "VerificationNotification.VerifyAgainCTA": "Demander à nouveau le courrier",
    "VerificationNotification.VerifyAgainText": "Nous vous avons envoyé un <strong>lien de confirmation</strong> à <strong>{email}</strong>. Vous n'avez pas reçu de mail ? Vérifiez éventuellement encore une fois dans le dossier spam. Ou vous pouvez ",
    "VerificationNotification.VerifyCTA": "Confirmer l'adresse e-mail",
    "VerificationNotification.error": "Malheureusement, cela n'a pas fonctionné. Veuillez réessayer.",
    "blackFridayNotificationText": "Aufgrund des sehr hohen Bestelleingangs bitten wir um Verständnis, dass die Zustellung momentan 2-3 Tage länger dauern kann!",
    "meta.title.addressbook": "Mon carnet d'adresses | Mon compte",
    "meta.title.bankstatements": "Mes relevés bancaires | Mon compte",
    "meta.title.bookings": "Mes réservations | Mon compte",
    "meta.title.callcenterLogin": "Callcenter Login | Mon compte",
    "meta.title.default": "Mon compte",
    "meta.title.flexikonto": "Mon Flexikonto | Mon compte",
    "meta.title.overview": "Aperçu | Mon compte",
    "meta.title.passwordForget": "mot de passe oublié",
    "meta.title.personaldata": "Mes données personnelles | Mon compte",
    "meta.title.premiumlounge": "Mon Lounge | Mon compte",
    "meta.title.shipmentinfos": "Mes commandes & livraisons | Mon compte",
    "AccountInformation.bic": "BIC: <split>DRESDEFF760</split>",
    "AccountInformation.iban": "QR IBAN: <split>CH30 3000 0001 6059 5151 6</split>",
    "AccountInformation.receiverAdditionals": "Industriestrasse 19 {break} 8112 Otelfingen",
    "AccountInformation.receiverName": "Ackermann Vertriebs AG",
    "ServiceContactTile.emailAddress": "service@<wbr />ackermann.ch",
    "ServiceContactTile.emailAddressEmployee": "service@<wbr />ackermann.ch",
    "ServiceContactTile.phoneNumber": "0848 85 85 21",
    "ServiceContactTile.phoneNumberEmployee": "0848 85 85 21",
    "ServiceContactTile.phoneTimes": "Du lundi au vendredi de 08h00 à 18h00 {break}(hors samedis, dimanches et jours fériés)",
    "ServiceContactTile.phoneTimesEmployee": "Du lundi au vendredi de 08h00 à 18h00 {break}(hors samedis, dimanches et jours fériés)"
  }
};
  
  export default deepmerge(ioLocale, messages);
  