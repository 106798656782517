import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Headline } from '@empiriecom/module-components/Headline';
import Button from '@empiriecom/module-components/Button';
import Input from '@empiriecom/module-components/FormControls/Input';
import Loader from '@empiriecom/module-components/Loader';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { useProfiling } from '@empiriecom/mybuy-components/modules/tmx';

const messages = defineMessages({
  headline: {
    id: 'ChangeIban.headline',
    defaultMessage: 'IBAN ändern',
  },
  inputLabel: {
    id: 'ChangeIban.inputLabel',
    defaultMessage: 'Neue IBAN',
  },
  inputPlaceholder: {
    id: 'ChangeIban.inputPlaceholder',
    defaultMessage: 'DE00000000000000000000',
  },
  defaultError: {
    id: 'ChangeIban.defaultError',
    defaultMessage: 'Es ist ein technischer Fehler aufgetreten',
  },
  noDataError: {
    id: 'ChangeIban.noDataError',
    defaultMessage: 'Die Daten wurden gespeichert, können aber nicht angezeigt werden',
  },
});

const StyledHeadline = styled(Headline)`
  margin: 1rem 0;
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  padding: 0.5rem;
`;

const TextInputWrapper = styled.div`
  label {
    margin-top: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: 1rem;
`;

const Loadie = styled.div`
  text-align: center;
  margin-top: 5rem;
`;

export const ChangeIbanForm: React.FC<{
  iban?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
}> = ({ iban = '', onSuccess, onCancel }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [ibanInput, setIbanInput] = useState<string>(iban);

  const customer = useCustomerContext();
  const customerApi = useCustomerApi();
  const profile = useProfiling();

  const sendData = async () => {
    // send async request when possible
    try {
      setError(null);
      if (!customerApi) {
        throw new Error(intl.formatMessage(messages.defaultError));
      }
      setLoading(true);
      const response = await profile(customerApi, 'saveIban', true, {
        ecLocale: 'de',
        saveIbanRequestPayload: { iban: ibanInput },
      });
      // handle first error only for now
      if (response.errors) {
        throw new Error(response.errors.elements[0].description);
      }
      if (response.customer) {
        customer.actions.update(response.customer);
      } else {
        throw new Error(intl.formatMessage(messages.noDataError));
      }
      onSuccess?.();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loadie>
      <Loader size="3rem" />
    </Loadie>
  ) : (
    <>
      <StyledHeadline level={4} textAlign="center">
        {intl.formatMessage(messages.headline)}
      </StyledHeadline>
      <Background>
        <TextInputWrapper>
          <div>
            <Input
              name="iban"
              value={ibanInput}
              placeholder={intl.formatMessage(messages.inputPlaceholder)}
              label={intl.formatMessage(messages.inputLabel)}
              error={error}
              onChange={(e) => {
                setIbanInput(e.target.value);
              }}
            />
          </div>
        </TextInputWrapper>
      </Background>
      <ButtonWrapper>
        <Button
          fullWidth
          layout="primary"
          size="large"
          type="submit"
          uppercase={false}
          onClick={() => sendData()}
          data-testid="submit-button"
        >
          <FormattedMessage id="ChangeIban.save" defaultMessage="Änderungen speichern" />
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          fullWidth
          layout="default"
          size="large"
          uppercase={false}
          onClick={() => {
            onCancel?.();
          }}
          data-testid="cancel-button"
        >
          <FormattedMessage id="ChangeIban.cancel" defaultMessage="abbrechen" />
        </Button>
      </ButtonWrapper>
    </>
  );
};
