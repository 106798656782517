import { ErrorState, ReclamationFormFieldName } from './types';

type ISetValue = {
  type: 'SET_VALUE';
  payload: {
    fieldName: ReclamationFormFieldName;
    value: string;
    isConfirmed?: boolean;
  };
};

type IConfirmInputValue = {
  type: 'CONFIRM_INPUT_VALUES';
  payload: {
    fieldNames: ReclamationFormFieldName[];
  };
};

type ISetErrors = {
  type: 'SET_ERRORS';
  payload: ErrorState;
};

export type ReclamationFormAction = ISetValue | IConfirmInputValue | ISetErrors;

export const setValue = (
  fieldName: ReclamationFormFieldName,
  value: string,
  isConfirmed?: boolean,
): ReclamationFormAction => ({
  type: 'SET_VALUE',
  payload: {
    fieldName,
    value,
    isConfirmed,
  },
});

export const confirmInputvalue = (
  fieldNames: ReclamationFormFieldName[],
): ReclamationFormAction => ({
  type: 'CONFIRM_INPUT_VALUES',
  payload: {
    fieldNames,
  },
});

export const setErrors = (fieldErrors: ErrorState): ReclamationFormAction => ({
  type: 'SET_ERRORS',
  payload: fieldErrors,
});
