import { useTracking, GtmGenericEvent } from '@empiriecom/module-components/hooks/useTracking';

export interface GtmMyAccountEvent extends GtmGenericEvent {
  event: 'ga_event';
  eventAction: EventAction;
  eventCategory: EventCategory;
}

/* could be done better with String Literal Types in typescript 4.1 */
export type EventCategory =
  | 'login'
  | 'login_resetPassword'
  | 'overview'
  | 'overview_paymentPlan'
  | 'addressbook'
  | 'addressbook_addAddress'
  | 'addressbook_changeAddress'
  | 'personalData'
  | 'personalData_changeEmail'
  | 'personalData_changePassword'
  | 'personalData_changeBillingAddress'
  | 'personalData_changePhoneNumber'
  | 'personalData_directDebit'
  | 'premiumLounge'
  | 'premiumLounge_cancelSubscription'
  | 'shipmentInfos'
  | 'shipmentInfos_cancel'
  | 'shipmentInfos_cancel_success'
  | 'shipmentInfos_eInvoice'
  | 'booking'
  | 'booking_eInvoice'
  | 'booking_eInvoice_complain'
  | 'booking_paymentPlan'
  | 'paylink'
  | 'flexikonto';

export type EventAction = 'click' | 'viewPage' | 'viewLayer';

/**
 * This hook provides a easy to use function for tracking events in the fragment.
 * For further documentation see https://confluence.empirie.com/display/ORI/Tracking+-+Doku
 * @returns (eventCategory: EventCategory, isLayer: boolean = false) => void
 */
const useEventTracking = () => {
  const dispatch = useTracking();
  return (eventCategory: EventCategory, eventAction: EventAction) =>
    dispatch<GtmMyAccountEvent>({
      event: 'ga_event',
      eventAction,
      eventCategory,
    });
};

export default useEventTracking;
