import React, { useState } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { EmailInput, PasswordInput } from '@empiriecom/module-components/FormControls/Input';
import { NotificationProps } from '@empiriecom/module-components/Notification';
import getValidationRules from '@empiriecom/mybuy-components/utils/validationRules';

import { ModuleConfig } from '@empiriecom/mybuy-components/interfaces/types';

import { CCLoginFormContainer } from './CCLoginFormContainer';

export interface CCEmailPasswordLoginParams {
  email: string;
  password: string;
  // remember: boolean;
}

export interface CCEmailPasswordLoginProps {
  onSubmit?: (credentials: CCEmailPasswordLoginParams) => void;
  error?: NotificationProps['content'];
}

const messages = defineMessages({
  title: {
    id: 'CCLogin.title',
    defaultMessage: 'Callcenter Anmeldung',
  },
  emailLabel: {
    id: 'CCLogin.email.label',
    defaultMessage: 'E-Mail-Adresse',
  },
  emailPlaceholder: {
    id: 'CCLogin.email.placeholder',
    defaultMessage: 'name@provider.de',
  },

  passwordPlaceholder: {
    id: 'CCLogin.password.placeholder',
    defaultMessage: '****',
  },

  login: {
    id: 'CCLogin.label.login',
    defaultMessage: 'Anmelden',
  },
});

export function CCLogin({ onSubmit, error }: CCEmailPasswordLoginProps) {
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [validity, setValidity] = useState<{ email: boolean; password: boolean }>({
    email: false,
    password: false,
  });
  const { formatMessage } = useIntl();

  const config = useConfig<ModuleConfig>();

  const validationRules = getValidationRules(config);

  const onValidityChange = (key: 'password' | 'email', value: boolean) => () => {
    if (validity[key] !== value) {
      setValidity((state) => ({ ...state, [key]: value }));
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({ email, password });
    }
  };

  const submitDisabled = !validity.email || !validity.password || !email || !password;

  return (
    <CCLoginFormContainer
      title={formatMessage(messages.title)}
      disableSubmit={submitDisabled}
      onSubmit={handleSubmit}
      error={error}
      submitLabel={formatMessage(messages.login)}
    >
      <div>
        <EmailInput
          data-testid="cc-login-email"
          validationRules={validationRules.email}
          onError={onValidityChange('email', false)}
          onValid={onValidityChange('email', true)}
          label={formatMessage(messages.emailLabel)}
          placeholder={formatMessage(messages.emailPlaceholder)}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div>
        <PasswordInput
          data-testid="cc-login-password"
          displayLength
          onError={onValidityChange('password', false)}
          onValid={onValidityChange('password', true)}
          placeholder={formatMessage(messages.passwordPlaceholder)}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
    </CCLoginFormContainer>
  );
}

export default CCLogin;
