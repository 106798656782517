import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Headline, Link } from '@empiriecom/module-components';
import { defineMessages, IntlShape } from 'react-intl';
import { BEFORE, ONLINE, PICKUP, BRING, ShipmentTypeWithoutNone } from './index';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
const Tracking = getIcon('Tracking');
const Pdf = getIcon('Pdf');
const InDelivery = getIcon('InDelivery');
const Wishlist = getIcon('Wishlist');
const Truck = getIcon('Truck');
const Return = getIcon('Return');

export const MessageHint = styled.div`
  border: 1px solid rgb(197, 197, 197);
  border-radius: 3px;
  padding: 1rem 0.75rem;
  font-size: 0.813rem;
  text-align: center;
  margin: 1rem 0.75rem;
`;
export const MainMessage = styled.p`
  margin: 0.25rem 1rem 0.063rem;
  font-size: 0.938rem;
`;
export const SecondaryMessage = styled.p`
  margin: 1.313rem 1rem 0.063rem;
  font-size: 0.938rem;
`;
export const StyledHeadline = styled(Headline)`
  margin-top: 0.188rem;
  margin-bottom: 0.063rem;
  font-weight: 600;
`;
export const SemiBold = styled.span`
  font-weight: 600;
`;
export const Section = styled.section`
  margin-top: 1rem;
`;
export const NoWrap = styled.span`
  white-space: nowrap;
`;
const messages = defineMessages({
  packingHeading: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.packingHeading',
    defaultMessage: '1. Verpacken',
  },
  packingMessage: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.packingMessage',
    defaultMessage:
      'Verpacke bitte deinen Artikel in das Paket - Idealerweise in das bereits von uns verwendete. Vergesse auch nicht, deinen Lieferschein mit den Retourengründen beizulegen.',
  },
  noteHeading: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.noteHeading',
    defaultMessage: 'Bitte beachte:',
  },
  noteMessage: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.noteMessage',
    defaultMessage:
      'Sende nur Artikel aus derselben Lieferung in einem Paket an uns zurück, damit wir deine Rücksendung bearbeiten und eindeutig zuordnen können. Falls du weitere Artikel aus anderen Lieferungen zurückgeben möchtest, müssen diese pro Lieferung verpackt und zurückgeschickt werden.',
  },
  printLabelHeading: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.printLabelHeading',
    defaultMessage: '2. Rücksende-Etikett',
  },
  printLabelMessage: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.printLabelMessage',
    defaultMessage:
      'Du kannst dir hier ein neues Rücksende-Etikett erstellen lassen, das du ausdruckst und auf das Paket klebst. Den Rest erledigt Hermes für dich.',
  },
  printLabelMessage2: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.printLabelMessage2',
    defaultMessage:
      '<semiBold>Bitte beachte: </semiBold> sofern du das dem Paket beigelegte Rücksende-Etikett schon genutzt hast, wende dich bitte an unseren Kundenservice unter {phoneNumber} bzw. {emailAddress} für ein neues Etikett. Nur so kommt deine Retoure auch wirklich bei uns an.',
  },
  parcelHeading: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.parcelHeading',
    defaultMessage: '3. PaketShop',
  },
  parcelMessage: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.parcelMessage',
    defaultMessage: 'Gib deine Rücksendung in einem Hermes PaketShop ab.',
  },
  relaxHeading: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.relaxHeading',
    defaultMessage: '4. Entspannen',
  },
  relaxMessage: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.relaxMessage',
    defaultMessage:
      'Danach kannst du dich entspannt zurücklehnen. Sobald deine Rücksendung von uns bearbeitet wurde, informieren wir dich per E-Mail und erstatten dir bereits gezahlte Beträge.',
  },
  contactHeadingBRING: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.contactHeading.Bring',
    defaultMessage: 'So kannst du deinen Artikel an uns zurückschicken',
  },
  contactMessageBRING: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.contactMessage.Bring',
    defaultMessage:
      'Benutze ganz einfach das Rücksende-Etikett, welches deiner Sendung beiliegt. Ansonsten kontaktiere bitte unsere Kundenbetreuung unter {emailAddress} oder {phoneNumber}',
  },
  contactHeadingPICKUP: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.contactHeading.Pickup',
    defaultMessage: 'So kannst du deine Speditionsartikel zurückschicken',
  },
  contactMessagePICKUP: {
    id: 'ReturnShipmentInfo.InstructionsForReturn.contactMessage.Pickup',
    defaultMessage:
      'Bitte kontaktiere unsere Kundenbetreuung unter {phoneNumber} zur Abholung deiner Rücksendung.',
  },
  contactEmail: {
    defaultMessage: 'service@baur.de',
    id: 'ReturnShipmentInfo.InstructionsForReturn.emailAddress',
  },
  contactPhone: {
    defaultMessage: '09572 - 50 50',
    id: 'ReturnShipmentInfo.InstructionsForReturn.phoneNumber',
  },
  contactHref: {
    defaultMessage: '/service-hilfe/kontakt',
    id: 'ReturnShipmentInfo.InstructionsForReturn.href',
  },
});

const InstructionsForReturn: FC<{
  shipmentType: ShipmentTypeWithoutNone;
  intl: IntlShape;
}> = ({ shipmentType, intl }) => {
  const dispatch = useTracking();
  useEffect(() => {
    if (shipmentType === ONLINE || shipmentType === BEFORE) {
      dispatch({
        event: 'ga_event',
        eventAction: 'viewLayer',
        eventCategory: 'return_labelPrint',
      });
    } else if (shipmentType === PICKUP) {
      dispatch({
        event: 'ga_event',
        eventAction: 'viewLayer',
        eventCategory: 'return_forwandingShipping',
      });
    } else if (shipmentType === BRING) {
      dispatch({ event: 'ga_event', eventAction: 'viewLayer', eventCategory: 'return_other' });
    } else {
      // do nothing
    }
  }, [shipmentType]);
  switch (shipmentType) {
    case ONLINE:
    case BEFORE:
      return (
        <>
          <Section>
            <Tracking />
            <StyledHeadline level={3}>{intl.formatMessage(messages.packingHeading)}</StyledHeadline>
            <MainMessage>{intl.formatMessage(messages.packingMessage)}</MainMessage>
            <MessageHint>
              <SemiBold>{intl.formatMessage(messages.noteHeading)}</SemiBold>
              <br />
              {intl.formatMessage(messages.noteMessage, {
                break: <br />,
              })}
            </MessageHint>
          </Section>
          <Section>
            <Pdf />
            <StyledHeadline level={3}>
              {intl.formatMessage(messages.printLabelHeading)}
            </StyledHeadline>
            <MainMessage>{intl.formatMessage(messages.printLabelMessage)}</MainMessage>
            <SecondaryMessage>
              {intl.formatMessage(messages.printLabelMessage2, {
                phoneNumber: (
                  <SemiBold>
                    <NoWrap>{intl.formatMessage(messages.contactPhone)}</NoWrap>
                  </SemiBold>
                ),
                emailAddress: (
                  <Link to={intl.formatMessage(messages.contactHref)}>
                    <SemiBold>{intl.formatMessage(messages.contactEmail)}</SemiBold>
                  </Link>
                ),
                semiBold: (str) => <SemiBold>{str}</SemiBold>,
              })}
            </SecondaryMessage>
          </Section>
          <Section>
            <InDelivery />
            <StyledHeadline level={3}>{intl.formatMessage(messages.parcelHeading)}</StyledHeadline>
            <MainMessage>{intl.formatMessage(messages.parcelMessage)}</MainMessage>
          </Section>
          <Section>
            <Wishlist />
            <StyledHeadline level={3}>{intl.formatMessage(messages.relaxHeading)}</StyledHeadline>
            <MainMessage>{intl.formatMessage(messages.relaxMessage)}</MainMessage>
          </Section>
        </>
      );
    case BRING:
    case PICKUP:
      return (
        <Section>
          {shipmentType === PICKUP && <Truck />}
          {shipmentType === BRING && <Return />}
          <StyledHeadline level={3}>
            {intl.formatMessage(
              messages[`contactHeading${shipmentType.toUpperCase()}` as keyof typeof messages],
            )}
          </StyledHeadline>
          <MainMessage>
            {intl.formatMessage(
              messages[`contactMessage${shipmentType.toUpperCase()}` as keyof typeof messages],
              {
                phoneNumber: <SemiBold>{intl.formatMessage(messages.contactPhone)}</SemiBold>,
                emailAddress: (
                  <Link to={intl.formatMessage(messages.contactHref)}>
                    <SemiBold>{intl.formatMessage(messages.contactEmail)}</SemiBold>
                  </Link>
                ),
              },
            )}
          </MainMessage>
        </Section>
      );
    // We won't be able to reach this point with our 5 cases, but the compiler needs it.
    /* istanbul ignore next */
    default:
      return null;
  }
};

export default InstructionsForReturn;
