import React, { createContext, FC, FunctionComponent, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { LoginStatus, PageProviderEntry, InternalPageMetadata } from '@/src/typings/Navigation';
import trackingAdapter from '@/src/utils/trackingAdapter';
import { titleTagMessages } from '@/config/pages';
import { parse } from 'query-string';
import { useLocation } from 'react-router';

export interface PageContextInterface {
  entry: PageProviderEntry;
  testingUrls: boolean;
  hashParameters: HashDataObject;
}

export interface PageProviderProps {
  entry: PageProviderEntry;
  testingUrls?: boolean;
  metaData?: InternalPageMetadata;
}

const DummyComponent: FC = () => {
  return <></>;
};

export const defaultPageContext = (): PageContextInterface => ({
  entry: {
    container: DummyComponent,
    path: '',
    requiredLoginStatus: LoginStatus.NotLoggedIn,
    trackingTemplate: '',
  },
  testingUrls: false,
  hashParameters: {},
});

export const PageContext = createContext<PageContextInterface>(defaultPageContext());
export const usePageContext = (): PageContextInterface => useContext(PageContext);

type HashDataObject = {
  invoiceNumber?: string | undefined;
  invoiceDate?: string | undefined;
};

export const getHashParameters = (locationHash: string): HashDataObject => {
  const hashObject = parse(locationHash) || {};
  const invoiceNumber = hashObject.invoicenumber as string | undefined;
  const invoiceDate = hashObject.invoicedate as string | undefined;

  return { invoiceNumber, invoiceDate };
};

export const PageProvider: FunctionComponent<PageProviderProps> = ({
  children,
  entry,
  testingUrls = false,
}): JSX.Element => {
  const intl = useIntl();
  trackingAdapter.page = entry;
  const { metaData } = entry;
  const title = metaData?.title;

  const { hash } = useLocation();
  const hashParameters = getHashParameters(hash);

  return (
    <>
      <Helmet>
        <title>
          {title ? intl.formatMessage(title) : intl.formatMessage(titleTagMessages.default)}
        </title>
      </Helmet>
      <PageContext.Provider
        value={{
          entry,
          testingUrls,
          hashParameters,
        }}
      >
        {children}
      </PageContext.Provider>
    </>
  );
};
