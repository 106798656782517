import { defineMessages } from 'react-intl';
import { LoginStatus, InternalPageContainer, InternalPageMetadata } from '@/src/typings/Navigation';

export const titleTagMessages = defineMessages({
  default: {
    id: 'meta.title.default',
    defaultMessage: 'Mein Konto',
  },
  overview: {
    id: 'meta.title.overview',
    defaultMessage: 'Übersicht | Mein Konto',
  },
  personaldata: {
    id: 'meta.title.personaldata',
    defaultMessage: 'Meine persönlichen Daten | Mein Konto',
  },
  bookings: {
    id: 'meta.title.bookings',
    defaultMessage: 'Meine Buchungen | Mein Konto',
  },
  shipmentinfos: {
    id: 'meta.title.shipmentinfos',
    defaultMessage: 'Meine Bestellungen & Lieferungen | Mein Konto',
  },
  addressbook: {
    id: 'meta.title.addressbook',
    defaultMessage: 'Mein Adressbuch | Mein Konto',
  },
  lounge: {
    id: 'meta.title.premiumlounge',
    defaultMessage: 'Meine Lounge | Mein Konto',
  },
  flexikonto: {
    id: 'meta.title.flexikonto',
    defaultMessage: 'Mein Flexikonto | Mein Konto',
  },
  bankstatements: {
    id: 'meta.title.bankstatements',
    defaultMessage: 'Meine Kontoauszüge | Mein Konto',
  },
  callcenterLogin: {
    id: 'meta.title.callcenterLogin',
    defaultMessage: 'Callcenter Login | Mein Konto',
  },
  passwordForget: {
    id: 'meta.title.passwordForget',
    defaultMessage: 'Passwort vergessen',
  },
});

export type Language = 'de' | 'fr' | 'nl';

export interface NextJSPage {
  paths: { [key in Language]?: string };
}
export interface NextJSPages<T = NextJSPage>
  extends Partial<InternalPages<Pick<InternalPage, 'paths'>>> {
  payback: T;
}

export interface InternalPage {
  paths: { [key in Language]: string };
  additionalPaths?: Array<string>;
  nativeAppCategories: string[];
  isPrefix?: boolean;
  container: InternalPageContainer;
  requiredLoginStatus: LoginStatus;
  metaData: InternalPageMetadata;
  trackingTemplate: string;
}

// give it internal page for no breaking changes
export type InternalPages<T = InternalPage> = {
  base: T;
  overview: T;
  personaldata: T;
  addressbook: T;
  bookings: T;
  shipmentinfos: T;
  lounge: T;
  flexikonto: T;
  bankstatements: T;
  callcenterLogin: T;
  callcenterADFSLoginResponse: T;
  passwordForget: T;
};

export namespace Bookings {
  export namespace Invoice {
    export const deUrl = '/mein-konto/meine-rechnungen';
    export const frUrl = '/fr/mon-compte/mes-factures';
    export const catalogCategory = 'MyAccountMob-meine-rechnungen';
  }
  export namespace Payment {
    export const deUrl = '/mein-konto/meine-zahlungen';
    export const frUrl = '/fr/mon-compte/mes-paiements';
    export const catalogCategory = 'MyAccountMob-meine-zahlungen';
  }
  export namespace Reimbursement {
    export const deUrl = '/mein-konto/meine-ruecksendungen';
    export const frUrl = '/fr/mon-compte/mes-retours';
    export const catalogCategory = 'MyAccountMob-meine-gutschriften';
  }
}

export namespace ShippingsAndOrders {
  export const deUrl = '/mein-konto/meine-bestellungen-und-lieferungen';
  export const frUrl = '/fr/mon-compte/mes-commandes-et-livraisons';
  export const catalogCategory = 'MyAccountMob-meine-bestellungen-und-lieferungen';
}
export const nextJSPages: NextJSPages<NextJSPage> = {
  payback: {
    paths: {
      de: '/mein-konto/payback',
    },
  },
  // proof of concept for nextjs migration
  // overview: {
  //   paths: {
  //     de: '/mein-konto/uebersicht',
  //     fr: '/fr/mon-compte/apercu',
  //     nl: '/mijn-account/overzicht',
  //   },
  // },
};
export const internalPages: InternalPages = {
  base: {
    nativeAppCategories: ['fixme'],
    container: InternalPageContainer.Overview,
    paths: {
      de: '/mein-konto',
      fr: '/fr/mon-compte',
      nl: '/mijn-account',
    },
    metaData: {
      title: titleTagMessages.overview,
    },
    requiredLoginStatus: LoginStatus.LoggedIn,
    trackingTemplate: 'overview',
  },
  overview: {
    nativeAppCategories: ['fixme'],
    container: InternalPageContainer.Overview,
    paths: {
      de: '/mein-konto/uebersicht',
      fr: '/fr/mon-compte/apercu',
      nl: '/mijn-account/overzicht',
    },
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.overview,
    },
    trackingTemplate: 'overview',
  },
  personaldata: {
    nativeAppCategories: ['MyAccountMob-meine-persoenlichen-daten'],
    container: InternalPageContainer.PersonalData,
    paths: {
      de: '/mein-konto/meine-persoenlichen-daten',
      fr: '/fr/mon-compte/mes-donnees-personnelles',
      nl: '/mijn-account/mijn-persoonlijke-gegevens',
    },
    isPrefix: true,
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.personaldata,
    },
    trackingTemplate: 'personalData',
  },
  shipmentinfos: {
    nativeAppCategories: ['MyAccountMob-meine-bestellungen-lieferungen'],
    container: InternalPageContainer.ShipmentInfos,
    paths: {
      de: '/mein-konto/meine-bestellungen-und-lieferungen',
      fr: '/fr/mon-compte/mes-commandes-et-livraisons',
      nl: '/mijn-account/mijn-bestellingen-en-leveringen',
    },
    isPrefix: true,
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.shipmentinfos,
    },
    trackingTemplate: 'shipmentInfos',
  },
  addressbook: {
    nativeAppCategories: ['MyAccountMob-mein-adressbuch'],
    container: InternalPageContainer.Addressbook,
    paths: {
      de: '/mein-konto/meine-lieferanschriften',
      fr: '/fr/mon-compte/mes-adresses-de-livraison',
      nl: '/mijn-account/mijn-leveringsadressen',
    },
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.addressbook,
    },
    trackingTemplate: 'addressbook',
  },
  lounge: {
    nativeAppCategories: ['MyAccountMob-myaccount-premium'],
    container: InternalPageContainer.Lounge,
    paths: { de: '/mein-konto/lounge', fr: '/fr/mon-compte/lounge', nl: '/mijn-account/lounge' },
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.lounge,
    },
    trackingTemplate: 'premiumLounge',
  },
  flexikonto: {
    nativeAppCategories: [],
    container: InternalPageContainer.Flexikonto,
    paths: {
      de: '/mein-konto/mein-flexikonto',
      fr: '/fr/mon-compte/mon-flexikonto',
      nl: '/mijn-account/mijn-flexi-account',
    },
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.flexikonto,
    },
    trackingTemplate: 'flexikonto',
  },
  bookings: {
    nativeAppCategories: [
      'MyAccountMob-meine-buchungen',
      Bookings.Invoice.catalogCategory,
      Bookings.Payment.catalogCategory,
      Bookings.Reimbursement.catalogCategory,
    ],
    container: InternalPageContainer.Bookings,
    paths: {
      de: '/mein-konto/meine-buchungen',
      fr: '/fr/mon-compte/mes-reservations',
      nl: '/mijn-account/mijn-boekingen',
    },
    additionalPaths: [
      Bookings.Invoice.deUrl,
      Bookings.Invoice.frUrl,
      Bookings.Payment.deUrl,
      Bookings.Payment.frUrl,
      Bookings.Reimbursement.deUrl,
      Bookings.Reimbursement.frUrl,
    ],
    requiredLoginStatus: LoginStatus.LoggedIn,
    metaData: {
      title: titleTagMessages.bookings,
    },
    trackingTemplate: 'booking',
  },
  bankstatements: {
    container: InternalPageContainer.Bankstatements,
    paths: {
      de: '/mein-konto/kontoauszuege',
      fr: '/fr/mon-compte/relevesbancaires',
      nl: '/mijn-account/rekeningoverzicht',
    },
    metaData: {
      title: titleTagMessages.bankstatements,
    },
    nativeAppCategories: [],
    requiredLoginStatus: LoginStatus.LoggedIn,
    trackingTemplate: 'bankstatements',
  },
  callcenterLogin: {
    container: InternalPageContainer.CallcenterLogin,
    paths: {
      de: '/mein-konto/callcenter',
      fr: '/fr/mon-compte/callcenter',
      nl: '/mijn-account/callcenter',
    },
    metaData: {
      title: titleTagMessages.callcenterLogin,
    },
    nativeAppCategories: [],
    requiredLoginStatus: LoginStatus.NotLoggedIn,
    trackingTemplate: 'callcenter',
  },
  callcenterADFSLoginResponse: {
    container: InternalPageContainer.CallcenterADFSLoginResponse,
    paths: {
      de: '/mein-konto/callcenter/adfstokenresponse',
      fr: '/fr/mon-compte/callcenter/adfstokenresponse',
      nl: '/mijn-account/callcenter/adfstokenresponse',
    },
    metaData: {
      title: titleTagMessages.callcenterLogin,
    },
    nativeAppCategories: [],
    requiredLoginStatus: LoginStatus.NotLoggedIn,
    trackingTemplate: 'callcenter',
  },
  passwordForget: {
    container: InternalPageContainer.PasswordForget,
    paths: {
      de: '/passwort',
      fr: '/passwort',
      nl: '/passwort',
    },
    metaData: {
      title: titleTagMessages.passwordForget,
    },
    nativeAppCategories: [],
    requiredLoginStatus: LoginStatus.NotLoggedIn,
    trackingTemplate: 'login_resetPassword',
  },
};

export default internalPages;
