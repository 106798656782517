import { CustomerStatusReturns } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { DataWithLoadingInfo } from '@empiriecom/mybuy-components/provider/CustomerProvider/interfaces';
import useCustomerStatus from './useCustomerStatus';

export type CustomerReturnsData = DataWithLoadingInfo<CustomerStatusReturns> & { news?: number };

function useCustomerReturns(): CustomerReturnsData {
  const customerStatus = useCustomerStatus();
  return {
    data: customerStatus.data?.returns,
    news: customerStatus.data?.news?.returnedItems,
    loading: customerStatus.loading,
  };
}

export default useCustomerReturns;
