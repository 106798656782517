import { FragmentConfig } from '@/config/types';
import ioConfig from '@empiriecom/mybuy-components/config/default';
import { ModuleConfig } from '@empiriecom/mybuy-components/interfaces/types';

/* eslint-disable prefer-destructuring */
const FRAGMENT_COMPANYID = process.env.FRAGMENT_COMPANYID || 'imwalkingde';
const FRAGMENT_HOST = process.env.FRAGMENT_HOST || 'emma.lan';
const NODE_ENV = process.env.NODE_ENV || '';
const BRANCH = process.env.BRANCH || 'master';

const typesafeIOConfig = ioConfig as ModuleConfig;

const config: FragmentConfig = {
  ...typesafeIOConfig,
  global: {
    enabled: true,
    values: {
      FRAGMENT_HOST,
      FRAGMENT_COMPANYID,
      NODE_ENV,
      EPRIVACY_ENABLED: false,
      BRANCH,
    },
  },
  locales: ['de-DE'],
  l10n: {},
  hidePriceSign: false,
  ccLogin: {
    ADFSEnabled: false,
    host: '',
    redirectURL: '',
    clientID: '',
    resource: '',
    devClientID: '',
    devResource: '',
  },
  companyId: 'emma',
  page: {
    enabled: true,
    values: {
      headerPath: {
        'fr-CH': '/fr/app/header',
        default: '/app/header',
      },
      emptyHeaderPath: {
        'fr-CH': '/fr/fragment/empty-header',
        default: '/fragment/empty-header',
      },
      // NEXT: emptyHeaderPath: '/fragment/empty-header',
      loginPath: {
        'fr-CH': '/fr/landingpages/fragment-login/?emmaMode=true',
        default: '/landingpages/fragment-login/?emmaMode=true',
      },
      // NEXT: loginPath: '/fragment/login',
      eInvoiceDependenciesPath: {
        'fr-CH': '/fr/landingpages/fragment-einvoice/?emmaMode=true',
        default: '/landingpages/fragment-einvoice/?emmaMode=true',
      },
    },
  },
  customerTypes: {
    employees: [],
  },
  backend: {
    baseUrl: '/api/customer/v1',
    customerBaseUrl: '/api/customer/v1',
    accountBaseUrl: '/api/customer/v1',
    loginBaseUrl: '/api/customer/v1',
    addressBaseUrl: '/api/customer/v1',
  },
  address: {
    ...ioConfig.address,
    correctionButton: 'default',
  },
  navigation: {
    headlineTextTransform: 'none',
  },
  tracking: {
    gtmId: '',
    tagmanagerSrc: undefined,
  },
  overview: {
    newsBadge: {
      color: 'primary',
    },
  },
  personalData: {
    supressEmailChange: false,
    isIbanEditable: false,
    isNL: false,
  },
  bookings: {
    overview: {
      hasExplicitMaturities: false,
      showOpenPayment: 'minimal-payment',
    },
  },
  shipmentInfos: {
    blackFridayNoti: false,
    returnLabelShown: true,
    hideTrackingIdWithoutUrl: false,
  },
  lounge: {
    showStatusAndData: true,
    showHint: true,
    icon: () => '',
    banner: (language: string, device: string) =>
      `https://bilder.baur.de/empiriecom/io/baurde/${language}/lounge_banner_${device}.jpg`,
    switchModalButtons: false,
    premiumOnTop: false,
  },
  paylink: {
    ...typesafeIOConfig.paylink,
    enabled: false,
    enforced: false,
    url: '/fragment/paylink',
  },
  returnDeclaration: {
    enabled: false,
    url: '/mein-konto/ruecksendeanmeldung',
  },
  kim: {
    ...ioConfig.kim,
    booking_esi: '/fragment/kim-booking',
    personaldata_esi: '/fragment/kim-personaldata',
    lbl_esi: '/fragment/kim-lbl',
  },
  password: {
    minLength: 8,
    maxLength: 256,
  },
  einvoice: {
    isFixedFooter: false,
    showReclaimLink: true,
  },
  permissions: {
    serviceMailEnabled: false,
    newsletterEnabled: true,
    eInvoiceEnabled: true,
    postalBankStatementEnabled: false,
    neighboursDeliveryEnabled: false,
    smsNotificationEnabled: false,
  },
  show: {
    bic: true,
    ibanBeforeBic: false,
    lastName: false,
    changeToEmailLogin: true,
    isServiceContactTileEnabled: true,
  },
  images: {
    ...ioConfig.images,
    thankyou: {
      default: 'https://bilder.baur.de/i/empiriecom/io/de/thank_you.gif',
      'fr-CH': 'https://bilder.baur.de/i/empiriecom/io/fr/thank_you.gif',
    },
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/baur_logo_small.png',
    },
    amex: {
      default: 'https://bilder.baur.de/i/empiriecom/amex.svg',
    },
    diners: {
      default: 'https://bilder.baur.de/i/empiriecom/diners.svg',
    },
    discover: {
      default: 'https://bilder.baur.de/i/empiriecom/discover.svg',
    },
    klarna: {
      default: 'https://bilder.baur.de/i/empiriecom/klarna_sofort.svg',
    },
    mastercard: {
      default: 'https://bilder.baur.de/i/empiriecom/mastercard.svg',
    },
    visa: {
      default: 'https://bilder.baur.de/i/empiriecom/visa.svg',
    },
    paypal: {
      default: 'https://bilder.baur.de/i/empiriecom/paypal.svg',
    },
    paydirekt: {
      default: 'https://bilder.baur.de/i/empiriecom/giropay.svg',
    },
  },
  joe: {
    enabled: false,
    registrationUrl: 'https://www.joe-club.at/registrieren',
    dataProtectionLinkUrl: '/service-hilfe/ueber-uns/datenschutz',
  },
  recoApi: '/api/inform/reco/graphql',
  favicon: '/favicon.ico',
  businessTerms: {
    default: '/agb',
    'fr-CH': '/cgv',
    'nl-NL': '/special/algemene-voorwaarden',
  },
  delayedDeliveryWarning: false,
  flexikonto: {
    enabled: false,
    rsvFeeIncluded: false,
    displayMinimumRate: true,
    paymentProtectionAgeBreakpoint: 60,
    externalUrls: {
      faq: '',
      paymentPause: {
        default: '/service-hilfe/service/bezahlung-finanzierung/flexikonto-zahlpause',
        'fr-CH': '/fr/aide-service/service/paiement-financement/flexikonto-pause-de-paiement',
      },
      paymentProtection: {
        default: '/service-hilfe/service/bezahlung-finanzierung/flexikonto-zahlschutz',
        'fr-CH': '/fr/aide-service/paiement-financement/flexikonto-protection-de-paiement',
      },
    },
  },
  checkMarkColor: 'success',
  chatBot: {
    enabled: false,
    key: '',
    visibility: {
      main: true,
      button: true,
      notifications: false,
    },
  },
  showHonorificForAddress: true,
} satisfies FragmentConfig;

export default config;
