import deepmerge from 'deepmerge';
import { Theme } from '@empiriecom/module-components';

export interface ChangePasswordTheme extends Theme {
  component: {
    changePassword: {
      formBackground: string;
      passwordLength: {
        color: string;
        colorValid: string;
      };
      securityHintBorder: string;
    };
  };
}

export const changePasswordTheme = (theme: Theme): ChangePasswordTheme => {
  let componentTheme = {
    formBackground: theme.greyLight.color.normal,
    passwordLength: {
      color: theme.greyDark.color.normal,
      colorValid: theme.success.color.normal,
    },
    securityHintBorder: `1px solid ${theme.greyNormal.color.normal}`,
  };

  // We don't really have any way to test this
  /* istanbul ignore next */
  if (theme.component.navigation) {
    componentTheme = deepmerge(componentTheme, theme.component.changePassword);
  }

  return {
    ...theme,
    component: {
      ...theme.component,
      changePassword: componentTheme,
    },
  };
};

export default changePasswordTheme;
