import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useMedia } from 'react-media-match';

import Skeleton from '@empiriecom/module-components/Skeleton';
import { Modal } from '@empiriecom/module-components/Modal';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import Card, { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import useMaturities from '@/src/hooks/useMaturities';
import { Maturities as FetchedMaturities } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/Maturities';
import PaymentPlan from '@/src/components/PaymentPlan';
import trackingAdapter from '@/src/utils/trackingAdapter';
import { FragmentConfig } from '@/config/types';
import internalPages, { Language } from '@/config/pages';
import { WithBadge } from '@/src/components/NewsBadge';
import { useHistory } from 'react-router-dom';
import { EventCategory } from '@/src/hooks/useEventTracking';
import EventTracking from '../EventTracking';
import Notification from '@empiriecom/module-components/Notification';
import { useLocation } from 'react-router';

const messages = defineMessages({
  OverviewHeadline: {
    id: 'Maturities.headline.overview',
    defaultMessage: 'Rechnungen / Zahlungen',
  },
  BookingsHeadline: {
    id: 'Maturities.headline.bookings',
    defaultMessage: 'Das Wichtigste in Kürze',
  },
  MaturitiesPaymentPlanOverview: {
    id: 'Maturities.paymentPlanOverview',
    defaultMessage: 'Übersicht nächste Zahltermine',
  },
  MaturitiesBookingsLink: {
    id: 'Maturities.bookingsLink',
    defaultMessage: 'Alle Buchungen',
  },
});

const BoldInformation = styled.div`
  font-weight: bold;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.5rem;
`;

const NextMaturities = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Maturity = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '0.65rem' : 'inherit')};
  height: 5rem;
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  cursor: pointer;
`;

const MaturityBar = styled.div<{ percentage: number }>`
  min-height: 1px;
  background-color: ${({ theme }) => theme.primary.color.normal};
  border-radius: 3px;
  height: ${({ percentage }) => `${0.03 * percentage}rem`};
  transition: height 5s;

  &.noheight {
    height: 0;
    max-height: 0;
  }
`;

const SubHeadline = styled.div`
  margin: 0;
  font-weight: bold;
  padding-top: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const ShowDetails = styled.div`
  cursor: pointer;
`;

const NegativeValue = styled.div`
  color: ${({ theme }) => theme.error.color.normal};
  text-align: right;
`;
const PositiveValue = styled.div`
  color: ${({ theme }) => theme.success.color.normal};
  text-align: right;
`;

const NeutralValue = styled.div`
  text-align: right;
`;

const badgeCss = css`
  border-radius: 10px;
  padding: 0 0.25rem;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.4px;
  font-size: 0.7125rem;
  text-align: center;
`;
const ErrorBadge = styled.div`
  background-color: ${({ theme }) => theme.error.color.normal};
  ${badgeCss}
`;
const SuccessBadge = styled.div`
  background-color: ${({ theme }) => theme.success.color.normal};
  ${badgeCss}
`;

const RateSection = styled.div`
  margin-left: 1.75rem;
`;
const LightTransparentText = styled.div`
  color: ${({ theme }) => theme.greyLight.color.textTransparent};
  display: inline-block;
`;
const ListIcon = getIcon('List');
const BillingIcon = getIcon('Billing');
const ZoomInIcon = getIcon('ZoomIn');
const ZoomOutIcon = getIcon('ZoomOut');

const StyledOpen = styled(ZoomInIcon)`
  margin-right: 0.25rem;
`;

const StyledClose = styled(ZoomOutIcon)`
  margin-right: 0.25rem;
`;

export const DisplayAccountBalance: FC<{
  currentAccountBalance: FetchedMaturities['currentAccountBalance'];
  hidePriceSign?: boolean;
}> = ({ currentAccountBalance, hidePriceSign }) => {
  if (typeof currentAccountBalance === 'undefined') return <></>;
  const { currencyCode, value } = currentAccountBalance;
  if (typeof value === 'undefined' || value === 0) {
    return (
      <>
        <NeutralValue>
          <FormattedCurrency value={value || 0} currency={currencyCode} />
        </NeutralValue>
        <NeutralValue>
          <div data-testid="neutral-balance">
            <FormattedMessage
              id="Maturities.accountBalanced"
              defaultMessage="Ihr Konto ist ausgeglichen"
            />
          </div>
        </NeutralValue>
      </>
    );
  }
  if (value < 0) {
    return (
      <>
        {hidePriceSign ? (
          <NeutralValue>
            <FormattedCurrency value={Math.abs(value)} currency={currencyCode} />
          </NeutralValue>
        ) : (
          <NegativeValue>
            <FormattedCurrency value={value} currency={currencyCode} />
          </NegativeValue>
        )}
        <ErrorBadge data-testid="open-balance-badge">
          <FormattedMessage id="Maturities.openBalance" defaultMessage="OFFENER SALDO" />
        </ErrorBadge>
      </>
    );
  }
  return (
    <>
      {hidePriceSign ? (
        <NeutralValue data-mf-replace-inner="**REMOVED**">
          <FormattedCurrency value={value} currency={currencyCode} />
        </NeutralValue>
      ) : (
        <PositiveValue>
          <FormattedCurrency value={value} currency={currencyCode} />
        </PositiveValue>
      )}
      <SuccessBadge data-testid="positive-balance-badge">
        <FormattedMessage id="Maturities.positiveBalance" defaultMessage="Guthaben" />
      </SuccessBadge>
    </>
  );
};

type Scope = 'overview' | 'bookings';

const Maturities: FC<{
  scope: Scope;
  mockedLoading?: boolean;
  mockedMaturities?: FetchedMaturities | null;
  mockedNews?: number;
}> = ({ scope, mockedLoading, mockedMaturities, mockedNews }): JSX.Element => {
  const { search } = useLocation();
  const [maturitiesOpen, setMaturitiesOpen] = useState<boolean>(false);
  const [paymentPlanModalOpen, setPaymentPlanModalOpen] = useState<boolean>(
    search.indexOf('nextpayments') >= 0,
  );
  const intl = useIntl();
  const history = useHistory();
  const maturityBars: Array<HTMLDivElement | null> = [];
  const config = useConfig<FragmentConfig>();
  const { loading, news, data: maturities } = useMaturities();
  const renderedLoading = mockedLoading !== undefined ? mockedLoading : loading;
  const renderedNews = scope === 'overview' ? mockedNews || news || undefined : undefined;
  const renderedMaturities = mockedMaturities !== undefined ? mockedMaturities : maturities;
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });

  // `Amount` might not actually contain any value even if it is there
  // The typeof check needs to be explicit as 0 would also be a false case
  const hasNegativeBalance =
    (typeof renderedMaturities?.currentAccountBalance?.value !== 'undefined' &&
      renderedMaturities?.currentAccountBalance?.value < 0) ??
    false;

  const navigateToAllBookings = () => {
    const allBookingsLink = internalPages.bookings.paths[intl.locale.split('-')[0] as Language];
    history.push(allBookingsLink);
  };

  useEffect(() => {
    if (renderedMaturities) {
      // separate effect for this because in the [loading] effect the maturityBars aren't pushed yet
      setTimeout(() => {
        maturityBars.forEach((ref) => ref?.classList.remove('noheight'));
      }, 10);
    }
  }, [renderedMaturities]);

  if (renderedLoading) {
    return <Skeleton height="10rem" />;
  }
  if (!renderedMaturities) {
    return (
      <Card
        headline={
          scope === 'overview'
            ? intl.formatMessage(messages.OverviewHeadline)
            : intl.formatMessage(messages.BookingsHeadline)
        }
        showHeaderSeparator
      >
        <CardContent>
          <Notification isInline level="warning">
            <FormattedMessage
              defaultMessage="Entschuldige bitte, wir können deinen Kontostand zur Zeit leider nicht anzeigen. <reload>Bitte lade die Seite neu!</reload> Falls der Fehler weiterhin besteht, haben wir intern ein technisches Problem und sind bereits dabei es zu beheben. Versuche es dann später noch einmal. Vielen Dank für dein Verständnis."
              id="Maturities.error"
              values={{
                reload: (chunks: ReactNode) => (
                  <a href="javascript:window.location.reload()">{chunks}</a>
                ),
              }}
            />
          </Notification>
        </CardContent>
      </Card>
    );
  }

  const currentMaturitiesValue = renderedMaturities.currentMaturities?.value || 0;
  const currentMaturitiesCurrency = renderedMaturities.currentMaturities?.currencyCode || 'EUR';
  const grandTotalValue = renderedMaturities.grandTotal?.value || 0;
  const grandTotalCurrency = renderedMaturities.grandTotal?.currencyCode || 'EUR';

  const showNeutralMaturities = !currentMaturitiesValue || currentMaturitiesValue === 0;

  const showNeutralGrandTotal = !grandTotalValue || grandTotalValue === 0;
  return (
    <WithBadge value={renderedNews} which={config.overview.newsBadge.color}>
      <Card
        headline={
          scope === 'overview'
            ? intl.formatMessage(messages.OverviewHeadline)
            : intl.formatMessage(messages.BookingsHeadline)
        }
      >
        <CardContent>
          {config.bookings.overview.hasExplicitMaturities && renderedMaturities.nextMaturities && (
            <>
              <div>
                <strong>
                  <FormattedMessage
                    defaultMessage="Kommende fällige Zahlungen"
                    id="Maturities.nextMaturities.headline"
                  />
                </strong>
              </div>
              <NextMaturities>
                {renderedMaturities.nextMaturities.map((mat) => (
                  <Maturity
                    isMobile={isMobile}
                    key={mat.date || '-'}
                    onClick={() => {
                      setPaymentPlanModalOpen(true);
                    }}
                  >
                    <div>{mat.date}</div>
                    <MaturityBar
                      data-mf-replace-inner="**REMOVED**"
                      percentage={mat.percentageOfMaxAmount || 0}
                      className="noheight"
                      ref={(inst) => maturityBars.push(inst)}
                    />
                    <div>
                      <strong data-mf-replace-inner="**REMOVED**">
                        <FormattedCurrency
                          value={mat.amount?.value || 0}
                          currency={mat.amount?.currencyCode || 'EUR'}
                        />
                      </strong>
                    </div>
                  </Maturity>
                ))}
              </NextMaturities>
              <CardSeperator />
            </>
          )}
          <LeftRight>
            <BoldInformation>
              <FormattedMessage id="Maturities.currentBalance" defaultMessage="Kontostand" />
            </BoldInformation>
            <BoldInformation>
              <DisplayAccountBalance
                currentAccountBalance={renderedMaturities.currentAccountBalance}
                hidePriceSign={config.hidePriceSign}
              />
            </BoldInformation>
          </LeftRight>
          {config.bookings.overview.showOpenPayment === 'installments' && hasNegativeBalance && (
            <>
              {!maturitiesOpen && (
                <>
                  <CardSeperator />
                  <LeftRight>
                    <div>[...]</div>
                    <ShowDetails onClick={() => setMaturitiesOpen(true)}>
                      <StyledOpen size="1rem" />
                      <FormattedMessage
                        id="Maturities.showDetails"
                        defaultMessage="Details anzeigen"
                      />
                    </ShowDetails>
                  </LeftRight>
                </>
              )}
              {maturitiesOpen && (
                <>
                  <CardSeperator />
                  <LeftRight>
                    <div />
                    <ShowDetails onClick={() => setMaturitiesOpen(false)}>
                      <StyledClose size="1rem" />
                      <FormattedMessage
                        id="Maturities.hideDetails"
                        defaultMessage="Details verbergen"
                      />
                    </ShowDetails>
                  </LeftRight>
                  <CardSeperator />
                  <LeftRight>
                    <div>
                      <FormattedMessage
                        id="Maturities.overdueMaturities"
                        defaultMessage="Überfällige Zahlungen"
                      />
                    </div>
                    <div data-mf-replace-inner="**REMOVED**">
                      <FormattedCurrency
                        value={renderedMaturities.overdueMaturities?.value || 0}
                        currency={renderedMaturities.overdueMaturities?.currencyCode || 'EUR'}
                      />
                    </div>
                  </LeftRight>
                  <CardSeperator />
                  <LeftRight>
                    <div>
                      <FormattedMessage
                        id="Maturities.currentMaturities"
                        defaultMessage="Aktuell fällige Zahlungen"
                      />
                    </div>
                    <div data-mf-replace-inner="**REMOVED**">
                      <FormattedCurrency
                        value={currentMaturitiesValue}
                        currency={currentMaturitiesCurrency}
                      />
                    </div>
                  </LeftRight>
                </>
              )}
              <CardSeperator />
              <LeftRight>
                <div>
                  <strong>
                    <FormattedMessage
                      id="Maturities.grandTotal"
                      defaultMessage="Gesamt zu zahlen"
                    />
                  </strong>
                  <br />
                  {renderedMaturities.nextMaturityDate && (
                    <>
                      <FormattedMessage id="Maturities.nextMaturity" defaultMessage="fällig am " />
                      {renderedMaturities.nextMaturityDate}
                    </>
                  )}
                </div>
                <div>
                  <strong data-mf-replace-inner="**REMOVED**">
                    <FormattedCurrency value={grandTotalValue} currency={grandTotalCurrency} />
                  </strong>
                </div>
              </LeftRight>
            </>
          )}
          {config.bookings.overview.showOpenPayment === 'minimal-payment' &&
            renderedMaturities.overdueMinimumRate &&
            !config.flexikonto.displayMinimumRate && (
              <>
                <CardSeperator />
                {renderedMaturities.overdueMinimumRateWithRsvFee && (
                  <SubHeadline>
                    <BoldInformation>
                      <LeftRight>
                        <div>
                          <FormattedMessage
                            id="Maturities.RVC.sum.Subheadline"
                            defaultMessage="Ihr fälliger Betrag"
                            values={{
                              italic: (...chunks: string[]) => (
                                <LightTransparentText>
                                  <i>{chunks}</i>
                                </LightTransparentText>
                              ),
                            }}
                          />
                        </div>
                        <NeutralValue>
                          <FormattedCurrency
                            value={renderedMaturities.overdueMinimumRateWithRsvFee.value}
                            currency={renderedMaturities.overdueMinimumRateWithRsvFee.currencyCode}
                          />
                        </NeutralValue>
                      </LeftRight>
                    </BoldInformation>
                  </SubHeadline>
                )}
                <RateSection>
                  {renderedMaturities.rsvFee && (
                    <LeftRight>
                      <div>
                        <FormattedMessage
                          id="Maturities.RVC.DeductedPromotion"
                          defaultMessage="davon Prämie Restschutz"
                        />
                      </div>
                      <NeutralValue>
                        <FormattedCurrency
                          value={renderedMaturities.rsvFee.value}
                          currency={renderedMaturities.rsvFee.currencyCode}
                        />
                      </NeutralValue>
                    </LeftRight>
                  )}
                </RateSection>
              </>
            )}
          {config.bookings.overview.showOpenPayment === 'minimal-payment' &&
            renderedMaturities.overdueMinimumRate &&
            config.flexikonto.displayMinimumRate && (
              <>
                <CardSeperator />
                <SubHeadline>
                  <FormattedMessage
                    id="Maturities.RVC.openPayments"
                    defaultMessage="Ihre offenen Zahlungen"
                  />
                </SubHeadline>
                <RateSection>
                  <LeftRight>
                    <div>
                      <FormattedMessage
                        id="Maturities.RVC.minimalPayment"
                        defaultMessage="aktuelle Mindestrate"
                      />
                    </div>
                    <NeutralValue data-mf-replace-inner="**REMOVED**">
                      <FormattedCurrency
                        value={renderedMaturities.overdueMinimumRate.value}
                        currency={renderedMaturities.overdueMinimumRate.currencyCode}
                      />
                    </NeutralValue>
                  </LeftRight>
                  {renderedMaturities.rsvFee && (
                    <LeftRight>
                      <div>
                        <FormattedMessage
                          id="Maturities.RVC.promotion"
                          defaultMessage="aktuelle Prämie Zahlschutz"
                        />
                      </div>
                      <NeutralValue>
                        <FormattedCurrency
                          value={renderedMaturities.rsvFee.value}
                          currency={renderedMaturities.rsvFee.currencyCode}
                        />
                      </NeutralValue>
                    </LeftRight>
                  )}
                  {renderedMaturities.overdueMinimumRateWithRsvFee && (
                    <>
                      <CardSeperator />
                      <BoldInformation>
                        <LeftRight>
                          <div>
                            <FormattedMessage
                              id="Maturities.RVC.sum"
                              defaultMessage="Ihr fälliger Betrag"
                            />
                          </div>
                          <NeutralValue>
                            <FormattedCurrency
                              value={renderedMaturities.overdueMinimumRateWithRsvFee.value}
                              currency={
                                renderedMaturities.overdueMinimumRateWithRsvFee.currencyCode
                              }
                            />
                          </NeutralValue>
                        </LeftRight>
                      </BoldInformation>
                    </>
                  )}
                </RateSection>
              </>
            )}
          {config.bookings.overview.showOpenPayment === 'sum-and-open' && (
            <>
              <CardSeperator />
              <SubHeadline>
                <FormattedMessage
                  id="Maturities.RVC.openPayments"
                  defaultMessage="Ihre offenen Zahlungen"
                />
              </SubHeadline>
              <LeftRight>
                <div>
                  <FormattedMessage
                    id="Maturities.RVC.DE.minimalPayment"
                    defaultMessage="Gesamt zu zahlen"
                  />
                </div>
                {showNeutralGrandTotal ? (
                  <NeutralValue data-mf-replace-inner="**REMOVED**">
                    <strong>
                      <FormattedCurrency value={grandTotalValue} currency={grandTotalCurrency} />
                    </strong>
                  </NeutralValue>
                ) : (
                  <NegativeValue data-mf-replace-inner="**REMOVED**">
                    <strong>
                      <FormattedCurrency value={grandTotalValue} currency={grandTotalCurrency} />
                    </strong>
                  </NegativeValue>
                )}
              </LeftRight>
              <LeftRight>
                <div>
                  <FormattedMessage
                    id="Maturities.RVC.DE.openPayments"
                    defaultMessage="davon noch offen"
                  />
                </div>
                {showNeutralMaturities ? (
                  <NeutralValue data-mf-replace-inner="**REMOVED**">
                    <FormattedCurrency
                      value={currentMaturitiesValue}
                      currency={currentMaturitiesCurrency}
                    />
                  </NeutralValue>
                ) : (
                  <NegativeValue data-mf-replace-inner="**REMOVED**">
                    <FormattedCurrency
                      value={currentMaturitiesValue}
                      currency={currentMaturitiesCurrency}
                    />
                  </NegativeValue>
                )}
              </LeftRight>
            </>
          )}
        </CardContent>
        <CardSeperator />
        {config.bookings.overview.hasExplicitMaturities &&
          renderedMaturities.nextMaturities &&
          hasNegativeBalance && (
            <CardAction
              icon={<ListIcon size="1.5rem" />}
              text={intl.formatMessage(messages.MaturitiesPaymentPlanOverview)}
              onClick={() => {
                trackingAdapter.sendLayerLandmark('Zahltermine');
                setPaymentPlanModalOpen(true);
              }}
            />
          )}
        {scope === 'overview' && (
          <CardAction
            icon={<BillingIcon size="1.5rem" />}
            text={intl.formatMessage(messages.MaturitiesBookingsLink)}
            onClick={navigateToAllBookings}
          />
        )}
        <Modal
          open={paymentPlanModalOpen}
          headline={intl.formatMessage({
            id: 'Maturities.paymentPlanModalHeadline',
            defaultMessage: 'Übersicht nächste Zahltermine',
          })}
          onRequestClose={() => {
            setPaymentPlanModalOpen(false);
          }}
          testId="paymentplan-modal"
          testIdClose="paymentplan-modal-close"
          noContentPadding
        >
          <EventTracking eventCategory={`${scope}_paymentPlan` as EventCategory} isLayer />
          <PaymentPlan />
        </Modal>
      </Card>
    </WithBadge>
  );
};

export default Maturities;
