// We need the '_ec' variable, and it starts with an underscore... :-(
/* eslint-disable semi,no-underscore-dangle */
import React, { FC } from 'react';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import { LoginStatus, PageProviderEntry } from '@/src/typings/Navigation';
import { Bookings, internalPages } from '@/config/pages';

declare global {
  namespace NodeJS {
    interface Global {
      version: string;
      // eslint-disable-next-line camelcase
      emma_pagegroup?: string;
      fritz: boolean;
      emma: boolean;
      _ec: {
        sid?: string;
        appUrlID?: string;
        track?: {
          global?: {
            pagetype?: {
              soasta?: string;
              long: string;
              short: string;
              displayname: string;
            };
          };
        };
      };
    }
  }

  interface Window {
    dataLayer: ArgusEvent[];
  }
}

export interface ArgusEventData {
  event?: string;
  landmarkName?: string;
  template?: string;
  pc1?: string;
  pc2?: string;
  customList?: Array<string | null>;
}

interface ArgusEvent {
  eventValue: ArgusEventData & {
    sessionId: string;
  };
  event: string;
}

export class TrackingAdapter {
  private isBrowser: boolean = isClientSide();

  private dataLayer: ArgusEvent[] = [];

  private currentPage: PageProviderEntry;

  public constructor() {
    // strict mode: inizialize currentPage
    const DummyComponent: FC = () => {
      return <></>;
    };

    this.currentPage = {
      container: DummyComponent,
      path: '',
      requiredLoginStatus: LoginStatus.NotLoggedIn,
      trackingTemplate: '',
    };

    // Soasta Tracking Variables
    global.version = 'fritz';
    global.fritz = true;
    global.emma = true;

    if (!global._ec) {
      global._ec = {};
    }
    if (!global._ec.track) {
      global._ec.track = {};
    }
    if (!global._ec.track.global) {
      global._ec.track.global = {};
    }
    if (!global._ec.track.global.pagetype) {
      global._ec.track.global.pagetype = {
        long: 'myaccount',
        short: 'STA',
        displayname: 'StaticContent-myaccount',
      };
    } else {
      global._ec.track.global.pagetype = {
        ...global._ec.track.global.pagetype,
        long: 'myaccount',
        short: 'STA',
        displayname: 'StaticContent-myaccount',
      };
    }

    if (this.isBrowser && window.dataLayer) {
      this.dataLayer = window.dataLayer;
    }
  }

  public sendLandmark(data: ArgusEventData, event = 'relaunchInspect'): void {
    this.dataLayer.push({
      event,
      eventValue: {
        ...data,
        customList: data.customList || [null],
        // eslint-disable-next-line no-underscore-dangle
        sessionId: global._ec && global._ec.sid ? global._ec.sid : 'sid',
      },
    });
  }

  // Example:  landmark=page  pc=Zahltermine   pc2=layer_myaccount
  public sendLayerLandmark(pc: 'Zahltermine' | 'PayBoxLayer' | 'eRechnung'): void {
    switch (pc) {
      case 'eRechnung':
        // https://www.imwalking.de/servlet/LandmarkServlet?clientId=1091&landmark=Zahlungsarten_MK&ssid=****&pc=PayBoxLayer&pc2=myaccount&Paylink=PayBox_Zahlungsarten&pT=****
        this.sendLandmark({
          landmarkName: 'page',
          pc1: 'Meine_Rechnungen_Meine_Rechnungsdetails',
          pc2: 'layer_myaccount',
        });
        break;

      case 'PayBoxLayer':
        // https://www.imwalking.de/servlet/LandmarkServlet?clientId=1091&landmark=Zahlungsarten_MK&ssid=****&pc=PayBoxLayer&pc2=myaccount&Paylink=PayBox_Zahlungsarten&pT=****
        this.sendLandmark({
          landmarkName: 'Zahlungsarten_MK',
          pc1: pc,
          pc2: 'myaccount',
          customList: ['Paylink=PayBox_Zahlungsarten'],
        });
        break;

      default:
        this.sendLandmark({
          landmarkName: 'page',
          pc1: pc,
          pc2: 'layer_myaccount',
        });
        break;
    }
  }

  public get page(): PageProviderEntry {
    return this.currentPage;
  }

  public set page(page: PageProviderEntry) {
    const previousPage = this.currentPage;
    this.currentPage = page;
    if (!previousPage || previousPage.path !== page.path) {
      switch (page.path) {
        case internalPages.addressbook.paths.de:
        case internalPages.addressbook.paths.fr:
          global.emma_pagegroup = 'MK_Adressbuch';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Adressbuch';
          }
          this.sendLandmark({
            landmarkName: 'page',
            pc1: 'Mein Adressbuch',
            pc2: 'myaccount',
          });
          break;
        case internalPages.bookings.paths.de:
        case internalPages.bookings.paths.fr:
        case Bookings.Invoice.deUrl:
        case Bookings.Invoice.frUrl:
        case Bookings.Payment.deUrl:
        case Bookings.Payment.frUrl:
        case Bookings.Reimbursement.deUrl:
        case Bookings.Reimbursement.frUrl:
          global.emma_pagegroup = 'MK_Meine_Buchungen';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Meine_Buchungen';
          }
          this.sendLandmark({
            landmarkName: 'page',
            pc1: 'Meine Buchungen',
            pc2: 'myaccount',
          });
          break;
        case internalPages.personaldata.paths.de:
        case internalPages.personaldata.paths.fr:
          global.emma_pagegroup = 'MK_Meine_Persoenlichen_Daten';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Meine_Persoenlichen_Daten';
          }
          this.sendLandmark({
            landmarkName: 'page',
            pc1: 'Meine persönlichen Daten',
            pc2: 'myaccount',
          });
          break;
        case internalPages.overview.paths.de:
        case internalPages.overview.paths.fr:
          global.emma_pagegroup = 'MK_Mein_Konto_Uebersicht';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Mein_Konto_Uebersicht';
          }
          this.sendLandmark({
            landmarkName: 'page',
            pc1: 'Uebersicht',
            pc2: 'myaccount',
          });
          break;
        case internalPages.shipmentinfos.paths.de:
        case internalPages.shipmentinfos.paths.fr:
          global.emma_pagegroup = 'MK_Bestellungen_Lieferungen';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Bestellungen_Lieferungen';
          }
          break;
        case internalPages.lounge.paths.de:
        case internalPages.lounge.paths.fr:
          global.emma_pagegroup = 'MK_Meine_Premiumkunden_Seite';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Meine_Premiumkunden_Seite';
          }
          break;
        default:
          global.emma_pagegroup = 'MK_Relaunch_unbekannt';
          if (global._ec.track?.global?.pagetype) {
            global._ec.track.global.pagetype.soasta = 'MK_Relaunch_unbekannt';
          }
          this.sendLandmark({
            landmarkName: 'page',
            pc1: 'Unbekannte Seite',
            pc2: 'myaccount',
          });
      }
    }
  }
}

export default new TrackingAdapter();
