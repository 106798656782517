import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMedia } from 'react-media-match';

import Accordion from '@empiriecom/module-components/Accordion';
import { Collapse } from '@empiriecom/module-components';
import { Modal } from '@empiriecom/module-components/Modal';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import getIcon from '@empiriecom/module-components/utils/getIcon';

import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';

import { PaymentPlan as FetchedPaymentPlan } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/PaymentPlan';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import PaymentPlanSkeleton from '@/src/components/PaymentPlan/skeleton';
// eslint-disable-next-line import/no-cycle
import EInvoice from '@/src/components/EInvoice';

const InvoiceIcon = getIcon('Invoice');

const Background = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  padding: 1rem;
`;

const Headline = styled.div`
  margin: 1rem 0 0.5rem 0;
  text-align: center;
  font-size: 1.25rem;
`;

const Amount = styled.div<{ isNegative?: boolean; large?: boolean }>`
  font-weight: bold;
  text-align: left;
  ${({ large }) => large && 'font-size: 1.125rem;'}
  ${({ isNegative, theme }) => isNegative && `color: ${theme.error.color.normal};`}
`;

const Date = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.base.color.textTransparent};
  text-align: left;
  font-weight: normal;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  margin: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
`;

const InvoiceWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
`;
const StyledInvoiceIcon = styled((props) => {
  // This has like 5 million props, screw that...
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InvoiceIcon {...props} />;
})`
  margin-right: 0.5rem;
`;

const PaymentPlan: FC<{ mockedPaymentPlan?: FetchedPaymentPlan | null }> = ({
  mockedPaymentPlan,
}): JSX.Element => {
  const userContext = useCustomerContext();
  const [isLoading, setIsLoading] = useState<boolean>(!mockedPaymentPlan);
  const [paymentPlan, setPaymentPlan] = useState<FetchedPaymentPlan | null>(
    mockedPaymentPlan || null,
  );
  const [eInvoiceModalOpen, setEInvoiceModalOpen] = useState<string>('');
  const intl = useIntl();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const accountApi = useAccountApi();

  const runsOnClientSide: boolean = isClientSide() && !userContext.isLoading;

  useEffect(() => {
    let isSubscribed = true;
    if (runsOnClientSide && !mockedPaymentPlan && accountApi) {
      accountApi
        .getPaymentPlan({ ecMobile: isMobile, ecLocale: userContext.ecLocale })
        .then((result) => {
          if (isSubscribed) {
            setPaymentPlan(result);
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isSubscribed) {
            setPaymentPlan(null);
            setIsLoading(false);
          }
        });
    }
    return () => {
      // Can't perform a React state update on an unmounted component
      isSubscribed = false;
    };
  }, [accountApi, runsOnClientSide, mockedPaymentPlan]);

  if (isLoading) {
    return <PaymentPlanSkeleton />;
  }

  if (!paymentPlan) {
    return (
      <div data-testid="paymentplan-error">
        <FormattedMessage
          defaultMessage="Deine Zahlungen können gerade nicht angezeigt werden. Bitte versuche es in Kürze erneut."
          id="PaymentPlan.error"
        />
      </div>
    );
  }

  return (
    <>
      <Headline>
        <FormattedMessage defaultMessage="Deine nächsten Zahlungen" id="PaymentPlan.headline" />
      </Headline>
      {!paymentPlan?.paymentEvents || paymentPlan.paymentEvents.length === 0 ? (
        <div>
          <FormattedMessage
            defaultMessage="Zum {date} sind keine Zahltermine vorhanden!"
            id="PaymentPlan.empty"
            values={{
              date: paymentPlan.checkDate,
            }}
          />
        </div>
      ) : (
        <Accordion layout="cardstyle">
          {paymentPlan?.paymentEvents.map((event, i) => (
            <Collapse
              id={event.maturityDate}
              key={event.maturityDate}
              initialIsOpen={i === 0}
              headline={
                <>
                  <Amount large data-mf-replace-inner="**REMOVED**">
                    <FormattedCurrency
                      value={event.amount?.value || 0}
                      currency={event.amount?.currencyCode || 'EUR'}
                    />
                  </Amount>
                  <Date data-mf-replace-inner="**REMOVED**">
                    <FormattedMessage defaultMessage="Fällig zum " id="PaymentPlan.due" />
                    {event.maturityDate}
                  </Date>
                </>
              }
            >
              {event.details?.map(
                (
                  { bookingText, amount, isNegative, date, documentNo, unifiedInvoiceId },
                  index,
                ) => (
                  <Card key={event.maturityDate + index}>
                    <LeftRight>
                      <div>
                        <div>{bookingText}</div>
                        <Date>{date}</Date>
                      </div>
                      <div>
                        <Amount isNegative={isNegative} data-mf-replace-inner="**REMOVED**">
                          <FormattedCurrency
                            value={amount?.value || 0}
                            currency={amount?.currencyCode || 'EUR'}
                          />
                        </Amount>
                      </div>
                    </LeftRight>
                    {unifiedInvoiceId && documentNo && (
                      <div>
                        <InvoiceWrap
                          onClick={() => setEInvoiceModalOpen(event.maturityDate + index)}
                        >
                          <StyledInvoiceIcon size="1rem" />
                          <FormattedMessage
                            defaultMessage="Rechnung Nr. {document} ansehen"
                            values={{
                              document: (
                                <span data-mf-replace-inner="**REMOVED**">
                                  &nbsp;{documentNo}&nbsp;
                                </span>
                              ),
                            }}
                            id="PaymentPlan.showInvoice"
                          />
                        </InvoiceWrap>
                        {eInvoiceModalOpen === event.maturityDate + index && (
                          <Modal
                            open
                            headline={intl.formatMessage({
                              id: 'PaymentPlan.eInvoideModalHeadline',
                              defaultMessage: 'Deine Rechnung',
                            })}
                            size="large"
                            onRequestClose={() => {
                              setEInvoiceModalOpen('');
                            }}
                            testId="einvoice-modal"
                            testIdClose="einvoice-modal-close"
                          >
                            <EInvoice id={unifiedInvoiceId} />
                          </Modal>
                        )}
                      </div>
                    )}
                  </Card>
                ),
              )}
            </Collapse>
          ))}
        </Accordion>
      )}
      <Background>
        <BorderedPanel
          headlineText={
            <FormattedMessage id="PaymentPlan.hintHeadline" defaultMessage="Bitte beachte:" />
          }
        >
          <FormattedMessage
            id="PaymentPlan.hintText"
            defaultMessage="Buchungen aus der Gesamtübersicht werden hier auf verschiedene Fälligkeitstermine aufgeteilt. Die einzelnen Rechnungen findest du eventuell unter derselben Belegnummer bei unterschiedlichen Fälligkeitsterminen wieder."
          />
        </BorderedPanel>
      </Background>
    </>
  );
};

export default PaymentPlan;
