import React from 'react';
import styled from 'styled-components';

export interface INewsBadge {
  /**
   * For the default badge children are the content of the badge
   * For the WithBadge component wrapper the children are the content you want to add a badge to
   */
  children: React.ReactNode;
  /**
   * className can be used to add additional styling to the badge itself
   */
  className?: string;
  /**
   * Value is the content of the badge, because of the size only numbers are supported for now
   * If no value is provided, no badge will be rendered
   */
  value?: number;
  /**
   * Either renders the badge in primary or secondary styling, defaults to primary
   */
  which?: 'primary' | 'secondary';
}

export const DefaultBadge = styled.div<{ which?: 'primary' | 'secondary' }>`
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 80%;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ theme, which = 'primary' }) => theme[which].color.normal};
  color: ${({ theme, which = 'primary' }) => theme[which].color.text};
  font-weight: bold;
`;

export const AbsoluteBadge = styled(DefaultBadge)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -25%);
  z-index: 2;
`;

export const BadgeContentWrapper = styled.div`
  display: block;
  position: relative;
`;

export const WithBadge = ({ className, children, value, which = 'primary' }: INewsBadge) => {
  return (
    <BadgeContentWrapper>
      {value && (
        <AbsoluteBadge className={className} which={which}>
          {value}
        </AbsoluteBadge>
      )}
      {children}
    </BadgeContentWrapper>
  );
};

export default DefaultBadge;
