import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router';
import qs from 'query-string';
import axios from 'axios';
import useLoginApi from '@empiriecom/mybuy-components/api/useLoginApi';
import { useCustomerContext } from '@empiriecom/mybuy-components';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import internalPages, { Language } from '@/config/pages';
import Loader from '@empiriecom/module-components/Loader';
import Notification from '@empiriecom/module-components/Notification';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const adfsCCMessages = defineMessages({
  errorMessage: {
    id: 'CallcenterLoginContainer.CCADFSLoginResponse.errorMessage',
    defaultMessage: 'Deine Anfrage konnte nicht verarbeitet werden. Versuche es bitte erneut!',
  },
});

export const CallCenterADFSLoginResponseContainer: React.FC = () => {
  const history = useHistory();
  const config = useConfig<FragmentConfig>();
  const { formatMessage } = useIntl();
  const [accessToken, setAccessToken] = React.useState<string | undefined>(undefined);
  const [shouldCallInternal, setShouldCallInternal] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { ecLocale } = useCustomerContext();
  const api = useLoginApi(accessToken);

  const sendADFSLogin = async (code: string | string[]) => {
    try {
      let redirectUrl: string = '';
      if (window) {
        redirectUrl = encodeURI(window.location.origin + config.ccLogin.redirectURL);
      }
      const isStaging = window.location.origin.includes('empirie.dev');

      const response = await axios.post(
        `${config.ccLogin.host}/token`,
        qs.stringify({
          client_id: isStaging ? config.ccLogin.devClientID : config.ccLogin.clientID,
          code: code,
          redirect_uri: redirectUrl,
          grant_type: 'authorization_code',
          resource: isStaging ? config.ccLogin.devResource : config.ccLogin.resource,
        }),
      );
      if (!response.data.access_token) {
        throw new Error('No access token was provided');
      }
      setAccessToken(response.data.access_token);
      setShouldCallInternal(true);
    } catch (e) {
      setErrorMessage(formatMessage(adfsCCMessages.errorMessage));
      console.error(e);
    }
  };

  const sendInternalADFSLogin = async () => {
    try {
      // should never happen but before anything explodes, just untestable
      /* istanbul ignore next */
      if (!api) {
        throw new Error('No valid api was found');
      }
      const ccResult = await api.doCallcenterADFSLogin({ ecLocale });
      setShouldCallInternal(false);
      if (ccResult.tokens) {
        apiKeyManager.setApiKeys(ccResult.tokens);
        const goToCCPage = internalPages.callcenterLogin.paths[ecLocale.split('-')[0] as Language];
        history.replace(goToCCPage);
      } else {
        throw new Error('No valid response was provided');
      }
    } catch (e) {
      setShouldCallInternal(false);
      setErrorMessage(formatMessage(adfsCCMessages.errorMessage));
      console.error(e);
    }
  };

  React.useEffect(() => {
    const queryParams = qs.parse(history.location.search);
    if (queryParams.error && queryParams.error_description) {
      const errorDescription = Array.isArray(queryParams.error_description)
        ? queryParams.error_description[0]
        : queryParams.error_description;
      setErrorMessage(decodeURIComponent(errorDescription));
    }
    if (queryParams.code && !accessToken) {
      sendADFSLogin(queryParams.code);
    }
  }, [history.location, accessToken]);

  React.useEffect(() => {
    /*
     * Short note: this hook ONLY should be called when the api changes after the access token was applied,
     * therefore only the api should be in the dependency array
     */
    if (api && shouldCallInternal) {
      sendInternalADFSLogin();
    }
  }, [api]);

  if (errorMessage) {
    return <Notification level="error" isInline content={errorMessage} />;
  }

  return (
    <div data-testid="loading">
      <Loader size="1rem" />
    </div>
  );
};

export default CallCenterADFSLoginResponseContainer;
