import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Input } from '@empiriecom/module-components/FormControls/Input';
import { TouchDetectingDateInput } from '@empiriecom/module-components/FormControls/Input/DateInput';
import { NotificationProps } from '@empiriecom/module-components/Notification';

import CCLoginFormContainer from './CCLoginFormContainer';

/**
 * @TODO extract customerNo and dob input components and use in activateemail feature and here after MR is merged
 */

interface CCLoginAsCustomerState {
  customerNo: string;
  dateOfBirth: string;
}

export interface CCLoginAsCustomerData {
  customerNo: string;
  dateOfBirth: Date;
}

const messages = defineMessages({
  title: {
    id: 'CCLoginAsCustomer.title',
    defaultMessage: 'Anmeldung als Kunde',
  },
  submitLabel: {
    id: 'CCLoginAsCustomer.submitLabel',
    defaultMessage: 'Als Kunde anmelden',
  },
  customerNoLabel: {
    id: 'CCLoginAsCustomer.customerNoLabel',
    defaultMessage: 'Kundennummer',
  },
  dob: {
    id: 'CCLoginAsCustomer.dobLabel',
    defaultMessage: 'Geburtsdatum',
  },
});

function CCLoginAsCustomer({
  onSubmit,
  error,
}: {
  onSubmit?: (data: CCLoginAsCustomerData) => void;
  error?: NotificationProps['content'];
}) {
  const [data, setData] = useState<CCLoginAsCustomerState>({
    customerNo: '',
    dateOfBirth: '',
  });

  const handleDateChange = (date: Date | undefined) => {
    let stateDate = '';
    /* istanbul ignore else */
    if (date) {
      stateDate = date.toISOString().substr(0, 10);
    }
    setData({ ...data, dateOfBirth: stateDate });
  };

  const handleChange = (prop: 'customerNo') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const update = { ...data, [prop]: e.target.value };
    setData(update);
  };

  const handleSubmit = () => {
    onSubmit?.({ customerNo: data.customerNo, dateOfBirth: new Date(data.dateOfBirth) });
  };

  const { formatMessage } = useIntl();

  return (
    <CCLoginFormContainer
      error={error}
      title={formatMessage(messages.title)}
      disableSubmit={!data.customerNo || !data.dateOfBirth}
      onSubmit={handleSubmit}
      submitLabel={formatMessage(messages.submitLabel)}
    >
      <div>
        <Input
          data-testid="cc-login-customerNo"
          onChange={handleChange('customerNo')}
          value={data.customerNo}
          placeholder="12345"
          label={formatMessage(messages.customerNoLabel)}
        />
      </div>
      <div>
        <TouchDetectingDateInput
          data-testid="cc-login-dateOfBirth"
          onDateChange={handleDateChange}
          value={data.dateOfBirth}
          label={formatMessage(messages.dob)}
        />
      </div>
    </CCLoginFormContainer>
  );
}

export default CCLoginAsCustomer;
