import { Theme } from '@empiriecom/module-components/theme/interface';
import { Select } from '@empiriecom/module-components/FormControls/Select';

import styled from 'styled-components';

export const ShipmentSelect = styled(Select)`
  min-width: 10rem;
  background: ${({ theme }: { theme: Theme }) =>
    `linear-gradient(to bottom, #ffffff 0%,${theme.greyLight.color.normal} 100%)`};
`;

export default ShipmentSelect;
