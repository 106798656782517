import React, { FC } from 'react';
import Card, { CardAction } from '@empiriecom/module-components/Card';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import Notification from '@empiriecom/module-components/Notification';
import { messages as AccountInformationMessages } from '../PaymentBox/AccountInformation/messages';
import { formatCurrency } from '@empiriecom/module-components/FormattedCurrency';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import { Link } from '@empiriecom/module-components';
import getIconSet from '@empiriecom/module-components/utils/getIconSet';
import { CustomerCreditDataPaymentData } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import internalPages, { Language } from '@/config/pages';
import { DisplayAccountBalance } from '../Maturities';
import { PartialPaymentCardSkeleton } from './FlexikontoSkeleton';
import { useHistory } from 'react-router';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const LeftRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.75rem;
`;

const Label = styled.div`
  font-weight: bold;
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.5rem 0;

  &.noMargin {
    margin: 0;
  }
`;

const InternalCard = styled(Card)`
  margin: 0.75rem 0;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
`;

const GridLeftRight = styled.div`
  font-size: 0.813rem;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Creates 2 columns */
  justify-items: flex-start;
  text-align: left;
`;

const HeadLine = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.5rem 0;
`;
const StyledBorderedPanel = styled(BorderedPanel)`
  margin: 0.75rem 0;
`;
const LightTransparentText = styled.div`
  color: ${({ theme }) => theme.greyLight.color.textTransparent};
  display: inline-block;
`;
const QRCodeImage = styled.img`
  width: 9.75rem;
  height: 9.75rem;
  margin: 1.25rem;
`;

// const UnstyledLink = styled(Link)`
//   text-decoration: none;
//   &:hover {
//     text-decoration: none;
//     svg {
//       fill: ${({ theme }) => theme.primary.color.normal};
//     }
//   }
// `;

const { Billing: BillingIcon, Installments: InstallmentsIcon } = getIconSet();

const messages = defineMessages({
  tip: {
    id: 'PartialPayment.Birthdate',
    defaultMessage:
      'Bitte überweisen Sie <strong>{currentAmountDue}</strong> bis zum <strong>{currentAmountDueDate}</strong> auf folgendes Konto:',
  },
  openBalance: {
    id: 'PartialPayment.openBalance',
    defaultMessage: 'OFFENER SALDO',
  },
  accountBalanceHeadline: {
    id: 'PartialPayment.AccountBalanceHeadline',
    defaultMessage: 'Kontostand',
  },
  currentAmountDue: {
    id: 'PartialPayment.CurrentAmountDue',
    defaultMessage: 'Ihr fälliger Betrag <italic>(inkl. Mindestrate):</italic>',
  },
  currentMinimumRate: {
    id: 'PartialPayment.MinimumRate',
    defaultMessage: 'Aktuelle Mindestrate',
  },
  currentRsvFee: {
    id: 'PartialPayment.RsvFee',
    defaultMessage: 'Aktuelle Prämie Zahlschutz',
  },
  rsvFeeIncluded: {
    id: 'PartialPayment.RsvFeeIncluded',
    defaultMessage: 'inkl. Prämie Zahlschutz',
  },
  allBookings: {
    id: 'PartialPayment.AllBookings',
    defaultMessage: 'Alle Buchungen',
  },
  or: {
    id: 'PartialPayment.Or',
    defaultMessage: 'ODER',
  },
  instructionsQr: {
    id: 'PartialPayment.InstructionsQr',
    defaultMessage: 'Bezahlen Sie bequem über den unten stehenden QR-Code in der App Ihrer Bank:',
  },
  inlineNotifcation: {
    id: 'PartialPayment.InlineNotification',
    defaultMessage:
      'Unser Treuebonus für Sie: {break}Sie können noch für bis zu {amountUntilChange} € bei uns bestellen – Ihre aktuelle monatliche Mindestrate bleibt gleich!',
  },
  toMyAccountStatements: {
    id: 'PartialPayment.ToMyAccountStatements',
    defaultMessage: 'Zu meinen Kontoauszügen',
  },
  purpose: {
    id: 'PartialPayment.Purpose',
    defaultMessage: 'Verwendungszweck:',
  },
  cardHeadline: {
    id: 'PartialPayment.CardHeadline',
    defaultMessage: 'Flexikonto Teilzahlung',
  },
  accountInformationReceiver: {
    id: 'PartialPayment.AccountInformationReceiver',
    defaultMessage: 'Empfänger',
  },
});
const PartialPaymentCard: FC<{ paymentData?: CustomerCreditDataPaymentData }> = ({
  paymentData,
}): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const allBookingsLink = internalPages.bookings.paths[intl.locale.split('-')[0] as Language];
  const {
    flexikonto: { rsvFeeIncluded, displayMinimumRate },
    show: { bic },
  } = useConfig<FragmentConfig>();

  if (!paymentData) {
    return <PartialPaymentCardSkeleton />;
  }
  const {
    bankTransferReference,
    overdueMinimumRateWithRsvFee,
    currentAccountBalance,
    paymentQrCode,
    nextMaturityDate,
    overdueMinimumRate,
    rsvFee,
    availableLimit,
  } = paymentData ?? {};
  const hasNegativeBalance =
    (typeof currentAccountBalance?.value !== 'undefined' && currentAccountBalance?.value < 0) ??
    false;
  return (
    <GreyBox>
      <Card
        showHeaderSeparator={false}
        headerIcon={<InstallmentsIcon />}
        headerVariant="additional"
        roundedIcon={false}
        headline={intl.formatMessage(messages.cardHeadline)}
        shouldHideHeaderDataForMouseflow
      >
        <HR className="noMargin" />
        <CardContent>
          <LeftRight>
            <Label>{intl.formatMessage(messages.accountBalanceHeadline)}</Label>
            <Label>
              <DisplayAccountBalance currentAccountBalance={currentAccountBalance} />
            </Label>
          </LeftRight>
          {hasNegativeBalance && overdueMinimumRateWithRsvFee && (
            <>
              {/* box/table */}
              <InternalCard elevationLevel="0dp">
                <CardContent>
                  <LeftRight>
                    <Label>
                      {intl.formatMessage(messages.currentAmountDue, {
                        italic: (...chunks: string[]) => (
                          <LightTransparentText>
                            <i>{chunks}</i>
                          </LightTransparentText>
                        ),
                      })}
                    </Label>
                    <Label>
                      {overdueMinimumRateWithRsvFee &&
                        formatCurrency(
                          overdueMinimumRateWithRsvFee.currencyCode,
                          overdueMinimumRateWithRsvFee.value,
                          intl,
                        )}
                    </Label>
                  </LeftRight>
                  <HR />
                  {displayMinimumRate && (
                    <LeftRight className="lightText">
                      <LightTransparentText>
                        {intl.formatMessage(messages.currentMinimumRate)}
                      </LightTransparentText>
                      <LightTransparentText>
                        {overdueMinimumRate &&
                          formatCurrency(
                            overdueMinimumRate.currencyCode,
                            overdueMinimumRate.value,
                            intl,
                          )}
                      </LightTransparentText>
                    </LeftRight>
                  )}
                  {rsvFeeIncluded && rsvFee && (
                    <LeftRight className="lightText">
                      <LightTransparentText>
                        {intl.formatMessage(messages.rsvFeeIncluded)}
                      </LightTransparentText>
                    </LeftRight>
                  )}
                  {!rsvFeeIncluded && rsvFee && (
                    <LeftRight className="lightText">
                      <LightTransparentText>
                        {intl.formatMessage(messages.currentRsvFee)}
                      </LightTransparentText>
                      <LightTransparentText>
                        {formatCurrency(rsvFee.currencyCode, rsvFee.value, intl)}
                      </LightTransparentText>
                    </LeftRight>
                  )}
                  <Link
                    to={allBookingsLink}
                    style={{ marginTop: '0.5rem', display: 'inline-block' }}
                  >
                    {intl.formatMessage(messages.allBookings)}
                  </Link>
                </CardContent>
              </InternalCard>
              {/* payment hint */}
              {overdueMinimumRateWithRsvFee &&
                nextMaturityDate &&
                intl.formatMessage(messages.tip, {
                  currentAmountDue: formatCurrency(
                    overdueMinimumRateWithRsvFee.currencyCode,
                    overdueMinimumRateWithRsvFee.value,
                    intl,
                  ),
                  currentAmountDueDate: nextMaturityDate,
                  strong: (...msg: string[]) => <strong>{msg}</strong>,
                })}
              {/* box for bank transfer */}
              <StyledBorderedPanel>
                <div>
                  <HeadLine>{intl.formatMessage(AccountInformationMessages.headline)}</HeadLine>
                  <GridLeftRight>
                    <div>{intl.formatMessage(messages.accountInformationReceiver)}</div>
                    <div>
                      {intl.formatMessage(AccountInformationMessages.receiverName)}
                      <br />
                      {intl.formatMessage(AccountInformationMessages.recieverAdditionals, {
                        break: <br />,
                      })}
                    </div>
                    {intl.formatMessage(AccountInformationMessages.iban, {
                      split: (...msg: string[]) => <div style={{ textAlign: 'start' }}>{msg}</div>,
                    })}
                    {bic &&
                      intl.formatMessage(AccountInformationMessages.bic, {
                        split: (...msg: string[]) => <div>{msg}</div>,
                      })}
                    {intl.formatMessage(messages.purpose)}
                    {bankTransferReference && (
                      <div style={{ textAlign: 'start' }}>
                        <strong>{bankTransferReference}</strong>
                      </div>
                    )}
                  </GridLeftRight>
                </div>
              </StyledBorderedPanel>
              {/* qr code */}
              {paymentQrCode && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '0.75rem 0',
                    }}
                  >
                    <HR style={{ width: '33%' }} />
                    {intl.formatMessage(messages.or)}
                    <HR style={{ width: '33%' }} />
                  </div>
                  <>
                    {intl.formatMessage(messages.instructionsQr)}
                    <div style={{ margin: '1rem 0', textAlign: 'center' }}>
                      {paymentQrCode && <QRCodeImage src={paymentQrCode} />}
                    </div>
                  </>
                </>
              )}
              {/* notification for OTB (open to buy) hint */}
              {availableLimit && availableLimit.value >= 1000 && (
                <>
                  <HR />
                  <Notification
                    level="info"
                    isInline
                    children={intl.formatMessage(messages.inlineNotifcation, {
                      break: <br />,
                      amountUntilChange: formatCurrency(
                        availableLimit.currencyCode,
                        availableLimit.value,
                        intl,
                      ),
                    })}
                  />
                </>
              )}
              {/* <HR className="marginGrowLeftRight" />
              <LeftRight style={{ padding: '0.25rem', alignItems: 'center' }}>
                <UnstyledLink to={allBookingsLink}>
                  {intl.formatMessage(messages.toMyAccountStatements)}
                  <ArrowMediumRightIcon />
                </UnstyledLink>
              </LeftRight> */}
            </>
          )}
        </CardContent>
        {hasNegativeBalance && !overdueMinimumRateWithRsvFee && (
          <>
            {/* show booking link although overdue box is not shown */}
            <HR className="noMargin" />
            <CardAction
              icon={<BillingIcon size="1.5rem" />}
              text={intl.formatMessage(messages.allBookings)}
              onClick={() => history.push(allBookingsLink)}
            />
          </>
        )}
      </Card>
    </GreyBox>
  );
};

export default PartialPaymentCard;
