import React, { useEffect, useState } from 'react';

import { apiKeyManager, ApiKeyManagerImpl } from '@empiriecom/mybuy-session/ApiKey';

import ActivityIndicator from '@empiriecom/mybuy-components/components/LoginContainer/ActivityIndicator';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useLogin from '@empiriecom/mybuy-components/components/LoginContainer/useLogin';

import { useHistory } from 'react-router-dom';
import { internalPages, Language } from '@/config/pages';
import CCLogin, { CCEmailPasswordLoginParams } from './CCLogin';
import CCLoginAsCustomer, { CCLoginAsCustomerData } from './CCLoginAsCustomer';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

function CallcenterLoginContainer() {
  const history = useHistory();
  const config = useConfig<FragmentConfig>();
  const { token, ecLocale } = useCustomerContext();
  const { callCenterLogin, loading, error, legacyLogin } = useLogin();
  const [showLegacyLogin, setShowLegacyLogin] = useState<boolean>(false);

  const handleSubmit = (credentials: CCEmailPasswordLoginParams) => {
    callCenterLogin(credentials);
  };

  const handleLoginAsCustomer = (credentials: CCLoginAsCustomerData) => {
    legacyLogin(
      {
        accountNumber: credentials.customerNo,
        birthdate: credentials.dateOfBirth,
      },
      false,
    ).then(() => {
      const goToOverview = internalPages.overview.paths[ecLocale.split('-')[0] as Language];
      history.replace(goToOverview);
    });
  };

  useEffect(() => {
    if (!token) {
      setShowLegacyLogin(false);
      return;
    }

    const contents = (apiKeyManager as ApiKeyManagerImpl).getTokenContents(token);

    if (
      typeof window !== 'undefined' &&
      contents?.role !== 1 &&
      contents?.type !== 6 &&
      config.ccLogin.ADFSEnabled
    ) {
      const redirectUrl = encodeURI(window.location.origin + config.ccLogin.redirectURL);
      const isStaging = window.location.origin.includes('empirie.dev');

      window.location.href = `${config.ccLogin.host}/authorize?response_type=code&client_id=${
        isStaging ? config.ccLogin.devClientID : config.ccLogin.clientID
      }&resource=${
        isStaging ? config.ccLogin.devResource : config.ccLogin.resource
      }&redirect_uri=${redirectUrl}`;

      return;
    }

    setShowLegacyLogin(contents?.role === 1);
  }, [config, token]);

  return (
    <ActivityIndicator active={loading}>
      {showLegacyLogin ? (
        <CCLoginAsCustomer onSubmit={handleLoginAsCustomer} error={error || undefined} />
      ) : (
        <CCLogin onSubmit={handleSubmit} error={error || undefined} />
      )}
    </ActivityIndicator>
  );
}

export default CallcenterLoginContainer;
