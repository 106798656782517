import { FC, useEffect } from 'react';
import useEventTracking, { EventCategory } from '../../hooks/useEventTracking';

type EventTrackingProps = {
  /** EventCategory from useEventTracking hook */
  eventCategory: EventCategory;
  /** isLayer from useEventTracking hook */
  isLayer?: boolean;
  /** if you need to trigger the same event twice, change this number */
  triggerId?: number;
};

export const EventTracking: FC<EventTrackingProps> = ({
  eventCategory,
  isLayer,
  triggerId = 0,
}) => {
  const dispatch = useEventTracking();
  const eventAction = isLayer ? 'viewLayer' : 'viewPage';
  useEffect(() => {
    dispatch(eventCategory, eventAction);
  }, [eventCategory, triggerId]);
  return null;
};

export default EventTracking;
