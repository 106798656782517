import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Skeleton from '@empiriecom/module-components/Skeleton';
import Card, { CardAction, CardSeperator } from '@empiriecom/module-components/Card';
import getIconSet from '@empiriecom/module-components/utils/getIconSet';
import { Link } from '@empiriecom/module-components/Link';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import useShipments from '@/src/hooks/useShipments';
import internalPages from '@/config/pages';
import { FragmentConfig } from '@/config/types';
import { WithBadge } from '@/src/components/NewsBadge';
import ProgressBar from '@/src/components/ProgressBar';
import PrepaymentHint from '@/src/components/PrepaymentHint';
import { CustomerStatusShipments } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

interface IShipmentInformation {
  /**
   * Toggles the component between loading and data display state
   */
  mockedLoading?: boolean;

  /**
   * Shipment data, look at CustomerStatusShipments
   */
  mockedData?: CustomerStatusShipments[];

  /**
   * Number to show in the badge, no value equals no badge
   */
  mockedNews?: number;
}

const ShipmentSkeleton = styled(Skeleton)`
  border-radius: 5px;
`;

const ShipmentCardContentWrapper = styled.div`
  display: block;
  padding: 0.5rem 1rem;
`;

const StyledProgressBar = styled(ProgressBar)`
  display: block;
  max-width: 15rem;
`;

const LinkWrapper = styled(Link)`
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  & > * {
    margin-right: 0.5rem;
  }
`;

const StyledShipmentShort = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  *:first-child {
    flex-grow: 1;
  }
`;

const VerticalSpacer = styled.div`
  margin: 0.25rem 0rem;
`;

const StyledShipmentDate = styled.div`
  color: ${({ theme }) => theme.base.color.textTransparent};
`;

const { Tracking, ArrowLargeRight } = getIconSet();

const messages = defineMessages({
  ShipmentCardHeadline: {
    id: 'OverviewContainer.ShipmentInformation.card.headline',
    defaultMessage: 'Bestellungen auf dem Weg',
  },
  ShipmentGoToAll: {
    id: 'OverviewContainer.ShipmentInformation.goToAll',
    defaultMessage: 'Alle Bestellungen & Lieferungen',
  },
});

const ShipmentDisplayWrapper: React.FC<{ badge?: number; allShipmentsLink: string }> = ({
  children,
  badge,
  allShipmentsLink,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const {
    overview: { newsBadge },
  } = useConfig<FragmentConfig>();

  const navigateToAllShipments = () => {
    history.push(allShipmentsLink);
  };

  return (
    <WithBadge value={badge} which={newsBadge.color}>
      <Card headline={formatMessage(messages.ShipmentCardHeadline)} showHeaderSeparator>
        <PrepaymentHint />
        <ShipmentCardContentWrapper>{children}</ShipmentCardContentWrapper>
        <CardSeperator />
        <CardAction
          icon={<Tracking size="1.5rem" />}
          text={formatMessage(messages.ShipmentGoToAll)}
          onClick={navigateToAllShipments}
        />
      </Card>
    </WithBadge>
  );
};

const SingleShipment: React.FC<{
  data: CustomerStatusShipments;
  showSeperator?: boolean;
  allShipmentsLink: string;
}> = ({ data, showSeperator, allShipmentsLink }) => {
  return (
    <div data-mf-replace-inner="**REMOVED**">
      <StyledShipmentShort>
        <div>
          {data.name && <VerticalSpacer>{data.name}</VerticalSpacer>}
          <VerticalSpacer>
            <StyledProgressBar testId="shipment-progress" percentage={data.percentage} />
          </VerticalSpacer>
          {data.info && <VerticalSpacer>{data.info}</VerticalSpacer>}
          {data.orderDate && (
            <VerticalSpacer>
              <StyledShipmentDate>{data.orderDate}</StyledShipmentDate>
            </VerticalSpacer>
          )}
        </div>
        <LinkWrapper to={allShipmentsLink}>
          <ArrowLargeRight />
        </LinkWrapper>
      </StyledShipmentShort>
      {showSeperator && <CardSeperator />}
    </div>
  );
};

const ShipmentInformation = ({ mockedLoading, mockedData, mockedNews }: IShipmentInformation) => {
  const { loading, news, data } = useShipments();
  const { locale } = useIntl();

  const goToAllShipmentsLink =
    locale.split('-')[0] === 'fr'
      ? internalPages.shipmentinfos.paths.fr
      : internalPages.shipmentinfos.paths.de;

  const renderedLoading = mockedLoading !== undefined ? mockedLoading : loading;
  const renderedData = mockedData || data || undefined;
  const renderedNews = mockedNews || news || undefined;

  if (renderedLoading) {
    return (
      <div data-testid="shipment-skeleton">
        <ShipmentSkeleton height="10rem" />
      </div>
    );
  }

  if (!renderedData || renderedData.length < 1) {
    return (
      <ShipmentDisplayWrapper badge={renderedNews} allShipmentsLink={goToAllShipmentsLink}>
        <FormattedMessage
          id="OverviewContainer.ShipmentInformation.fallback.text"
          defaultMessage="Leider ist keine Sendung zu dir unterwegs."
        />
      </ShipmentDisplayWrapper>
    );
  }

  return (
    <ShipmentDisplayWrapper badge={renderedNews} allShipmentsLink={goToAllShipmentsLink}>
      {renderedData &&
        renderedData.map((shipmentEntry, index) => (
          <SingleShipment
            data={shipmentEntry}
            showSeperator={index < renderedData.length - 1}
            allShipmentsLink={goToAllShipmentsLink}
          />
        ))}
    </ShipmentDisplayWrapper>
  );
};

export default ShipmentInformation;
