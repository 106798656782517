import React from 'react';
import Card from '@empiriecom/module-components/Card';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import FormattedCurrency from '@empiriecom/module-components/FormattedCurrency';
import {
  BankstatementsCircleData,
  BankstatementsCircleDataInnerTextTypeEnum,
  BankstatementsCircleDataSublineTextTypeEnum,
  BankstatementsTable,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';

const LeftRight = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    flex-direction: row-reverse;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    width: 60%;
  }
`;
const RightWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    width: 16rem;
  }
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.5rem 0;
`;

const Circle = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  font-size: 0.75rem;
  text-align: center;
  font-weight: bold;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  padding: 1.5rem;
  border-radius: 150px;
  border: solid 8px ${({ theme }) => theme.additional.color.normal};
`;

const CircleContent = styled.div`
  white-space: nowrap;
  width: 125%;
  margin-left: -0.75rem;
`;

const GrandTotal = styled.div`
  font-size: 1.313rem;
`;

const CircleStats = (props: { circle: BankstatementsCircleData; table: BankstatementsTable }) => {
  return (
    <Card>
      <CardContent>
        <RightWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Circle>
              <CircleContent>
                {props.circle.innerTextType ===
                BankstatementsCircleDataInnerTextTypeEnum.Withdraw ? (
                  <FormattedMessage
                    id="CircleStats.innerTextType.withdraw"
                    defaultMessage="Am {date}{break}wird dieser Betrag{break}eingezogen"
                    values={{
                      break: <br />,
                      date: 'test',
                    }}
                  />
                ) : null}
                {props.circle.innerTextType === BankstatementsCircleDataInnerTextTypeEnum.ToPay ? (
                  <FormattedMessage
                    id="CircleStats.innerTextType.toPay"
                    defaultMessage="Am {date}{break}wird dieser Betrag{break}eingezogen"
                    values={{
                      break: <br />,
                      date: 'test',
                    }}
                  />
                ) : null}
              </CircleContent>
              <GrandTotal>
                <FormattedCurrency
                  value={props.circle.value.value}
                  currency={props.circle.value.currencyCode}
                />
              </GrandTotal>
            </Circle>
            <div style={{ textAlign: 'center' }}>
              {props.circle.sublineTextType ===
              BankstatementsCircleDataSublineTextTypeEnum.IncreasingPossible ? (
                <FormattedMessage
                  id="CircleStats.sublineTextType.increasingPossible"
                  defaultMessage="Am {break}wird dieser Betrag{break}eingezogen"
                  values={{
                    break: <br />,
                  }}
                />
              ) : null}
              {props.circle.sublineTextType ===
              BankstatementsCircleDataSublineTextTypeEnum.IncreasedDueToCustomersWish ? (
                <FormattedMessage
                  id="CircleStats.sublineTextType.increasedDueToCustomersWish"
                  defaultMessage="Am {break}wird dieser Betrag{break}eingezogen"
                  values={{
                    break: <br />,
                  }}
                />
              ) : null}
            </div>
          </div>
        </RightWrapper>
        <LeftWrapper>
          <BorderedPanel>
            <LeftRight>
              <div>
                <FormattedMessage
                  id="MinimalMaturities.monthlyRate"
                  defaultMessage="Monatlicher Betrag"
                />
              </div>
              <div>
                <FormattedCurrency
                  value={props.table.current.value}
                  currency={props.table.current.currencyCode}
                />
              </div>
            </LeftRight>
            <>
              <HR />
              <LeftRight>
                <div>
                  <FormattedMessage id="MinimalMaturities.overdue" defaultMessage="Rückstand" />
                </div>
                <div>
                  <FormattedCurrency
                    value={props.table.delayed.value}
                    currency={props.table.delayed.currencyCode}
                  />
                </div>
              </LeftRight>
            </>
            <>
              <HR />
              <LeftRight>
                <div>
                  <strong>
                    <FormattedMessage
                      id="MinimalMaturities.grandTotal"
                      defaultMessage="Minimal zahlbar"
                    />
                  </strong>
                </div>
                <div>
                  <strong>
                    <FormattedCurrency
                      value={props.table.minimumPayment.value}
                      currency={props.table.minimumPayment.currencyCode}
                    />
                  </strong>
                </div>
              </LeftRight>
            </>
          </BorderedPanel>
        </LeftWrapper>
      </CardContent>
    </Card>
  );
};

export default CircleStats;
