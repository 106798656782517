import { FunctionComponent } from 'react';
import { MessageDescriptor } from 'react-intl';

export const enum InternalPageContainer {
  Overview = 1,
  PersonalData,
  Addressbook,
  Bookings,
  ShipmentInfos,
  CallcenterLogin,
  Lounge,
  Flexikonto,
  Bankstatements,
  PasswordForget,
  CallcenterADFSLoginResponse,
}

// eslint-disable-next-line import/prefer-default-export
export enum LoginStatus {
  LoggedIn,
  NotLoggedIn,
}

export type PageProviderEntry = {
  path: string;
  isPrefix?: boolean;
  container: FunctionComponent;
  requiredLoginStatus: LoginStatus;
  metaData?: InternalPageMetadata;
  trackingTemplate: string;
};

// This needs to live here to prevent a circular dependency
export type InternalPageMetadata = {
  title?: MessageDescriptor;
};
