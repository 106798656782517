import deepmerge from 'deepmerge';
import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import { FragmentConfig } from '@/config/types';

const deepmergeconfig = (
  left: FragmentConfig,
  right: FragmentConfig | Partial<FragmentConfig> | DeepPartial<FragmentConfig>,
): FragmentConfig =>
  deepmerge<FragmentConfig>(left, right as Partial<FragmentConfig>, {
    arrayMerge: <T>(_destination: T[], source: T[]) => source,
  });

export default deepmergeconfig;
