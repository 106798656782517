import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { VerificationStatus } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import Notification from '@empiriecom/module-components/Notification';
import { useCustomerApi } from '@empiriecom/mybuy-components';

const TextCTA = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const CustomNotification = styled(Notification)`
  animation: none;

  &.inline {
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
`;

const messages = defineMessages({
  verificationError: {
    id: 'VerificationNotification.error',
    defaultMessage: 'Das hat leider nicht funktioniert. Versuch es bitte nochmal.',
  },
});

export const VerificationNotificationContent: React.FC<{
  status?: VerificationStatus;
  email: string;
  onTrigger: () => Promise<void>;
}> = ({ status, email, onTrigger }) => {
  switch (status) {
    case VerificationStatus.NotVerified: {
      return (
        <CustomNotification level="warning" isInline>
          <div>
            <FormattedMessage
              id="VerificationNotification.Text"
              defaultMessage="Bestätige deine E-Mail-Adresse, um die <strong>Sicherheit deines Kontos zu erhöhen</strong>."
              values={{
                strong: (...chunks: string[]) => <strong>{chunks}</strong>,
              }}
            />
          </div>
          <TextCTA onClick={onTrigger}>
            <FormattedMessage
              id="VerificationNotification.VerifyCTA"
              defaultMessage="E-Mail Adresse bestätigen"
            />
          </TextCTA>
        </CustomNotification>
      );
    }
    case VerificationStatus.WaitingForClick: {
      return (
        <CustomNotification level="info" isInline>
          <div>
            <FormattedMessage
              id="VerificationNotification.VerifyAgainText"
              defaultMessage="Wir haben dir einen <strong>Bestätigungslink</strong> an <strong>{email}</strong> geschickt. Du hast keine Mail bekommen? Sieh ggf. noch mal im Spamordner nach. Oder du kannst die "
              values={{
                strong: (...chunks: string[]) => <strong>{chunks}</strong>,
                email: <span data-mf-replace-inner="**REMOVED**">{email}</span>,
              }}
            />
          </div>
          <TextCTA onClick={onTrigger}>
            <FormattedMessage
              id="VerificationNotification.VerifyAgainCTA"
              defaultMessage="Mail erneut anfordern"
            />
          </TextCTA>
        </CustomNotification>
      );
    }
    default:
      return null;
  }
};

export const VerificationNotification: React.FC<{}> = () => {
  const { customer, actions, ecLocale } = useCustomerContext();
  const api = useCustomerApi();
  const [error, setError] = useState<string | null>(null);
  const intl = useIntl();

  if (!customer || !customer.email || !api) {
    return null;
  }

  const { verificationStatus, email } = customer;
  const triggerVerification = async () => {
    setError(null);
    try {
      const result = await api.triggerEmailVerification({
        ecLocale,
      });
      actions.update(result);
    } catch {
      setError(intl.formatMessage(messages.verificationError));
    }
  };

  return (
    <>
      {error && (
        <CustomNotification level="error" isInline>
          {error}
        </CustomNotification>
      )}
      <VerificationNotificationContent
        status={verificationStatus}
        email={email}
        onTrigger={triggerVerification}
      />
    </>
  );
};

export default VerificationNotification;
