import React from 'react';
import styled from 'styled-components';

export interface IProgressBar {
  /**
   * Can be used to provide a custom test-id
   */
  testId?: string;

  /**
   * Percentage filled of the progress bar, only values in range of 0-100 effect the UI
   */
  percentage?: number;

  /**
   * Can be used to change the styling of the component with styled-components
   */
  className?: string;
}

const StyledProgressBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.5rem;
  position: relative;
  background-color: ${({ theme }) => theme.greyNormal.color.normal};
  border-radius: 10px;
`;

const FilledProgressBar = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 0.5rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.primary.color.normal};
  border-radius: 10px;
`;

const ProgressBar: React.FC<IProgressBar> = ({ testId, percentage, className }) => {
  const getCorrectedPercentage = (value: number | undefined): number => {
    if (!value) {
      return 0;
    }
    if (value >= 100) {
      return 100;
    }
    if (value <= 0) {
      return 0;
    }
    return value;
  };

  return (
    <StyledProgressBar data-testid={testId || 'progress-bar'} className={className}>
      <FilledProgressBar width={getCorrectedPercentage(percentage)} />
    </StyledProgressBar>
  );
};

export default ProgressBar;
