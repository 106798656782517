import { defineMessages } from 'react-intl';
import { ValidationRules } from '@empiriecom/module-components/FormControls/Input/BasicInput';
import { ReclamationFormFieldName, ReclamationFormFieldState } from './types';

type ValidationSchema = {
  [key in ReclamationFormFieldName]: ValidationRules;
};

export const messages = defineMessages({
  articleNumberRequired: {
    id: 'ReclamationForm.articleNumber.required',
    defaultMessage: 'Die Artikelnummer wird benötigt!',
  },
  articleDescriptionRequired: {
    id: 'ReclamationForm.articleDescription.required',
    defaultMessage: 'Die Artikelbeschreibung wird benötigt!',
  },
  firstNameRequired: {
    id: 'ReclamationForm.firstName.required',
    defaultMessage: 'Der Vorname wird benötigt!',
  },
  lastNameRequired: {
    id: 'ReclamationForm.lastName.required',
    defaultMessage: 'Der Nachname wird benötigt!',
  },
  streetRequired: {
    id: 'ReclamationForm.street.required',
    defaultMessage: 'Die Straße wird benötigt!',
  },
  houseNumberRequired: {
    id: 'ReclamationForm.houseNumber.required',
    defaultMessage: 'Die Hausnummer wird benötigt!',
  },
  zipCodeRequired: {
    id: 'ReclamationForm.zipCode.required',
    defaultMessage: 'Die PLZ wird benötigt!',
  },
  cityRequired: {
    id: 'ReclamationForm.city.required',
    defaultMessage: 'Der Ort wird benötigt!',
  },
  emailRequired: {
    id: 'ReclamationForm.email.required',
    defaultMessage: 'Die E-Mail-Adresse wird benötigt!',
  },
  reclamationDescriptionRequired: {
    id: 'ReclamationForm.reclamationDescription.required',
    defaultMessage: 'Die Mängelbeschreibung wird benötigt!',
  },
});

export const validationConfig: ValidationSchema = {
  articleNumber: [
    {
      required: true,
      error: messages.articleNumberRequired,
    },
  ],
  articleDescription: [
    {
      required: true,
      error: messages.articleDescriptionRequired,
    },
  ],
  shippingFirstName: [],
  shippingLastName: [],
  shippingStreet: [],
  shippingHouseNumber: [],
  shippingZipCode: [],
  shippingCity: [],
  reclamationReason: [],
  reclamationDescription: [
    {
      required: true,
      error: messages.reclamationDescriptionRequired,
    },
  ],
  replacementNumber: [],
  dataTypeShield: [],
  contactBy: [],
};

export const validateField = (
  fieldName: ReclamationFormFieldName,
  fieldState: ReclamationFormFieldState,
) => {
  const rulesForThisField = validationConfig[fieldName];
  let newErrorMessage;
  for (let i = 0; i < rulesForThisField.length; i += 1) {
    const currentRule = rulesForThisField[i];
    const { inputValue } = fieldState;
    /* istanbul ignore else */
    if (currentRule.required) {
      newErrorMessage = inputValue ? undefined : currentRule.error;
    }
    if (newErrorMessage) {
      break;
    }
  }
  return newErrorMessage;
};
