import React from 'react';
import { defineMessages } from 'react-intl';
import {
  ShipmentInfosShipmentList,
  ShipmentInfosShipmentListTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { CancelShipmentAction } from './CancelShipmentAction';
import ReturnShipmentInfo from '../ReturnShipmentInfo';

export const messages = defineMessages({
  similarProductsLink: {
    id: 'ShipmentCardAction.similarproducts.link',
    defaultMessage: 'Ähnliche Artikel',
  },
  recoSliderHeadline: {
    id: 'ShipmentCardAction.RecoSlider.headline',
    defaultMessage: 'Empfehlungen für dich',
  },
});

export function ShipmentCardAction({
  shipmentList,
  onCancelSuccess,
}: {
  shipmentList: ShipmentInfosShipmentList;
  onCancelSuccess?: (id: string) => void;
}) {
  switch (shipmentList.type) {
    case ShipmentInfosShipmentListTypeEnum.AwaitingDownpayment:
    case ShipmentInfosShipmentListTypeEnum.Ordered:
      return <CancelShipmentAction onSuccess={onCancelSuccess} shipment={shipmentList} />;
    case ShipmentInfosShipmentListTypeEnum.Canceled:
    case ShipmentInfosShipmentListTypeEnum.Returned:
      return null;
    case ShipmentInfosShipmentListTypeEnum.Delivered:
      return (
        <>
          <ReturnShipmentInfo shipment={shipmentList} />
        </>
      );
    default:
      return null;
  }
}

export default ShipmentCardAction;
