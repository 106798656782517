// import PageProvider from '@/providers/pageProvider'
import React, { FC, FunctionComponent, useEffect } from 'react';
import ComponentProvider from '@empiriecom/module-components/ComponentProvider';
import { SessionProvider } from '@empiriecom/mybuy-session/SessionProvider';
import { hot } from 'react-hot-loader';
import { HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router';
import ConfigProvider from '@empiriecom/module-components/ConfigProvider';
import LocaleSwitcher from '@empiriecom/module-components/LocaleSwitcher';
import MyAccountContainer from '@/src/components/Pages/MyAccountContainer';
import { CustomerProvider } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { PageProvider } from '@/src/components/Pages/PageProvider';
import { NavigationProvider } from '@/src/components/NavigationProvider';
import OverviewContainer from '@/src/components/OverviewContainer';
import { PageProviderEntry } from '@/src/typings/Navigation';
import { FragmentConfig } from '@/config/types';
import config from '@/config';
import {
  internalPages as internalPagesDefinition,
  type NextJSPage,
  nextJSPages,
} from '@/config/pages';
import mapInternalPageToFC from '@/src/utils/mapInternalPageToFC';
import { useHistory } from 'react-router-dom';
import useTracking, { TrackingProvider } from '@empiriecom/module-components/hooks/useTracking';
import ReturnDeclarationUrlShortener from '@/src/components/ReturnDeclarationContainer/ReturnDeclarationUrlShortener';
import ReturnDeclarationContainer from '@/src/components/ReturnDeclarationContainer';
import styled from 'styled-components';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import messages from '../../../locales';
import NativeAppHeader from '@/src/components/App/NativeAppHeader';
import { ChatBot } from '@/src/components/ChatBot';
import { TmxProfiler } from '@empiriecom/mybuy-components/modules/tmx';
import useIsLoggedIn, { useIsAutoLogin } from '@empiriecom/mybuy-components/utils/useIsLoggedIn';
import { GlycerinLoginStatus, GTMEventGlycerinPageview } from '@empiriecom/mybuy-components/types';
import mapPageTypeAndTemplate from '@empiriecom/mybuy-components/types/mapPageTypeAndTemplate';
import { Language } from '@empiriecom/mybuy-components/config/default';
import { useIntl } from 'react-intl';

const ReturnDeclarationWrapper = styled.div`
  margin: 1rem auto;
  max-width: ${({ theme }) => theme.global.maxWidth};
`;

export const ReloadLink: FC = () => {
  useEffect(() => {
    // istanbul ignore else
    if (isClientSide()) {
      // check if URLSearchParams is available
      if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('rl', '1');
        window.history.replaceState(null, window.location.pathname, `?${searchParams.toString()}`);
        window.location.search = searchParams.toString();
      } else {
        // window.reload fallback for browsers without URLSearchParams support (e.g. IE)
        (window as Window).location.reload();
      }
    }
  }, []);

  return null;
};

const AppRouter = (): JSX.Element => {
  // Convert the enum to the actual components we want to render here
  // This creates a separate entry for every path that the InternalPage maps to.
  // Should be fine for our number of pages but we need to keep an eye on that, this might be a point for future optimization
  const internalPages: PageProviderEntry[] = Object.values(internalPagesDefinition)
    .map((entry) => {
      const { paths, additionalPaths, requiredLoginStatus, metaData, trackingTemplate } = entry;
      const container = mapInternalPageToFC(entry.container);
      const mappedEntries: PageProviderEntry[] = [
        ...Object.values(paths),
        ...(additionalPaths ?? []),
      ].map((path) => ({
        path,
        isPrefix: entry.isPrefix,
        requiredLoginStatus,
        container,
        metaData,
        trackingTemplate,
      }));
      return mappedEntries;
    })
    .reduce((acc, entries) => [...acc, ...entries], []);

  const nextJSPagePaths: NextJSPage['paths'][keyof NextJSPage['paths']][] = Object.values(
    nextJSPages,
  ).reduce((acc, { paths }: NextJSPage) => [...acc, ...Object.values(paths)], []);

  const history = useHistory();

  const PageEvent: FC<{ page?: string }> = ({ page }) => {
    const dispatch = useTracking();
    const intl = useIntl();
    const isLoggedIn = useIsLoggedIn();
    const isAutoLogin = useIsAutoLogin();

    useEffect(() => {
      const language = intl.locale.split('-')[0];

      let glycerinLoginStatus = GlycerinLoginStatus.NOTLOGGEDIN;

      if (isLoggedIn) {
        // istanbul ignore next
        glycerinLoginStatus = GlycerinLoginStatus.LOGGEDIN;
      }
      if (isAutoLogin) {
        // istanbul ignore next
        glycerinLoginStatus = GlycerinLoginStatus.SOFTLOGGEDIN;
      }

      if (page) {
        const pageLevels = window.location.pathname.split('/').slice(1);

        dispatch({
          event: 'pageMetaData',
          Seitentyp: 'myaccount',
          page: {
            type: 'myaccount',
            template: page,
          },
        });

        const event: GTMEventGlycerinPageview = {
          event: 'Pageview',
          PageviewData: {
            loginStatus: glycerinLoginStatus,
            pageTypes: mapPageTypeAndTemplate('myaccount'),
            pageLevels,
            language: language as Language,
          },
        };
        dispatch(event);
      }
    }, [page]);

    return null;
  };

  return (
    <Switch>
      {/* reload link on MyAccount Payback to NextJS App 
      this occurs if we are already in this SPA and call the route from within react-router,
      what a browser reload here does is make a network call to the istio service mesh, looks for the virtual service with the matching route AND with app-order true,
      the service mesh sees we have a app-order true cookie in this SPA's virtual service, 
      and instead allocates the traffic to the NextJS App's virtual service because app-order cookie is matched and in this SPA's virtual service we explicitly check that the cookie is NOT set
      */}
      {nextJSPagePaths.map((nextJSPath) => (
        <Route
          key={nextJSPath}
          path={nextJSPath}
          render={() => {
            if (typeof document !== 'undefined') {
              const expires = `; expires=${Date.now() + 6 * 60 * 60 * 1000}`;
              document.cookie = `app-order=true${expires}; path=/`;
            }
            return <ReloadLink />;
          }}
        />
      ))}
      {/* first, we add routes for all "live" URLs within the fragment */}
      {internalPages.map((entry) => (
        <Route
          key={entry.path}
          exact={!entry.isPrefix}
          path={entry.path}
          render={() => (
            <PageProvider entry={entry}>
              <MyAccountContainer />
              <PageEvent page={entry.trackingTemplate} />
            </PageProvider>
          )}
        />
      ))}
      {/* next, we add routes for all test URLs within the fragment (prefix 'fritz-mein-konto') */}
      {internalPages.map((entry) => (
        <Route
          key={`/RL${entry.path}`}
          exact={!entry.isPrefix}
          path={entry.path.replace(/^\//g, '/fritz-')}
          render={() => (
            <PageProvider entry={entry} testingUrls>
              <MyAccountContainer />
              <PageEvent page={entry.trackingTemplate} />
            </PageProvider>
          )}
        />
      ))}

      {/* support old wishlist url and redirect to wishlist fragment */}
      <Route
        key="/mein-konto/mein-merkzettel"
        path={[
          '/fr/mon-compte/ma-liste-denvies',
          '/mein-konto/mein-merkzettel',
          '/fritz-mein-konto/mein-merkzettel',
        ]}
        render={() => {
          history.replace('/merkzettel');
          return <ReloadLink />;
        }}
      />
      {/* called from link in e-mail */}
      <Route
        path="/ruecksendeanmeldung/:id"
        render={() => (
          <ReturnDeclarationWrapper data-testid="return-declaration-url-shortener">
            <ReturnDeclarationUrlShortener />
          </ReturnDeclarationWrapper>
        )}
      />
      <Route
        path="/mein-konto/ruecksendeanmeldung"
        render={() => (
          <ReturnDeclarationWrapper>
            <ReturnDeclarationContainer />
            <PageEvent page="return_announcement" />
          </ReturnDeclarationWrapper>
        )}
      />
      {/* this only exists for the server side rendering - the client side bundle doesn't contain the router (or the App component) */}
      <Route
        key="/mein-konto/lbl"
        path={['/fr/mon-compte/lbl', '/mein-konto/lbl', '/fritz-mein-konto/lbl']}
        render={() => <OverviewContainer showKim={false} isLblEsi />}
      />
      {/* Internal redirect to route from all other my-account pages to the overview page */}
      <Redirect key="redirectAll" from="/mein-konto" to="/mein-konto/uebersicht" />
      <Redirect key="redirectAll" from="/fr/mon-compte" to="/fr/mon-compte/apercu" />
      <Redirect key="redirectAll" from="/mijn-account" to="/mijn-account/overzicht" />
      {/* Route for potential links rendered with router Link component the should be redirected */}
      <Route path={['*']} render={() => <ReloadLink />} />
    </Switch>
  );
};

const App: FunctionComponent<{
  appMode?: boolean;
  testConfig?: FragmentConfig;
  device?: 'mobile' | 'tablet' | 'desktop';
}> = ({ testConfig, device }): JSX.Element => {
  const usableConfig = testConfig || config;
  const isNativeApp = !!(typeof window !== 'undefined' && window.isNativeApp);

  return (
    <HelmetProvider>
      <ConfigProvider config={usableConfig}>
        <TrackingProvider
          value={{
            gtmId: config.tracking.gtmId,
            domain: config.tracking.domain,
            src: config.tracking.tagmanagerSrc,
          }}
        >
          <ComponentProvider
            data-testid="app-component-provider"
            fallbackLocale={testConfig?.locales[0] ?? config.locales[0]}
            messages={messages}
            mediaOverwrite={device}
          >
            <SessionProvider>
              {/* We need Fragment (<>...</>) here for Styled Components rendering */}
              <>
                <LocaleSwitcher localesToSwitch={testConfig?.locales ?? config.locales} />
                <CustomerProvider shouldLoadCustomerStatus>
                  {isNativeApp && <NativeAppHeader />}
                  {config.chatBot.enabled && <ChatBot />}
                  <TmxProfiler />
                  <NavigationProvider>
                    <AppRouter />
                  </NavigationProvider>
                </CustomerProvider>
              </>
            </SessionProvider>
          </ComponentProvider>
        </TrackingProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
};

export default hot(module)(App);
