import { useState, useEffect, useCallback } from 'react';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import {
  GetInvoiceRequest,
  Invoice as InvoiceData,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

export interface AsyncInvoiceData {
  isLoading: boolean;
  hasErrors: boolean;
  data?: InvoiceData;
}

export const useInvoice = (params: GetInvoiceRequest): AsyncInvoiceData => {
  const api = useAccountApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [data, setData] = useState<InvoiceData | undefined>(undefined);
  const { id, ecLocale } = params;

  const fetchInvoices = useCallback(async () => {
    try {
      if (api && id) {
        setIsLoading(true);
        const response = await api.getInvoice({ id, ecLocale });
        setData(response);
      }
    } catch {
      setHasErrors(true);
    } finally {
      setIsLoading(false);
    }
  }, [api, id, ecLocale]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return {
    isLoading,
    hasErrors,
    data,
  };
};

export default useInvoice;
