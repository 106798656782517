import React, { FC } from 'react';
import styled from 'styled-components';
import Card from '@empiriecom/module-components/Card';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { defineMessages, useIntl } from 'react-intl';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import { Esi } from '@empiriecom/module-components/Esi';

const messages = defineMessages({
  headline: {
    id: 'FaqCard.Headline',
    defaultMessage: 'Fragen?{br}Wir sind für Sie da',
  },
});

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.25rem 0;

  &.noMargin {
    margin: 0;
  }
  &.marginGrowLeftRight {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const ActionsQuestionIcon = getIcon('ActionsQuestion');

export const FaqCard: FC = () => {
  const intl = useIntl();
  const {
    flexikonto: {
      externalUrls: { faq },
    },
  } = useConfig<FragmentConfig>();
  return (
    <GreyBox>
      <Card
        showHeaderSeparator={false}
        headerIcon={<ActionsQuestionIcon />}
        headerVariant="additional"
        roundedIcon={false}
        headline={intl.formatMessage(messages.headline, { br: <br /> }) as string}
        shouldHideHeaderDataForMouseflow
      >
        <HR className="noMargin" />
        <CardContent>
          <Esi src={faq} />
        </CardContent>
      </Card>
    </GreyBox>
  );
};
