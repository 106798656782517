import React, { FunctionComponent } from 'react';
import Skeleton from '@empiriecom/module-components/Skeleton';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton)`
  margin: 0;
`;

const Card = styled.div`
  background: white;

  display: flex;
  flex-direction: column;
  border-radius: 3px;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
`;

const BigGap = styled(StyledSkeleton)`
  margin-bottom: 1rem;
`;

const MediumGap = styled(StyledSkeleton)`
  margin-bottom: 0.8rem;
`;

const SmallGap = styled(StyledSkeleton)`
  margin-bottom: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  padding: 1rem;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const IconCircle = styled(StyledSkeleton)`
  border-radius: 100%;
  width: 3.25rem;
  height: 3.25rem;
  margin: 0 1rem 0 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding: 1rem;

  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: 1px solid ${({ theme }) => theme.greyLight.color.normal};
`;

const ContentTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;

  padding-left: 1rem;
`;

const ProductLinkAndPrice = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: center;
  padding: 1rem;
`;

const ShipmentInfosCardSkeleton: FunctionComponent = (): JSX.Element => {
  return (
    <Card>
      <Header>
        <IconCircle height="3.25rem" />
        <HeaderTextContainer>
          <MediumGap width="80%" height="1rem" />
          <StyledSkeleton width="70%" height="0.813rem" />
        </HeaderTextContainer>
      </Header>
      <Content>
        <StyledSkeleton width="33%" height="10rem" />
        <ContentTextContainer>
          <BigGap height="1rem" width="80%" />

          <SmallGap height="0.75rem" width="75%" />
          <SmallGap height="0.75rem" width="75%" />
          <SmallGap height="0.75rem" width="75%" />
          <SmallGap height="0.75rem" width="60%" />

          <ProductLinkAndPrice>
            <SmallGap height="0.75rem" width="20%" />
            <Price>
              <SmallGap height="0.813rem" width="100%" />
              <SmallGap height="0.75rem" width="33%" />
            </Price>
          </ProductLinkAndPrice>
        </ContentTextContainer>

        <StyledSkeleton width="100%" height="3rem" />
      </Content>
      <Footer>
        <StyledSkeleton height="1rem" width="50%" />
      </Footer>
    </Card>
  );
};

export default ShipmentInfosCardSkeleton;
