import React, { ReactNode } from 'react';

import { Button } from '@empiriecom/module-components/Button';
import { DefaultCard } from '@empiriecom/module-components/Card';
import Notification, { NotificationProps } from '@empiriecom/module-components/Notification';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
`;

const StyledCard = styled(DefaultCard)`
  margin: 1.438rem 0.938rem 2.375rem 0.938rem;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    width: 31.938rem;

    margin-left: auto;
    margin-right: auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.625rem 1.313rem;

  > *:not(:last-child) {
    margin-bottom: 1.088rem;
  }
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 2.375rem 0.938rem 2.375rem 0.938rem;

  background-color: ${({ theme }) => theme.base.color.normal};

  > *:not(:last-child) {
    margin-bottom: 1.063rem;
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    flex-flow: row-reverse;
    justify-content: space-between;

    > * {
      width: calc((100% - 31.938rem) / 2);
      margin-bottom: 0;
    }
  }
`;

export function CCLoginFormContainer({
  children,
  onSubmit,
  disableSubmit,
  error,
  submitLabel,
  title,
}: {
  children?: ReactNode;
  onSubmit: () => void;
  disableSubmit?: boolean;
  error?: NotificationProps['content'];
  submitLabel?: string;
  title?: string;
}) {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Root data-testid="login-form">
      <StyledCard headline={title}>
        <CardContent>
          {error && <Notification level="error" isInline content={error} />}
          {children}
        </CardContent>
      </StyledCard>

      <Footer>
        <Button
          data-testid="cc-login-submit"
          layout="primary"
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          {submitLabel}
        </Button>
      </Footer>
    </Root>
  );
}

export default CCLoginFormContainer;
