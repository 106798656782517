import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  GetShipmentInfosRequest,
  GetShipmentInfosTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import Headline from '@empiriecom/module-components/Headline';
import { useShipmentInfos } from '@/src/components/AccountProvider';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import { Button } from '@empiriecom/module-components/Button';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { ShipmentInfoTypeSelect } from '../ShipmentInfoTypeSelect';
import { ShipmentTimeRangeSelect } from '../ShipmentTimeRangeSelect';
import { ShipmentInfoCard } from '../ShipmentInfoCard';
import { ProductNotFoundHint } from './ProductNotFoundHint';
import ShipmentInfosCardSkeleton from '../ShipmentInfoCard/skeleton';

export const SHIPMENT_INFOS_TESTID = 'shipment-infos';

const ShipmentInfosHeadline = styled(Headline)`
  text-align: center;
  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    text-align: left;
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  > * {
    flex: 1 0 40%;
    margin: 0.25rem;
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    flex-direction: row;

    & > * {
      flex: 0 1 auto;
    }
  }
`;

const ShipmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    padding: 2rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${({ theme }) => theme.global.spacer.medium};
  }
`;

const messages = defineMessages({
  headline: {
    id: 'ShipmentInfos.headline',
    defaultMessage: 'Bestellungen schneller finden:',
  },
  noDataHint: {
    id: 'ShipmentInfos.nodata-hint',
    defaultMessage: 'Bitte wählen Sie den gewünschten Status oder Zeitraum.',
  },
  loadMore: {
    id: 'ShipmentInfos.loadMore',
    defaultMessage: 'weitere laden',
  },
});

const LoadMoreIcon = getIcon('LoadReload');

const LoadMoreButton = styled(Button)`
  margin: 1.875rem auto;
`;

export function ShipmentInfos() {
  const intl = useIntl();

  const { ecLocale } = useCustomerContext();

  const [params, setParams] = useState<GetShipmentInfosRequest>({
    type: GetShipmentInfosTypeEnum.All,
    ecLocale,
  });

  const { infos, loadMore, isLoading } = useShipmentInfos(params);

  const isInvalidBackendData = !infos?.shipmentList;
  const hasEmptyShipments = infos?.shipmentList?.length === 0;
  const hasNoMoreShipments = !loadMore;
  const isProductNotFoundHintDisplayed =
    isInvalidBackendData || hasEmptyShipments || hasNoMoreShipments;

  return (
    <ShipmentContainer data-testid={SHIPMENT_INFOS_TESTID}>
      <ShipmentInfosHeadline level={3}>
        {intl.formatMessage(messages.headline)}
      </ShipmentInfosHeadline>
      <Toolbar>
        <ShipmentTimeRangeSelect
          disabled={isLoading}
          value={params.days}
          onSelect={(value) =>
            setParams((state) => ({
              ...state,
              days: value,
              type: state.type,
              ecLocale,
            }))
          }
        />
        <ShipmentInfoTypeSelect
          disabled={isLoading}
          value={params.type}
          onSelect={(type) => setParams((state) => ({ ...state, type, ecLocale }))}
        />
      </Toolbar>
      <CardsContainer>
        {isLoading && !infos?.shipmentList?.length ? (
          <ShipmentInfosCardSkeleton />
        ) : (
          <>
            {!infos?.shipmentList?.length && <p>{intl.formatMessage(messages.noDataHint)}</p>}
            {infos?.shipmentList?.map((info, idx) =>
              isLoading ? (
                <ShipmentInfosCardSkeleton key={info.product.sku + idx} />
              ) : (
                <ShipmentInfoCard key={info.product.sku + idx} shipmentList={info} />
              ),
            )}
          </>
        )}
      </CardsContainer>
      {loadMore && (
        <LoadMoreButton
          data-testid="shipments-load-more"
          onClick={() => {
            loadMore();
          }}
        >
          {intl.formatMessage(messages.loadMore)}
          <LoadMoreIcon />
        </LoadMoreButton>
      )}
      {isProductNotFoundHintDisplayed && <ProductNotFoundHint />}
    </ShipmentContainer>
  );
}

export default ShipmentInfos;
