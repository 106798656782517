import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReturnDeclarationSkeleton from '@/src/components/ReturnDeclarationContainer/skeleton';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import { setId } from '@/utils/returnDeclarationUtil';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const ReturnDeclarationUrlShortener = () => {
  const history = useHistory();
  const config = useConfig<FragmentConfig>();

  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    if (isClientSide()) {
      const url = pathname.substring(pathname.lastIndexOf('/') + 1);

      setId(url);
      history.replace({
        ...history.location,
        pathname: config.returnDeclaration.url,
        search: '',
      });
    }
  }, [pathname]);

  return <ReturnDeclarationSkeleton />;
};

export default ReturnDeclarationUrlShortener;
