import { ErrorFields, ErrorState, ReclamationFormFieldName, ReclamationFormState } from './types';
import { ReclamationFormAction } from './actions';
import { validateField } from './validation';

export const getFieldErrors = (
  state: ReclamationFormState,
  fieldNames?: ReclamationFormFieldName[],
): ErrorState => {
  const selectedFieldNames = fieldNames || (Object.keys(state) as Array<ReclamationFormFieldName>);
  let hasErrors = false;
  const fieldErrors = selectedFieldNames.reduce((acc, key) => {
    const currentField = state[key];
    const errorMessage = validateField(key, currentField);
    acc[key] = {
      errorMessage: errorMessage || null,
    };
    if (errorMessage) {
      hasErrors = true;
    }
    return acc;
  }, {} as ErrorFields);
  return {
    hasErrors,
    errors: fieldErrors,
  };
};

export const reclamationFormReducer = (
  state: ReclamationFormState,
  action: ReclamationFormAction,
): ReclamationFormState => {
  switch (action.type) {
    case 'SET_VALUE': {
      const { fieldName, value, isConfirmed } = action.payload;
      const previousFieldData = state[fieldName];
      return {
        ...state,
        [fieldName]: {
          ...previousFieldData,
          inputValue: value,
          value: isConfirmed ? value : previousFieldData.value,
        },
      };
    }
    case 'CONFIRM_INPUT_VALUES': {
      const allFieldKeys = Object.keys(state) as Array<ReclamationFormFieldName>;
      const { fieldNames } = action.payload;
      const newState = allFieldKeys.reduce(
        (acc, key) => {
          const currentField = state[key];
          if (fieldNames.includes(key)) {
            acc[key] = {
              ...currentField,
              value: currentField.inputValue,
              errorMessage: undefined,
            };
          } else {
            acc[key] = {
              ...currentField,
            };
          }
          return acc;
        },
        { ...state },
      );
      return newState;
    }
    case 'SET_ERRORS':
    default: {
      const allFieldKeys = Object.keys(state) as Array<ReclamationFormFieldName>;
      const { errors: fieldErrors } = action.payload;
      const errorFieldKeys = Object.keys(fieldErrors);
      const newState = allFieldKeys.reduce(
        (acc, key) => {
          const currentField = state[key];
          if (errorFieldKeys.includes(key)) {
            acc[key] = {
              ...currentField,
              errorMessage: fieldErrors[key]?.errorMessage || undefined,
            };
          } else {
            acc[key] = {
              ...currentField,
            };
          }
          return acc;
        },
        { ...state },
      );
      return newState;
    }
  }
};

export default reclamationFormReducer;
