import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-media-match';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SkeletonProvider, withSkeleton } from '@empiriecom/module-components/Skeleton';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import Maturities from '@/src/components/Maturities';
import PaymentBox from '@/src/components/PaymentBox';
import { Headline } from '@empiriecom/module-components';
import PrepaymentHint from '@/src/components/PrepaymentHint';
import { Kim } from '@empiriecom/mybuy-components/components/Kim';
import BookingContainerSkeleton from './skeleton';
import BookingList from './BookingList';
import EventTracking from '../EventTracking';
import { useLocation } from 'react-router';
import Notification from '@empiriecom/module-components/Notification';
import VerificationNotification from '../VerificationNotification';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';

const NoPrint = styled.div`
  @media print {
    /* not needed in print view */
    display: none;
  }
`;

const StyledHeadline = styled(Headline)`
  margin: 0;
  padding: 0.5rem;
`;

const GreyBox = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  padding: 1rem;
`;

const LeftRight = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  > div {
    &:first-child {
      margin-bottom: ${({ isMobile }) => (isMobile ? '1rem' : '0')};
    }
    width: ${({ isMobile }) => (isMobile ? '100%' : 'calc(50% - 0.5rem)')};
  }
`;

const KimWrapper = styled.div`
  padding-top: 2rem;
`;

const messages = defineMessages({
  kimTitle: {
    id: 'BookingContainer.Kim.title',
    defaultMessage: 'Wie gefällt Ihnen die Buchungsseite?',
  },
});
interface LocationState {
  showBookings?: boolean;
}

const BookingContainerContent: FC = (): JSX.Element => {
  const { state } = useLocation<LocationState>();
  const bookingOverviewHeadline = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isSubscribed = true;
    if (state && state.showBookings && isSubscribed && bookingOverviewHeadline.current) {
      // this will be triggered from ProductNotFoundHint Component when the user clicks on the Link component
      bookingOverviewHeadline.current.scrollIntoView();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  const { formatMessage } = useIntl();
  const customer = useCustomerContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const { kim }: FragmentConfig = useConfig();

  const kimId = customer.customer?.extra?.kid ? parseInt(customer.customer?.extra?.kid, 10) : 0;

  return (
    <div data-testid="booking-container-wrapper">
      <EventTracking eventCategory="booking" />
      <NoPrint>
        <VerificationNotification />
        {customer.customer?.customerSpecificHint && (
          <Notification level="warning" isInline>
            {customer.customer?.customerSpecificHint}
          </Notification>
        )}
        <StyledHeadline level={3}>
          <FormattedMessage
            defaultMessage="Meine Buchungen"
            id="BookingContainer.bookings.headline"
          />
        </StyledHeadline>
        <GreyBox>
          <LeftRight isMobile={isMobile}>
            <div>
              <PrepaymentHint />
              <Maturities scope="bookings" />
            </div>
            <PaymentBox />
          </LeftRight>
        </GreyBox>
      </NoPrint>
      {state && <div ref={bookingOverviewHeadline} data-testid="ref-scroll"></div>}

      <StyledHeadline level={3}>
        <FormattedMessage
          defaultMessage="Buchungsübersicht"
          id="BookingContainer.bookingOverview.headline"
        />
      </StyledHeadline>
      <BookingList />
      {kim?.enabled && (
        <KimWrapper>
          <Kim
            i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
            headline={formatMessage(messages.kimTitle)}
            x03={20}
            x05="BUCH"
            x06={kimId}
          />
        </KimWrapper>
      )}
    </div>
  );
};

const BookingContainerWithSkeleton = withSkeleton(
  BookingContainerContent,
  BookingContainerSkeleton,
);

const BookingContainer: FC = (): JSX.Element => {
  const { isLoading } = useCustomerContext();
  return (
    <SkeletonProvider isLoading={isLoading}>
      <BookingContainerWithSkeleton />
    </SkeletonProvider>
  );
};

export default BookingContainer;
