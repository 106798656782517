import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  ShipmentInfosShipmentList,
  ShipmentInfosShipmentListTypeEnum,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import DefaultCard, { CardSeperator } from '@empiriecom/module-components/Card';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { IconProps } from '@empiriecom/module-components/Icon/IconProps';
import { Collapse } from '@empiriecom/module-components/Collapse';

import ShipmentInfoAPlusList from '@/src/components/APlusList';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import { ShipmentInfosProductView } from './ShipmentInfosProductView';
import { ShipmentCardDetails } from './ShipmentCardDetails';
import { ShipmentCardAction } from './ShipmentCardAction';

const shipmentStatusMessages = defineMessages({
  all: {
    id: 'ShipmentInfosShipmentListType.all',
    defaultMessage: 'Alle',
  },
  ordered: {
    id: 'ShipmentInfosShipmentListType.ordered',
    defaultMessage: 'Bestellt',
  },
  in_delivery: {
    id: 'ShipmentInfosShipmentListType.in_delivery',
    defaultMessage: 'In Lieferung',
  },
  delivered: {
    id: 'ShipmentInfosShipmentListType.delivered',
    defaultMessage: 'Zugestellt',
  },
  canceled: {
    id: 'ShipmentInfosShipmentListType.canceled',
    defaultMessage: 'Storniert',
  },
  returned: {
    id: 'ShipmentInfosShipmentListType.returned',
    defaultMessage: 'Zurückgeschickt',
  },
  sent: {
    id: 'ShipmentInfosShipmentListType.sent',
    defaultMessage: 'Unterwegs',
  },
  shipment_prepared: {
    id: 'ShipmentInfosShipmentListType.shipment_prepared',
    defaultMessage: 'Versand vorbereitet',
  },
  awaiting_downpayment: {
    id: 'ShipmentInfosShipmentListType.awaiting_downpayment',
    defaultMessage: 'Warten auf Anzahlung',
  },
  returnlabel_created: {
    id: 'ShipmentInfosShipmentListType.returnlabel_created',
    defaultMessage: 'Rücksende-Etikett erstellt',
  },
  return_shipment: {
    id: 'ShipmentInfosShipmentListType.return_shipment',
    defaultMessage: 'Rücksende-Etikett erstellt',
  },
});

function iconForType(type?: ShipmentInfosShipmentListTypeEnum): React.FunctionComponent<IconProps> {
  /**
   * @TODO get the correct icons
   */

  switch (type) {
    case ShipmentInfosShipmentListTypeEnum.Canceled:
      return getIcon('Canceled');
    case ShipmentInfosShipmentListTypeEnum.Ordered:
      return getIcon('CheckOrder');
    case ShipmentInfosShipmentListTypeEnum.ShipmentPrepared:
      return getIcon('Tracking');
    case ShipmentInfosShipmentListTypeEnum.Sent:
      return getIcon('Truck');
    case ShipmentInfosShipmentListTypeEnum.InDelivery:
      return getIcon('InDelivery');
    case ShipmentInfosShipmentListTypeEnum.Delivered:
    case ShipmentInfosShipmentListTypeEnum.ReturnShipment:
      return getIcon('CatHome');
    case ShipmentInfosShipmentListTypeEnum.Returned:
      return getIcon('Return');
    case ShipmentInfosShipmentListTypeEnum.ReturnlabelCreated:
      return getIcon('Returnslip');
    default:
      return getIcon('ShipmentDetails');
  }
}

function useShipmentTypeIcon(type?: ShipmentInfosShipmentListTypeEnum) {
  const [Icon, setIcon] = useState<React.FunctionComponent<IconProps> | null>();
  useEffect(() => {
    setIcon(iconForType(type));
  }, [type]);

  return Icon ? <Icon /> : null;
}

export const messages = defineMessages({
  detailsHeadline: {
    id: 'ShipmentInfoCard.details.headline',
    defaultMessage: 'Bestelldetails & Sendungsverfolgung',
  },
});

const StyledShipmentInfoAPlusList = styled(ShipmentInfoAPlusList)`
  margin: 0 0.938rem;
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
`;

export function ShipmentInfoCard({ shipmentList }: { shipmentList: ShipmentInfosShipmentList }) {
  const icon = useShipmentTypeIcon(shipmentList.type);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useTracking();

  const headline = intl.formatMessage(shipmentStatusMessages[shipmentList.type]);

  return (
    <DefaultCard headerIcon={icon} headline={headline} subHeadline={shipmentList.state}>
      <ShipmentInfosProductView product={shipmentList.product}>
        <ShipmentCardAction shipmentList={shipmentList} />
      </ShipmentInfosProductView>

      <StyledShipmentInfoAPlusList aplus={shipmentList.product.aplus} />

      <CardSeperator />
      <Collapse
        handleCollapse={{
          isOpen,
          onToggle: () => {
            setIsOpen(!isOpen);
            dispatch({
              event: 'ga_event',
              eventAction: 'click',
              eventCategory: 'booking_shipmentDetails',
            });
          },
        }}
        layout="cardstyle"
        headline={intl.formatMessage(messages.detailsHeadline)}
      >
        <ShipmentCardDetails shipmentList={shipmentList} />
      </Collapse>
    </DefaultCard>
  );
}

export default ShipmentInfoCard;
