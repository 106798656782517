import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReturnDeclaration from '@/src/components/ReturnDeclarationContainer/ReturnDeclaration';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import ReturnDeclarationSkeleton from '@/src/components/ReturnDeclarationContainer/skeleton';
import { useHistory } from 'react-router-dom';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import { getId } from '@/utils/returnDeclarationUtil';
import { FormattedMessage } from 'react-intl';
import Notification from '@empiriecom/module-components/Notification';

const ReturnDeclarationContainerContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ReturnDeclarationContainer: FC = () => {
  const history = useHistory();
  const config = useConfig<FragmentConfig>();
  const [customerNo, setCustomerNo] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isClientSide()) {
      const id = getId();
      setCustomerNo(id);
      setIsLoading(false);
    }
  }, [isClientSide, getId]);

  if (!config.returnDeclaration.enabled) {
    history.replace({
      ...history.location,
      pathname: '/mein-konto/uebersicht',
      search: '',
    });
  }

  if (config.returnDeclaration.enabled) {
    if (isLoading) {
      return <ReturnDeclarationSkeleton />;
    }

    if (customerNo) {
      return (
        <ReturnDeclarationContainerContent data-testid="return-declaration-container">
          <ReturnDeclaration customerNo={customerNo} />
        </ReturnDeclarationContainerContent>
      );
    }
  }

  return (
    <Notification
      level="error"
      isInline
      content={
        <FormattedMessage
          id="ReturnDeclarationContainer.accessDenied.error"
          defaultMessage="Dieser Link ist leider nicht gültig."
        />
      }
    />
  );
};

export default ReturnDeclarationContainer;
