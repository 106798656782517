import React, { FC } from 'react';
import styled from 'styled-components';
import Card from '@empiriecom/module-components/Card';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import Notification from '@empiriecom/module-components/Notification';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { CustomerCreditDataPaymentInsurance } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { PaymentProtectionCardSkeletion } from '@/src/components/FlexikontoContainer/FlexikontoSkeleton';
import { Link } from '@empiriecom/module-components/Link';
import getLocalizedPath from '@/src/utils/getLocalizedPath';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const messages = defineMessages({
  headline: {
    id: 'PaymentProtectionCard.Headline',
    defaultMessage: 'Flexikonto Zahlschutz',
  },
  youngInsurance0: {
    id: 'PaymentProtectionCard.YoungInsurance0',
    defaultMessage:
      'Arbeitslosigkeit{break}<small>Schutz bei unverschuldetem Arbeitsplatzverlust</small>',
  },
  youngInsurance1: {
    id: 'PaymentProtectionCard.YoungInsurance1',
    defaultMessage:
      'Arbeitsunfähigkeit{break}<small>Schutz bei Krankheit, Unfall, Berufs- und Erwerbsunfähigkeit</small>',
  },
  youngInsurance2: {
    id: 'PaymentProtectionCard.YoungInsurance2',
    defaultMessage:
      'Pflegebedürftigkeit{break}<small>Pflegegeld-Bezug ab Pflegestufe 5 und höher</small>',
  },
  youngInsurance3: {
    id: 'PaymentProtectionCard.YoungInsurance3',
    defaultMessage:
      'Schwere Erkrankungen{break}<small>Schutz bei Krebs, Herzinfarkt, Schlaganfall, Erblindung, Gehörverlust</small>',
  },
  youngInsurance4: {
    id: 'PaymentProtectionCard.YoungInsurance4',
    defaultMessage: 'Ableben{break}<small>bei Ableben doppelte Leistung (max. 12.000 €)</small>',
  },
  oldInsurance0: {
    id: 'PaymentProtectionCard.OldInsurance0',
    defaultMessage:
      'Schwere Erkrankungen{break}<small>Schutz bei Krebs, Herzinfarkt, Schlaganfall, Erblindung, Gehörverlust</small>',
  },
  oldInsurance1: {
    id: 'PaymentProtectionCard.OldInsurance1',
    defaultMessage:
      'Unfallbedingte Dauerinvalidität{break}<small>Unfallbedingter Dauerinvalidität von mind. 50 %</small>',
  },
  oldInsurance2: {
    id: 'PaymentProtectionCard.OldInsurance2',
    defaultMessage: 'Ableben',
  },
});

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.25rem 0;

  &.noMargin {
    margin: 0;
  }
  &.marginGrowLeftRight {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  > * + * {
    margin-top: 0.25rem;
  }
`;

const Li = styled.li`
  font-size: 0.813rem;
  letter-spacing: 0.18px;
  line-height: 1.5;
  display: flex;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.success.color.normal};
  }
`;

const ListText = styled.div`
  padding-top: 0.2rem;
`;

const SmallerText = styled.span`
  font-size: 0.688rem;
`;

const Checkmark = getIcon('Checkmark');
const InsuranceIcon = getIcon('Insurance');
const PhoneIcon = getIcon('Phone');

const StyledCheckmark = styled(Checkmark)`
  margin-right: 0.5rem;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  width: 17px;
  height: 17px;
  margin-right: 0.825rem;
`;

const ActionWrapper = styled.div`
  padding: 0.75rem;
  font-size: 0.813rem;
`;

export const PaymentProtectionCard: FC<{
  paymentInsurance?: CustomerCreditDataPaymentInsurance;
}> = ({ paymentInsurance }): React.ReactElement => {
  const {
    companyId,
    flexikonto: {
      paymentProtectionAgeBreakpoint,
      externalUrls: { paymentProtection },
    },
  } = useConfig<FragmentConfig>();
  const intl = useIntl();
  const { age, enabled } = paymentInsurance || {};

  if (!paymentInsurance) {
    return <PaymentProtectionCardSkeletion />;
  }

  return (
    <GreyBox>
      <Card
        showHeaderSeparator={false}
        headerIcon={<InsuranceIcon />}
        headerVariant="additional"
        roundedIcon={false}
        headline={intl.formatMessage(messages.headline)}
        shouldHideHeaderDataForMouseflow
      >
        <HR className="noMargin" />
        <CardContent>
          {enabled && (
            <Notification isInline level="success">
              <FormattedMessage
                id="PaymentProtectionCard.Enabled"
                defaultMessage="Der Flexikonto Zahlschutz ist aktiv"
              />
            </Notification>
          )}
          <div>
            <strong>
              <FormattedMessage
                id="PaymentProtectionCard.BulletPointHead"
                defaultMessage="Der Flexikonto Zahlschutz bietet Ihnen und Ihrem im gemeinsamen Haushalt lebenden Partner in vielen unvorhergesehenen Situationen optimale <b>Sicherheit</b>:"
                values={{
                  b: (...chunks: string[]) => <b>{chunks}</b>,
                }}
              />
            </strong>
          </div>
          {age && age >= paymentProtectionAgeBreakpoint && (
            <Ul>
              {[...Array(3).fill(undefined)].map((_v, i) => {
                /* Not the best solution but maybe the only way to access messages dynamically */
                const text = intl.formatMessage(
                  (messages as { [key: string]: MessageDescriptor })[`oldInsurance${i}`],
                  {
                    small: (chunks) => <SmallerText>{chunks}</SmallerText>,
                    break: <br />,
                  },
                );
                if (text !== '-') {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Li key={i}>
                      <div>
                        <StyledCheckmark size="1.5rem" />
                      </div>
                      <ListText>{text}</ListText>
                    </Li>
                  );
                }
              })}
            </Ul>
          )}
          {age && age < paymentProtectionAgeBreakpoint && (
            <Ul>
              {[...Array(5).fill(undefined)].map((_v, i) => {
                /* Not the best solution but maybe the only way to access messages dynamically */
                const text = intl.formatMessage(
                  (messages as { [key: string]: MessageDescriptor })[`youngInsurance${i}`],
                  {
                    small: (chunks) => <SmallerText>{chunks}</SmallerText>,
                    break: <br />,
                  },
                );
                if (text !== '-') {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Li key={i}>
                      <div>
                        <StyledCheckmark size="1.5rem" />
                      </div>
                      <ListText>{text}</ListText>
                    </Li>
                  );
                }
              })}
            </Ul>
          )}
          {enabled && (
            <>
              <HR />
              <div>
                <FormattedMessage
                  id="PaymentProtectionCard.Service"
                  defaultMessage="{phoneIcon} <phonelink>088223123</phonelink> <b>testTest</b> {br} {hr}"
                  values={{
                    phoneIcon: <StyledPhoneIcon />,
                    phonelink: (...chunks: string[]) => (
                      <a href={`tel:${chunks.join('')}`}>{chunks}</a>
                    ),
                    b: (...chunks: string[]) => <b>{chunks}</b>,
                    br: <br />,
                    hr: <HR />,
                  }}
                />
              </div>
            </>
          )}
          {(companyId === 'universalat' || companyId === 'ottoversandat') && enabled ? null : (
            <>
              <HR />
              <div>
                <b>
                  <FormattedMessage
                    id="PaymentProtectionCard.GoodToKnow"
                    defaultMessage="<b>Gut zu wissen</b>"
                    values={{
                      br: <br />,
                      b: (...chunks: string[]) => <b>{chunks}</b>,
                    }}
                  />
                </b>
                <br />
                <FormattedMessage
                  id="PaymentProtectionCard.GoodToKnowText"
                  defaultMessage="<b>Der Versicherungsbeitrag</b> beträgt 1,15% des offenen Saldos Ihres Kundenkontos. Besteht kein offener Saldo, muss natürlich nichts bezahlt werden."
                  values={{
                    br: <br />,
                    b: (...chunks: string[]) => <b>{chunks}</b>,
                  }}
                />
              </div>
            </>
          )}
        </CardContent>
        {!enabled && (
          <>
            <HR className="marginGrowLeftRight" />
            <ActionWrapper>
              <Link
                iconAlignment="right"
                iconType="ArrowMediumRight"
                to={getLocalizedPath(paymentProtection, intl.locale)}
              >
                <FormattedMessage
                  id="PaymentProtectionCard.CompletePaymentProtection"
                  defaultMessage="Jetzt den Flexikonto Zahlschutz abschließen"
                />
              </Link>
            </ActionWrapper>
          </>
        )}
      </Card>
    </GreyBox>
  );
};
