import React from 'react';

export const ActivateVoucherIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M29.606 6c1.046 0 1.894.782 1.894 1.746v16.508c0 .964-.848 1.746-1.894 1.746H2.394C1.348 26 .5 25.218.5 24.254v-4.745l.335-.116c1.634-.566 2.415-1.579 2.415-3.12 0-1.54-.776-2.53-2.405-3.057L.5 13.104V7.746C.5 6.782 1.348 6 2.394 6h27.212zM7.819 7.034H2.394c-.475 0-.86.353-.86.787v4.65l.107.043c1.706.685 2.605 1.922 2.641 3.645l.002.11c0 1.738-.857 3.009-2.516 3.753l-.235.1v4.057c0 .417.356.758.805.785l.056.002 5.106-.001V23.5a.5.5 0 0 1 .998-.048l.002.048v1.465h21.106c.439 0 .8-.3.854-.687l.007-.099V7.821c0-.434-.386-.787-.861-.787H8.18a.5.5 0 0 1 .317.418L8.5 7.5v2a.5.5 0 0 1-.998.048L7.5 9.5v-2a.5.5 0 0 1 .32-.466zM8 19a.5.5 0 0 1 .498.452l.002.048v2a.5.5 0 0 1-.998.048L7.5 21.5v-2A.5.5 0 0 1 8 19zm14.5-3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm1.844-5.867a.506.506 0 0 1 .057.666l-.032.04L14.348 21.84a.483.483 0 0 1-.692.026.506.506 0 0 1-.057-.666l.032-.04L23.652 10.16a.483.483 0 0 1 .692-.026zM22.5 17.015a1.985 1.985 0 1 0 0 3.97 1.985 1.985 0 0 0 0-3.97zM8 15a.5.5 0 0 1 .498.452l.002.048v2a.5.5 0 0 1-.998.048L7.5 17.5v-2A.5.5 0 0 1 8 15zm8.5-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1.015a1.985 1.985 0 1 0 0 3.97 1.985 1.985 0 0 0 0-3.97zM8 11a.5.5 0 0 1 .498.452l.002.048v2a.5.5 0 0 1-.998.048L7.5 13.5v-2A.5.5 0 0 1 8 11z"
        id="obwhqp9eva"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="n686448cfb" fill="#fff">
        <use href="#obwhqp9eva" />
      </mask>
      <g mask="url(#n686448cfb)" fill="#262729">
        <path d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>
);
