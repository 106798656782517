import React from 'react';
import styled from 'styled-components';
import { Theme } from '@empiriecom/module-components/ThemeProvider';

export const NoArticleFallbackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <path
        id="prefix__a"
        d="M23.523 28.22c2.067-.24 3.708-1.88 3.947-3.947l2.707-2.707c.21.69.323 1.424.323 2.184 0 4.142-3.358 7.5-7.5 7.5-.76 0-1.493-.113-2.184-.323l2.707-2.707zM23 16.25c1.17 0 2.276.268 3.262.745l-2.348 2.348c-.295-.061-.6-.093-.914-.093-2.485 0-4.5 2.015-4.5 4.5 0 .313.032.62.093.914l-2.348 2.349c-.477-.987-.745-2.094-.745-3.263 0-4.142 3.358-7.5 7.5-7.5zm11.207 1.286c.485.412.793 1.027.793 1.713 0 1.243-1.007 2.251-2.25 2.251-.686 0-1.301-.308-1.714-.793l1.071-1.071c.131.218.37.364.643.364.414 0 .75-.336.75-.75 0-.273-.146-.512-.364-.643l1.071-1.071zm1.807-1.807c.754.619 1.236 1.547 1.236 2.584v11.812c0 1.862-1.556 3.375-3.45 3.375H18.243l1.5-1.5H33.8c1.075 0 1.95-.85 1.95-1.875V18.312c0-.617-.318-1.17-.806-1.514l1.07-1.07zM10.311 32.946c-.938-.604-1.561-1.642-1.561-2.821V18.312c0-1.862 1.556-3.375 3.45-3.375h3.24c.128 0 .32-.134.356-.24l.838-2.441c.244-.718 1.011-1.256 1.775-1.256h9.182c.763 0 1.528.537 1.774 1.254l.419 1.22-1.18 1.18-.658-1.914c-.036-.105-.228-.24-.355-.24h-9.182c-.127 0-.32.135-.355.24l-.839 2.444c-.246.717-1.01 1.254-1.774 1.254H12.2c-1.075 0-1.95.85-1.95 1.875v11.812c0 .759.48 1.423 1.167 1.716l-1.106 1.105zm.83 5.535C14.427 41 18.538 42.5 23 42.5c10.77 0 19.5-8.73 19.5-19.5 0-4.462-1.498-8.573-4.02-11.86L11.14 38.48zm-2.237-2.007l27.57-27.57C32.974 5.556 28.227 3.5 23 3.5 12.23 3.5 3.5 12.23 3.5 23c0 5.227 2.056 9.973 5.404 13.474zM23 45.5C10.574 45.5.5 35.426.5 23S10.574.5 23 .5 45.5 10.574 45.5 23 35.426 45.5 23 45.5z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <mask id="prefix__b" fill="#fff">
        <use href="#prefix__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" href="#prefix__a" />
      <g fill="#8C8480" mask="url(#prefix__b)">
        <path d="M0 0H48V48H0z" transform="translate(-1 -1)" />
      </g>
    </g>
  </svg>
);

const NoArticleFallbackImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: { theme: Theme }) => theme.greyLight.color.normal};
  width: 100%;
  height: 100%;
  min-width: inherit;
  min-height: inherit;

  & > svg {
    fill: ${({ theme }: { theme: Theme }) => theme.base.color.text};
  }
`;

export const NoArticleFallbackImage: React.FC = () => (
  <NoArticleFallbackImageWrapper>
    <NoArticleFallbackIcon />
  </NoArticleFallbackImageWrapper>
);

export default NoArticleFallbackIcon;
