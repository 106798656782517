import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';

import { Modal } from '@empiriecom/module-components/Modal';
import { DefaultCard } from '@empiriecom/module-components/Card';
import Button from '@empiriecom/module-components/Button';
import { Headline, Theme } from '@empiriecom/module-components';
import { ShipmentInfosShipmentList } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { useShipmentInfos } from '@/src/components//AccountProvider';

import RecoSlider from '@empiriecom/module-components/RecoSlider';
import { FragmentConfig } from '@/config/types';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import EventTracking from '../../EventTracking';
import { ShipmentInfosProductView } from './ShipmentInfosProductView';

const Checkmark = getIcon('Checkmark');

const StyledHeadline = styled(Headline)`
  text-align: center;
  margin: 0 -0.75rem 2rem -0.75rem;
  padding: 0.875rem 0;
  background: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.688rem;
  }
`;

const Content = styled.div`
  min-height: 100%;
  /* padding: 0 0.75rem 0.75rem 0.75rem; */
  background-color: ${({ theme }: { theme: Theme }) => theme.greyLight.color.normal};

  .card {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
`;

const SuccessMessageRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-top: 5.125rem;
    color: #7dae2e;
    /* width: 3.438rem;
    height: 2.688rem; */
  }

  .main-message {
    font-size: 1.313rem;
    text-align: center;
    margin-bottom: 1.438rem;
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.global.spacer.medium};
  }

  .sub-message {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.global.spacer.large};
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }

  .btn {
    margin-bottom: 4.313rem;
  }

  .headline {
    width: 100%;
    margin: 0 0;

    margin-bottom: ${({ theme }: { theme: Theme }) => theme.global.spacer.medium};
  }
`;

const messages = defineMessages({
  cancel: {
    id: 'CancelShipmentAction.label.cancel',
    defaultMessage: 'Abbrechen',
  },
  cancelOrder: {
    id: 'CancelShipmentAction.label.cancelOrder',
    defaultMessage: 'Artikel stornieren',
  },
  cancelOrderConfirm: {
    id: 'CancelShipmentAction.label.cancelOrder.confirm',
    defaultMessage: 'Stornieren',
  },
  close: {
    id: 'CancelShipmentAction.label.close',
    defaultMessage: 'Schließen',
  },

  modalTitle: {
    id: 'ShipmentInfosProductView.modal.title',
    defaultMessage: 'Möchtest Du wirklich stornieren?',
  },
  modalTitleConfirm: {
    id: 'CancelShipmentAction.modal.title.confirm',
    defaultMessage: 'Stornierung bestätigen',
  },
  modalTitleSuccess: {
    id: 'ShipmentInfosProductView.modal.title.success',
    defaultMessage: 'Stornierung erfolgreich',
  },

  modalMessageSuccess: {
    id: 'ShipmentInfosProductView.modal.message.canceled.main',
    defaultMessage: 'Dein Artikel wurde storniert.',
  },
  modalMessageSuccessSub: {
    id: 'ShipmentInfosProductView.modal.message.canceled.sub',
    defaultMessage: 'Beim nächsten Mal findest du bestimmt etwas Passendes für dich.',
  },
});

export function CancelShipmentAction({
  shipment,
  onSuccess,
}: {
  shipment: ShipmentInfosShipmentList;
  onSuccess?: (id: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const intl = useIntl();
  const config = useConfig<FragmentConfig>();
  const { cancelOrder, load } = useShipmentInfos();

  const id = shipment.orderDetails?.orderDetailId ?? '';

  const handleClose = () => {
    // We need to reload on close and not directly after cancelling successfully because the rerendering removes the modalbox otherwise
    if (success) {
      onSuccess?.(id);
      load?.();
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    cancelOrder?.(id, () => {
      setSuccess(true);
    });
  };

  if (shipment.orderDetails?.cancelable) {
    return (
      <>
        <Button onClick={() => setOpen(true)}>{intl.formatMessage(messages.cancelOrder)}</Button>
        {open && (
          <Modal
            noContentPadding
            headline={intl.formatMessage(
              success ? messages.modalTitleSuccess : messages.modalTitle,
            )}
            onRequestClose={handleClose}
            testIdClose="cancel-shipment-close"
            open={open}
          >
            <Content>
              {success ? (
                <>
                  <SuccessMessageRoot>
                    <EventTracking eventCategory="shipmentInfos_cancel_success" isLayer />
                    <Checkmark size="5rem" />
                    <div className="main-message">
                      {intl.formatMessage(messages.modalMessageSuccess)}
                    </div>
                    <div className="sub-message">
                      {intl.formatMessage(messages.modalMessageSuccessSub)}
                    </div>
                    <Button className="btn" onClick={handleClose}>
                      {intl.formatMessage(messages.close)}
                    </Button>
                  </SuccessMessageRoot>
                  <RecoSlider
                    preferHeadline
                    rdeParams={{
                      rdeType: 'magellanSimilarProducts',
                      recoApi: config.recoApi,
                      requestParams: {
                        size: '',
                        productId: shipment?.product.articleNumber,
                      },
                      trackingProps: {
                        pageTemplate: 'BestellungUndLieferung',
                        sourceCategoryId: '',
                      },
                      RecoSliderBaseProps: {
                        SliderSettingsProps: {
                          headline: '',
                        },
                        ProductProps: {
                          imageRatioName: '$PL_landscape_hq$',
                        },
                      },
                    }}
                  />
                </>
              ) : (
                <>
                  <EventTracking eventCategory="shipmentInfos_cancel" isLayer />
                  <StyledHeadline level={3}>
                    {intl.formatMessage(messages.modalTitleConfirm)}
                  </StyledHeadline>
                  <DefaultCard className="card">
                    <ShipmentInfosProductView product={shipment?.product}>
                      <ButtonContainer>
                        <Button layout="primary" onClick={handleSubmit}>
                          {intl.formatMessage(messages.cancelOrderConfirm)}
                        </Button>
                        <Button onClick={handleClose}>{intl.formatMessage(messages.cancel)}</Button>
                      </ButtonContainer>
                    </ShipmentInfosProductView>
                  </DefaultCard>
                </>
              )}
            </Content>
          </Modal>
        )}
      </>
    );
  }

  return null;
}

export default CancelShipmentAction;
