import React from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import ProductLink, { updateUrl } from '@empiriecom/module-components/Link/ProductLink';
import { ProductBase } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import Image from '@empiriecom/module-components/Image';
import { Price } from '@empiriecom/module-components/Price';
import { NoArticleFallbackImage } from '@/src/components/icons/NoArticleFallback';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Root = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 1rem;
  > img {
    width: calc(33% - 0.5rem);
    max-width: 10rem;
  }

  .content {
    width: calc(67% - 0.5rem);
  }

  .footer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: flex-end;

    > *:last-child {
      justify-content: flex-end;
      font-size: 0.813rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    width: unset;
    display: block;

    clear: both;

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 0.75rem;
`;

const ProductName = styled.span`
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 0.813rem;
`;

const ProductImageWrapper = styled.div`
  display: inline-block;
  width: 4rem;
  max-height: 5rem;
  padding: 0 0.5rem 0.5rem 0;
  margin-right: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    float: left;
  }

  & img {
    max-width: 100%;
    max-height: 5rem;
    object-fit: contain;
    font-size: 50%;
    text-overflow: ellipsis;
  }
`;

const StyledProductLink = styled(ProductLink)`
  margin-right: auto;
  white-space: nowrap;
`;

const ChildContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.global.breakpoints.tablet}) {
    display: block;
  }
`;

const messages = defineMessages({
  amount: {
    id: 'ShipmentInfosProductView.label.amount',
    defaultMessage: 'Anzahl',
  },
  productLink: {
    id: 'ShipmentInfosProductView.productLink',
    defaultMessage: 'zum Artikel',
  },
});

export const ShipmentInfosProductViewTestIds = {
  contentContainer: 'shipment-product_content-container',
  childContainer: 'shipment-product_child-container',
};

export function ShipmentInfosProductView({
  product,
  children,
}: {
  children?: React.ReactNode;
  product: ProductBase;
}) {
  const intl = useIntl();

  return (
    <Root>
      <ProductImageWrapper>
        {product.imageUrl ? (
          <Image src={product.imageUrl} alt={product.name} fallback={<NoArticleFallbackImage />} />
        ) : (
          <NoArticleFallbackImage />
        )}
      </ProductImageWrapper>
      <FlexColumn
        data-testid={ShipmentInfosProductViewTestIds.contentContainer}
        className="content"
      >
        <ProductName>{product.name}</ProductName>
        <span>{product.variation}</span>
        <span>
          {intl.formatMessage(messages.amount)}: {product.quantity || '--'}
        </span>
        <FlexRow className="footer">
          <StyledProductLink href={updateUrl(product.url ? product.url : '')}>
            {intl.formatMessage(messages.productLink)}
          </StyledProductLink>
          <Price
            layout="defaultInCard"
            currency={product.price.currencyCode}
            price={product.price.value}
            oldPrice={product.oldPrice?.value}
          />
        </FlexRow>
        <ChildContainer data-testid={ShipmentInfosProductViewTestIds.childContainer}>
          {children}
        </ChildContainer>
      </FlexColumn>
    </Root>
  );
}

export default ShipmentInfosProductView;
