import React, { FC } from 'react';
import Card from '@empiriecom/module-components/Card';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Grid, Link } from '@empiriecom/module-components';
import { formatCurrency } from '@empiriecom/module-components/FormattedCurrency';
import { CustomerCreditDataDelayedPayment } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { PaymentPauseCardSkeletion } from './FlexikontoSkeleton';
import internalPages, { Language } from '@/config/pages';
import getIconSet from '@empiriecom/module-components/utils/getIconSet';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import getLocalizedPath from '@/src/utils/getLocalizedPath';

const { DelayedPayment: DelayedPaymentIcon, ArrowMediumRight: ArrowMediumRightIcon } = getIconSet();
const messages = defineMessages({
  inactiveSubheadline: {
    id: 'PaymentPause.InactivelSubheadline',
    defaultMessage: 'Sie haben aktuell keine aktive Zahlpause.',
  },
  inactiveInstructions: {
    id: 'PaymentPause.InactivelInstructions',
    defaultMessage:
      'Wählen Sie die Flexikonto Zahlpause bei Ihrem nächsten Einkauf im Bestellschritt Zahlung und bezahlen Sie erst nach 3 Monaten.*{break}* Aufpreis 4,99% vom Bestellwert.Bonität vorausgesetzt.',
  },
  activeInstructions: {
    id: 'PaymentPause.activeInstructions',
    defaultMessage:
      'Hier finden Sie eine Übersicht über Ihre aktiven Zahlpausen und dem Fälligkeitsdatum.',
  },
  purchaseDate: {
    id: 'PaymentPause.InitialPurchaseDate',
    defaultMessage: 'Einkaufsdatum',
  },
  purchaseAmount: {
    id: 'PaymentPause.PurchaseAmount',
    defaultMessage: 'Betrag',
  },
  purchaseDueDate: {
    id: 'PaymentPause.PurchaseDueDate',
    defaultMessage: 'ist fällig am',
  },
  furtherInstructions: {
    id: 'PaymentPause.FurtherInstructions',
    defaultMessage: 'Alle Informationen zur Flexikonto Zahlpause',
  },
  cardHeadline: {
    id: 'PaymentPause.CardHeadline',
    defaultMessage: 'Flexikonto Zahlpause',
  },
  purchaseFrom: {
    id: 'PaymentPause.PurchaseFrom',
    defaultMessage: 'Einkauf vom ',
  },
});

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.div`
  font-weight: bold;

  &.marginTop {
    margin-top: 0.25rem;
  }
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyLight.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.25rem 0;

  &.noMargin {
    margin: 0;
  }
  &.marginGrowLeftRight {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;
const StyledGrid = styled(Grid)`
  grid-template-columns: 2fr 1fr 1fr;
`;
const LeftRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .left {
    width: 80%;
  }
`;
const UnstyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    svg {
      fill: ${({ theme }) => theme.primary.color.normal};
    }
  }
`;

const PaymentPauseCard: FC<{
  delayedPayment?: CustomerCreditDataDelayedPayment;
}> = ({ delayedPayment }): JSX.Element => {
  const intl = useIntl();
  const {
    flexikonto: {
      externalUrls: { paymentPause },
    },
  } = useConfig<FragmentConfig>();
  const allBookingsLink = internalPages.bookings.paths[intl.locale.split('-')[0] as Language];
  // decide whether to show the delayed payment card or inactive state
  if (!delayedPayment) {
    return <PaymentPauseCardSkeletion />;
  }

  const showDelayedPayment =
    delayedPayment && delayedPayment.entries && delayedPayment.entries.length > 0;

  return (
    <>
      <GreyBox>
        <Card
          showHeaderSeparator={false}
          headerIcon={<DelayedPaymentIcon />}
          headerVariant="additional"
          roundedIcon={false}
          headline={intl.formatMessage(messages.cardHeadline)}
          shouldHideHeaderDataForMouseflow
        >
          <HR className="noMargin" />
          <CardContent>
            {!showDelayedPayment && (
              <>
                <h4 style={{ marginBottom: '0', marginTop: '0' }}>
                  {intl.formatMessage(messages.inactiveSubheadline)}
                </h4>
                <p style={{ marginTop: '0', marginBottom: '0' }}>
                  {intl.formatMessage(messages.inactiveInstructions, {
                    break: (
                      <>
                        <br />
                        <br />
                      </>
                    ),
                  })}
                </p>
              </>
            )}
            {showDelayedPayment && (
              <>
                {intl.formatMessage(messages.activeInstructions)}
                <HR />
                <StyledGrid>
                  <div>
                    <Label>{intl.formatMessage(messages.purchaseDate)}</Label>
                    {delayedPayment?.entries?.map(({ shoppingDate }, index) => (
                      <Link to={allBookingsLink} key={index} style={{ display: 'block' }}>
                        {intl.formatMessage(messages.purchaseFrom)}
                        {intl.formatDate(shoppingDate, {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                        })}
                      </Link>
                    ))}
                    {/* mapped data entries go in here */}
                  </div>
                  <div style={{ textAlign: 'right', marginRight: '1rem' }}>
                    <Label>{intl.formatMessage(messages.purchaseAmount)}</Label>
                    {/* mapped data entries go in here */}
                    {delayedPayment?.entries?.map(({ amount: { currencyCode, value } }, index) => (
                      <div key={index}>{formatCurrency(currencyCode, value, intl)}</div>
                    ))}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Label>{intl.formatMessage(messages.purchaseDueDate)}</Label>
                    {/* mapped data entries go in here */}
                    {delayedPayment?.entries?.map(({ dueToDate }, index) => (
                      <div key={index}>
                        {intl.formatDate(dueToDate, {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                        })}
                      </div>
                    ))}
                  </div>
                </StyledGrid>
              </>
            )}
            <HR className="marginGrowLeftRight" />
            <LeftRight style={{ padding: '0.25rem', alignItems: 'center' }}>
              <UnstyledLink to={getLocalizedPath(paymentPause, intl.locale)}>
                {intl.formatMessage(messages.furtherInstructions)}
                <ArrowMediumRightIcon />
              </UnstyledLink>
            </LeftRight>
          </CardContent>
        </Card>
      </GreyBox>
    </>
  );
};

export default PaymentPauseCard;
