import AddressbookContainerSkeleton from '@/src/components/AddressbookContainer/skeleton';
import { SkeletonProvider, withSkeleton } from '@empiriecom/module-components/Skeleton';
import { Addressbook } from '@empiriecom/mybuy-components/components/Addressbook';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import React, { FC } from 'react';
import EventTracking from '../EventTracking';
import VerificationNotification from '../VerificationNotification';

const AddressbookContainerContent: FC = (): JSX.Element => {
  return (
    <div data-testid="addressbook-container-wrapper">
      <EventTracking eventCategory="addressbook" />
      <VerificationNotification />
      <Addressbook />
    </div>
  );
};

const AddressbookContainerWithSkeleton = withSkeleton(
  AddressbookContainerContent,
  AddressbookContainerSkeleton,
);

const AddressbookContainer: FC = (): JSX.Element => {
  const { isLoading } = useCustomerContext();
  return (
    <>
      <SkeletonProvider isLoading={isLoading}>
        <AddressbookContainerWithSkeleton />
      </SkeletonProvider>
    </>
  );
};

export default AddressbookContainer;
