import { CustomerStatusAdvantages } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { DataWithLoadingInfo } from '@empiriecom/mybuy-components/provider/CustomerProvider/interfaces';
import useCustomerStatus from './useCustomerStatus';

export type CustomerAdvantagesData = DataWithLoadingInfo<CustomerStatusAdvantages>;

function useCustomerAdvantages(): CustomerAdvantagesData {
  const customerStatus = useCustomerStatus();
  return {
    data: customerStatus.data?.advantages,
    loading: customerStatus.loading,
  };
}

export default useCustomerAdvantages;
