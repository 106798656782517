import React, { useCallback, useState, ReactNode, createContext } from 'react';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import { useCustomerContext } from '@empiriecom/mybuy-components';
import {
  GetShipmentInfosRequest,
  ShipmentInfos,
} from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';

export interface ShipmentsContextInterface {
  data?: ShipmentInfos;
  load?: (params?: GetShipmentInfosRequest) => Promise<ShipmentInfos>;
  loadMore?: () => Promise<void>;
  cancel?: (orderDetailId: string, onSuccess?: () => void) => Promise<void>;
}

export const ShipmentsContext = createContext<ShipmentsContextInterface>({});

export function ShipmentsProvider({ children }: { children?: ReactNode }) {
  const [data, setData] = useState<ShipmentInfos>();
  const [params, setParams] = useState<GetShipmentInfosRequest>();

  const api = useAccountApi();
  const { ecLocale } = useCustomerContext();

  const load = async (requestParams?: GetShipmentInfosRequest) => {
    const usedParameters = requestParams || params;
    if (api && usedParameters) {
      setParams({ ...usedParameters });
      const result = await api.getShipmentInfos(usedParameters);
      setData(result);
      return result;
    }
    throw new Error('No api or params available');
  };

  const loadMore = useCallback(
    () =>
      api!.getShipmentInfos({ ...params!, id: data!.nextDataId }).then((result) => {
        setParams((state) => ({ ...state!, id: data!.nextDataId }));
        setData((state) => ({
          ...state,
          nextDataId: result.nextDataId,
          shipmentList: [...(state!.shipmentList || []), ...(result.shipmentList || [])],
        }));
      }),
    [api, data?.nextDataId, params],
  );

  const cancel = useCallback(
    (id: string, onSuccess?: () => void) => {
      return api!.cancelOrderDetail({ orderDetailId: id, ecLocale }).then((result) => {
        const { success: cancelSuccess } = result;

        if (!cancelSuccess && result.errors) {
          // make the promise fail and enter catch block
          throw new Error('cancel failed');
        }

        onSuccess?.();
      });
    },
    [api, params],
  );

  return (
    <ShipmentsContext.Provider
      value={{
        load: api ? load : undefined,
        data,
        loadMore: api && data?.nextDataId ? loadMore : undefined,
        cancel: api && data ? cancel : undefined,
      }}
    >
      {children}
    </ShipmentsContext.Provider>
  );
}
