import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import { Button } from '@empiriecom/module-components/Button';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import { ActivateVoucherIcon } from '@/src/components/icons/ActivateVoucher';
import Chip from '@empiriecom/mybuy-components/components/Chip';
import { useEffect, useState } from 'react';
import getIcon from '@empiriecom/module-components/utils/getIcon';

const PersonalCouponBox = styled(BorderedPanel)<{ isActive: boolean }>`
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.success.color.normal : theme.greyNormal.color.normal};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.313rem;

  & > *:first-child {
    flex: 0 0 2rem;
  }
`;

const PersonalCouponContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  text-align: left;
`;

const PersonalCouponHeader = styled.div`
  font-weight: 600;
  font-size: 0.813rem;
  line-height: 1.38;
`;

const PersonalCouponHint = styled.div`
  color: ${({ theme }) => theme.global.color.textDarkTransparent};
`;

const Pointer = styled.div`
  cursor: pointer;
`;

const StyledChip = styled(Chip)`
  padding-left: 0.25rem;
  padding-right: 0;
`;

interface PersonalCouponProps {
  code?: string;
  text?: string;
  conditions?: string;
}

const ArrowMiniDown = getIcon('ArrowMiniDown');
const ArrowMiniUp = getIcon('ArrowMiniUp');

export const PersonalCoupon: React.FC<PersonalCouponProps> = ({ code, text, conditions }) => {
  const [isCouponActivated, setIsCouponActivated] = useState<boolean>(false);
  const [conditionsOpen, setConditionsOpen] = useState<boolean>(false);

  const handleCouponCheck = () => {
    if (code) {
      setIsCouponActivated(apiKeyManager.getBonuscodes().includes(code));
    }
  };

  useEffect(() => {
    handleCouponCheck();
  }, [apiKeyManager, code]);

  if (!code) {
    return null;
  }

  const onCouponActivation = async () => {
    await apiKeyManager.setBonuscodes([...apiKeyManager.getBonuscodes(), code]);
    setIsCouponActivated(true);
  };

  return (
    <PersonalCouponBox isActive={isCouponActivated}>
      <ActivateVoucherIcon />
      <PersonalCouponContent>
        <PersonalCouponHeader>
          <FormattedMessage id="PersonalCoupon.Header" defaultMessage="Dein Dankeschön:" /> {text}
        </PersonalCouponHeader>
        {isCouponActivated && (
          <>
            <StyledChip
              hasCheckmark
              colorScheme="success"
              variant="outlined-transparent"
              size="small"
            >
              <FormattedMessage
                id="PersonalCoupon.ActiveChip"
                defaultMessage="Gutschein angewendet"
              />
            </StyledChip>
            <PersonalCouponHint>
              <FormattedMessage
                id="PersonalCoupon.ActiveHint"
                defaultMessage="Der Gutschein wird in Schritt 5 „Prüfen und Bestellen“ angewendet."
              />
            </PersonalCouponHint>
          </>
        )}
        {!isCouponActivated && (
          <Button size="small" onClick={onCouponActivation}>
            <FormattedMessage id="PersonalCoupon.Button" defaultMessage="Gutschein anwenden" />
          </Button>
        )}
        <Pointer tabIndex={0} onClick={() => setConditionsOpen(!conditionsOpen)}>
          <FormattedMessage
            id="PersonalCoupon.ConditionsHeadline"
            defaultMessage="*Gutscheinbedingungen"
          />
          {conditionsOpen ? <ArrowMiniUp /> : <ArrowMiniDown />}
        </Pointer>
        {conditionsOpen && <div>{conditions}</div>}
      </PersonalCouponContent>
    </PersonalCouponBox>
  );
};

export default PersonalCoupon;
