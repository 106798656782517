import React, { FunctionComponent } from 'react';
import { useMedia } from 'react-media-match';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';
import { renderFont } from '@empiriecom/module-components';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { usePageContext } from '@/src/components/Pages/PageProvider';
import { BypassBlock } from '@empiriecom/mybuy-components/components/BypassBlock';
import {
  useNavigationContext,
  Navigation,
  NavigationEntry,
} from '@/src/components/NavigationProvider';
import { PageProviderEntry } from '@/src/typings/Navigation';
import { FragmentConfig } from '@/config/types';
import internalPages, { Language } from '@/config/pages';

const ArrowMiniDown = getIcon('ArrowMiniDown');
const ArrowLargeDown = getIcon('ArrowLargeDown');

const NavigationContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
  border-bottom: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
`;
const messages = defineMessages({
  bypassHeadline: {
    id: 'Navigation.MyAccount.Bypass.Headline',
    defaultMessage: 'Mein Konto Navigation überspringen',
  },
});
const NavigationHeadline = styled(Link)<{ ismobile: string; config: FragmentConfig }>`
  text-transform: ${({ config }) => config.navigation.headlineTextTransform};
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  padding: 0.5rem;
  ${({ ismobile, theme }) =>
    ismobile === 'true'
      ? `
        font-weight: bold;

        margin: 1rem 0 0 0;
        
        &:link, &:visited, &:hover, &:active {
          color: ${theme.primary.color.text};
          background-color: ${theme.primary.color.normal};
        }
        svg {
          margin-right: 1rem;
          fill: ${theme.primary.color.text};
        }

        `
      : `
        &:hover {
          background-color: ${theme.greyLight.color.normal};
        }
        svg {
          margin-right: 1rem;
          fill: ${theme.base.color.text};
        }

      `};
`;

const NavigationLinkClosed = css`
  position: relative;
  &:before {
    content: '+';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    font-weight: bold;
    font-size: 1.125rem;
  }
`;

const NavigationLinkOpen = css`
  position: relative;
  &:before {
    content: '-';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    font-weight: bold;
    font-size: 1.125rem;
  }
`;

const NavigationLink = styled(Link)<{
  active: string;
  ismobile: string;
  hassubentries: string;
  config: FragmentConfig;
}>`
  text-decoration: none;
  display: block;
  ${({ active, theme }) => (active === 'true' ? renderFont('base', 'bold', theme) : '')};
  ${({ ismobile, theme }) =>
    ismobile === 'true'
      ? `
        padding: 0.5rem 0 0.5rem 1rem;
        background-color: ${theme.greyLight.color.normal};
        border-bottom: 1px solid ${theme.greyNormal.color.normal};
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          fill: ${theme.base.color.text};
          margin-right: 1.5rem;
        }
        `
      : `
        padding: 0.25rem 0 0.25rem 1rem;

        &:hover {
          background-color: ${theme.greyLight.color.normal};
        }
      `};
  ${({ active, hassubentries, ismobile, theme }) => `
    ${
      active === 'true' && ismobile === 'true'
        ? `
          &:link, &:visited, &:hover, &:active {
            color: ${theme.primary.color.text};
            background-color: ${theme.primary.color.normal};
          }
          svg {
            fill: ${theme.primary.color.text};
            margin-right: 1.5rem;
          }
          `
        : ''
    };
    ${
      active === 'true' && hassubentries === 'true ' && ismobile === 'false'
        ? NavigationLinkOpen
        : ''
    }
    ${
      active === 'false' && hassubentries === 'true' && ismobile === 'false'
        ? NavigationLinkClosed
        : ''
    }
  `}
`;

const NavigationEntrySkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  border-radius: 4px;
  width: 100%;
`;

const SubEntriesWrapper = styled.div`
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.greyNormal.color.normal};
`;

const NavigationWrapper = styled.div<{ isMobile: string }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '25%')};
`;

export interface ExternalNavigationProps {}

/**
 * Checks if the NavigationEntry to be rendered matches the currently active page.
 * This also looks for possible child Elements as one of that could be active
 * and that turns the current node active as well
 */
export const isActive = (currentPath: string, testee: NavigationEntry): boolean => {
  if (currentPath === testee.path) {
    return true;
  }
  if (testee.children != null) {
    const matchingSubEntry = testee.children.find((child) => isActive(currentPath, child));
    // matchingSubEntry will be undefined if nothing is found or a NavigationEntry if something is found so we can just check for that
    return typeof matchingSubEntry !== 'undefined';
  }
  return false;
};

const SingleNavigationLink: FunctionComponent<{
  testingUrls: boolean;
  isMobile: string;
  selectedEntry: PageProviderEntry;
  renderEntry: NavigationEntry;
  config: FragmentConfig;
}> = ({ testingUrls, isMobile, selectedEntry, renderEntry, config }): JSX.Element => {
  const active = isActive(selectedEntry.path, renderEntry) || false;

  const hasChildren = renderEntry.children !== null && renderEntry.children.length > 0;

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  /*
       internal links might be active or folded out or both if a child element is active
  */
  if (active && hasChildren) {
    return (
      <>
        <NavigationLink
          data-testid={`nav-${renderEntry.path} internal`}
          onClick={scrollToTop}
          to={testingUrls ? renderEntry.path.replace(/^\//g, '/fritz-') : renderEntry.path}
          active={active.toString()}
          ismobile={isMobile}
          hassubentries={hasChildren.toString()}
          config={config}
        >
          {renderEntry.name.defaultMessage} {isMobile === 'true' && <ArrowMiniDown size="1.5rem" />}
        </NavigationLink>
        <SubEntriesWrapper>
          {/* ignore this for now, we have circular using here */}
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <NavigationEntryList
            entries={renderEntry.children || []}
            testingUrls={testingUrls}
            isMobile={isMobile}
            selectedEntry={selectedEntry}
            config={config}
          />
        </SubEntriesWrapper>
      </>
    );
  }
  return (
    <NavigationLink
      data-testid={`nav-${renderEntry.path} internal`}
      onClick={scrollToTop}
      to={testingUrls ? renderEntry.path.replace(/^\//g, '/fritz-') : renderEntry.path}
      active={active.toString()}
      ismobile={isMobile}
      hassubentries={hasChildren.toString()}
      config={config}
    >
      {renderEntry.name.defaultMessage}{' '}
      {isMobile === 'true' && hasChildren && <ArrowMiniDown size="1.5rem" />}
    </NavigationLink>
  );
};

const NavigationEntryList: FunctionComponent<{
  entries: Navigation;
  testingUrls: boolean;
  isMobile: string;
  selectedEntry: PageProviderEntry;
  config: FragmentConfig;
}> = ({ entries, testingUrls, isMobile, selectedEntry, config }): JSX.Element => {
  return (
    <>
      {entries.map((e) => (
        <SingleNavigationLink
          selectedEntry={selectedEntry}
          isMobile={isMobile}
          testingUrls={testingUrls}
          renderEntry={e}
          key={e.path}
          config={config}
        />
      ))}
    </>
  );
};

const ExternalNavigation: FunctionComponent<ExternalNavigationProps> = (): JSX.Element => {
  const { entry, testingUrls } = usePageContext();
  const { locale, formatMessage } = useIntl();
  const internationalizedBasePath = internalPages.base.paths[locale.split('-')[0] as Language];
  const config = useConfig<FragmentConfig>();
  const navigation = useNavigationContext();

  const isMobile = useMedia({ mobile: 'true', tablet: 'false', desktop: 'false' });
  return (
    <BypassBlock buttonText={formatMessage(messages.bypassHeadline)} skipScrollIntoView>
      <NavigationContainer>
        <NavigationHeadline
          data-testid="nav-main"
          to={internationalizedBasePath}
          ismobile={isMobile}
          config={config}
        >
          <FormattedMessage defaultMessage="Mein Konto" id="Navigation.headline" />
          <ArrowLargeDown size="1.5rem" />
        </NavigationHeadline>
        {navigation.length > 0 ? (
          <NavigationEntryList
            testingUrls={testingUrls}
            entries={navigation}
            isMobile={isMobile}
            selectedEntry={entry}
            config={config}
          />
        ) : (
          <NavigationWrapper isMobile={isMobile}>
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
            <NavigationEntrySkeleton height="1rem" />
          </NavigationWrapper>
        )}{' '}
      </NavigationContainer>
    </BypassBlock>
  );
};

export default ExternalNavigation;
