import React, { FC, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import Card from '@empiriecom/module-components/Card';
import { Headline } from '@empiriecom/module-components/Headline';
import { Modal } from '@empiriecom/module-components/Modal';
import Toggle from '@empiriecom/module-components/Toggle';
import useConfig from '@empiriecom/module-components/hooks/useConfig';

import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useCustomerApi from '@empiriecom/mybuy-components/api/useCustomerApi';

import { Permissions } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/Permissions';
import { FragmentConfig } from '@/config/types';
import { useMedia } from 'react-media-match';
import useTracking from '@empiriecom/module-components/hooks/useTracking';
import { PhoneNumberWithTypeTypeEnum } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/PhoneNumberWithType';
import Button from '@empiriecom/module-components/Button';
import PhoneBook from '@empiriecom/mybuy-components/components/PhoneBook';

const messages = defineMessages({
  notifications: {
    id: 'AccountSettings.Notifications',
    defaultMessage: 'Benachrichtigungen',
  },
  moreInformation: {
    id: 'AccountSettings.MoreInformation',
    defaultMessage: 'weitere Informationen',
  },
  autologin: {
    id: 'AccountSettings.Autologin.Headline',
    defaultMessage: 'Aktivieren Sie die automatische Anmeldung.',
  },
  serviceMail: {
    id: 'AccountSettings.ServiceMail.Headline',
    defaultMessage: 'Service-Infos',
  },
  serviceMailInfotext0: {
    id: 'AccountSettings.ServiceMail.entry0',
    defaultMessage: 'Bestelleingangsbestätigung',
  },
  serviceMailInfotext1: {
    id: 'AccountSettings.ServiceMail.entry1',
    defaultMessage: 'Versandbestätigung',
  },
  serviceMailInfotext2: {
    id: 'AccountSettings.ServiceMail.entry2',
    defaultMessage: 'Retoureneingangsbestätigung',
  },
  serviceMailInfotext3: {
    id: 'AccountSettings.ServiceMail.entry3',
    defaultMessage: 'Zahlungseingangsbestätigung',
  },
  serviceMailInfotext4: {
    id: 'AccountSettings.ServiceMail.entry4',
    defaultMessage: 'Aufforderung zu Anzahlung',
  },
  serviceMailInfotext5: {
    id: 'AccountSettings.ServiceMail.entry5',
    defaultMessage: 'Bewertungsemail',
  },
  serviceMailInfotext6: {
    id: 'AccountSettings.ServiceMail.entry6',
    defaultMessage: '-',
  },
  serviceMailInfotext7: {
    id: 'AccountSettings.ServiceMail.entry7',
    defaultMessage: '-',
  },
  serviceMailInfotext8: {
    id: 'AccountSettings.ServiceMail.entry8',
    defaultMessage: '-',
  },
  serviceMailInfotext9: {
    id: 'AccountSettings.ServiceMail.entry9',
    defaultMessage: '-',
  },
  eInvoice: {
    id: 'AccountSettings.eInvoice.Headline',
    defaultMessage: 'eRechnung',
  },
  eInvoiceInfotext: {
    id: 'AccountSettings.eInvoice.Infotext',
    defaultMessage:
      'Wir benachrichtigen Sie per Mail, sobald eine digitale Rechnung im Kundenkonto vorliegt und abrufbar ist. So schonen wir gemeinsam die Umwelt.',
  },
  eInvoiceInfoBenefits: {
    id: 'AccountSettings.eInvoice.InfoBenefits',
    defaultMessage: ' ',
  },
  postalBankStatement: {
    id: 'AccountSettings.PostalBankStatement.Headline',
    defaultMessage: 'Postalische Kontoauszüge:',
  },
  postalBankStatementInfotext: {
    id: 'AccountSettings.PostalBankStatement.Infotext',
    defaultMessage: 'TODO',
  },
  smsNotification: {
    id: 'AccountSettings.SmsNotification.Headline',
    defaultMessage: 'SMS Genehmigung:',
  },
  smsNotificationInfotext: {
    id: 'AccountSettings.SmsNotification.Infotext',
    defaultMessage:
      'Wenn ich bei der Bestellung die Lieferung an einen PostNL-Punkt wähle oder einen großen Artikel von Dynalogic zu mir nach Hause liefern lasse, möchte ich bei Lieferung meiner Bestellung eine kostenlose SMS erhalten.',
  },
  newsletter: {
    id: 'AccountSettings.Newsletter.Headline',
    defaultMessage: 'Newsletter',
  },
  newsletterHeadlineModal: {
    id: 'AccountSettings.Newsletter.HeadlineModal',
    defaultMessage: 'Newsletter',
  },
  newsletterInfotext: {
    id: 'AccountSettings.Newsletter.Infotext',
    defaultMessage:
      'Kostenlose News zu Sparvorteilen, Trends rund um Mode, Schuhe und Wohnen, Sale und Aktionen – so verpassen Sie garantiert nichts mehr. Wird der Newsletter hier aktiviert, schicken wir Ihnen einen Bestätigungslink per Mail. Mit nur einem Klick auf den Link in der Mail sind Sie dabei.',
  },
  neighboursDelivery: {
    id: 'AccountSettings.NeighboursDelivery.Headline',
    defaultMessage: 'An Nachbar zustellen:',
  },
  neighboursDeliveryInfotext: {
    id: 'AccountSettings.NeighboursDelivery.Infotext',
    defaultMessage:
      'Der Zusteller kann mein Paket beim Nachbarn abgeben, wenn ich nicht zu Hause bin. Eine Änderung dieser Lieferpräferenz ist nur für Artikel möglich, die noch nicht in Rechnung gestellt wurden.',
  },
});

const ModalContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.greyLight.color.normal};
  height: 100%;
`;

const ModalHeadline = styled(Headline)`
  margin: 0;
  padding: 1rem 0;
  background-color: #fff;
`;

const StyledHeadline = styled(Headline)<{ isMobile: boolean }>`
  margin: 0 0 1rem 0;
  ${({ isMobile }) => isMobile && 'text-align: center;'}
  background-color: ${({ theme }) => theme.base.color.normal};
  padding: 0.5rem;
`;

const GreyBox = styled.div`
  padding: 0.5rem;
`;

const InfoModalBenefits = styled.div`
  ul {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 0.5rem;
  }
`;

const CardHead = styled.div`
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const CardContent = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0.5rem;
`;

const ToggleLayoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    flex: 1 1 auto;
  }

  & > :last-child {
    flex: 0 0 3rem;
  }
`;

const MoreLink = styled.div`
  font-size: 0.688rem;
  text-decoration: underline;
  cursor: pointer;
`;

const HR = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.greyNormal.color.normal};
  border-bottom: none; /* Without this, firefox renders an additional darker border bottom */
  margin: 0.5rem 0;

  &.noMargin {
    margin: 0;
  }
`;

const Space = styled.div`
  height: 0.5rem;
`;
const AccountSettings: FC = (): JSX.Element => {
  const intl = useIntl();
  const [serviceMailModalOpen, setServiceMailModalOpen] = useState<boolean>(false);
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [infoModalHeadline, setInfoModalHeadline] = useState<string>('');
  const [infoModalText, setInfoModalText] = useState<string>('');
  const [infoModalBenefits, setInfoModalBenefits] = useState<any>(null);
  const [phoneModalOpen, setPhoneModalOpen] = useState<boolean>(false);
  const customerContext = useCustomerContext();
  const isMobile = useMedia({ mobile: true, tablet: false, desktop: false });
  const customerApi = useCustomerApi();
  const { customer, customerInformation } = customerContext || {};
  const { permissions, phoneNumbers } = customer || {};
  const {
    autologin,
    eInvoice,
    newsletter,
    serviceMail,
    postalBankStatement,
    smsNotifications,
    neighboursDelivery,
  } = permissions || {}; // Get customers permissions.
  const dispatch = useTracking();

  const {
    permissions: {
      serviceMailEnabled,
      newsletterEnabled,
      eInvoiceEnabled,
      postalBankStatementEnabled,
      smsNotificationEnabled,
      neighboursDeliveryEnabled,
    }, // Decide if toggle box should be shown.
  } = useConfig<FragmentConfig>();

  const mobilePhoneNumber = phoneNumbers?.find(
    (phone) => phone.type === PhoneNumberWithTypeTypeEnum.MOBILE,
  )?.number;

  const saveSetting = async ({
    which,
    selected,
  }: {
    which: keyof Omit<Permissions, 'parcelShopSMS' | 'parcelShopSMSIndex'>;
    selected: boolean;
  }) => {
    const newPermissions: Permissions = {};
    newPermissions[which] = selected;
    const result = await customerApi!.updatePermissions({
      permissions: newPermissions,
      ecLocale: customerContext.ecLocale,
    });
    if (result.customer) {
      await customerContext.actions.update(result.customer);
    } else {
      throw new Error('No good result was returned');
    }
    // istanbul ignore else
    if (result.tokens) {
      await apiKeyManager.setApiKeys(result.tokens);
    }
    if (which === 'newsletter' && customerInformation?.data?.hashEmail) {
      /* istanbul ignore next */
      dispatch({
        event: 'ga_event',
        eventAction: customerInformation.data.hashEmail,
        eventCategory: selected ? 'SubscribeNewsletter' : 'UnsubscribeNewsletter',
      });
    }
  };

  return (
    <>
      {/* Modal for simple info texts */}
      <Modal
        open={infoModalOpen}
        noContentPadding
        headline={intl.formatMessage(messages.notifications)}
        onRequestClose={() => {
          setInfoModalOpen(false);
          setInfoModalHeadline('');
          setInfoModalText('');
          setInfoModalBenefits(null);
        }}
        testId="info-modal"
        testIdClose="info-modal-close"
      >
        <ModalContentWrapper>
          <ModalHeadline textAlign="center" level={4}>
            {infoModalHeadline}
          </ModalHeadline>
          <GreyBox>
            {infoModalText}
            {infoModalBenefits && (
              <InfoModalBenefits data-testid="infoModal-benefits">
                {infoModalBenefits}
              </InfoModalBenefits>
            )}
          </GreyBox>
        </ModalContentWrapper>
      </Modal>

      {/* Modal for styled service mail info text */}
      <Modal
        open={serviceMailModalOpen}
        noContentPadding
        headline={intl.formatMessage(messages.notifications)}
        onRequestClose={() => {
          setServiceMailModalOpen(false);
        }}
        testId="service-modal"
        testIdClose="service-modal-close"
      >
        <ModalContentWrapper>
          <ModalHeadline textAlign="center" level={4}>
            {intl.formatMessage(messages.serviceMail)}
          </ModalHeadline>
          <GreyBox>
            <div>
              <strong>
                <FormattedMessage
                  id="AccountSettings.ServiceMailInfo.Headline"
                  defaultMessage="Wir halten Sie per Mail über alle Themen rund um Ihre Bestellung auf dem Laufenden. So entgeht Ihnen nichts. Sie erhalten z.B."
                />
              </strong>
            </div>
            <ul>
              {[...Array(10).fill(undefined)].map((_v, i) => {
                /* Not the best solution but maybe the only way to access messages dynamically */
                const text = intl.formatMessage(
                  (messages as { [key: string]: MessageDescriptor })[`serviceMailInfotext${i}`],
                );
                if (text !== '-') {
                  // exception because the array would not change
                  // eslint-disable-next-line react/no-array-index-key
                  return <li key={i}>{text}</li>;
                }
                return null;
              })}
            </ul>
          </GreyBox>
        </ModalContentWrapper>
      </Modal>
      <StyledHeadline level={2} stylingLevel={4} isMobile={isMobile}>
        <FormattedMessage id="AccountSettings.Headline" defaultMessage="Einstellungen" />
      </StyledHeadline>
      <GreyBox>
        <Card>
          {/* Autologin */}
          <CardHead>
            <FormattedMessage
              id="AccountSettings.Autologin"
              defaultMessage="automatische Anmeldung"
            />
          </CardHead>
          <HR className="noMargin" />
          <CardContent>
            <ToggleLayoutWrapper className="wrapper">
              <div>
                <div>
                  <strong>{intl.formatMessage(messages.autologin)}</strong>
                </div>
                <div>
                  <FormattedMessage
                    id="AccountSettings.Autologin.Text"
                    defaultMessage="Beim nächsten Besuch erkennen wir Sie wieder und Sie müssen sich nicht jedes Mal neu anmelden."
                  />
                </div>
              </div>
              <Toggle
                isUncontrolled
                selected={autologin || false}
                onToggle={() => saveSetting({ which: 'autologin', selected: !autologin })}
              />
            </ToggleLayoutWrapper>
          </CardContent>
        </Card>
        {(serviceMailEnabled ||
          newsletterEnabled ||
          eInvoiceEnabled ||
          postalBankStatementEnabled ||
          smsNotificationEnabled) && (
          <>
            <Space />
            <Card>
              <CardHead>{intl.formatMessage(messages.notifications)}</CardHead>
              <HR className="noMargin" />
              <CardContent>
                {serviceMailEnabled && (
                  <>
                    {/* Service-Infos */}
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.serviceMail)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.ServiceMail.Text"
                            defaultMessage="So entgehen Ihnen keine Informationen über Ihre Bestellungen."
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setServiceMailModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={serviceMail || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'serviceMail',
                            selected: !serviceMail,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                    <HR />
                  </>
                )}
                {eInvoiceEnabled && (
                  <>
                    {/* eRechnung */}
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.eInvoice)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.eInvoice.Text"
                            defaultMessage="Entscheiden Sie sich für die Umwelt und gegen Papierrechnungen."
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setInfoModalHeadline(intl.formatMessage(messages.eInvoice));
                            setInfoModalText(intl.formatMessage(messages.eInvoiceInfotext));
                            setInfoModalBenefits(
                              intl.formatMessage(messages.eInvoiceInfoBenefits, {
                                ul: /* istanbul ignore next */ (msg: string) => <ul>{msg}</ul>,
                                li: /* istanbul ignore next */ (msg: string) => <li>{msg}</li>,
                                strong: /* istanbul ignore next */ (msg: string) => (
                                  <strong>{msg}</strong>
                                ),
                              }),
                            );
                            setInfoModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={eInvoice || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'eInvoice',
                            selected: !eInvoice,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                    <HR />
                  </>
                )}
                {postalBankStatementEnabled && (
                  <>
                    {/* postal bank statement */}
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.postalBankStatement)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.PostalBankStatement.Text"
                            defaultMessage="Lasse dir Kontoauszüge per Post zuschicken (zzg. 1,50 €)"
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setInfoModalHeadline(intl.formatMessage(messages.postalBankStatement));
                            setInfoModalText(
                              intl.formatMessage(messages.postalBankStatementInfotext),
                            );
                            setInfoModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={postalBankStatement || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'postalBankStatement',
                            selected: !postalBankStatement,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                    <HR />
                  </>
                )}
                {smsNotificationEnabled && (
                  <>
                    {/* SMS Notifications */}
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.smsNotification)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.SmsNotification.Text"
                            defaultMessage="Erhalte Benachrichtigungen per SMS"
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setInfoModalHeadline(intl.formatMessage(messages.smsNotification));
                            setInfoModalText(intl.formatMessage(messages.smsNotificationInfotext));
                            setInfoModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                        {smsNotifications && (
                          <>
                            <br />
                            {mobilePhoneNumber && (
                              <div>
                                <FormattedMessage
                                  id="AccountSettings.SmsNotification.MobilePhoneNumber"
                                  defaultMessage="Gewählte Handynummer:{break}{phoneNumber}"
                                  values={{ phoneNumber: mobilePhoneNumber, break: <br /> }}
                                />
                              </div>
                            )}
                            <Space />
                            <div>
                              <Button
                                layout="default"
                                data-testid="accountsettings-phonemodal-button"
                                onClick={() => {
                                  setPhoneModalOpen(true);
                                }}
                                uppercase={false}
                              >
                                {mobilePhoneNumber ? (
                                  <FormattedMessage
                                    id="AccountSettings.SmsNotification.ChangeMobileNumber"
                                    defaultMessage="Handynummer ändern"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="AccountSettings.SmsNotification.AddMobileNumber"
                                    defaultMessage="Handynummer hinzufügen"
                                  />
                                )}
                              </Button>
                            </div>
                            <Modal
                              open={phoneModalOpen}
                              headline=""
                              onRequestClose={() => {
                                setPhoneModalOpen(false);
                              }}
                              testId="accountsettings-phonemodal"
                              testIdClose="accountsettings-phonemodal-close"
                              noContentPadding
                            >
                              <div>
                                <PhoneBook />
                              </div>
                            </Modal>
                          </>
                        )}
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={smsNotifications || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'smsNotifications',
                            selected: !smsNotifications,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                    <HR />
                  </>
                )}
                {newsletterEnabled && (
                  <>
                    {/* Newsletter */}
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.newsletter)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.Newsletter.Text"
                            defaultMessage="Sichern Sie sich News zu Trends, Aktionen und inspirierende Ideen."
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setInfoModalHeadline(
                              intl.formatMessage(messages.newsletterHeadlineModal),
                            );
                            setInfoModalBenefits(
                              intl.formatMessage(messages.newsletterInfotext, {
                                ul: /* istanbul ignore next */ (msg: string) => <ul>{msg}</ul>,
                                li: /* istanbul ignore next */ (msg: string) => <li>{msg}</li>,
                                strong: /* istanbul ignore next */ (msg: string) => (
                                  <strong>{msg}</strong>
                                ),
                                break: <br />,
                              }),
                            );
                            setInfoModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={newsletter || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'newsletter',
                            selected: !newsletter,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                    <HR />
                  </>
                )}
              </CardContent>
            </Card>
            {neighboursDeliveryEnabled && (
              <>
                <Space />
                <Card>
                  <CardHead>
                    <FormattedMessage id="AccountSettings.Delivery" defaultMessage="Zustellung" />
                  </CardHead>
                  <CardContent>
                    <ToggleLayoutWrapper className="wrapper">
                      <div>
                        <div>
                          <strong>{intl.formatMessage(messages.neighboursDelivery)}</strong>
                        </div>
                        <div>
                          <FormattedMessage
                            id="AccountSettings.NeighboursDelivery.Text"
                            defaultMessage="In deiner Abwesenheit kannst du Pakete auch an Nachbarn zustellen lassen"
                          />
                        </div>
                        <MoreLink
                          onClick={() => {
                            setInfoModalHeadline(intl.formatMessage(messages.neighboursDelivery));
                            setInfoModalText(
                              intl.formatMessage(messages.neighboursDeliveryInfotext),
                            );
                            setInfoModalOpen(true);
                          }}
                        >
                          {intl.formatMessage(messages.moreInformation)}
                        </MoreLink>
                      </div>
                      <Toggle
                        isUncontrolled
                        selected={neighboursDelivery || false}
                        onToggle={() =>
                          saveSetting({
                            which: 'neighboursDelivery',
                            selected: !neighboursDelivery,
                          })
                        }
                      />
                    </ToggleLayoutWrapper>
                  </CardContent>
                </Card>
              </>
            )}
          </>
        )}
      </GreyBox>
    </>
  );
};

export default AccountSettings;
