import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './messages';
import BorderedPanel from '@empiriecom/mybuy-components/components/BorderedPanel';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import SeperatedText from '@empiriecom/mybuy-components/components/SeperatedText';
import QRPaymentAction from '@empiriecom/mybuy-components/components/QRPayment';

const HeadLine = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Line = styled.div`
  text-align: center;
  margin-top: 0.2rem;
`;

const SeperationSpacing = styled(SeperatedText)`
  margin: 0.75rem auto;
`;

const QRContent = styled.div`
  text-align: left;
  font-size: 0.813rem;
`;

const QRAction = styled(QRPaymentAction)`
  justify-content: flex-start;
  margin: 0.5rem 0;
`;

const AccountInformationWrapper: FC<{ contentOnly?: boolean }> = ({
  contentOnly,
  children,
}): JSX.Element => {
  return contentOnly ? <div>{children}</div> : <BorderedPanel>{children}</BorderedPanel>;
};

const AccountInformation: FC<{
  contentOnly?: boolean;
  headline?: string;
  showQRPayment?: boolean;
  className?: string;
}> = ({ contentOnly = false, headline, showQRPayment, className }): JSX.Element => {
  const intl = useIntl();
  const { customer } = useCustomerContext() || {};
  const config = useConfig<FragmentConfig>();

  const IbanLine: JSX.Element = (
    <Line>
      {intl.formatMessage(messages.iban, { split: (...chunks: string[]) => <>{chunks}</> })}
    </Line>
  );

  return (
    <div className={className}>
      <AccountInformationWrapper contentOnly={contentOnly}>
        <HeadLine>{headline || intl.formatMessage(messages.headline)}</HeadLine>
        <Line>{intl.formatMessage(messages.receiverTitle)}</Line>
        <Line>{intl.formatMessage(messages.receiverName)}</Line>
        <Line>
          {intl.formatMessage(messages.recieverAdditionals, {
            break: <br />,
          })}
        </Line>
        {config.show.ibanBeforeBic && IbanLine}
        {config.show.bic && (
          <Line>
            {intl.formatMessage(messages.bic, { split: (...chunks: string[]) => <>{chunks}</> })}
          </Line>
        )}
        {!config.show.ibanBeforeBic && IbanLine}
        <Line data-mf-replace-inner="**REMOVED**">
          {intl.formatMessage(messages.subject, {
            bankTransferReference: customer?.bankTransferReference,
            strong: (...chunks: string[]) => <strong>{chunks}</strong>,
          })}
        </Line>
      </AccountInformationWrapper>
      {config.show.qrInvoice && showQRPayment && (
        <QRContent>
          <SeperationSpacing>
            <FormattedMessage defaultMessage="ODER" id="AccountInformation.qrPayment.Or" />
          </SeperationSpacing>
          <div>
            <FormattedMessage
              defaultMessage="Nutzen Sie den QR-Zahlschein über den unten stehenden Link:"
              id="AccountInformation.qrPayment.infoText"
            />
          </div>
          <QRAction />
        </QRContent>
      )}
    </div>
  );
};

export default AccountInformation;
