import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Image from '@empiriecom/module-components/Image';
import Card, {
  HeadlineElement,
  SubHeadlineElement,
  CardSeperator,
  CardAction,
} from '@empiriecom/module-components/Card';
import Slider from '@empiriecom/module-components/Slider';
import getIcon from '@empiriecom/module-components/utils/getIcon';
import { FormattedCurrency } from '@empiriecom/module-components/FormattedCurrency';
import { SliderSettings } from '@empiriecom/module-components/Slider/types';
import { BookingListBookingList } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/BookingListBookingList';
import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import useAccountApi from '@empiriecom/mybuy-components/api/useAccountApi';
import { isClientSide } from '@empiriecom/mybuy-components/utils/EnvironmentTest';
import { ImageListData } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1/models/ImageListData';
import { NoArticleFallbackImage } from '@/src/components/icons/NoArticleFallback';
import { QRIcon } from '@empiriecom/mybuy-components/components/image/QRIcon';
import {
  useQRPaymentAction,
  messages as qrMessages,
} from '@empiriecom/mybuy-components/components/QRPayment';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';

const BillingIcon = getIcon('Billing');

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 1rem;
`;

const CardContentWrapper = styled.div`
  font-size: 0.812rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  display: block;
  padding: 0.5rem;

  > div {
    margin-bottom: 0;
  }
`;

const SlideImage = styled.div`
  display: block;
  max-width: 7rem;
  min-height: 7rem;
`;

const SlideText = styled.div`
  hyphens: auto;
  overflow: hidden;
`;

const Price = styled.div<{ negative: boolean }>`
  font-weight: bold;
  font-size: 0.85rem;
  ${({ theme, negative }) => negative && `color: ${theme.error.color.normal}`}
`;

const BookingListElement: React.FC<{
  entry: BookingListBookingList;
  imageData?: ImageListData[];
  onEInvoiceOpen?: (unifiedInvoiceId: string) => void;
}> = ({ entry, imageData, onEInvoiceOpen }) => {
  const { formatMessage } = useIntl();
  const { isLoading, ecLocale } = useCustomerContext();
  const { openQRPayment } = useQRPaymentAction();
  const config: FragmentConfig = useConfig();
  const [bookingImages, setBookingImages] = useState<ImageListData[]>(imageData || []);

  const { name, date, amount, negative, additionalText, unifiedInvoiceId, itemsId, linkText } =
    entry;
  const realBookingImages: ImageListData[] = imageData || bookingImages;
  const accountApi = useAccountApi();
  const runsOnClientSide: boolean = isClientSide() && !isLoading;

  useEffect(() => {
    const loadBookingImages = async (bookingId: string) => {
      if (accountApi) {
        const bookingImageResult = await accountApi.getBookingImages({
          id: bookingId,
          ecLocale,
        });
        if (bookingImageResult.data) {
          setBookingImages(bookingImageResult.data);
        }
      }
    };

    if (runsOnClientSide && itemsId && !imageData) {
      // noinspection JSIgnoredPromiseFromCall
      loadBookingImages(itemsId);
    }
  }, [runsOnClientSide, accountApi, itemsId, imageData]);

  const priceHeader: React.ReactNode =
    amount && amount.value != null && amount.currencyCode ? (
      <Price negative={negative || false}>
        {!negative && !config.hidePriceSign && '+'}
        <FormattedCurrency
          data-mf-replace-inner="**REMOVED**"
          value={config.hidePriceSign ? Math.abs(amount.value) : amount.value}
          currency={amount.currencyCode}
        />
      </Price>
    ) : null;

  const sliderSettings: SliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <Card elevationLevel="2dp">
      <CardHead>
        <div data-mf-replace-inner="**REMOVED**">
          <HeadlineElement>{name}</HeadlineElement>
          <SubHeadlineElement condensed={false}>
            <FormattedMessage defaultMessage="vom " id="BookingListElement.from" />
            {`${date}`}
          </SubHeadlineElement>
        </div>
        <div>{priceHeader}</div>
      </CardHead>
      <CardSeperator />
      {additionalText && (
        <>
          <CardContentWrapper data-mf-replace-inner="**REMOVED**">
            {additionalText}
          </CardContentWrapper>
        </>
      )}
      {realBookingImages && realBookingImages.length > 0 && (
        <div>
          <CardContentWrapper>
            {realBookingImages && realBookingImages.length > 0 && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Slider {...sliderSettings}>
                {realBookingImages.map((imageEntry) => (
                  <div key={imageEntry.text}>
                    {imageEntry.imageUrl && (
                      <SlideImage data-testid="slide-image">
                        <Image
                          src={imageEntry.imageUrl}
                          alt={imageEntry.text || ''}
                          fallback={<NoArticleFallbackImage />}
                        />
                      </SlideImage>
                    )}
                    {!imageEntry.imageUrl && imageEntry.text && (
                      <SlideText data-testid="product-text">{imageEntry.text}</SlideText>
                    )}
                  </div>
                ))}
              </Slider>
            )}
          </CardContentWrapper>
          <CardSeperator />
        </div>
      )}
      {linkText && unifiedInvoiceId && onEInvoiceOpen && (
        <>
          <div data-mf-replace-inner="**REMOVED**">
            <CardAction
              icon={<BillingIcon size="1.5rem" />}
              text={linkText}
              onClick={() => {
                onEInvoiceOpen(unifiedInvoiceId);
              }}
            />
          </div>
        </>
      )}
      {config.show.qrInvoice && negative && (
        <CardAction
          icon={<QRIcon />}
          text={formatMessage(qrMessages.defaultCTAText)}
          onClick={openQRPayment}
        />
      )}
    </Card>
  );
};

export default BookingListElement;
