import React, { FC } from 'react';
import {
  SkeletonProvider,
  withSkeleton,
} from '@empiriecom/module-components/Skeleton/SkeletonProvider';

import { useCustomerContext } from '@empiriecom/mybuy-components/provider/CustomerProvider';
import ShipmentInfosContainerSkeleton from '@/src/components/ShipmentInfosContainer/skeleton';

import { ShipmentsProvider } from '@/src/components/AccountProvider/ShipmentsProvider';
import ShipmentInfoHeader from '@/src/components/ShipmentInfosContainer/ShipmentInfoHeader';
import ShipmentInfos from '@/src/components/ShipmentInfosContainer/ShipmentInfos';
import { Kim } from '@empiriecom/mybuy-components/components/Kim';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import EventTracking from '../EventTracking';
import useConfig from '@empiriecom/module-components/hooks/useConfig';
import { FragmentConfig } from '@/config/types';
import Notification from '@empiriecom/module-components/Notification';
import VerificationNotification from '../VerificationNotification';
import DelayedDeliveryWarning from '@/src/components/DelayedDeliveryNotification';

const KimWrapper = styled.div`
  padding-top: 2rem;
`;

const messages = defineMessages({
  kimTitle: {
    id: 'ShipmentInfosContainer.Kim.title',
    defaultMessage: 'Wie gefällt Ihnen die Bestellungen und Lieferungen Seite?',
  },
});

const ShipmentInfosContainerContent: FC = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const customer = useCustomerContext();
  const {
    shipmentInfos: { blackFridayNoti },
  }: FragmentConfig = useConfig();
  const { kim }: FragmentConfig = useConfig();

  const kimId = customer.customer?.extra?.kid ? parseInt(customer.customer.extra.kid, 10) : 0;

  return (
    <ShipmentsProvider>
      <section>
        <VerificationNotification />
        {blackFridayNoti && (
          <Notification
            level="warning"
            children={
              <FormattedMessage
                id="blackFridayNotificationText"
                defaultMessage="Aufgrund des sehr hohen Bestelleingangs bitten wir um Verständnis, dass die Zustellung momentan 2-3 Tage länger dauern kann!"
              />
            }
          />
        )}
        <DelayedDeliveryWarning />
        {customer.customer?.customerSpecificHint && (
          <Notification level="warning" isInline>
            {customer.customer?.customerSpecificHint}
          </Notification>
        )}
        <ShipmentInfoHeader />
        <ShipmentInfos />
      </section>
      {kim?.enabled && (
        <KimWrapper>
          <Kim
            i_survey="3__46eb818ef33bf3a77969ee4bd67bdf2a"
            headline={formatMessage(messages.kimTitle)}
            x03={20}
            x05="BL"
            x06={kimId}
          />
        </KimWrapper>
      )}
    </ShipmentsProvider>
  );
};

const ShipmentInfosContainerWithSkeleton = withSkeleton(
  ShipmentInfosContainerContent,
  ShipmentInfosContainerSkeleton,
);

const ShipmentInfosContainer: FC = (): JSX.Element => {
  const { isLoading } = useCustomerContext();
  return (
    <>
      <SkeletonProvider isLoading={isLoading}>
        <EventTracking eventCategory="shipmentInfos" />
        <ShipmentInfosContainerWithSkeleton />
      </SkeletonProvider>
    </>
  );
};

export default ShipmentInfosContainer;
