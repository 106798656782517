import React, { FC } from 'react';
import styled from 'styled-components';
import Skeleton from '@empiriecom/module-components/Skeleton';

const BookingEntrySkeleton = styled(Skeleton)`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const BookingContainerSkeleton: FC = (): JSX.Element => (
  <div data-testid="booking-list-skeleton">
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
    <BookingEntrySkeleton height="5rem" />
  </div>
);

export default BookingContainerSkeleton;
