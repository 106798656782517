import { CustomerStatusShipments } from '@empiriecom/mybuy-frontend-api/backend-mybuy-customer/v1';
import { DataWithLoadingInfo } from '@empiriecom/mybuy-components/provider/CustomerProvider/interfaces';
import useCustomerStatus from './useCustomerStatus';

export type ShipmentsData = DataWithLoadingInfo<CustomerStatusShipments[]> & { news?: number };

export function useShipments(): ShipmentsData {
  const customerStatus = useCustomerStatus();
  return {
    data: customerStatus.data?.shipments,
    news: customerStatus.data?.news?.shipments,
    loading: customerStatus.loading,
  };
}

export default useShipments;
